import { Module, MutationTree } from 'vuex'
import { RootState } from './index'

export interface UiState {
  preventedCounter: number,
}

const state = (): UiState => ({
  // track all opened menus, modals, etc. and enable
  // scroll when ALL hided
  preventedCounter: 0,
})

const mutations: MutationTree<UiState> = {
  scrollToggle (state, value: boolean) {
    value ? scrollEnableMutator(state) : scrollDisableMutator(state)
  },
  scrollDisable: scrollDisableMutator,
  scrollEnable: scrollEnableMutator,
}

function scrollDisableMutator (state: UiState) {
  ++state.preventedCounter
  // should work only for mobiles - why
  if (state.preventedCounter === 1) {
    document.body.classList.add('disable-scroll')
  }
}

function scrollEnableMutator (state: UiState) {
  if (state.preventedCounter !== 0) --state.preventedCounter

  // enable scroll when ALL menus hided
  if (!state.preventedCounter) {
    document.body.classList.remove('disable-scroll')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
} as Module<UiState, RootState>
