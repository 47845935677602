import { formatHistoryTime, parseNumber } from '@/lib/utils'

export class TradeModel {
  id: number
  pair: PairName
  fee_currency: string
  key: string
  total: string
  price: string
  amount: string
  tradeType: TradeType
  time: number
  fee: string
  createdAt: string

  constructor (rawTrade: RawUserTrade, pair?: PairName) {
    this.id = rawTrade.deal_order_id
    this.pair = rawTrade.market || pair
    this.fee_currency = rawTrade.fee_currency
    this.key = `${rawTrade.deal_order_id}:${rawTrade.id}:${rawTrade.side}`
    this.price = parseNumber(rawTrade.price)
    this.amount = parseNumber(rawTrade.amount)
    this.tradeType = (rawTrade.side === 2 ? 'buy' : 'sell')
    this.time = rawTrade.time
    this.fee = rawTrade.fee
    this.createdAt = formatHistoryTime(rawTrade.time * 1000)
    this.total = rawTrade.total
      ? parseNumber(rawTrade.total)
      : parseNumber(String(parseFloat(rawTrade.amount) * parseFloat(rawTrade.price)), 8)
  }
}
