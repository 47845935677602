
<template>
  <div
    :class="classes"
    class="exc-sidebar"
  >
    <div
      class="exc-sidebar__mobile-panel txt--main bg--black pl-20 pr-20 pt-16 pb-16"
      @click="toggleMobileSidebar"
    >
      <div class="exc-sidebar__mobile-arrow">
        <exc-dropdown-triangle
          :direction="mobSidebarToggle ? 'top' : 'bottom'"
        />
      </div>
      <div class="txt txt--uppercase txt--12px ml-4 mr-auto">
        {{ currentItem ? currentItem.name : '' }}
      </div>
    </div>
    <div class="exc-sidebar__container bg--theme-03 pb-32">
      <div
        v-for="item in items"
        :key="item.label"
      >
        <exc-mob-nav-item
          v-if="item.children"
          class="txt--grey"
          :is-menu-group-selected="activeMenuGroup.toLowerCase() === item.value.toLowerCase()"
          @open="onOpenNavItem(item.value)"
          @close="onCloseNavItem(item.value)"
        >
          <template #name="{ isMenuGroupSelected: isActive }">
            <exc-mob-dropdown-item
              :is-submenu-selected="isActive"
              class="exc-sidebar__submenu-name"
            >
              <template
                v-if="item.icon"
                #dot
              >
                <component :is="item.icon" />
              </template>
              <template #name>
                <span class="txt--weight-400">
                  {{ item.name }}
                </span>
              </template>
            </exc-mob-dropdown-item>
          </template>
          <template #dropdown>
            <div class="exc-sidebar__submenu-container">
              <exc-mob-dropdown-item
                v-for="submenu in item.children"
                :key="submenu.value"
                v-bind="{ ...submenu }"
                @click.native="toggleMobileSidebar"
              >
                <template
                  v-if="submenu.icon"
                  #dot
                >
                  <component :is="submenu.icon" />
                </template>
                <template #name>
                  {{ submenu.name }}
                </template>
              </exc-mob-dropdown-item>
            </div>
          </template>
        </exc-mob-nav-item>
        <exc-mob-dropdown-item
          v-else
          v-bind="{ ...item }"
          @click.native="onClickItem(item.value)"
        >
          <template
            v-if="item.icon"
            #dot
          >
            <component :is="item.icon" />
          </template>
          <template #name>
            {{ item.name }}
          </template>
        </exc-mob-dropdown-item>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import ExcMobDropdownItem from '@/components/common/header/parts/ExcMobDropdownItem.vue'
import ExcDropdownTriangle from '@/components/common/svg/ExcDropdownTriangle.vue'
import ExcMobNavItem from '@/components/common/header/parts/ExcMobNavItem.vue'

export default {
  name: 'ExcSidebar',
  components: {
    ExcMobDropdownItem,
    ExcDropdownTriangle,
    ExcMobNavItem,
  },
  props: {
    currentItem: { type: Object, required: true },
    items: { type: Array, required: true },
  },
  data () {
    return {
      mobSidebarToggle: false,
      activeMenuGroup: '',
    }
  },
  computed: {
    classes () {
      return {
        // 'exc-sidebar--hide-on-mobile': this.hideOnMobile,
        'exc-sidebar--open': this.mobSidebarToggle,
      }
    },
  },
  methods: {
    onOpenNavItem (val) {
      this.activeMenuGroup = val
    },
    onCloseNavItem (val) {
      this.activeMenuGroup = val
    },
    onClickItem (val) {
      this.onOpenNavItem(val)
      this.toggleMobileSidebar()
    },
    toggleMobileSidebar () {
      this.mobSidebarToggle = !this.mobSidebarToggle

      this.$emit('toggle', this.mobSidebarToggle)

      if (this.mobSidebarToggle) {
        document.body.classList.add('prevent-scroll') // TODO: remove later when merged
      } else {
        document.body.classList.remove('prevent-scroll') // TODO: remove later when merged
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-sidebar {
  $root: &;
  position: relative;

  $mobilePathBlockHeight: toRem(55px); // toRem(44px);

  @include bp(m) {
    // min-height: toRem(800px);
  }

  // &--hide-on-mobile {
  //   #{$root}__mobile-panel{
  //     display: none;
  //   }
  // }

  &__container {
    right: -100%;
    position: absolute;
    width: 100vw;
    height: calc(100vh - #{$h-Header} - #{$mobilePathBlockHeight});
    top: $mobilePathBlockHeight;
    z-index: 999;
    overflow: auto;
    transition: .3s;

    @include bp(m) {
      position: static;
      min-width: toRem(250px);
      width: auto;
      height: 100%;
    }
  }

  &--open {
    #{$root}__container {
      right: 0;
      transition: .3s;
    }

    // #{$root}__mobile-arrow {
    //   transform: rotate(90deg)
    // }
  }

  &__mobile-panel {

    @include bp(m) {
      display: none !important;
    }
  }

  &__submenu-name {
    transform: translateX(-32px);
    display: inline-block;
  }

  &__mobile-panel {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include bp(m) {
      display: none !important;
    }
  }

  &__mobile-arrow {
    transform: rotate(90deg)
  }
}
</style>
