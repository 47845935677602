import { Module } from 'vuex'

export interface TweetsState {
  fetchStatus: FetchStatus;
  list: Tweet[];
}

const TweetsModule: Module<TweetsState, any> = {
  namespaced: true,
  state: () => ({
    list: [],
    fetchStatus: 'init',
  }),
  mutations: {
    setFetchStatus (state, status) {
      state.fetchStatus = status
    },
    setData (state, tweets) {
      state.list = tweets
      state.fetchStatus = 'ok'
    },
  },
  getters: {
    loading (state) {
      return state.fetchStatus === 'init' || state.fetchStatus === 'loading'
    },
  },
  actions: {
    async init ({ commit, state }) {
      if (state.fetchStatus === 'ok') {
        return
      }

      commit('setFetchStatus', 'loading')

      const { response, errors } = await this.$api.getTweets()

      if (errors) {
        commit('setFetchStatus', 'error')
        console.error(errors)
        return
      }

      commit('setData', parseResponse(response!.data))
    },

  },
}

function parseResponse (tweets: Tweet[]): Tweet[] {
  return tweets.map(({ id, screenName, name, text, createdAt, image }) => ({
    id,
    name,
    createdAt,
    text: text
      // .replace(/\n\s*\n/g, '\n')
      // .replace(/\r?\n/g, "<br />")
      .replace(
        /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+/g, g =>
          `<br /><a href="${g}" target="_blank" rel="noopener">${g}</a>`,
      ),
    screenName,
    image,
    profileLink: `https://twitter.com/${screenName}`,
    link: `https://twitter.com/${screenName}/status/${id}`,
    likeLink: `https://twitter.com/intent/like?tweet_id=${id}`,
    retweetLink: `https://twitter.com/intent/retweet?tweet_id=${id}`,
  }))
}

export default TweetsModule
