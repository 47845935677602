import { Module } from 'vuex'

import { TicketBalanceModel } from '@/lib/data-models/ticket/TicketBalanceModel'
// import { ConfirmationModal } from "@/lib/ConfirmationModal";
import { actionWrapper } from '@/lib/utils'
import { RootState } from '@/store/index'

type TicketInfoType = {
  date: string;
  email: string;
  topic: string;
  text: string;
  language: string;
  category: string;
  status: string;
}

// type TicketsRowType = {
//     date: number;
//     value: string[];
//     id: string;
//     deleted?: boolean;
// };

type Comment = {
  commentId: number;
  username: string;
  text: string;

  // front
  rejected?: boolean;
}

export interface TicketState {
  ticketsRow: GetTicketsListResponse[];
  total: number;
  currentSorting: string;
  currentStatusFilter: string;
  offset: number;

  ticketInfo: TicketInfoType;
  ticketComment: Comment[];
  statuses: string[];
}

const TicketStateModule: Module<TicketState, RootState> = {
  namespaced: true,
  state: () => ({
    ticketsRow: [],
    total: 0,
    currentSorting: '',
    currentStatusFilter: '',
    offset: 0,

    ticketInfo: {} as TicketInfoType,
    ticketComment: [],
    statuses: [],
  }),
  mutations: {
    setTicketsRowNew (state, { response: { records } }) {
      if (records) {
        state.ticketsRow = state.ticketsRow
          .concat(records)

        state.offset = Math.max(state.ticketsRow.length, state.offset)
        // .sort(({createdAt: a}, {createdAt: b}) => (+b) - (+a));
      }
    },
    setCurrentStatusFilter (state, pld) {
      state.currentStatusFilter = pld
    },
    setNewTicketRow (state, { ticket }) {
      state.ticketsRow = [ticket, ...state.ticketsRow]
      state.offset = Math.max(state.ticketsRow.length, state.offset)
      // const ticketFormated = new TicketBalanceModel(ticket)
      // state.ticketsRow = [ ticketFormated, ...state.ticketsRow ]
    },
    setSortedTicketsRowNew (state, { response: { records } }) {
      if (records) {
        state.ticketsRow = records
        state.offset = Math.max(state.ticketsRow.length, state.offset)
      } else {
        // assume records is empty array
        state.ticketsRow = []
      }
    },
    setTotalTicketsCount (state, { response: { total } }) {
      state.total = total
    },
    setCurrentSorting (state, pld) {
      state.currentSorting = pld
    },
    deleteSupportTicket (state, { payload: { id } }) {
      state.ticketsRow = state.ticketsRow.filter(
        item => (+item.id) !== (+id),
      )
    },

    setTicketInfo (state, { info }) {
      state.ticketInfo = info
    },
    setTicketComments (state, { comments }) {
      state.ticketComment = comments
    },
    setTicketComment (state, { comment }) {
      state.ticketComment = [
        ...state.ticketComment,
        comment,
      ]
    },
    setStatuses (state, { response }) {
      state.statuses = response
    },
    revertComment (state, { commentId }) {
      state.ticketComment = state.ticketComment.map((comment) => {
        if (comment.commentId === commentId) {
          return {
            ...comment,
            rejected: true,
          }
        } else {
          return comment
        }
      })
    },
  },
  getters: {
    getTickets (state) {
      return state.ticketsRow.map(t => new TicketBalanceModel(t))
    },
    offset (state) {
      return Math.max(state.ticketsRow.length, state.offset)
    },
    hasMoreTickets (state) {
      return state.total > state.ticketsRow.length
    },

    sentComments (state) {
      return state.ticketComment.filter(comment => !comment.rejected)
    },
  },
  actions: {
    actionGetMoreTickets (...args) {
      return actionWrapper({
        apiRequest: (_, { context: { getters, state } }) => this.$api.getTicketsList({
          sorting: state.currentSorting,
          filter: state.currentStatusFilter,
          offset: getters.offset,
          limit: 50,
        }),
        mutationName: 'setTicketsRowNew',
        successHandler: ({ context: { commit }, response: { response } }) => {
          commit('setTotalTicketsCount', { response })
        },
      }).bind(this)(...args)
    },

    actionGetSortedTickets (...args) {
      return actionWrapper({
        apiRequest: (_, { context: { getters, state } }) => {
          return this.$api.getTicketsList({
            offset: 0,
            limit: Math.max(getters.offset, 50),
            sorting: state.currentSorting,
            filter: state.currentStatusFilter,
          })
        },
        mutationName: 'setSortedTicketsRowNew',
        successHandler: ({ context: { commit }, response: { response } }) => {
          commit('setTotalTicketsCount', { response })
        },
      }).bind(this)(...args)
    },

    actionGetTickets (...args) {
      return actionWrapper({
        apiRequest: (_, { context: { getters } }) => this.$api.getTicketsList({ offset: getters.offset, limit: 50 }),
        mutationName: 'setTicketsRowNew',
        successHandler: ({ context: { commit }, response: { response } }) => {
          commit('setTotalTicketsCount', { response })
        },
      }).bind(this)(...args)
    },

    async actionGetTicket ({ commit }, { id }) {
      const { response, errors, status } = await this.$api.getTicketInfo({ ticketId: id })
      if (status === 400) {
        this.localePath('not-found')
        return
      }

      const languages = await this.$api.getLanguages()

      if (errors || !response || languages.errors || !languages.response) {
        console.error(errors)

        return
      }

      const language = languages.response.find(element => element.code === response[0].userLanguage)

      const info = {
        date: response[0].createdAt, // formatDate(response[0].createdAt * 1000),
        email: response[0].email,
        topic: response[0].topic,
        language: language!.name,
        category: response[0].category,
        status: response[0].status,
        text: response[0].text,
      }
      const comments = response[0].comments

      commit('setTicketInfo', { info })
      commit('setTicketComments', { comments })
    },
    statusInitialization (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getTicketSatuses(),
        mutationName: 'setStatuses',
      }).bind(this)(...args)
    },
    deleteTicket (...args) {
      return actionWrapper({
        apiRequest: ({ id }) => this.$api.deleteTicket({ ticketId: id }),
        mutationName: 'deleteSupportTicket',
        successHandler: () => this.$router.push('/support'),
      }).bind(this)(...args)
    },
  },
}

export default TicketStateModule
