// import { parseNumber } from '@/lib/utils'
import Decimal from 'decimal.js-light'
// import store from '@/store'

let store
// if (process.browser) {
//   window.onNuxtReady(({ $store }) => {
//     store = $store
//   })
// }

export interface LendingApplicationItemInterface {
  amount: string
  currency: Ticker
  days_from: number
  days_to: number
  // free_amount: string
  amount_from: string
  amount_to: string
  free_amount: string
  id: number
  percent_from: string
  percent_to: string
  status: number
  username: string
  lvt: object
}

export class LendingApplicationItem {
  amount: string
  currency: string
  currencyBySlash: string
  daysFrom: number
  daysTo: number
  // freeAmount: string
  amountFrom: number
  amountTo: number
  freeAmount: number
  id: number
  percentFrom: number
  percentTo: number
  status: number
  username: string
  lvt: object
  lvtBySlash: string
  belongsToCurrentUser: boolean

  constructor (obj: LendingApplicationItemInterface) {
    const currencies = obj.currency.split(',')

    this.amount = obj.amount
    this.currency = obj.currency
    this.currencyBySlash = obj.currency
      .split(',')
      .filter(k => obj.lvt[k])
      .join(' / ')
    this.daysFrom = obj.days_from
    this.daysTo = obj.days_to
    this.freeAmount = parseFloat(new Decimal(obj.free_amount).toFixed(2))
    this.amountFrom = parseFloat(new Decimal(obj.amount_from).toFixed(2))
    this.amountTo = parseFloat(new Decimal(obj.amount_to).toFixed(2))
    this.id = obj.id
    this.percentFrom = parseFloat(obj.percent_from)
    this.percentTo = parseFloat(obj.percent_to)
    this.status = obj.status
    this.username = obj.username
    this.lvt = obj.lvt
    this.lvtBySlash =
      currencies
        .filter(o => obj.lvt[o])
        .map(t => parseFloat(obj.lvt[t]))
        .join(' / ') + ' %'

    const currentUserName = store.state.auth.user.nickname
    // console.log(currentUserName)
    this.belongsToCurrentUser = this.username === currentUserName
    // console.warn('LendingApplicationItemInterface', obj, this)
  }
}
