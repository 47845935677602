import { Auth } from '@nuxtjs/auth-next/dist/runtime'
import cloneDeep from 'lodash/cloneDeep'

export enum EAuthSchemeType {
  BASIC = 'BASIC',
  DEVICE = 'DEVICE',
  TWO_FA = 'TWO_FA',
  DEVICE_AND_TWO_FA = 'DEVICE_AND_TWO_FA'
}

export enum ECookiesKeys {
  REMEMBER_ME = 'remember_me',
  SOCKET_TOKEN = 'socketToken'
}

export const commonStrategyProps = {
  token: {
    property: 'tokens.access_token',
    maxAge: 1800,
    global: true,
    // type: 'Bearer'
  },
  refreshToken: {
    property: 'tokens.refresh_token',
    data: 'refresh_token',
    maxAge: 60 * 60 * 24 * 30,
    // maxAge: 60 * 10,
  },
  user: {
    property: false,
    autoFetch: true,
  },
  endpoints: {
    refresh: {
      url: '/api/v1/auth/refresh-token',
      method: 'post',
    },
    user: {
      url: '/v2/settings/general',
      method: 'get',
    },
    logout: {
      url: '/api/v1/auth/logout',
      method: 'post',
    },
  },
  // autoLogout: true,
}

export async function wrapForResponse (promise: Promise<ReturnType<typeof Auth['prototype']['loginWith']>>) {
  const response = await promise
  // debugger
  if (!response) return null
  // TODO: fix this variations on backend
  if (response && !response.data) return response
  return response.data
}

/**
 * In response when data comes from backend in `response` property, renames it to `data`
 */
export function replaceResponseKeyWithData (resp: any) {
  const transformed = JSON.parse(resp)
  transformed.data = transformed.response
  delete transformed.response
  return transformed
}

/**
 * Renames `data` property to `response`
 */
export function replaceDataKeyWithResponse (resp: any) {
  const transformed = (() => {
    if (typeof resp === 'object') return cloneDeep(resp)
    if (typeof resp === 'string') return JSON.parse(resp)
    throw new Error('What is the type of response ? ' + typeof resp + ' !!!')
  })()
  transformed.response = transformed.data || transformed.response || {} // {} because backend still can return any thing
  transformed.response.errors = transformed.errors
  transformed.response.message = transformed.message || transformed.response?.message
  transformed.response.status = transformed.status

  // For support old response format. TODO: remove in future
  const transformedResponseKeys = [
    // auth and 2fa
    'temp_user_id',
    'email_life_time',
    'life_time',

    // geetest
    '_token',
  ]
  transformedResponseKeys.forEach((key) => {
    if (transformed.response[key]) return
    transformed.response[key] = transformed[key]
  })

  delete transformed.data
  return transformed
}
