import { Context } from '@nuxt/types'

const defaultTranslation = require('@/locales/defaultTranslation.json')

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function localeLoader (ctx: Context, lang: String) {
  return new Promise(function (resolve) {
    try {
      return ctx.app.$axios
        .$get('/v2/locale', { params: { lang } })
        .then((resp: any) => {
          if (resp) {
            replaceNotFoundTranslations(resp)
            resolve(resp)
          } else {
            console.error('WRONG RESPONSE FORMAT OR API ERROR::', resp)
            resolve(defaultTranslation)
          }
        })
        .catch((err: any) => {
          console.error('RESPONSE ERROR GET-SITE-TRANSLATE::', err)
          resolve(defaultTranslation)
        })
    } catch (err) {
      console.error('FAILED $AXIOS GET REQUEST::', err)
      resolve(defaultTranslation)
    }
  })
}

function getValue (resp: any, objVal: any) {
  if (
    typeof objVal === 'object' &&
    objVal !== null &&
    !Array.isArray(objVal) &&
    typeof resp === 'object' &&
    resp !== null
  ) {
    const keysInFallback = Object.keys(objVal)
    const keysInResponse = Object.keys(resp)

    const missingKeys = keysInFallback.filter(k => !keysInResponse.includes(k))
    if (missingKeys.length) {
      // replace missing key values with fallback values
      missingKeys.forEach((k: string) => {
        resp[k] = objVal[k]
      })
    }

    Object.keys(objVal).map((k: string) => {
      return getValue(resp[k], objVal[k])
    })
  } else {
    // there is a string, or it is an array (it's ok)
  }
}

// for missing translations replaces not found keys with fallback (default) english translations
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
function replaceNotFoundTranslations (responseTranslations: any) {
  getValue(responseTranslations, defaultTranslation)
}
