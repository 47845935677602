import Decimal from "decimal.js-light"

// import { Timezone } from "@/declarations/datafeed-api";

// { "response": [{ 
//     "type": "Borrow", 
//     "days": 4, 
//     "price": "123123123.000000000000000000", 
//     "is_checked": 0, 
//     "status": "Reject", 
//     "percent": 5
//     "created_at": { "date": "2019-09-04 14:03:46.000000", "timezone_type": 3, "timezone": "UTC" } 
// }]

export interface TimezoneDateInterface {
    date: string
    timezone_type: number
    timezone: string // Timezone
}

export class TimezoneDate {
    dateMysql: string
    date: Date
    timezoneType: number
    timezone: string // Timezone
    dateMysqlSimple: string
    constructor(o: TimezoneDateInterface) {
        this.dateMysql = o.date
        this.date = new Date(o.date)
        this.timezoneType = o.timezone_type
        this.timezone = o.timezone
        this.dateMysqlSimple = o.date.split('.').slice(0, -1).join('.')
    }
}

export class UserNotificationTypeModel {
    type: string
    name: string
    constructor(type: string) {
        this.type = type
        this.name = type
    }
}
export class UserNotificationStatusModel {
    status: string
    name: string
    constructor(status: string) {
        this.status = status
        this.name = status
    }
}

export interface UserNotificationModelInterface {
    type: string
    days: number
    price: string
    is_checked: number
    status: number
    percent: string
    created_at: TimezoneDateInterface
    loan_id: number
    id_notification: number
}

export class UserNotificationModel {
    type: UserNotificationTypeModel
    days: number
    price: number
    isChecked: boolean
    status: number
    // status: UserNotificationStatusModel
    percent: number
    percentString: string
    createdAt: TimezoneDate
    loanId: number
    id: number
    constructor(o: UserNotificationModelInterface) {
        this.type = new UserNotificationTypeModel(o.type)
        this.days = o.days
        this.price = parseFloat(new Decimal(o.price).toFixed(2)) // o.price
        this.isChecked = !!o.is_checked
        this.status = o.status
        // this.status = new UserNotificationStatusModel(o.status)
        this.percent = parseFloat(new Decimal(o.percent).toFixed(2))
        this.percentString = this.percent + ' %'
        this.createdAt = new TimezoneDate(o.created_at)
        this.loanId = o.loan_id
        this.id = o.id_notification
    }
}