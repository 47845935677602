import { Module } from 'vuex'
import { actionWrapper } from '@/lib/utils'
// import router from '@/router'
import { TradingCompetitionModel } from '@/lib/data-models/trading-competition/TradingCompetitionModel'
import { TradingCompetitionPlanModel } from '@/lib/data-models/trading-competition/TradingCompetitionPlanModel'
import { TradingCompetitionDetailModel } from '@/lib/data-models/trading-competition/TradingCompetitionDetailModel'
import { RootState } from './index'

export interface TradingCompetitionState {
  competitions: TradingCompetitionGroupsResponseItem[],
  plans: { [key in Ticker]: TradingCompetitionPlanResponseItem } | {},
  planDetails: { [key in number]: TradingCompetitionDetailModel } | {},
  history: TradingCompetitionPlanResponseItem[]
}

const TradingCompetitionStateModule: Module<TradingCompetitionState, RootState> = {
  namespaced: true,
  state: () => ({
    competitions: [],
    plans: {},
    planDetails: {},
    history: [],
  }),
  actions: {
    getTradingCompetition (...args) {
      return actionWrapper<TradingCompetitionState>({
        apiRequest: () => this.$api.getCompetitionGroups(),
        mutationName: 'setTradingCompetition',
        cacheTime: 1000 * 60,
      }).bind(this)(...args)
    },

    getTradingCompetitionPlans (...args) {
      actionWrapper<TradingCompetitionState>({
        apiRequest: (ticker: Ticker) => this.$api.getCompetitionPlansForTicker(ticker),
        errorHandler: redirectToNotFound,
        mutationName: 'setPlans',
        cacheTime: 1000 * 60,
      }).bind(this)(...args)
    },

    getTradingCompetitionHistory (...args) {
      return actionWrapper<TradingCompetitionState>({
        apiRequest: () => this.$api.getCompetitionHistory(),
        mutationName: 'setHistory',
        cacheTime: 1000 * 60,
      }).bind(this)(...args)
    },

    getTradingCompetitionPlanDetails (...args) {
      return actionWrapper<TradingCompetitionState>({
        apiRequest: id => this.$api.getCompetitionData(id),
        errorHandler: redirectToNotFound,
        mutationName: 'setPlanDetails',
        cacheTime: 1000 * 60 * 5,
      }).bind(this)(...args)
    },

    updateTradingCompetitionDetails (...args) {
      return actionWrapper<TradingCompetitionState>({
        apiRequest: id => this.$api.getCompetitionData(id),
        mutationName: 'setPlanDetails',
      }).bind(this)(...args)
    },
  },
  mutations: {
    setTradingCompetition (state, { response }) {
      state.competitions = response
      // state.competitions = response.map((d: TradingCompetitionGroupsResponseItem) => new TradingCompetitionModel(d))
    },
    setPlans (state, { payload, response }) {
      state.plans[payload] = response
      // state.plans[payload] = response.map(p => new TradingCompetitionPlanModel(p))
      state.plans = { ...state.plans }
    },
    setHistory (state, { response }) {
      state.history = response
      // state.history = response.map(p => new TradingCompetitionPlanModel(p))
    },
    setPlanDetails (state, { response, payload }) {
      state.planDetails[payload] = response
      // state.planDetails[payload] = new TradingCompetitionDetailModel(response)
      state.planDetails = { ...state.planDetails }
    },
  },
  getters: {
    getTradingCompetitions: (state) => {
      return state.competitions.map(c => new TradingCompetitionModel(c))
    },
    getPlans: state => (ticker: Ticker) => {
      if (!state.plans[ticker]) return null
      return state.plans[ticker].map(p => new TradingCompetitionPlanModel(p))
    },
    getHistory: (state) => {
      return state.history.map(p => new TradingCompetitionPlanModel(p))
    },
    getPlanDetails: state => (id: string) => {
      if (!state.planDetails[id]) return null
      return new TradingCompetitionDetailModel(state.planDetails[id])
    },
  },
}

export default TradingCompetitionStateModule

function redirectToNotFound (_, resp) {
  if (resp.status === 404) this.localePush('/not-found')
}
