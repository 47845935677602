












import Vue from 'vue'

export default Vue.extend({
  name: 'ExcTextWithDots',
  props: {
    text: {
      type: String,
      default: '',
    },
    textClasses: {
      type: String,
      default: '',
    },
  },
})
