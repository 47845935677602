<template>
  <div
    :id="$attrs.id || uniqueId"
    v-click-outside="onClickOutsideOfDropdown"
    :class="classes"
    class="exc-base-text-dropdown"
    @mouseleave="onHoverOnField('mouseleave')"
  >
    <div
      :class="{ 'exc-base-text-dropdown--disabled': disabled }"
      class="exc-base-text-dropdown__container"
      @click="onClickOnField"
      @mouseover="onHoverOnField('mouseover')"
    >
      <div class="exc-base-text-dropdown__form-control txt txt--color-04 txt--16px">
        <!-- for empty option -->
        <span
          v-if="!hasSelectedValue && !$scopedSlots.empty"
          class="pl-12 pr-12 txt txt--14px txt--grey"
        >
          {{ placeholder }}
        </span>

        <slot
          v-if="!hasSelectedValue"
          v-bind="{ size, disabled }"
          name="empty"
        />

        <!-- for selected option(s)  -->
        <slot
          v-if="hasSelectedValue"
          v-bind="{ option: value, size, disabled }"
          name="selected"
        >
          <span
            class="txt txt--26px txt--36px--m txt--height-34px txt--height-44px--m txt--bold txt--color-10"
          >
            {{ value.label }}
          </span>
        </slot>
      </div>

      <exc-input-side-icon
        class="exc-base-text-dropdown__open-icon"
        @click="onClickOnArrow"
      >
        <slot name="icon">
          <exc-dropdown-triangle
            :direction="isOpened ? 'bottom' : 'top'"
            width="34"
            height="auto"
            class="txt--color-04"
          />
        </slot>
      </exc-input-side-icon>
    </div>

    <transition name="fade">
      <div
        v-if="isOpened"
        class="exc-base-text-dropdown__dropdown"
        @mouseover="onHoverOnField('mouseover')"
      >
        <div
          class="exc-base-text-dropdown__options-list bor--radius-20 txt--10px bg--color-01"
        >
          <!-- list of options -->
          <template v-if="outputOptions.length">
            <div
              v-for="option in outputOptions"
              :key="option.id"
              class="exc-base-text-dropdown__option txt--color-04"
              :class="{
                'active': value && option ? value.value === option.value : false,
              }"
              @click="onClickSelectOption(option)"
            >
              <!-- list item -->
              <slot v-bind="{ option, size, disabled }" name="item">
                <div class="exc-base-text-dropdown__option-row">
                  <span class="txt txt--16px txt--height-20px txt--color-04">
                    {{ option.label }}
                  </span>

                  <svg
                    v-if="value && option ? value.value === option.value : false"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M16.3394 6.08347C15.9323 5.72646 15.2993 5.75444 14.9274 6.14906L8.82732 12.6302L5.88029 9.73944C5.49128 9.35927 4.85929 9.35348 4.46629 9.72786C4.07228 10.1013 4.06628 10.712 4.45429 11.0922L8.14329 14.7105C8.3323 14.8958 8.58828 14.999 8.85626 14.999C8.86226 14.999 8.86927 14.999 8.87528 15C9.15128 14.9942 9.41129 14.8803 9.59627 14.6835L16.4073 7.44689C16.7783 7.05124 16.7483 6.44145 16.3394 6.08347Z" fill="#A5A1FF"/>
                  </svg>
                </div>
              </slot>
            </div>
          </template>

          <template v-else>
            <div
              class="exc-base-text-dropdown__option txt txt--grey txt--uppercase txt--center"
            >
              {{ $t('common.noItems') ? $t('common.noItems') : 'no items' }}
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import {
  sizeValidator,
  defaultSize,
  uniqueId,
  onlyThemePropValidator,
} from '@/lib/utils'
import ClickOutside from 'vue-click-outside'
import ExcInputSideIcon from '@/components/common/form/elements/ExcInputSideIcon.vue'
import ExcDropdownTriangle from '@/components/common/svg/ExcDropdownTriangle.vue'
const bool = { type: Boolean, default: false }
export default {
  name: 'ExcBaseDropdown',
  components: {
    ExcInputSideIcon,
    ExcDropdownTriangle,
  },
  directives: {
    ClickOutside,
  },
  props: {
    value: {
      type: [Array, Object],
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
    onlyTheme: onlyThemePropValidator(),
    dropdownOnHover: bool,
    openUp: bool,
    placeholder: { type: String, default: '' },
  },
  data () {
    return {
      isOpened: false,
    }
  },
  computed: {
    uniqueId () {
      return uniqueId('exc-base-text-dropdown_')
    },
    classes () {
      return {
        // 'exc-base-text-dropdown--fixed-width': !this.autoWidth,
        [`exc-base-text-dropdown--${this.size}`]: true,
        'exc-base-text-dropdown--without-arrow': this.withoutArrow,
        'exc-base-text-dropdown--dropdown-on-hover': this.dropdownOnHover,
        'exc-base-text-dropdown--open-up': this.openUp,
        'exc-base-text-dropdown--open-down': !this.openUp,
      }
    },
    hasSelectedValue () {
      return !!this.value
    },
    outputOptions () {
      return this.options
    },
  },
  methods: {
    onClickSelectOption (option) {
      if (!this.isMultiselect) {
        this.$emit('input', option)
        this.$emit('close')
        this.isOpened = false
      }
    },
    onClickOnField () {
      if (!this.disabled) {
        this.isOpened = !this.isOpened
        if (this.isOpened) {
          this.$emit('open')
        }
      }
    },
    onClickOnArrow () {
      if (!this.isOpened) {
        this.$emit('close')
        this.isOpened = false
      } else {
        this.$emit('open')
        this.isOpened = true
      }
    },
    onClickOutsideOfDropdown () {
      if (this.isOpened && !this.disabled) {
        this.$emit('close')
        this.isOpened = false
      }
    },
    onClickOnEmptyOption () {
      if (!this.disabled) {
        this.isOpened = false
        this.$emit('input', false)
      }
    },
    onHoverOnField (action) {
      // mouseover | mouseleave
      // TODO: fix when on mobile user have to click twice
      if (this.dropdownOnHover) {
        if (action === 'mouseover') {
          !this.isOpened && this.$emit('open')
          this.isOpened = true
        } else if (action === 'mouseleave') {
          this.$emit('close')
          this.isOpened = false
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.exc-base-text-dropdown {
  $root: &;
  $optionsHeight: toRem(200px);
  display: flex;
  flex-direction: column;

  &--open-up {
    #{$root}__dropdown {
      order: 1;
    }
    #{$root}__options-list {
      bottom: 1px;
    }
  }

  &--open-down {
    #{$root}__dropdown {
      order: 3;
    }
    #{$root}__options-list {
      top: 1px;
    }
  }

  &__container {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    order: 2;
    position: relative;
    z-index: 1;
  }

  &__form-control {
    display: flex;
    align-items: center;
    width: calc(100% - 42px);
  }

  &__open-icon {
    margin-left: toRem(6px);
    display: flex;

    @include bp(m) {
      margin-left: toRem(8px);
    }
  }

  &__dropdown {
    position: relative;
    z-index: 100;
  }

  &__options-list {
    position: absolute;

    width: fit-content;
    margin-left: auto;

    // this is when it has border
    // top: 1px;
    left: -1px;
    right: -1px;

    height: auto;
    max-height: $optionsHeight;
    overflow-y: auto;
    z-index: 100;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.3);
    @include webkit-scroll();

    ::-webkit-scrollbar-track, &::-webkit-scrollbar-track {
      margin: toRem(20px) 0 toRem(10px) 0;
    }
  }

  &__option {
    cursor: pointer;
    transition: all 0.2s;
    padding: toRem(10px) toRem(16px);

    &:hover {
      background-color: color(main-transp);
      color: color(main);
    }

    &.active {
      background-color: rgba(165, 161, 255, 0.1);
    }

    &-row {
      display: flex;
      flex-direction: row;
      gap: toRem(14px);
      align-items: center;
    }
  }

  $sizes: (
    xl: (),
    l: (),
    m: (),
    s: (),
    xs: (),
  );

  @each $size, $params in $sizes {
    &--#{$size} {
      #{$root}__open-icon {
        padding-right: toRem(map-get($uiKitSizes, $size) / 3);
      }
    }
  }
}
</style>
