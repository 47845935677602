<template>
  <span
    v-if="showTooltip && content && showTooltipOnSmallScreen"
    class="BaseToolTip txt txt--14px txt--height-18px txt--color-04"
    :style="{left: `${xPositionRegardingTarget - x}px`, top:`${yPositionRegardingTarget - y}px` }"
    :class="classes"
    v-html="content"
  />
</template>

<script>

export default {
  // is used on old Trade page
  name: 'BaseToolTip',
  props: {
    trigger: {
      type: String,
      default: 'mouseover',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    xPositionRegardingTarget: {
      type: Number,
      default: 0,
    },
    yPositionRegardingTarget: {
      type: Number,
      default: 0,
    },
    alignment: {
      type: Object,
      default: () => null,
    },
    targetRect: {
      type: Object,
      default: () => null,
    },
    content: {
      type: String,
      default: '',
    },
    invertAlignmentHorizontal: {
      type: Function,
      default: () => null,
    },
    invertAlignmentVertical: {
      type: Function,
      default: () => null,
    },
    mainStyle: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      x: 0,
      y: 0,
      arrowWidth: 8,
      showTooltipOnSmallScreen: true,
    }
  },
  computed: {
    classes () {
      return [
        this.arrowPointerClass,
        { 'BaseToolTip--main': this.mainStyle },
      ]
    },
    arrowPointerClass () {
      const { target: { horizontal, vertical } } = this.alignment
      switch (horizontal) {
        case 1:
          return 'right'
        case 2:
          switch (vertical) {
            case 1:
              return 'bottom'
            case 3:
              return 'top'
            default:
              return ''
          }
        case 3:
          return 'left'
        default:
          return 'left'
      }
    },
  },
  updated () {
    this.showTooltipOnSmallScreen = window.innerWidth > 768
    if (this.showTooltip && this.content && this.showTooltipOnSmallScreen) {
      this.x = this.xComponentPosition()
      this.y = this.yComponentPosition()
    }
  },
  methods: {
    xComponentPosition () {
      if (!this.showTooltip && this.content) {
        return 0
      }
      const { width, x } = this.$el.getBoundingClientRect()
      const { overlay: { horizontal } } = this.alignment
      if (x < 0 || (window.innerWidth - (x + width)) < 10) {
        this.invertAlignmentHorizontal()
      }

      switch (horizontal) {
        case 1:
          return width + this.arrowWidth
        case 2:
          return (width / 2)
        case 3:
          return 0 - this.arrowWidth
      }
    },
    yComponentPosition () {
      if (!this.showTooltip && this.content) {
        return 0
      }
      const { height, y } = this.$el.getBoundingClientRect()
      const { overlay: { vertical } } = this.alignment

      if ((this.targetRect.y + height) < 0 || window.innerHeight < (y + height)) {
        this.invertAlignmentVertical()
      }

      switch (vertical) {
        case 1:
          return height - pageYOffset + this.arrowWidth
        case 2:
          return (height / 2) - pageYOffset
        case 3:
          return 0 - pageYOffset - this.arrowWidth
      }
    },
  },
}
</script>

<style scoped lang="scss">
$arrowWidth: 8px;

.BaseToolTip {
  position: absolute;
  z-index: $z-tooltip;
  background: color('color-01');
  border-radius: 8px;
  text-align: center;
  display: inline-block;
  padding: toRem(10px) toRem(10px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.15);
  font-size: 12px;

  --arrow-color: #{color('color-01')};

  &--main {
    color: color(color-01);
    background: color(main);
    border-radius: toRem(100px);
    font-size: toRem(14px);

    --arrow-color: #{color(main)};
  }

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
  }

  &.left::before {
    right: 100%;
    border-top: $arrowWidth solid transparent;
    border-right: $arrowWidth solid var(--arrow-color);
    border-bottom: $arrowWidth solid transparent;
  }

  &.right::before {
    left: 100%;
    border-top: $arrowWidth solid transparent;
    border-left: $arrowWidth solid var(--arrow-color);
    border-bottom: $arrowWidth solid transparent;
  }

  &.left::before,
  &.right::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.top::before {
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -8px;
    border-left: $arrowWidth solid transparent;
    border-right: $arrowWidth solid transparent;
    border-bottom: $arrowWidth solid var(--arrow-color);
  }

  &.bottom::before {
    bottom: -8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-left: $arrowWidth solid transparent;
    border-right: $arrowWidth solid transparent;
    border-top: $arrowWidth solid var(--arrow-color);
  }
}
</style>
