<template>
  <!-- eslint-disable vue/valid-v-on -->
  <!-- eslint-disable vue/no-parsing-error  -->
  <textarea
    :value="value"
    v-bind="$attrs"
    :class="classes"
    class="base-textarea bor--all bor--transp-02 bor--radius-25 txt txt--14px txt--color-04 txt--weight-500"
    v-on="{ ...$listeners, input: ev => $emit('input', ev.target.value) }"
  />
</template>

<script>
import { boolType } from '@/lib/utils'

export default {
  name: 'BaseTextarea',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    /* eslint-disable vue/require-default-prop */
    resizable: boolType(false),
    withoutBorder: boolType(false),
    hasError: boolType(false),
    hasSuccess: boolType(false),
  },
  computed: {
    classes () {
      return {
        'base-textarea--resizable': this.resizable,
        'bor--all bor--radius-25 txt txt--14px txt--color-04': true,
        'bor--transp-02': !this.withoutBorder,
        'bor--transp sha--none base-input--without-border': this.withoutBorder,
        'bor--red': this.hasError,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
textarea {
  width: 100%;
  background-color: transparent;
  resize: none;
  padding: toRem(10px) toRem(16px);
  height: toRem(70px);

  &:focus:not(:disabled) {
    // TODO: refactor to mixin and add everywhere
    box-shadow: inset 0 0 0 1px color(main);
    outline: none;
  }

  &.base-textarea--resizable {
    resize: initial;
  }
}

textarea:disabled {
  color: inherit;
  opacity: .5;
  cursor: default;
  background-color: transparent;
}

textarea::-webkit-outer-spin-button,
textarea::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  // -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: transparent;
}
</style>
