import { DEFAULT_LIMIT } from '@/lib/api'
import { actionWrapper } from '@/lib/utils'
import { RootState } from '@/store'
// import { ProfileModules } from "@/store/profile";
import {
  // commonProfileGetters,
  commontSortComparator,
  // mergeStacks,
  // parseRawOrders,
  // parseRawOrdersByAllPairs,
} from '@/store/profile/helpers'
import { Module } from 'vuex'
// import { Map, Stack } from "immutable";
import { OrderModel } from '@/lib/data-models/orders/OrderModel'

export type FinishedOrdersMap = { [key in PairName]: Order[] }
// export type FinishedOrdersMap = Map<PairName, Stack<Order>>;

export interface FinishedOrdersState {
  allEntries: Order[]
  showLoadMore: boolean
}

const FinishedOrdersModule: Module<FinishedOrdersState, RootState> = {
  namespaced: true,
  state: () => ({
    allEntries: [],
    showLoadMore: false,
  }),
  mutations: {
    resetData (state) {
      state.allEntries = []
      state.showLoadMore = false
    },

    setShowLoadMore (state, pld: boolean) {
      state.showLoadMore = pld
    },

    setAllFinishedOrders (state: FinishedOrdersState, pld: Order[]) {
      state.allEntries = pld

      state.showLoadMore = !!pld.length
    },

    addFinishedOrders (state: FinishedOrdersState, pld: Order[]) {
      if (Array.isArray(pld)) {
        state.allEntries = state.allEntries.concat(
          pld.sort(commontSortComparator),
        )
      } else {
        state.allEntries.unshift(pld)
      }
    },
  },
  actions: {
    fetchFinishedOrders (...args) {
      return actionWrapper({
        beforeRequestHandler: ({ payload }) =>
          (payload.offset = (payload.page - 1) * DEFAULT_LIMIT),
        apiRequest: ({ offset, ...params }) =>
          this.$api.$getFinishedOrders({
            offset,
            limit: DEFAULT_LIMIT,
            ...params,
          }),
        mutationName: '',
        successHandler: ({
          context: { commit },
          response: { response },
          payload: { page },
        }) => {
          const { records } = response

          const formatedRecords = records.length
            ? response.records.map(order => new OrderModel(order))
            : []

          if (page > 1) {
            commit('addFinishedOrders', formatedRecords)
          } else {
            commit('setAllFinishedOrders', formatedRecords)
          }

          const length = records.length

          if (length === 0 || length < DEFAULT_LIMIT) {
            commit('setShowLoadMore', false)
          } else {
            commit('setShowLoadMore', true)
          }
        },
      }).bind(this)(...args)
    },
    updateFinishedOrdersSockets ({ commit }, payload) {
      if (payload[0] === 3) commit('addFinishedOrders', new OrderModel(payload[1]))
    },
  },
  getters: {
    getAllEntries (state) {
      return state.allEntries
    },
  },
}

export default FinishedOrdersModule
