import Decimal from 'decimal.js-light'
// import { $t } from '@/lib/i18n'

export class StakingPoolItemModel {
  isWithdrawalTimer: boolean
  withdrawalBlockTimer: Date
  withdrawalUnblockTimer: Date
  logo: string
  action: string // "Invest only"
  coin: string // "Bitcoin"
  id: number // 11
  investBoxStartTime: Date // "12.07.2020 00:00"
  isBlockedDeposit: boolean // блокировка депозита в стейкинг
  isBlockedWithdrawal: boolean // блокировка вывода из стейкинга
  maxInvestVal: Decimal // "100.00"
  minInvestVal: Decimal // "0.50"
  percent: string // "10.00"
  period: StakingPoolPlanPeriod // "HOUR"
  periodName: string // "Hour"
  status: StakingPoolPlanStatus // "ACTIVE"
  _statusName: string // "Active"
  ticker: Ticker
  autoWithdrawal: boolean
  userInvestment: number
  userStatus: StatingPoolUserStatus | null

  constructor (o: StakingPlanResponseItem) {
    this.isWithdrawalTimer = !!o.withdrawl_timer
    this.withdrawalBlockTimer = new Date(o.withdrawl_block_timer)
    this.withdrawalUnblockTimer = new Date(o.withdrawl_unblock_timer)
    this.logo = o.logo || '/img/balance/coinDefaultIcon.png'
    this.action = o.action
    this.coin = o.coin
    this.id = o.id
    this.investBoxStartTime = new Date(o.investbox_start_time)
    this.isBlockedDeposit = o.is_blocked_deposit
    this.isBlockedWithdrawal = o.is_blocked_withdrawl
    this.maxInvestVal = new Decimal(o.maxInvest)
    this.minInvestVal = new Decimal(o.minInvest)
    this.percent = o.percent
    this.period = o.period
    this.periodName = o.periodName
    this.status = o.status
    this._statusName = o.statusName
    this.ticker = o.ticker
    this.autoWithdrawal = !!o.autoCancel
    this.userInvestment = o.user_investment || null
    this.userStatus = o.user_status ? o.user_status : null
  }

  get statusName () {
    return `staking.statuses.${this._statusName.toLowerCase()}`
  }

  get maxInvest () {
    return this.maxInvestVal.isZero() ? 'staking.noLimit' : this.maxInvestVal
  }

  get minInvest () {
    return this.minInvestVal.isZero() ? 'staking.noLimit' : this.minInvestVal
  }

  get isStatusActive () {
    return this.status === 'ACTIVE'
  }

  get isStatusFinished () {
    return this.status === 'FINISHED'
  }

  get isStatusHide () {
    return this.status === 'HIDE'
  }

  get isStatusPending () {
    return this.status === 'PENDING'
  }

  get isUserStatusOpen () {
    return this.userStatus === 'OPEN'
  }

  get isUserStatusClose () {
    return this.userStatus === 'CLOSE'
  }

  get statusColor () {
    return this.isStatusActive
      ? 'green'
      : this.isStatusFinished
        ? 'grey'
        : this.isStatusPending
          ? 'orange'
          : 'red'
  }

  get currentTimer () {
    // если withdrawl_timer true
    // и is_blocked_withdrawl true
    // то таймер withdrawl_block_timer

    // если withdrawl_timer true
    // и is_blocked_withdrawl false
    // то таймер withdrawl_unblock_timer

    // если withdrawl_timer false
    // то таймер вообще не отображается.

    if (this.isWithdrawalTimer && this.isBlockedWithdrawal) {
      return {
        label: 'staking.labels.unblockedAfter',
        endTime: this.withdrawalBlockTimer,
      }
    } else if (this.isWithdrawalTimer && !this.isBlockedWithdrawal) {
      return {
        label: 'staking.labels.blockedAfter',
        endTime: this.withdrawalUnblockTimer,
      }
    } else {
      return {
        label: '',
        endTime: new Date(),
      }
    }
  }
}
