import { Module } from 'vuex'
import { actionWrapper } from '@/lib/utils'

export interface CryptoCardsState {
  isOrdered: boolean
}

const CryptoCardsModule: Module<CryptoCardsState, any> = {
  namespaced: true,

  state: () => ({
    isOrdered: false,
  }),

  mutations: {
    SET_CRYPTO_CARDS_STATE (state, { response }) {
      state.isOrdered = response?.isOrdered
    },
  },

  getters: {
  },

  actions: {
    getCardApplicationStatus: actionWrapper<CryptoCardsState>({
      apiRequest () {
        return this.$api.getCardApplicationStatus()
      },
      mutationName: 'SET_CRYPTO_CARDS_STATE',
    }),
  },
}

export default CryptoCardsModule
