import { formatHistoryTime } from '@/lib/utils'
import Decimal from 'decimal.js-light'

export class DepositModel {
  ticker: Ticker
  createdAt: string
  time: number
  currency: string
  amount: string
  status: string
  address: string
  txHash: string | null
  explorerLink: string | null
  fee: number // Decimal
  isDemo: boolean
  image: string

  constructor (rawDeposit: RawUserDepositWithdraw) {
    this.ticker = rawDeposit.ticker
    this.createdAt = formatHistoryTime(rawDeposit.createdAt * 1000)
    this.time = rawDeposit.createdAt
    this.currency = rawDeposit.currency
    this.amount = rawDeposit.amount
    this.status = rawDeposit.status
    this.address = rawDeposit.address
    this.txHash = rawDeposit.txHash
    this.fee = new Decimal(rawDeposit.fee || 0).toNumber()
    this.explorerLink = rawDeposit.explorerLink
    this.image = rawDeposit.image || rawDeposit.logo
    this.isDemo = rawDeposit.ticker.charAt(0) === 'd'
  }
}
