import { Module } from 'vuex'
// import { Map, Stack } from "immutable"; //
// import { Decimal } from "decimal.js";
// import { resetCaptcha } from "@/lib/utils";
import { DEFAULT_LIMIT } from '@/lib/api'
import { RootState } from '@/store'
import { actionWrapper } from '@/lib/utils'
import { CodeModel } from '@/lib/data-models/codes/CodeModel'
// import { float, Form, required, custom } from "@/plugins/validators";
// import { notifyError } from "@/lib/bus";
// import { commontSortComparator, mergeStacks} from "@/store/profile/helpers";

// const pattern = new RegExp(`${window.TRANSCODE_PREFIX}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}[${window.TICKER_LIST.join("|")}]{3,}$`, "i");

interface CodeEntry {
  data: RawCode[];
  // data: Stack<RawCode>;
  loadedAll: boolean;
}

export type CodesMap = { [key in Ticker | 'All']: CodeEntry };

// export type CodesMap = Map<Ticker | "All", CodeEntry>;

export interface CodesState {
  // fetchStatus: FetchStatus;
  // initialized: boolean;
  data: CodesMap | {};
  // data: CodesMap;
  allEntries: any;

  // showModal: boolean;
  myCodes: RawCode[];
  showLoadMore: boolean;
  // loading: boolean;
}

const CodesModule: Module<CodesState, RootState> = {
  namespaced: true,
  state: () => ({
    // fetchStatus: "init",
    // initialized: false,
    allEntries: [],
    data: {
      All: {
        loadedAll: false,
        data: [],
      },
    },
    // data: Map(),

    // showModal: false,
    myCodes: [],
    showLoadMore: false,
    // loading: false,
  }),
  mutations: {
    // setFetchStatus(state, fetchStatus) {
    //     state.fetchStatus = fetchStatus;
    //     state.initialized = state.initialized || (fetchStatus === "ok" || fetchStatus === "error");
    // },
    // setLoadedAllCodes(state, { ticker }) {
    //     state.data[ticker || 'All']['loadedAll'] = true;
    //     state.data = { ...state.data }
    //     // state.data = state.data.setIn([ticker || "All", "loadedAll"], true);
    // },
    // openModal(state) {
    //     state.showModal = true;
    // },
    // closeModal(state) {
    //     state.showModal = false;
    // },
    // setMyCodes(state, { response: { records } }) {
    //     const codes = records.map(code => new CodeModel(code))
    //     state.myCodes = codes;
    // },
    updateCodes (state: CodesState, { response: { records }, payload: { ticker } }) {
      // const stack = records.map(code => new CodeModel(code))
      state.data[ticker || 'All'].data = records // TODO: make sure path exists
      state.data = { ...state.data }
      // state.data = state.data.updateIn([ticker || "All", "data"], prev =>
      //     mergeStacks<RawCode>(prev, stack, t => `${t.ticker}${t.amount}${t.date}`, commontSortComparator)
      // );

      // state.initialized = state.initialized || ticker === "All";
    },

    // New mutations
    setCodes (state: CodesState, pld: { formatedRecords: DepositWithdrawEntry[], nextPage: boolean }) {
      state.allEntries = pld.formatedRecords
      state.showLoadMore = !!pld.nextPage
    },

    addCodes (state: CodesState, pld: { formatedRecords: DepositWithdrawEntry[], nextPage: boolean }) {
      state.allEntries = [...state.allEntries, ...pld.formatedRecords]
      state.showLoadMore = !!pld.nextPage
    },
    // toggleLoading(state: CodesState, pld: boolean) {
    //     state.loading = pld
    // },

    unsetCode (state: CodesState, { payload: { code } }) {
      state.allEntries = state.allEntries.filter(entry => entry.code !== code)
    },
  },
  getters: {
    getCodesList: (state: CodesState) => state.allEntries.map(c => new CodeModel(c)),
    getMyCodes: (state: CodesState) => state.myCodes.map(c => new CodeModel(c)),
    codesByTicker: state => (ticker) => {
      return state.data[ticker || 'All'].data.map(c => new CodeModel(c))
      // return (state.data.getIn([ticker || "All", "data"]) || Stack()).toArray();
    },
    // fetchedCodesByTicker: (state, getters) => (ticker) => {
    //     return getters.codesByTicker(ticker).length;
    // },
    // codesIsLoaded(state) {
    //     return state.initialized;
    // },
    // codesIncrementalLoading: state => ticker => {
    //     return (
    //         state.initialized &&
    //         !state.data[ticker || "All"]["loadedAll"]
    //         // !state.data.getIn([ticker || "All", "loadedAll"])
    //     )
    // }
  },
  actions: {
    // async init({ state, commit, dispatch }) {
    //     commit("setFetchStatus", "loading");

    //     await Promise.all([
    //         dispatch("fetchMainBalances"),
    //         dispatch("actionKycStatus"),
    //         Promise.all([
    //             dispatch("getCodes", {}),
    //             dispatch("getHistoryCodes", {})
    //         ])
    //     ]);

    //     commit("setFetchStatus", "ok");
    // },

    // We dontt have my codes table after redesign
    getCodes (...args) {
      return actionWrapper({
        apiRequest: ({ ticker }) => this.$api.getMyCodes({ ticker: ticker || '' }),
        mutationName: 'setCodes',
      }).bind(this)(...args)
    },
    // async getHistoryCodes({ state, commit, getters }, { ticker }) {
    //     if (state.data[ticker || "All"]["loadedAll"]) {
    //     // if (state.data.getIn([ticker || "All", "loadedAll"])) {
    //         return;
    //     }

    //     const { response, errors } = await this.$api.getHistoryCodes({
    //         ticker: ticker || "",
    //         limit: DEFAULT_LIMIT,
    //         offset: getters.fetchedCodesByTicker(ticker)
    //     });

    //     if (errors) {
    //         console.error(errors);
    //         commit("setFetchStatus", "error");
    //         return;
    //     }

    //     const length = response!.records.length;
    //     const total = response!.total;
    //     if (length === 0 || length === total) {
    //         commit("setLoadedAllCodes", {
    //             ticker: ticker || "All"
    //         });
    //     }

    //     if (length > 0) {
    //         commit("updateCodes", {
    //             ticker: ticker || "All",
    //             stack: parseRawCodes(response!.records),
    //             length
    //         });
    //     }
    // },
    getHistoryCodes (...args) {
      return actionWrapper({
        beforeRequestHandler: ({ context: { getters }, payload }) => {
          payload.offset = getters.codesByTicker(payload.ticker)
        },
        apiRequest: ({ ticker, offset }) =>
          this.$api.getHistoryCodes({
            ticker: ticker || '',
            limit: DEFAULT_LIMIT,
            offset,
          }),
        mutationName: 'updateCodes',
      }).bind(this)(...args)
    },
    // async fetchAllHistory({ state, commit, dispatch }, { ticker }) {
    //     if (state.data[ticker || "All"]["loadedAll"] || state.fetchStatus === "loading") {
    //     // if (state.data.getIn([ticker || "All", "loadedAll"]) || state.fetchStatus === "loading") {
    //         return;
    //     }

    //     commit("setFetchStatus", "loading");
    //     await dispatch("getHistoryCodes", { ticker });
    //     commit("setFetchStatus", "ok");
    // },
    // closeModal({ dispatch, commit }) {
    //     commit("closeModal");
    //     commit("activateCodeForm/reset", { clearFields: true });
    // },

    // New action

    // async fetchCodes({commit}, {page, ...params}) {
    //     commit('toggleLoading', true)

    //     const offset = (page - 1) * DEFAULT_LIMIT

    //     const { ticker } = params

    //     if(page === 1) {
    //         commit('setCodes', { formatedRecords: [] })
    //     }

    //     const { response, errors } = await this.$api.getHistoryCodes({
    //         ticker: ticker || '',
    //         limit: DEFAULT_LIMIT,
    //         offset,
    //     });

    //     if (errors) {
    //         console.error(errors);
    //         commit('toggleLoading', false)
    //         return;
    //     }

    //     const { records, total, limit } = response as any

    //     const formatedRecords = records.length ? parseRawCodes(records) : []

    //     const nextPage = Math.ceil(total / limit) > page

    //     if(page > 1) {
    //         commit('addCodes', { formatedRecords, nextPage })
    //     } else {
    //         commit('setCodes', { formatedRecords, nextPage })
    //     }

    //     commit('toggleLoading', false)
    // },
    fetchCodes (...args) {
      return actionWrapper({
        beforeRequestHandler: ({ payload }) => {
          payload.offset = (payload.page - 1) * DEFAULT_LIMIT
        },
        apiRequest: ({ ticker, offset }) => this.$api.getHistoryCodes({
          ticker: ticker || '',
          limit: DEFAULT_LIMIT,
          offset,
        }),
        mutationName: '',
        successHandler: ({ context: { commit }, payload: { page }, response: { response } }) => {
          const { records, total, limit } = response as any

          // const codes = records.map(code => new CodeModel(code))

          const formatedRecords = records.length ? records : []

          const nextPage = Math.ceil(total / limit) > page

          if (page > 1) {
            commit('addCodes', { formatedRecords, nextPage })
          } else {
            commit('setCodes', { formatedRecords, nextPage })
          }
        },
      }).bind(this)(...args)
    },
    activateCode (context, { ...args }) {
      return actionWrapper({
        apiRequest: payload => this.$api.postActivateCode({ body: payload }),
        mutationName: 'unsetCode',
        successHandler ({ context, response, payload }) {
          if (payload.errorHandler && typeof payload.errorHandler === 'function') {
            payload.successHandler({ context, response, payload })
          }
        },
        errorHandler: ({ context, response, payload }, res) => {
          if (payload.errorHandler && typeof payload.errorHandler === 'function') {
            payload.errorHandler({ context, response, payload }, res)
          }
        },
      }).bind(this)(context, { ...args })
    },
    deleteCode (...args) {
      return actionWrapper({
        apiRequest: payload => this.$api.deleteCode(payload),
        mutationName: 'unsetCode',
      }).bind(this)(...args)
    },
  },
}

export default CodesModule
