import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '../index'

export interface OtcBalance {
  balance: string
  chart_value: number
  name: string
  ticker: string
}

export interface OtcFiat {
  balance: string
  currency_id: number
  name: string
  ticker: string
}

export interface OtcAdminState {
  balances: OtcBalance[]
  fiatAmount: OtcFiat[]
}

const state = () => ({
  balances: [],
  fiatAmount: [],
}) as OtcAdminState

const actions: ActionTree<OtcAdminState, RootState> = {
  async getBalances ({ commit }) {
    const { response, errors } = await this.$api.otcAdminBalances()

    if (errors) {
      console.error(errors)
      return
    }

    commit('setBalances', response.balances)
    commit('setFiats', response.fiat_amount)
  },
}

const mutations: MutationTree<OtcAdminState> = {
  setBalances (state, pld: OtcBalance[]) {
    state.balances = pld
  },
  setFiats (state, pld: OtcFiat[]) {
    state.fiatAmount = pld
  },
}

const modules = {}

const getters: GetterTree<OtcAdminState, RootState> = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules,
} as Module<OtcAdminState, RootState>
