<template>
  <div class="exc-card-tickers-exchange-container">
    <div
      class="exc-card-tickers-exchange-container__container"
      :class="{
        'exc-card-tickers-exchange-container--only-body': !$slots[
          'action-button'
        ],
        'exc-card-tickers-exchange-container__container--otc': otcPage,
      }"
    >
      <exc-ticker-logos-block
        :fiat-logo="fiatLogo"
        :crypto-logo="cryptoLogo"
        :transparent-border="logoWithoutBorder"
        class="exc-card-tickers-exchange-container__logos"
      >
        <template #mobile-block>
          <slot name="mobile-title-info" />
        </template>
      </exc-ticker-logos-block>
      <div class="exc-card-tickers-exchange-container__body">
        <slot name="body" />
      </div>
      <div
        v-if="$slots['action-button']"
        class="exc-card-tickers-exchange-container__action"
      >
        <slot name="action-button" />
      </div>
    </div>
  </div>
</template>

<script>
import ExcTickerLogosBlock from '@/components/common/ticker/ExcTickerLogosBlock_new.vue'

export default {
  name: 'ExcCardTickersExchangeContainer',
  components: {
    ExcTickerLogosBlock,
  },
  props: {
    fiatLogo: {
      type: String,
      default: '',
    },
    cryptoLogo: {
      type: String,
      default: '',
    },
    // cardType: {
    //   validator: v => ['green', 'red', 'main', 'grey'].includes(v),
    //   default: 'grey',
    // },
    otcPage: {
      type: Boolean,
      default: false,
    },
    logoWithoutBorder: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
.exc-card-tickers-exchange-container {
  &__container {
    display: grid;
    // grid-auto-rows: minmax(20px, auto);
    grid-gap: toRem(space(20));
    grid-template-areas:
      'logos'
      'body'
      'action';
    padding: toRem(20px) 0;

    @include bp(l) {
      grid-template-columns: 2fr 8fr 1fr;
      grid-template-areas: 'logos body action';
      padding: 0 toRem(28px);

      &--otc {
        grid-template-columns: 1fr 9fr 1fr;
      }
    }
  }

  &--only-body {
    grid-template-areas:
      'logos'
      'body';

    @include bp(s) {
      grid-template-columns: 100px 1fr;
      grid-template-areas:
        'logos .'
        'logos body'
        'logos .';
    }

    @include bp(l) {
      grid-template-columns: 100px auto;
      grid-template-areas: 'logos body';
    }
  }

  &__logos {
    grid-area: logos;
    display: flex;
    padding: 0 toRem(16px);
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 0 toRem(10px);

    @include bp(l) {
      padding: 0;
      margin: 0;
    }
  }

  &__body {
    grid-area: body;
    align-self: center;
    padding: 0 toRem(10px);

    @include bp(m) {
      padding: 0;
    }

    @include bp(m) {
      padding: toRem(10px);
    }
  }

  &__action {
    grid-area: action;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 toRem(10px);

    @include bp(l) {
      align-items: center;
    }
  }

  // &__btn {
  //     width: 100%;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     justify-content: center;

  //     @include bp(m) {
  //         width: 200px;
  //         margin-right: 50px;
  //     }
  // }
}
</style>
