<template>
  <div class="exchange-footer-apps">
    <a
      v-for="app in applications"
      :key="`app-${app.component}`"
      :href="app.link"
      target="_blank"
      class="exchange-footer-apps__item"
    >
      <img
        :src="app.url"
        :alt="app.component"
        width="120"
        height="44"
        loading="lazy"
      >
      <!-- <component :is="app.component" /> -->
    </a>
  </div>
</template>
<script>
export default {
  name: 'ExchangeFooterApps',
  components: {
    // AppStore: () => import('@/assets/icons/apps/app-store-full.svg?inline'),
    // PlayMarket: () => import('@/assets/icons/apps/play-market-full.svg?inline'),
  },

  data () {
    return {
      applications: [
        {
          component: 'app-store',
          url: require('@/assets/icons/apps/app-store-full.svg'),
          link: this.$store.state.globals.appLinks.ios,
        },
        {
          component: 'play-market',
          url: require('@/assets/icons/apps/play-market-full.svg'),
          link: this.$store.state.globals.appLinks.android,
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.exchange-footer-apps {
  display: flex;
  gap: toRem(16px);

  @include bp(l) {
    flex-direction: column;
  }

  &__link {
    display: block;
  }
}
</style>
