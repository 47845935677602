import { GetterTree, Module, MutationTree, ActionTree } from 'vuex'
import { actionWrapper } from '@/lib/utils'
import { LoanModel } from '@/lib/data-models/p2p/LoanModel'
import { CreditHistoryModel } from '@/lib/data-models/p2p/CreditHistoryModel'
import { LoansStatModel } from '@/lib/data-models/p2p/LoansStatModel'
import { RootState } from '..'

// This is set on backend
const LOANS_PER_PAGE = 10

interface P2PAdminInterface {
  myCreditsRaw: P2PLoanResponse[]
  myCreditHistory: P2PLoanHistoryResponse[]
  myCreditsRequestsHistory: P2PLoanHistoryResponse[]
  loansStats: Hash<P2PLoansStatResponse>
  loansToShow: number
}

const state: P2PAdminInterface = {
  myCreditsRaw: [],
  myCreditHistory: [],
  myCreditsRequestsHistory: [],
  loansStats: {},
  loansToShow: 0,
}

const mutations: MutationTree<P2PAdminInterface> = {
  setMyLoans (state, { response, payload }) {
    state.loansToShow = response.count
    const isNextPage = payload && payload.offset
    if (isNextPage) {
      state.myCreditsRaw = [...state.myCreditsRaw, ...response.data]
      return
    }

    state.myCreditsRaw = response.data
  },
  setLoanDetails (state, { response }) {
    state.myCreditHistory = response.data
    state.loansToShow = response.count
  },
  resetLoanRequestsHistory (state) {
    state.myCreditHistory = []
  },
  setMyLoanRequestsHistory (state, { response, payload }) {
    console.log(response, payload)
    state.loansToShow = response.count
    const isNextPage = payload && payload.offset
    if (isNextPage) {
      state.myCreditsRequestsHistory = [
        ...state.myCreditsRequestsHistory,
        ...response.data,
      ]
      return
    }
    state.myCreditsRequestsHistory = response.data
  },
  setMyLoansStats (state, { response }) {
    state.loansStats = response
  },
  changeLoanStatus (state, { response, payload }) {
    state.myCreditsRequestsHistory = state.myCreditsRequestsHistory.map(
      (creditRequest) => {
        if (+creditRequest.id === +payload) return response

        return creditRequest
      },
    )
  },
}

const actions: ActionTree<P2PAdminInterface, RootState> = {
  getP2PMyLoans (...args) {
    return actionWrapper({
      apiRequest: payload => this.$api.getP2PMyLoansList(payload),
      mutationName: 'setMyLoans',
    }).bind(this)(...args)
  },
  getP2PLoanDetails (...args) {
    return actionWrapper({
      apiRequest: ({ id, params }) => this.$api.getP2PBorrowDetails(id, params),
      mutationName: 'setMyLoanRequestsHistory',
    }).bind(this)(...args)
  },
  getP2PMyLoanRequestsHistory (...args) {
    return actionWrapper({
      apiRequest: payload => this.$api.getP2PBorrowsList(payload),
      mutationName: 'setMyLoanRequestsHistory',
    }).bind(this)(...args)
  },
  getP2PLoansStats (...args) {
    return actionWrapper({
      apiRequest: () => this.$api.getP2PTotalData(),
      mutationName: 'setMyLoansStats',
    }).bind(this)(...args)
  },
  acceptLoanRequest (...args) {
    return actionWrapper({
      apiRequest: id => this.$api.acceptLoanRequest(id),
      mutationName: 'changeLoanStatus',
    }).bind(this)(...args)
  },
  rejectLoanRequest (...args) {
    return actionWrapper({
      apiRequest: id => this.$api.rejectLoanRequest(id),
      mutationName: 'changeLoanStatus',
    }).bind(this)(...args)
  },
}

const getters: GetterTree<P2PAdminInterface, RootState> = {
  myCredits: state => state.myCreditsRaw.map(credit => new LoanModel(credit)),
  myCreditRequests: state =>
    state.myCreditHistory.map(
      creditRequest => new CreditHistoryModel(creditRequest),
    ),
  myCreditRequestsHistory: state =>
    state.myCreditsRequestsHistory.map(
      creditRequest => new CreditHistoryModel(creditRequest),
    ),
  myLoanStats: (state) => {
    return Object.entries(state.loansStats).reduce((acc, [ticker, stats]) => {
      acc[ticker] = new LoansStatModel(stats)

      return acc
    }, {})
  },
  hasMoreLoans: state => state.loansToShow > LOANS_PER_PAGE,
}

export const admin: Module<P2PAdminInterface, RootState> = {
  namespaced: true,
  state: () => state,
  actions,
  mutations,
  getters,
}

export default admin
