import { Module } from 'vuex'
import pickBy from 'lodash/pickBy'
import reduce from 'lodash/reduce'
import { BASE_COOKIE_OPTIONS } from '@/constants'
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
// import variables from '!!sass-variable-loader?preserveVariableNames!../assets/scss/_vars.scss'
// // @ts-ignore
// // eslint-disable-next-line import/no-webpack-loader-syntax
// import nn   nnnmn,               from '!!sass-variable-loader?preserveVariableNames!../assets/scss/common/colors.scss'

const variables = {}
const variables2 = {}

const cssVars = pickBy({ ...variables, ...variables2 }, (_, key) => key.startsWith('c-') && key.includes('C-Patex'))

const DARK_THEME_ENABLED_KEY = 'darkThemeEnabled'

export interface DarkThemeState {
  enabled: boolean;
  variableStyles: string;
}

const DarkThemeStateModule: Module<DarkThemeState, {}> = {
  namespaced: true,
  state: () => ({
    enabled: false,
    variableStyles: '',
  }),
  mutations: {
    init (state) {
      state.enabled = /* this.$cookies.get(DARK_THEME_ENABLED_KEY) || */false
      state.variableStyles = adjustVariables(state.enabled)
    },
    toggle (state) {
      state.enabled = !state.enabled

      this.$cookies.set(DARK_THEME_ENABLED_KEY, false, BASE_COOKIE_OPTIONS)

      state.variableStyles = adjustVariables(state.enabled)
    },
    disable (state) {
      state.enabled = false

      state.variableStyles = adjustVariables(state.enabled)
    },
  },
}

function adjustVariables (dark: boolean = false) {
  // Fucking function...
  return reduce(cssVars, (acc, value: string, key: string) => {
    if (key.endsWith('-dark')) {
      return acc
    }

    const parsedKey = key.replace(`${'C-Patex'}-`, '') // TODO: fix this
    const chosenValue = dark ? cssVars[`${key}-dark`] : value

    return acc + `--${parsedKey}: ${chosenValue};`
  }, '')
}

export default DarkThemeStateModule
