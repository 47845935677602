// import { loadingGetters } from "@/store/lib/helpers";
import { Module } from 'vuex'
import assign from 'lodash/assign'

import { RootState } from '@/store'
import { actionWrapper, formatDate } from '@/lib/utils'
import { SessionDataModel } from '~/lib/data-models/sessions/SessionDataModel'

export interface SecuritySettingsState {
  fetchStatus: FetchStatus;
  // twoFactorGenerateStatus: FetchStatus;
  twoFactorSecret: string | null;
  twoFactorQrUrl: string | null;
  twoFactorEnabled: boolean;
  sessions: Session[];
}

const SecuritySettingsModule: Module<SecuritySettingsState, RootState> = {
  namespaced: true,
  state: () => ({
    fetchStatus: 'init',
    // twoFactorGenerateStatus: "init",
    twoFactorSecret: '',
    twoFactorQrUrl: '',
    twoFactorEnabled: false,
    sessions: [],
  }),
  mutations: {
    setFetchStatus (state, status: FetchStatus) {
      state.fetchStatus = status
    },
    // setTwoFactorGenerateStatus(state, status: FetchStatus) {
    //     state.twoFactorGenerateStatus = status;
    // },
    setTwoFactorEnabled (state, { response: { twoFactorEnabled } }) {
      state.twoFactorEnabled = twoFactorEnabled
    },
    setTwoFactorSecretAndQrUrl (state, { response: { twoFactorSecret, twoFactorQrUrl } }) {
      state.twoFactorSecret = twoFactorSecret
      state.twoFactorQrUrl = twoFactorQrUrl
    },
    setSessions (state, { response: { sessions } }) {
      state.sessions = [...sessions]
    },
    removeSession (state, { payload: { id, revert } }) {
      state.sessions = state.sessions.map((s) => {
        if (s.id === id) {
          return assign({ ...s }, { deleted: !revert })
          // return merge(s, { deleted: !revert });
        }

        return s
      })
      state.sessions = [...state.sessions]
    },
  },
  getters: {
    // ...loadingGetters(),
    sessions (state) {
      return state.sessions
        .map(s => ({ ...s }))
        .sort(({ lastActivity: lastActivityA }, { lastActivity: lastActivityB }) => lastActivityB - lastActivityA)
        .map(
          ({ id, ipAddress, lastActivity, userAgent, isCurrent, platform, deleted }) => ({
            isCurrent,
            id,
            ipAddress,
            platform,
            // @TODO fix type error
            // @ts-ignore
            createdAt: formatDate(lastActivity * 1000),
            userAgent,
            deleted,

          }))
        .filter(({ deleted }) => !deleted)
    },
    newSessions (state) {
      return state.sessions
        .map(s => new SessionDataModel(s))
        .sort(({ lastActivity: lastActivityA }, { lastActivity: lastActivityB }) => lastActivityB - lastActivityA)
        .sort(({ isCurrent: isCurrentA }, { isCurrent: isCurrentB }) => Number(isCurrentB) - Number(isCurrentA))
        .filter(({ deleted }) => !deleted)
    },
    currentSession (state) {
      return state.sessions.find(session => session.isCurrent)
    },
  },
  actions: {
    actionGetSecuritySettings (...args) {
      return actionWrapper<SecuritySettingsState>({
        apiRequest: () => this.$api.getSecuritySettings(),
        mutationName: 'setTwoFactorEnabled',
        successHandler: ({ context: { commit }, response }) => {
          commit('setTwoFactorSecretAndQrUrl', response)
          this.$authentication.fetchUser()
          // const userSettings = {
          //   isTwoFactorEnabled: response!.twoFactorEnabled,
          // }

          // commit('profile/setGeneralSettings', { userSettings }, { root: true })
        },
      }).bind(this)(...args)
    },
    actionGetSessions (...args) {
      return actionWrapper<SecuritySettingsState>({
        apiRequest: () => this.$api.getSessions(),
        mutationName: 'setSessions',
      }).bind(this)(...args)
    },
    handleTerminate (...args) {
      return actionWrapper<SecuritySettingsState>({
        apiRequest: ({ id }) => this.$api.postTerminateSession({ id }),
        mutationName: 'removeSession',
        successHandler: ({ response: { status }, payload: { isCurrent } }) => {
          if (isCurrent || status === 302) {
            window.location.href = '/'
          }
        },
        errorHandler: ({ context: { commit }, payload: { id } }) => {
          commit('removeSession', { payload: { id, revert: true } })
        },
      }).bind(this)(...args)
    },
    generateNewFactorSecret (...args) {
      return actionWrapper<SecuritySettingsState>({
        apiRequest: () => this.$api.getGenerateNewFactorSecret(),
        mutationName: 'setTwoFactorSecretAndQrUrl',
      }).bind(this)(...args)
    },
  },
}

export default SecuritySettingsModule
