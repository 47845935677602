import { Module } from 'vuex'
import { actionWrapper } from '@/lib/utils'
import { Location } from 'vue-router'

export interface Deposit {
  value: number,
  ticker: string,
}

export interface ieoState {
  isFromIeoPage: Location | null,
  list: [] | null,
  currentIeo: any,
  minDeposit: Deposit | null,
  maxDeposit: Deposit | null,
}

const ieo: Module<ieoState, any> = {
  namespaced: true,
  state: () => ({
    isFromIeoPage: null,
    list: null,
    currentIeo: null,
    minDeposit: null,
    maxDeposit: null,
  }),
  actions: {
    getIeoItemAction (...args) {
      return actionWrapper<ieoState>({
        apiRequest: (pld: any) =>
          this.$api.getIeoItem(pld.slug, this.$i18n.locale),
        mutationName: 'setSingleIeo',
      }).bind(this)(...args)
    },
    getIeoList (...args) {
      return actionWrapper<ieoState>({
        apiRequest: () => this.$api.getIeoList(this.$i18n.locale),
        mutationName: 'setIeoList',
      }).bind(this)(...args)
    },
  },
  mutations: {
    setIsFromIeoPage (state, pld) {
      state.isFromIeoPage = pld
    },
    // updateCurrentIeoStatus(state, pld: 'Active' | 'Success') {
    //     state.currentIeo.status = pld
    //     if (pld === 'Active') state.currentIeo.status_type = 'active'
    //     if (pld === 'Success') state.currentIeo.status_type = 'finished'
    // },
    setMinDeposit (state, pld) {
      state.minDeposit = pld
    },
    setMaxDeposit (state, pld) {
      state.maxDeposit = pld
    },
    setIeoList (state, pld) {
      state.list = pld.response
    },
    setSingleIeo (state, pld) {
      state.currentIeo = pld.response
    },
  },
}
export default ieo
