var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exc-tabs",class:_vm.mainClasses},[_c('div',{staticClass:"exc-tabs__buttons"},_vm._l((_vm.buttons),function(btn,i){return _c('div',{key:btn.id || btn.value,staticClass:"exc-tabs__tab txt--14px txt--weight-500",class:Object.assign({}, {'exc-tabs--active txt--color-04':
          btn.value.toLowerCase() === _vm.selectedButton.toLowerCase(),
        'txt--color-03':
          btn.value !== _vm.selectedButton && !_vm.disabledTabIndexes.includes(i),
        'txt--grey': btn.value !== _vm.selectedButton && _vm.disabled,
        'exc-tabs--disabled txt--grey': _vm.disabledTabIndexes.includes(i)},
        _vm.tabClasses),on:{"click":function($event){!_vm.disabled &&
          !_vm.disabledTabIndexes.includes(i) &&
          _vm.$emit('change-tab', btn)}}},[_vm._t(btn.value || i + 1,function(){return [_vm._v("\n        "+_vm._s(btn.label)+"\n        "),(btn.badge && btn.badge.text)?_c('exc-label',{staticClass:"txt--8px ml-4"},[_vm._v("\n          "+_vm._s(btn.badge.text)+"\n        ")]):_vm._e()]},null,Object.assign({}, btn,
          {isActive: btn.value.toLowerCase() === _vm.selectedButton.toLowerCase()}))],2)}),0),_vm._v(" "),(_vm.useScroll)?_c('div',{staticClass:"exc-tabs__scroll-icon"},[_c('exc-dropdown-triangle',{staticClass:"txt--main"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }