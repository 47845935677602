import Decimal from "decimal.js-light"

export interface FiatItemInterface {
    currency_id: number
    main_balance: string
    ticker: Ticker
}

export class FiatItem {
    currencyId: number
    mainBalance: string
    ticker: Ticker
    constructor(o: FiatItemInterface) {
        this.currencyId = o.currency_id
        this.mainBalance = parseFloat(new Decimal(o.main_balance).toFixed(2)) + ' $'
        this.ticker = o.ticker
    }
}
