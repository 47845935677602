// import { $t } from '@/lib/i18n'
import { formatDate } from '@/lib/utils'

export const ticketStatuses = {
  in_proccess: 'main',
  fixed: 'green',
  cancelled: 'red',
  created: 'grey',
}

export class TicketBalanceModel {
  id: number | string
  date: string
  createdAt: number | string
  category: string
  topic: string
  statusName: string

  constructor (o: GetTicketsListResponse) {
    this.id = o.id
    this.date = formatDate(+o.createdAt * 1000)
    this.createdAt = o.createdAt
    this.category = o.category
    this.topic = o.topic
    this.statusName = o.status
  }

  get status () {
    return {
      value: `support.ticket.status.${this.statusName}`,
      class: `txt--${ticketStatuses[this.statusName]}`,
    }
  }
}
