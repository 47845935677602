<template>
  <div
    class="exc-input-side-icon"
    v-on="$listeners"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: 'ExcInputSideIcon',
}
</script>
<style lang="scss" scoped>
.input-side-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
