import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '../../index'
import paginationInfo from './paginationInfo'

export interface OtcOrdersState {
  ordersList: OtcOrderItem[]
}

const state = () => ({
  ordersList: [],
}) as OtcOrdersState

const actions: ActionTree<OtcOrdersState, RootState> = {
  async getOrders (
    { commit },
    { page, status }: { page: number, status?: string },
  ) {
    const { response, errors } = await this.$api.otcUserGetOrdersList(page, status)

    if (errors) {
      console.error(errors)
      return
    }

    page > 1
      ? commit('addOrders', response.orders)
      : commit('setOrders', response.orders)
    commit('paginationInfo/setPagination', response)
  },
}

const mutations: MutationTree<OtcOrdersState> = {
  setOrders (state, pld: OtcOrderItem[]) {
    state.ordersList = pld
  },
  addOrders (state, pld: OtcOrderItem[]) {
    state.ordersList = [...state.ordersList, ...pld]
  },
  setArbitrationStatus (state, id) {
    if (state.ordersList.length) {
      state.ordersList = state.ordersList.map((order) => {
        if (+order.id === +id) {
          return {
            ...order,
            button_type: 'arbitration',
            status: 'arbitration',
          }
        }
        return order
      })
    }
  },
}

const getters: GetterTree<OtcOrdersState, RootState> = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: { paginationInfo },
} as Module<OtcOrdersState, RootState>
