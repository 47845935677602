


































































































































































import Vue from 'vue'

import ExcNoItems from '@/components/common/svg/ExcNoItems.vue'
import ExcLoader from '@/components/common/svg/ExcLoader.vue'
import { sizeValidator, defaultSize } from '@/lib/utils'
import ExcDropdownTriangle from '../svg/ExcDropdownTriangle.vue'

interface Column {
  value: string
  hideOnMobile?: boolean
}

interface Row {
  [key: string]: boolean
}

const headingFormatChecker = (headers: string[] | Column) => {
  if (Array.isArray(headers)) return true
  return Object.values(headers).every(
    header => header.toString() === header || 'value' in header,
  )
}

const DESC = 'DESC'
const ASC = 'ASC'

export default Vue.extend({
  name: 'ExcTable',
  components: {
    ExcNoItems,
    ExcLoader,
    ExcDropdownTriangle,
  },
  props: {
    heading: {
      type: [Array, Object],
      required: true,
      validator: headingFormatChecker,
    },
    sorting: {
      // keys same as row names and values as direction or true
      type: Object,
      default: () => ({}),
    },
    rows: { type: Array, required: true },
    emptyMessage: { type: String, default: '' },
    animated: { type: Boolean, default: false },
    bgInHead: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    hideHeaderBorder: { type: Boolean, default: false },
    disableStripedRows: { type: Boolean, default: false },
    tableTitle: { type: String, default: '' },
    isHideMoreArrow: { type: Boolean, default: false },
    activeRow: {
      type: Object,
      default: () => ({}),
    },
    adaptive: { type: Boolean, default: false }, // if ftrue will change table view for mobile devices, if false table will not change on mobiles
    cellSize: { validator: sizeValidator, default: defaultSize },
    cellWidth: { validator: sizeValidator, default: defaultSize },
    lessTitle: {
      type: String,
      default: 'less',
    },
    moreTitle: {
      type: String,
      default: 'more',
    },
  },
  data () {
    return {
      isVisible: {} as Row,
      DESC,
      ASC,
      specifiedSorting: {} as any,
    }
  },
  computed: {
    showMobileToggle (): boolean {
      return (
        Object.values(this.heading).filter(head => head !== '').length >= 6 &&
        this.adaptive &&
        !this.isHideMoreArrow
      )
    },
    classes (): { [key: string]: boolean } {
      return {
        'exc-table--adaptive': this.adaptive,
        'exc-table--show-row-toggle': this.showMobileToggle,
        'exc-table--striped': !this.disableStripedRows,
        'exc-table--borderless': this.hideHeaderBorder,
        [`exc-table--width-${this.cellWidth}`]: this.cellWidth,
      }
    },
    cellSizeClass () {
      return {
        'exc-table--xl pt-4 pb-4 pl-20 exc-table--pr-20 pr-8':
          this.cellSize === 'xl',
        'exc-table--l pt-4 pb-4 pl-16 exc-table--pr-16 pr-4':
          this.cellSize === 'l',
        'exc-table--m pt-16--l pb-16--l pl-12 exc-table--pr-12 pr-4':
          this.cellSize === 'm',
        'exc-table--s pt-4 pb-4 pl-8 exc-table--pr-8 pr-0':
          this.cellSize === 's',
        'exc-table--sm pt-8--l pb-8--l pl-8 pt-8 pb-8 exc-table--pr-8 pr-8':
          this.cellSize === 'sm',
        'exc-table--xs pt-4 pb-4 pl-4 exc-table--pr-4 pr-0':
          this.cellSize === 'xs',
      }
    },
    firstColumnName () {
      return Array.isArray(this.heading) ? 0 : Object.keys(this.heading)[0]
    },
  },
  mounted () {
    setTimeout(() => {
      ;(this.$refs.table as HTMLHtmlElement).addEventListener(
        'scroll',
        this.onScrollToBottom,
      )
    })

    // check values
    const valuesIsOk = Object.values(this.sorting).every(s => !!s)
    if (!valuesIsOk) {
      throw new Error(
        'Initial values for sorting should be true or "ASC" | "DESC"',
      )
    }

    // only one sorting per table
    const valSpecified = Object.values(this.sorting).filter(
      s => typeof s === 'string',
    ).length
    if (valSpecified > 1) throw new Error('No more then one sorting allowed.')

    // assign initial sorting
    Object.keys(this.sorting).forEach((k) => {
      if (typeof this.sorting[k] === 'string') {
        this.specifiedSorting[k] = this.sorting[k]
      }
    })
    this.specifiedSorting = { ...this.specifiedSorting }
  },
  beforeDestroy () {
    ;(this.$refs.table as HTMLHtmlElement).removeEventListener(
      'scroll',
      this.onScrollToBottom,
    )
  },
  methods: {
    onClickTh (ind: number | string) {
      // set new sorting
      if (!this.specifiedSorting[ind] || this.specifiedSorting[ind] === ASC) {
        this.specifiedSorting = {}
        this.specifiedSorting[ind] = DESC
      } else if (this.specifiedSorting[ind] === DESC) {
        this.specifiedSorting = {}
        this.specifiedSorting[ind] = ASC
      } else {
        // not remove sorting yet
        // this.specifiedSorting = {}
      }

      // apply reactivity
      this.specifiedSorting = { ...this.specifiedSorting }

      this.$emit('sort', this.specifiedSorting)
    },
    toggleAllOptionsVisibility (rowIndex: number) {
      this.isVisible = {
        ...this.isVisible,
        [`row${rowIndex}`]: !this.isVisible[`row${rowIndex}`],
      }
    },
    onScrollToBottom (e: any) {
      if (
        e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        !this.loading
      ) {
        this.$emit('scroll-to-bottom')
      }
    },
    compColumnValue (col: string | Column) {
      if (col instanceof Object) return col.value
      return col
    },
  },
})
