import Vue from 'vue'
import * as fecha from 'fecha'
import { formatHistoryTime } from '@/lib/utils'
import shortNumberModule from 'short-number'
import Decimal from 'decimal.js-light'

import {
  formatNumber as fmtNumber,
  noExponents,
  capitalizeFirstLetter, shortifyNumber,
} from '@/lib/utils'

export function withPlusSign (value) {
  const val = parseFloat(value)
  if (val > 0) return '+' + value
  return value
}

export function shortNumber (value) {
  if (isNaN(value) || value.replace(/\s/g, '') === '') return 0
  return shortNumberModule(parseFloat(value))
}

const filters = {
  formatNumber (value, params) {
    return fmtNumber(value, params)
  },
  noHttpOnBeginning (value) {
    return value.replace(/(^\w+:|^)\/\//, '')
  },
  uppercaseFirst (value) {
    if (typeof value !== 'string') return value
    return capitalizeFirstLetter(value)
  },
  // https://date-fns.org/v2.15.0/docs/format
  formatDate (value, formatter) {
    return fecha.format(value, formatter)
  },
  formatNumberDecimal (value, { decimal } = { decimal: 8 }) {
    if (isNaN(value)) return value
    const formatedNumber = new Decimal(value).toFixed(decimal).toLocaleString()
    const result = noExponents(formatedNumber)
    const [first, latest] = result.split('.')
    return [first.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), latest].join('.')
  },
  numberFormatter (value = 0) {
    return String(value).match(/(\d+?)(?=(\d{3})+(?!\d)|$)/g).join(' ')
  },
  shortString (value = '', maxLength = Infinity) {
    if (value.length <= maxLength) return value
    return value.slice(0, maxLength) + '…'
  },
  withPlusSign,
  shortNumber,
  formatHistoryTime,
  shortifyNumber,
}

export type TFiltersPlugin = typeof filters

export default ({ app }, inject) => {
  const formatNumberByLocale = (val, options) => {
    const config = {
      maximumFractionDigits: 8,
      currencyShow: false,
      ...options,
    }

    let value = val
    let sign = ''

    if (typeof val === 'string' && val.includes('%')) {
      value = val.replace('%', '')
      sign = '%'
    }

    if (typeof val === 'string' && config.currencyShow) {
      const data = val.trim().split(' ')
      value = data[0]
      sign = data[1] ? ` ${data[1]}` : ''
    }

    if (isNaN(Number(value))) {
      return val
    }

    return new Intl.NumberFormat(config.locale || app.i18n.locale, config).format(value) + sign
  }

  const allFilters = { ...filters, formatNumberByLocale }

  Object.entries(allFilters).forEach(([name, fn]) => Vue.filter(name, fn))

  inject('filters', allFilters)
}

// TODO: move all shims to single file
declare module 'vue/types/vue' {
  interface Vue {
    $filters: TFiltersPlugin
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $filters: TFiltersPlugin
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $filters: TFiltersPlugin
  }
}
