import { Module } from 'vuex'
import { actionWrapper } from '@/lib/utils'
import { RootState } from '.'

export interface NewTicketModalState {
  languages: any;
  categories: string[];
}

const NewTicketModalModule: Module<NewTicketModalState, RootState> = {
  namespaced: true,
  state: () => ({
    languages: [],
    categories: [],
  }),
  mutations: {
    setLanguages (state, { response }) {
      state.languages = response.map(lang => ({
        name: lang.name,
        code: lang.code,
      }))
    },
    setCategories (state, { response }) {
      state.categories = response.map((cat, id) => ({
        name: cat,
        id,
      }))
    },
  },
  actions: {
    fetchSupportModalLanguages (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getLanguages(),
        mutationName: 'setLanguages',
      }).bind(this)(...args)
    },
    fetchSupportCategories (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getSupportCategory(),
        mutationName: 'setCategories',
      }).bind(this)(...args)
    },
  },
}

export default NewTicketModalModule
