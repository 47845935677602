const middleware = {}

middleware['default'] = require('../middleware/default.js')
middleware['default'] = middleware['default'].default || middleware['default']

middleware['disabled'] = require('../middleware/disabled.ts')
middleware['disabled'] = middleware['disabled'].default || middleware['disabled']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['otcAdminRequired'] = require('../middleware/otcAdminRequired.ts')
middleware['otcAdminRequired'] = middleware['otcAdminRequired'].default || middleware['otcAdminRequired']

middleware['servicesPages'] = require('../middleware/servicesPages.js')
middleware['servicesPages'] = middleware['servicesPages'].default || middleware['servicesPages']

middleware['withoutAuthOnly'] = require('../middleware/withoutAuthOnly.ts')
middleware['withoutAuthOnly'] = middleware['withoutAuthOnly'].default || middleware['withoutAuthOnly']

export default middleware
