import { AUTH_TOKEN_COOKIE_KEY, REFERRAL_CODE } from '~/constants'

const REFERRAL_ROUTE_NAME = 'referral-id'

const checkForReferral = app => async (to, _, next) => {
  if (to?.name?.startsWith(REFERRAL_ROUTE_NAME)) {
    const authTokens = app.$cookies.get(AUTH_TOKEN_COOKIE_KEY)
    const { id } = to.params

    if (id && !authTokens) {
      app.$cookies.set(REFERRAL_CODE, id)
      return next('/register')
    }

    return next('/')
  }

  return next()
}

export default ({ app }) => app.router.beforeResolve(checkForReferral(app))
