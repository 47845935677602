<template>
  <exc-input-label-error-wrapper
    :field="field"
    :label="label"
    :info-message="infoMessage"
    :custom-error="customError"
    :optional-errors="optionalErrors"
    :name="name"
    :form-key="formKey"
    class="exc-input"
  >
    <!-- eslint-disable vue/valid-v-on -->
    <!-- eslint-disable vue/no-parsing-error  -->
    <template #default="slotProps">
      <fieldset
        class="exc-input__field"
        :class="{
          'exc-input__field--readonly': readonly,
          'exc-input__field--has-error': slotProps.hasError,
        }"
      >
        <div class="exc-input__inner">
          <exc-base-input
            ref="input"
            :value="value"
            without-border
            v-bind="{ ...$attrs, disabled, ...slotProps }"
            class="exc-input__input"
            v-on="{ ...$listeners, input: val => $emit('input', val) }"
          />
          <slot />
        </div>
      </fieldset>
    </template>
  </exc-input-label-error-wrapper>
</template>
<script>
import ExcInputLabelErrorWrapper from '@/components/common/form/validation/ExcInputLabelErrorWrapper.vue'
import ExcBaseInput from '@/components/common/form/input/ExcBaseInput.vue'

export default {
  name: 'ExcInputWithSlot',
  components: {
    ExcBaseInput,
    ExcInputLabelErrorWrapper,
  },
  props: {
    value: {
      validator: v => ['string', 'number'].includes(typeof v) || v === null,
      required: true,
    },
    label: { type: String, default: '' },
    field: {
      type: [Object], // vuelidate field
      default: null,
    },
    customError: { type: String, default: '' },
    infoMessage: { type: String, default: '' },
    name: { type: String, default: '' },
    formKey: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    optionalErrors: { type: Object, default: () => ({}) },
    readonly: { type: Boolean, default: false },
  },

  methods: {
    focus () {
      this.$refs.input && this.$refs.input.focus()
    },
    blur () {
      this.$refs.input && this.$refs.input.blur()
    },
  },
}
</script>
<style lang="scss" scoped>
.exc-input {
  position: relative;

  &__field {
    padding: 0;
    border: 1px solid currentColor;
    border-radius: 100px;
    color: color('transp-02');

    &--readonly {
      border-style: dashed;
    }

    &--has-error {
      color: color(red);
    }
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  ::v-deep {
    .exc-base-input {
      border: unset !important;
    }
  }
}
</style>
