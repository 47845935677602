import { Module } from 'vuex'

import { RootState } from '@/store'

export interface LevelsState {
  levels: any
  userLevelsInfo: Object
  isActivatedTicket: Boolean
}

const LevelsModule: Module<LevelsState, RootState> = {
  namespaced: true,
  state: () => ({
    levels: [],
    userLevelsInfo: null,
    isActivatedTicket: false,
  }),
  mutations: {
    setLevels (state, levels) {
      state.levels = levels
    },
    setUserLevelsInfo (state, info) {
      state.userLevelsInfo = info
    },
    setTicketState (state, isActivated) {
      state.isActivatedTicket = isActivated
    },
  },
  actions: {
    async getLevels ({ commit }) {
      try {
        const { response } = await this.$api.getLevels()

        commit('setLevels', response?.levels?.data || [])
      } catch (err) {
        console.error('levels was not found::', err)
      }
    },

    async getLevelsInfo ({ commit }) {
      try {
        const { response } = await this.$api.getLevelsTicketInfo()

        commit('setUserLevelsInfo', response?.info)
        commit(
          'setTicketState',
          !!(
            response.info?.availablePoints ||
            response.info?.availablePoints === 0
          ),
        )
      } catch (err) {
        console.error(err)
      }
    },
  },
  getters: {
    getAllLevels (state) {
      return state.levels
    },
    getLevelsInfo (state) {
      return state.userLevelsInfo
    },
  },
}

export default LevelsModule
