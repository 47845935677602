import { Module } from 'vuex'
// import { Map, Stack } from "immutable"; //
import { DEFAULT_PAGE, DEFAULT_LIMIT } from '~/lib/api'
// import { ProfileModules } from "@/store/profile";
// import {
//     commonProfileGetters,
//     commontSortComparator,
//     // mergeStacks,
//     parseRawDeposits,
//     parseRawDepositsByAllPairs,
// } from "@/store/profile/helpers";
import { actionWrapper } from '~/lib/utils'
import { DepositModel } from '~/lib/data-models/deposits/DepositModel'
import { RootState } from '~/store'

export type WithdrawalsMap = { [key: string]: DepositWithdrawEntry[] };

// export type WithdrawalsMap = Map<Ticker, Stack<DepositWithdrawEntry>>;

export interface WithdrawalState {
  data: WithdrawalsMap;
  allEntries: DepositWithdrawEntry[];

  showLoadMore: boolean
}

const WithdrawalModule: Module<WithdrawalState, RootState> = {
  namespaced: true,
  state: () => ({

    data: {},
    // data: Map(),
    allEntries: [],
    showLoadMore: false,
  }),
  mutations: {
    setWithdraws (state: WithdrawalState, pld: { formatedRecords: DepositWithdrawEntry[], nextPage: boolean }) {
      state.allEntries = pld.formatedRecords
      state.showLoadMore = !!pld.nextPage
    },

    addWithdraws (state: WithdrawalState, pld: { formatedRecords: DepositWithdrawEntry[], nextPage: boolean }) {
      state.allEntries = [...state.allEntries, ...pld.formatedRecords]
      state.showLoadMore = !!pld.nextPage
    },
  },
  getters: {
    // withdrawalCurrencies(state: WithdrawalState) {
    //     return Object.keys(state.data)
    //     // return state.data.keySeq().toArray();
    // },
  },
  actions: {
    fetchAllWithdraws (...args) {
      return actionWrapper({
        beforeRequestHandler: ({ payload }) => {
          payload.offset = (payload.page - 1) * DEFAULT_LIMIT
        },
        apiRequest: ({ offset }) => this.$api.getWithdrawals({
          offset,
          limit: DEFAULT_LIMIT,
        }),
        mutationName: '',
        successHandler: ({ context: { commit }, response: { response }, payload: { page } }) => {
          const { records, total, limit } = response

          const formatedRecords = records.length ? records.map(withdraw => new DepositModel(withdraw)) : []

          const nextPage = Math.ceil(total / limit) > page

          if (page > 1) {
            commit('addWithdraws', { formatedRecords, nextPage })
          } else {
            commit('setWithdraws', { formatedRecords, nextPage })
          }
        },
      }).bind(this)(...args)
    },
    fetchWithdrawsByTicker (...args) {
      return actionWrapper({
        beforeRequestHandler: ({ payload }) => {
          payload.limit = payload.limit || DEFAULT_LIMIT
          payload.page = payload.page || DEFAULT_PAGE
          payload.offset = (payload.page - 1) * payload.limit
        },
        apiRequest: ({ offset, ticker, limit }) => this.$api.getWithdrawalByTicker({
          ticker,
          offset,
          limit,
        }),
        mutationName: '',
        successHandler: ({ context: { commit }, response: { response }, payload: { page } }) => {
          const { records, total, limit } = response

          const formatedRecords = records.length ? records.map(withdraw => new DepositModel(withdraw)) : []

          const nextPage = Math.ceil(total / limit) > page

          if (page > 1) {
            commit('addWithdraws', { formatedRecords, nextPage })
          } else {
            commit('setWithdraws', { formatedRecords, nextPage })
          }
        },
      }).bind(this)(...args)
    },
  },
}

export default WithdrawalModule
