

































































































import Vue from 'vue'

let idCount = 0

export default Vue.extend({
  name: 'ExcFullLogo',
  computed: {
    id () {
      return idCount
    },
  },
  beforeMount () {
    idCount++
  },
})
