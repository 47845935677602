import { arrayWithLength, fromServerValidation, formatNotifyErrorTitle } from '@/lib/utils'
// import { $t } from '@/lib/i18n'

export const serverFormMixin = {
  data () {
    return {
      mix_serverErrorsData: {},
    }
  },
  computed: {
    mix_serverErrors () {
      const rv = {}
      Object.entries(this.mix_serverErrorsData).forEach(([k, v]) => {
        if (!Array.isArray(v)) rv[k] = v
        else rv[k] = v.map(item => this.$t(item))
      })
      return rv
      // return Object.fromEntries(Object.entries(this.mix_serverErrorsData).map(([k, v]) => {
      //     if (Array.isArray(v)) this.mix_serverErrorsData[k] = v.map(item => $t(item))
      // }))
    },
  },
  methods: {
    mix_setServerErrors (errors, variables = false) {
      delete errors.trace
      this.mix_serverErrorsData = {
        ...this.mix_serverErrorsData,
        ...errors,
        ...(variables && { variables }),
      }
      if (this.$v && this.$v.$touch) this.$v.$touch()
    },
    mix_getServerError (fieldKey) {
      const fromServer = this.mix_serverErrorsData[fieldKey] && this.mix_serverErrorsData[fieldKey][0]
      const variables = this.mix_serverErrorsData?.variables || {}

      return fromServer
        ? { fromServer: this.$t(fromServer, variables || {}) }
        : {}
    },
    // automatically adds fromServer validation to all fields
    mix_validationsServerWrapper (props) {
      return Object.keys(props).reduce((acc, name) => {
        acc[name] = {
          ...props[name],
          fromServer: fromServerValidation(name),
        }
        return acc
      }, {})
    },
    mix_clearServerError (key) {
      if (this.mix_serverErrorsData && arrayWithLength(this.mix_serverErrorsData[key])) {
        this.mix_serverErrorsData[key] = []
        this.mix_serverErrorsData = { ...this.mix_serverErrorsData } // reactivity fix
      }
    },
    mix_clearAllServerErrors () {
      this.mix_serverErrorsData = {}
    },
    /**
         * @param {any} errors
         * @param {{duration?: number, ignoreTitleForKeys?: string[]}} userOptions
         */
    mix_notifyServerErrors (errors, userOptions = {}, duration = 3000) {
      const defaultOptions = {
        ignoreTitleForKeys: ['totp'],
      }

      const options = Object.assign({}, defaultOptions, userOptions)

      Object.entries(errors).forEach(([key, value]) => {
        value.forEach((text) => {
          let notifyOptions = {
            type: 'error',
            duration,
            text: this.$t(text),
          }

          if (!options.ignoreTitleForKeys.includes(key)) {
            notifyOptions = Object.assign({}, notifyOptions, {
              title: key === '0'
                ? this.$t('common.notify.error')
                : formatNotifyErrorTitle(key),
            })
          }

          this.$notify(notifyOptions)
        })
      })
    },
  },
}
