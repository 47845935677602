export class BurningStageItemDto {
  id: number
  isActivated: boolean
  amount: string
  coin: string
  description: string
  logo: string
  name: string
  status: 'ACTIVE' | 'HIDE' | 'FINISH'
  ticker: Ticker
  type: string

  constructor (o: IBurningStageResponseItem) {
    Object.assign(this, o)
    this.isActivated = !!o.activated
    // this.logo = o.logo.replace(/localhost/g, 'testsite-profit.pp.ua')
  }

  get isStatusActive () {
    return this.status === 'ACTIVE'
  }

  get isStatusHide () {
    return this.status === 'HIDE'
  }

  get isStatusFinish () {
    return this.status === 'FINISH'
  }
}
