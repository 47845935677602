import Vue from 'vue'
import { MediaQueries } from 'vue-media-queries'

const mq = new MediaQueries()

Vue.use(mq)

export default (ctx) => {
  ctx.app.mediaQueries = mq
}
