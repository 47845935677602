import Decimal from 'decimal.js-light'
import TradingCompetitionBase from './TradingCompetitionBase'

export class TradingCompetitionDetailModel extends TradingCompetitionBase {
  id: number
  startTime: Date
  endTime: Date
  type: 'ORDERS' | 'SELLVOLUME'
  places: {
    from: number,
    to: number,
    amount: string
    id: string,
    ticker: Ticker
  }[]

  userActivated: boolean // если он true то кнопка должна уже быть отключена
  // для активации кнопки
  // отправлять POST на
  // /v2/tr-competitions-takeplace
  // с значением id компетигина в котором находишься

  nextUpdate: Date
  competitionUsers: {
    id: string,
    place: number,
    name: string,
    value: string
  }[]

  description: string
  marketList: PairName[]
  userPlace: string
  userValue: Decimal
  dropdownOptions: { name: string, pairName: PairName }[]

  constructor (d: TradingCompetitionDataResponse) {
    super(d)

    this.id = d.id
    this.type = d.type
    this.startTime = new Date(d.start_time)
    this.endTime = new Date(d.end_time)
    this.places = d.places.map(p => ({ ...p, id: `${p.from}${p.to}${p.amount}` }))
    this.userActivated = d.userActivated
    this.nextUpdate = new Date(d.nextUpdate)
    this.competitionUsers = d.competitionusers
    this.description = d.description
    this.marketList = d.marketlist
    this.dropdownOptions = d.marketlist.map(p => ({
      name: p.replace('_', '/'),
      pairName: p,
    }))
    this.userPlace = d.userPlace
    this.userValue = new Decimal(d.userValue)
  }

  get isTypeOrders () {
    return this.type === 'ORDERS'
  }

  get isTypeSellVolume () {
    return this.type === 'SELLVOLUME'
  }
}
