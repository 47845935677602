// import { showP2pError, notifySuccess, notifyInfo } from '@/lib/bus'
// import { fromToValidator } from './lendBorrowOrder'
// import { $t } from '@/lib/i18n'
import { InputWithSelect } from '@/data-objects/props/InputWithSelect'
import { LabeledValue } from '@/data-objects/props/LabeledValue'
// import api from '@/lib/api'
import { Module } from 'vuex'
import { custom, Form } from '../lib/vuex-form'
import {
  destructAndMapFieldsEndValues,
  destructAndMapFieldsDefaults,
  destructAndMapFields,
  FieldsSetupStructureInterface,
  fromToValidator,
  clearPropsFromEmptyFields,
} from '../lib/helpers'
import { RootState } from '..'

export const formFieldsProps: FieldsSetupStructureInterface = {
  amount_from: {
    default: new InputWithSelect(
      '',
      new LabeledValue(
        (process.client && window.P2P_FIAT && window.P2P_FIAT[0] && window.P2P_FIAT[0].tiker) || 'BTC',
        (process.client && window.P2P_FIAT && window.P2P_FIAT[0] && window.P2P_FIAT[0].tiker) || 'BTC',
      ),
    ),
    // default: new InputWithSelect('', new LabeledValue(firstFiatTicker, firstFiatTicker)),
    type: String,
    assignValueFn: val => val.input,
    validators: [
      custom((value, fields) => {
        // const requiredMess = required()(value.input, fields, rootState, rootGetters)
        // if (requiredMess) return requiredMess
        // const min = minValue(50)(value.input, fields, rootState, rootGetters)
        // if (min) return min
        return fromToValidator(
          value.input,
          fields.amount_to.input,
          'p2p.field.amountTo',
        )
      }),
    ],
  },
  amount_to: {
    default: new InputWithSelect(
      '',
      new LabeledValue(
        (process.client && window.P2P_FIAT && window.P2P_FIAT[0] && window.P2P_FIAT[0].tiker) || 'BTC',
        (process.client && window.P2P_FIAT && window.P2P_FIAT[0] && window.P2P_FIAT[0].tiker) || 'BTC',
      ),
    ),
    type: String,
    assignValueFn: val => val.input,
    validators: [
      custom((value, fields) => {
        // const requiredMess = required()(value.input, fields, rootState, rootGetters)
        // if (requiredMess) return requiredMess
        // const min = minValue(50)(value.input, fields, rootState, rootGetters)
        // if (min) return min
        return fromToValidator(
          fields.amount_from.input,
          value.input,
          'p2p.field.amountFrom',
        )
      }),
    ],
  },
  days_from: {
    default: '',
    type: String,
    assignValueFn: val => val,
    validators: [
      // required(),
      // minValue(3),
      // maxValue(90),
      custom((value, fields) => {
        return fromToValidator(
          value,
          fields.days_to,
          'p2p.field.daysTo',
        )
      }),
    ],
  },
  days_to: {
    default: '',
    type: String,
    assignValueFn: val => val,
    validators: [
      // required(),
      // minValue(3),
      // maxValue(90),
      custom((value, fields) => {
        return fromToValidator(
          fields.days_from,
          value,
          'p2p.field.daysFrom',
        )
      }),
    ],
  },
  percent_from: {
    default: '',
    type: String,
    assignValueFn: val => val,
    validators: [
      // required(),
      // minValue(0.01),
      // maxValue(5),
      custom((value, fields) => {
        // const isLess = percentMinFrom(value, 0.01)
        // if (isLess) return isLess
        return fromToValidator(
          value,
          fields.percent_to,
          'p2p.field.pledgeRateTo',
        )
      }),
    ],
  },
  percent_to: {
    default: '',
    type: String,
    assignValueFn: val => val,
    validators: [
      // required(),
      // minValue(0.01),
      // maxValue(5),
      custom((value, fields) => {
        // const isHigher = percentMaxTo(value, 2)
        // if (isHigher) return isHigher
        return fromToValidator(
          fields.percent_from,
          value,
          'p2p.field.pledgeRateFrom',
        )
      }),
    ],
  },
  fiat_currency: {
    default: '',
    type: String,
    assignValueFn: val => val,
  },
  currency: {
    default: [],
    type: String,
    assignValueFn: arr =>
      arr
        .reduce((acc, val) => {
          acc.push(val.value)
          return acc
        }, [])
        .join(','),
    validators: [
    //   custom((value, fields, rootState, rootGetters) => {
    //     // if array of selected currencies is empty, return error message
    //     // return required()(!!value.length, fields, rootState, rootGetters)
    //   })
    ],
  },
}

const searchOrderForm = new Form({
  fields: destructAndMapFields(formFieldsProps),
  defaultValues: destructAndMapFieldsDefaults(formFieldsProps),
  async onSubmit ({ commit, dispatch, getters }) {
    const fields = getters.allFields

    const formObj = destructAndMapFieldsEndValues(fields, formFieldsProps)
    formObj.fiat_currency = fields.amount_from.select.value
    const params = clearPropsFromEmptyFields(formObj)

    commit('setFetchStatus', 'loading')

    params.offset = 0
    await dispatch('p2p/appLend/getLendingAllApps', params, { root: true })

    commit('setFetchStatus', 'ok')
  },
})

interface P2pSearchOrderStateInterface {}

export const searchOrder: Module<P2pSearchOrderStateInterface, RootState> = {
  namespaced: true,
  // state,
  // actions,
  // mutations,
  modules: { searchOrderForm },
}
