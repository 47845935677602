
// import { Collection } from 'immutable' //
import Decimal from 'decimal.js-light'
import range from 'lodash/range'
import { Commit } from 'vuex'
// import Decimal from 'decimal.js-light'
// import { $t } from '@/lib/i18n'

// export function referralLink (obj) {
//   console.log(obj)
//   const projectName = process.client ? location.origin : ''
//   obj.response.link = projectName + obj.response.link
//   return obj
// }

export function generateYears () {
  const currentYear = new Date().getFullYear()
  return range(1930, currentYear + 1 - 18).reverse()
}

export function resetCaptcha (formKey: string, commit: Commit) {
  try {
    GTest.reset()
  } catch (err) { }

  commit(`${formKey}/setFieldValue`, false, { root: true })
  commit(`${formKey}/setErrors`, { captcha: [] }, { root: true })
}

export function leapYear (year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
}

export function onPageLoad (fn: Function, wait: number = undefined) {
  if (document.readyState !== 'complete') {
    window.addEventListener('load', () => {
      wait ? setTimeout(fn, wait) : fn()
    })
  } else {
    wait ? setTimeout(fn, wait) : fn()
  }
}

// better import from this file rather from utils.ts
export function wait (time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

export function waitForElement (fn, selector) {
  const elem = document.querySelector(selector)
  if (elem) {
    fn(elem)
  } else {
    setTimeout(() => waitForElement(fn, selector), 500)
  }
}

export function waitForCondition (fn: Function) {
  return new Promise((resolve) => {
    function checkIfTruthy (f: Function) {
      if (f()) {
        resolve(true)
      } else {
        setTimeout(() => checkIfTruthy(f), 500)
      }
    }
    checkIfTruthy(fn)
  })
}

// export async function waitForElement(selector) {
//     let someTime = 0
//     // const maxTime = 1111
//     const timeout = 333
//     const res = new Promise(async resolve => {
//         const el = document.querySelector(selector)
//         console.log(el, someTime)
//         if (!el) {
//             await wait(timeout)
//             // if (someTime > maxTime) {
//             //     console.error(`Cant find ${selector} for ${maxTime / 1000} sec`)
//             // }
//             // someTime += timeout
//             return await waitForElement(selector)
//         } else {
//             resolve(el)
//         }
//     })
//     console.warn('RES:::', res)
//     return res
// }

export function generateDateByYear (year, month) {
  if (!month || month === undefined) {
    return range(1, 29)
  }

  switch (month.name) {
    case 'April':
    case 'June':
    case 'September':
    case 'November':
      return range(1, 31)
    case 'February':
      return leapYear(year) ? range(1, 30) : range(1, 29)
    default:
      return range(1, 32)
  }
}

export const months = [
  { name: 'January', index: '0', days: range(1, 32) },
  {
    name: 'February',
    index: '1',
    days (year) {
      return leapYear(year) ? range(1, 30) : range(1, 29)
    },
  },
  { name: 'March', index: '2', days: range(1, 32) },
  { name: 'April', index: '3', days: range(1, 31) },
  { name: 'May', index: '4', days: range(1, 32) },
  { name: 'June', index: '5', days: range(1, 31) },
  { name: 'July', index: '6', days: range(1, 32) },
  { name: 'August', index: '7', days: range(1, 32) },
  { name: 'September', index: '8', days: range(1, 31) },
  { name: 'October', index: '9', days: range(1, 32) },
  { name: 'November', index: '10', days: range(1, 31) },
  { name: 'December', index: '11', days: range(1, 32) },
]

export function convertDate (day, month, year) {
  const monthNumber = +month + 1

  const mm = monthNumber < 10 ? `0${monthNumber}` : monthNumber

  const dd = day < 10 ? `0${day}` : day

  return `${year}-${mm}-${dd}`
}

export function escapeLocaleFromRoute (name) {
  return (name || '').replace('localized_', '')
}

export function getPathWithoutLocale (path) {
  return path
    .split('/')
    .filter(slice => !window.PARSED_LANGUAGES.includes(slice))
    .join('/')
}

export function getActualRouterPath (path) {
  const newPath = getPathWithoutLocale(path)

  return window.PARSED_LANGUAGES.includes(window.APP_LANGUAGE) && window.APP_LANGUAGE !== 'en'
    ? `/${window.APP_LANGUAGE}${newPath}`
    : newPath
}

// export const disabledDarkThemeRoutes = [
//   'resetPassword',
//   'welcome',
//   'forgotPassword',
//   'twoFactor',
//   'verifyAccount',
//   'auth',
//   'login',
//   'register',
// ].map(r => routes[r].name)

// export function shouldEnableDarkTheme(routeName: string): boolean {
//   return !disabledDarkThemeRoutes.includes(escapeLocaleFromRoute(routeName))
// }

// export function uniqByImmutable<T>(
//     collection: Collection<any, any>,
//     predicate: (t: T) => any
// ) {
//     return collection.groupBy(predicate).map(g => g.first())
// }

export function filterByLeftTicker (leftTicker) {
  return (pair) => {
    const left = pair.split('_')[0]
    if (left === leftTicker) return false
    return true
  }
}
export function filterByRightTicker (rightTicker) {
  return (pair) => {
    const right = pair.split('_')[1]
    if (right === rightTicker) return false
    return true
  }
}

export function isImageFile (type) {
  if (!type) {
    return true
  }
  return ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'].includes(type)
  // return /image\//.test(type)
}

// export function formatNumber(val, { decimal } = { decimal: 8 }) {
//     if (isNaN(val)) return val
//     // const number = Math.round(+val * (10 ** decimal)) / (10 ** decimal)
//     // return (number >= 1000 || number <= -1000) ? parseFloat(number.toString()).toLocaleString() : number.toFixed(decimal)
//     const dec = new Decimal(val)

//     if (dec.greaterThanOrEqualTo(100) || dec.lessThanOrEqualTo(-1000)) {
//         return parseFloat(dec.toFixed(decimal)).toLocaleString()
//     }

//     return new Decimal(dec.toFixed(decimal)).toString()

// }

// it makes number short but not less than 2 numbers after dot
// 123.11118888 -> 123.111188 but 123123123123.123 ->123123123123.12
export function shortifyNumber (val: string | number, sigDig = 8) {
  const v = (() => {
    if (typeof val === 'string') return new Decimal(val.replace(',', ''))
    return new Decimal(val)
  })()
  const maxVal = '9'.repeat(sigDig - 2)
  if (v.greaterThan(maxVal)) return v.toDecimalPlaces(2).toString()
  return v.toSignificantDigits(sigDig).toString()
}

export function getThemeColor (variable: string): string {
  variable = variable.replace('c-', `c-${'C-Patex'}-`) // TODO: fix on other projects

  return window.CSS_VARS[window.DARK_MODE ? `${variable}-dark` : variable]
}

export function isFileUploaded (obj) {
  return obj.name !== undefined || obj.length > 0
}
