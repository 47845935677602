import { setLocale } from '@/lib/localization'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      language: {
        // value: window.APP_LANGUAGE,
        // label: window.LANGUAGES.find(({ code }) => code === window.APP_LANGUAGE).name,
        value: 'en',
        label: 'English',
      },
    }
  },
  computed: {
    ...mapState({
      MixinNavLangSwitch_languages: state => state.globals.languages || [],
    }),

    MixinNavLangSwitch_currentLanguage () {
      return this.MixinNavLangSwitch_languages.find(l => l.code === this._currentLocale) || {}
    },
    _currentLocale () {
      return this.$i18n.locale === 'fallbackLocale' ? 'en' : this.$i18n.locale // TODO: fallbackLocale? nuxt?
    },
    MixinNavLangSwitch_currentLocale () {
      return this._currentLocale
    },
  },
  methods: {
    async MixinNavLangSwitch_setLocale (locale) {
      const code = typeof locale === 'object' && locale ? locale.code : 'en'
      process.client && setTimeout(() => {
        this.$zendeskWidget.updateSettings(this.$zendeskWidget.langPreferences)
        this.$zendeskWidget.setLocale(code)
      }, 1500)
      await this.$i18n.setLocale(code)
      // setLocale(this, code)
    },
    MixinNavLangSwitch_onClickLang (language) {
      return this.MixinNavLangSwitch_setLocale(language)
    },
  },
}
