// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/icons/statuses/default-check.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".exc-checkbox[data-v-33f8813b]{position:relative}.exc-checkbox label[data-v-33f8813b]{margin:0;cursor:pointer;top:0;left:0}.exc-checkbox__input[data-v-33f8813b]{display:none}.exc-checkbox label[data-v-33f8813b]:before{content:\"\";height:16px;width:16px;min-height:16px;min-width:16px;transition:all .15s ease-out 0s;color:#fff;cursor:pointer;outline:none;margin:0;z-index:888;position:absolute;top:calc(50% + 2px);transform:translateY(-50%);left:0;border:1px solid rgba(145,148,159,.4)!important;padding:.5625rem;border-radius:.25rem}.exc-checkbox label[data-v-33f8813b]:hover:before{border:1px solid #1c1d20!important;border:1px solid var(--c-main,#1c1d20)!important}.exc-checkbox__input:checked+label[data-v-33f8813b]:before{content:\"\";display:flex;align-items:center;justify-content:center;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:#1c1d20;background-color:var(--c-main,#1c1d20);background-repeat:no-repeat;background-position:50%}.exc-checkbox__input[data-v-33f8813b]:before{top:50%;left:50%;transform:translate(-50%,-50%)}.exc-checkbox__input:disabled+label[data-v-33f8813b]:before{background:rgba(145,148,159,.6)!important;border:1px solid rgba(145,148,159,.4)!important}@-webkit-keyframes click-wave-data-v-33f8813b{0%{height:16px;width:16px;opacity:.2}to{height:70px;width:70px;margin-left:-27px;margin-top:-27px;opacity:0}}@keyframes click-wave-data-v-33f8813b{0%{height:16px;width:16px;opacity:.2}to{height:70px;width:70px;margin-left:-27px;margin-top:-27px;opacity:0}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
