import { Module, MutationTree } from 'vuex'
import { FiatItem, FiatItemInterface } from '@/data-objects/p2p/FiatItem'
// import api from '@/lib/api'
import { CryptoItem, CryptoItemInterface } from '@/data-objects/p2p/CryptoItem'
import {
  UserNotificationModel,
  UserNotificationModelInterface,
} from '@/lib/data-models/p2p/UserNotificationModel'
import {
  LendingApplicationItem,
  LendingApplicationItemInterface,
} from '@/data-objects/p2p/LendingApplicationItem'
import { notifyError, showP2pError } from '@/lib/bus'
import { RootState } from '..'

interface P2pStatisticsStateInterface {
  fiat: null | FiatItem[]
  crypto: null | CryptoItem[]
  userNotifications: null | UserNotificationModel[]
  lendingUserApps: null | LendingApplicationItem[]
}

const state: P2pStatisticsStateInterface = {
  fiat: null,
  crypto: null,
  userNotifications: null,
  lendingUserApps: null,
}

const actions = {
  async getActualCurrencyBalance ({ commit, state }) {
    const resp = await this.$api.p2pGetActualCurrencyBalance()
    if (resp.errors) {
      console.error('api.p2pGetActualCurrencyBalance::', resp.errors)
      notifyError({
        text: resp.errors[0],
        title: 'p2pGetActualCurrencyBalance',
      })
      return
    }
    const { CRYPTO, FIAT } = resp.response
    commit('setCrypto', CRYPTO)
    commit('setFiat', FIAT)
    return state
  },
  async getUserNotifications ({ commit, state }) {
    const { response, errors } = await this.$api.p2pUserNotifications()
    if (errors) {
      console.error('api.p2pUserNotifications::', errors)
      // notifyError({ text: errors[0], title: 'p2pUserNotifications' })
      showP2pError(errors)
      return
    }
    commit('setUserNotifications', response)

    return state
  },
  async getLendingUserApps ({ commit, state }) {
    const { response, errors } = await this.$api.p2pLendUser()
    if (errors) {
      console.error('api.p2pLendUser::', errors)
      // notifyError({ text: errors[0], title: 'p2pLendUser' })
      showP2pError(errors)
      return
    }
    commit('setLendingUserApps', response)
    return state
  },
}

const mutations: MutationTree<P2pStatisticsStateInterface> = {
  setFiat (state, pld: FiatItemInterface[]) {
    state.fiat = pld.map(o => new FiatItem(o))
  },
  setCrypto (state, pld: CryptoItemInterface[]) {
    state.crypto = pld.map(o => new CryptoItem(o))
  },
  setUserNotifications (state, pld: UserNotificationModelInterface[]) {
    state.userNotifications = pld.map(o => new UserNotificationModel(o))
  },
  setLendingUserApps (state, pld: LendingApplicationItemInterface[]) {
    state.lendingUserApps = pld.map(o => new LendingApplicationItem(o))
  },
}

export const statistics: Module<P2pStatisticsStateInterface, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
}
