<template>
  <div
    :class="classes"
    class="exc-ticker-logo"
  >
    <img
      :src="src"
      :alt="alt"
      @error="setAltImg"
    >
  </div>
</template>

<script>
import { sizeValidator, defaultSize } from '@/lib/utils'

export default {
  name: 'ExcTickerLogo',
  props: {
    src: {
      type: String,
      default: '/img/balance/coinDefaultIcon.png',
    },
    alt: {
      type: String,
      default: '',
    },
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    transparentBorder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes () {
      return {
        // sizes
        'exc-ticker-logo--xs': this.size === 'xs',
        'exc-ticker-logo--s': this.size === 's',
        'exc-ticker-logo--m': this.size === 'm',
        'exc-ticker-logo--l': this.size === 'l',
        'exc-ticker-logo--xl': this.size === 'xl',

        // options
        'exc-ticker-logo--active': this.isActive,
        'exc-ticker-logo--transparent-border': this.transparentBorder && !this.isActive,
      }
    },
  },
  methods: {
    setAltImg (event) {
      console.log(12312312312312323)
      event.target.src = require('@/static/img/balance/coinDefaultIcon.svg') // '/img/balance/coinDefaultIcon.png'
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-ticker-logo {
  $sizeMobile: 24px;
  $tickerSize: (
    xs: 24px,
    s: 28px,
    m: 32px,
    l: 40px,
    xl: 44px,
  );
  display: flex;
  align-items: center;
  justify-content: center;

  width: toRem($sizeMobile);
  height: toRem($sizeMobile);
  max-width: toRem($sizeMobile);
  max-height: toRem($sizeMobile);
  min-width: toRem($sizeMobile);
  min-height: toRem($sizeMobile);
  //border-radius: 50%;
  //border: 1px solid color(color-05);

  &--transparent-border {
    border-color: transparent;
  }

  &--active {
    border-color: color(main);
  }

  @include bp(s) {
    @each $size, $value in $tickerSize {
      &--#{$size} {
        width: toRem($value);
        height: toRem($value);
        max-width: toRem($value);
        max-height: toRem($value);
        min-width: toRem($value);
        min-height: toRem($value);
      }
    }
  }

  img {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    //border-radius: 999px;
  }
}
</style>
