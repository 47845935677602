<template>
  <exc-header-nav-item
    class="exc-header-desk-nav-creator"
    v-bind="{ ...link, name: undefined, label: undefined, menu: undefined, onlyTheme: onlyTheme }"
  >
    <template #name>
      <span :class="{'flx flx--items-center': link.label}">
        {{ link.name }}
        <exc-header-label-creator
          v-if="link.label"
          :label="link.label"
        />
      </span>
    </template>
    <template #dropdown>
      <template v-for="menuItem in link.menu">
        <exc-header-dropdown-item
          v-if="menuItem.condition === undefined || menuItem.condition"
          :key="menuItem.name"
          v-bind="{ ...menuItem, label: undefined, name: undefined, condition: undefined, onlyTheme: onlyTheme }"
        >
          <template
            v-if="menuItem.icon"
            #left
          >
            <component :is="menuItem.icon" />
          </template>

          <template #name>
            {{ menuItem.name }}
            <exc-header-label-creator
              v-if="menuItem.label"
              :label="menuItem.label"
            />
          </template>

          <template
            v-if="menuItem.description"
            #description
          >
            {{ menuItem.description }}
          </template>
        </exc-header-dropdown-item>
      </template>
    </template>
  </exc-header-nav-item>
</template>

<script>
import { onlyThemePropValidator } from '@/lib/utils'
import ExcHeaderNavItem from './ExcHeaderNavItem.vue'
import ExcHeaderDropdownItem from './ExcHeaderDropdownItem.vue'
import ExcHeaderLabelCreator from './ExcHeaderLabelCreator.vue'

export default {
  name: 'ExcHeaderDeskNavCreator',
  components: {
    ExcHeaderNavItem,
    ExcHeaderDropdownItem,
    ExcHeaderLabelCreator,
  },
  props: {
    link: { type: Object, required: true },
    onlyTheme: onlyThemePropValidator(),
  },
}
</script>
