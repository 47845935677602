































import Vue from 'vue'

export default Vue.extend({
  name: 'TdxDropdownTriangle',
  props: {
    direction: {
      validator: v => v === 'top' || v === 'bottom',
      default: 'bottom',
    },
  },
})
