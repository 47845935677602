











import Vue from 'vue'

export default Vue.extend({
  name: 'ExcLabel',
  props: {
    /* eslint-disable vue/require-default-prop */
    transparent: { type: Boolean, default: false },
    green: { type: Boolean, default: false },
    red: { type: Boolean, default: false },
    main: { type: Boolean, default: false },
    grey: { type: Boolean, default: false },
    purple: { type: Boolean, default: false },
    violet: { type: Boolean, default: false },
    circle: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    translateY: { type: String, default: '-2px' },
    translateX: { type: String, default: '0' },
  },
  computed: {
    classes () {
      return {
        'bor--all txt txt--bold pl-4 pr-4': true,

        'bor--radius-01': !this.circle,
        'exc-label--bordered': this.circle,

        // for colored background
        'txt--color-04 bor--transp bg--color-10': !this.transparent && this.purple,
        'txt--color-09 bor--transp bg--transp-007': !this.transparent && this.green,
        'txt--color-09 bor--transp bg--red': !this.transparent && this.red,
        'txt--color-09 bor--transp bg--transp-001': !this.transparent && this.grey,
        // TODO: orange instead of purple
        'txt--color-01 bor--transp bg--color-09': !this.transparent && this.violet,
        'txt--color-01 bor--transp bg--main': !this.transparent && !this.green && !this.red && !this.grey && !this.violet && !this.purple, // default

        // for transparent
        'txt--green bor--green bg--transp': this.transparent && this.green,
        'txt--red bor--red bg--transp': this.transparent && this.red,
        'txt--grey bor--grey bg--transp': this.transparent && this.grey,
        // TODO: orange instead of violet
        'txt--color-01 bor--color-09 bg--transp': this.transparent && this.violet,
        'txt--main bor--main bg--transp': this.transparent && !this.green && !this.red && !this.grey && !this.violet, // default for transparent
      }
    },
  },
})
