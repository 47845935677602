<template>
  <exc-input-label-error-wrapper
    :field="field"
    :label="label"
    :info-message="infoMessage"
    :custom-error="customError"
    :name="name"
    :form-key="formKey"
    class="exc-textarea"
  >
    <!-- eslint-disable vue/valid-v-on -->
    <!-- eslint-disable vue/no-parsing-error  -->
    <template #default="slotProps">
      <exc-base-textarea
        ref="input"
        :value="value"
        v-bind="{ ...$attrs, ...slotProps }"
        class="textarea-simple__input"
        v-on="{ ...$listeners, input: val => $emit('input', val) }"
      />
    </template>
  </exc-input-label-error-wrapper>
</template>

<script>
import ExcInputLabelErrorWrapper from '@/components/common/form/validation/ExcInputLabelErrorWrapper.vue'
import ExcBaseTextarea from '@/components/common/form/textarea/ExcBaseTextarea.vue'

export default {
  name: 'ExcTextarea',
  components: {
    ExcInputLabelErrorWrapper,
    ExcBaseTextarea,
  },
  props: {
    value: {
      validator: v => ['string', 'number'].includes(typeof v) || v === null,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    field: {
      type: [Object], // vuelidate field
      default: null,
    },
    customError: {
      type: String,
      default: '',
    },
    infoMessage: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    formKey: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-textarea {
  $root: &;

  position: relative;

  &__label {
    margin: 0 0 toRem(5px) 0;
  }

  &__info {
    position: absolute;
    top: 100%;
    left: 0;
    height: toRem(15px);
    display: flex;
  }
}
</style>
