<template>
  <exc-link
    v-bind="$attrs"
    :force-use-link="forceUseLink"
    no-decoration
    class="exc-header-dropdown-item"
    v-on="$listeners"
  >
    <template #default="{ isActive }">
      <div
        class="exc-header-dropdown-item__container pr-16 pl-16"
        :class="sizeClass"
      >
        <div
          v-if="$slots.left"
          class="exc-header-dropdown-item__left"
          :class="{
            // 'txt--color-06-dark': isActive,
            // 'txt--color-04': onlyTheme === 'dark',
            // 'txt--color-03 txt--hover--color-04': onlyTheme === 'light',
            // 'txt--color-03 txt--hover--color-04': !onlyTheme,
            'txt--color-03': true,
          }"
        >
          <slot name="left" v-bind="{ isActive }" />
        </div>
        <div class="exc-header-dropdown-item__middle">
          <div class="exc-header-dropdown-item__name">
            <span
              :class="{
                'txt--main': isActive,
                'txt--color-04': !isActive && onlyTheme === 'dark',
                'txt--color-04': !isActive && onlyTheme === 'light',
                'txt--color-04': !isActive && !onlyTheme,
              }"
              class="txt txt--16px txt--height-18px"
            >
              <slot name="name" v-bind="{ isActive }" />
            </span>
          </div>
          <div class="exc-header-dropdown-item__description">
            <span
              :class="{ 'txt--main': isActive, 'txt--color-04': !isActive }"
              class="txt txt--10px"
            >
              <slot name="description" v-bind="{ isActive }" />
            </span>
          </div>
        </div>
        <div v-if="$slots.right" class="exc-header-dropdown-item__right">
          <slot name="right" v-bind="{ isActive }" />
        </div>
      </div>
    </template>
  </exc-link>
</template>

<script>
import { onlyThemePropValidator, sizeValidator, defaultSize } from '@/lib/utils'
import ExcLink from '@/components/common/navigation/link/ExcLink.vue'

export default {
  name: 'ExcHeaderDropdownItem',
  components: {
    ExcLink,
  },
  props: {
    onlyTheme: onlyThemePropValidator(),
    forceUseLink: { type: Boolean, default: false },
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
  },
  computed: {
    sizeClass () {
      return {
        'pt-4 pb-4': this.size === 'xs',
        'pt-8 pb-8': this.size === 's',
        'pt-10 pb-10': this.size === 'm',
        'pt-16 pb-16': this.size === 'l',
        // 'pt-12 pb-12': this.size === 'xl',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-header-dropdown-item {
  $root: &;

  &__container {
    display: flex;
    cursor: pointer;
    transition: all 0.3s;
    max-width: 380px; // MAX WIDTH IS HERE !!!
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
      width: 2px;
      transition: all 0.2s;
      background-color: transparent;
    }

    * {
      transition: all 0.3s;
    }

    &:hover {
      // background-color: $c-color-01;
      color: $c-color-04;
      background-color: $c-transp-04;

      #{$root}__left {
        color: $c-color-04;
      }

      //.exc-header-dropdown-item__name {
      //  color: color("color-01");
      //}
      //
      //&::v-deep {
      //  .exc-header-dropdown-item__name {
      //    fill: color("color-05");
      //  }
      //}
    }

    .main-label {
      color: color(color-09) !important;
    }
  }

  &__left {
    width: toRem(20px);
    margin-right: toRem(5px);
    display: flex;
    align-items: center;
  }

  &__middle {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  // &__right {}

  &__name {
    display: flex;
  }

  &__description {
    display: flex;
  }
}
</style>
