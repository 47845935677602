import { Module, MutationTree, ActionContext } from 'vuex'
import {
  LendingApplicationItem,
  // LendingApplicationItemInterface,
} from '@/data-objects/p2p/LendingApplicationItem'
import { UserStatManagementModel } from '@/lib/data-models/p2p/UserStatManagementModel'
import { OrderRequestModel } from '@/lib/data-models/p2p/OrderRequestModel'
import { OrderLendModel } from '@/lib/data-models/p2p/OrderLendModel'
import { BorrowingApplicationItem } from '@/data-objects/p2p/BorrowingApplicationItem'
import { showP2pError } from '@/lib/bus'
import { RootState } from '..'

interface P2pFundsManagementStateInterface {
  userStatManagement: null | object
  userActiveOrders: null | object
  userDealsHistory: null | object
}

const state: P2pFundsManagementStateInterface = {
  userStatManagement: null,
  userActiveOrders: null,
  userDealsHistory: null,
}

const actions = {
  async getUserStatManagement ({
    commit,
    state,
  }: ActionContext<
    P2pFundsManagementStateInterface,
    RootState
  >): // props: LendingApplicationItemInterface
  Promise<P2pFundsManagementStateInterface> {
    const { response, errors } = await this.$api.p2pUserStatManagement()
    if (errors) {
      console.error('api.p2pUserStatManagement::', errors)
      // notifyError({ text: errors[0], title: 'api.p2pUserStatManagement()' })
      showP2pError(errors)
      return
    }
    commit('setUserStatManagement', response)
    return state
  },
  async getUserActiveOrders ({
    commit,
    state,
  }: ActionContext<P2pFundsManagementStateInterface, RootState>): Promise<
    P2pFundsManagementStateInterface
  > {
    const { response, errors } = await this.$api.p2pActiveOrders()
    if (errors) {
      console.error('api.p2pActiveOrders::', errors)
      // notifyError({ text: errors[0], title: 'api.p2pActiveOrders()' })
      showP2pError(errors)
      return
    }
    commit('setUserActiveOrders', response)
    return state
  },
  async getUserDealsHistory ({
    commit,
    state,
  }: ActionContext<P2pFundsManagementStateInterface, RootState>): Promise<
    P2pFundsManagementStateInterface
  > {
    const { response, errors } = await this.$api.p2pDealsHistory()
    if (errors) {
      console.error('api.p2pDealsHistory::', errors)
      // notifyError({ text: errors[0], title: 'api.p2pDealsHistory()' })
      showP2pError(errors)
      return
    }
    commit('setUserDealsHistory', response)
    return state
  },
}

const mutations: MutationTree<P2pFundsManagementStateInterface> = {
  setUserDealsHistory (state, pld) {
    state.userDealsHistory = {
      borrow: pld.borrow
        ? pld.borrow.map(o => new BorrowingApplicationItem(o))
        : [],
      lend: pld.loan ? pld.loan.map(o => new LendingApplicationItem(o)) : [],
    }
  },
  setUserActiveOrders (state, pld) {
    state.userActiveOrders = {
      borrow: pld.borrow ? pld.borrow.map(o => new OrderRequestModel(o)) : [],
      lend: pld.loan ? pld.loan.map(o => new OrderLendModel(o)) : [],
    }
  },
  setUserStatManagement (state, pld) {
    state.userStatManagement = {}
    Object.keys(pld).forEach((key) => {
      state.userStatManagement[key] = new UserStatManagementModel(pld[key])
    })
  },
}

export const fundsManagement: Module<
  P2pFundsManagementStateInterface,
  RootState
> = {
  namespaced: true,
  state,
  actions,
  mutations,
}
