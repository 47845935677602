import Vue from 'vue'
import { invertAlignment } from '@/lib/toolTipAlignmentType'
import BaseToolTip from '@/components/base/BaseToolTip'

const antiTriggers = {
  focus: 'blur',
  mouseover: 'mouseleave',
}

const createdTooltip = {}
let hideTimeoutId = false

const getXpositionRegardingTarget = (alignment, targetRect) => {
  const { horizontal } = alignment.target
  switch (horizontal) {
    case 1:
      return targetRect.x
    case 2:
      return targetRect.x + (targetRect.width / 2)
    case 3:
      return targetRect.right
    default:
      return targetRect.x
  }
}
const getYpositionRegardingTarget = (alignment, targetRect) => {
  const { vertical } = alignment.target
  switch (vertical) {
    case 1:
      return targetRect.top
    case 2:
      return targetRect.y + targetRect.height / 2
    case 3:
      return targetRect.y + targetRect.height
    default:
      return targetRect.top
  }
}

const removeTooltip = (trigger) => {
  createdTooltip[trigger].$props.showTooltip = false
  createdTooltip[trigger].$props.content = null
}

const tooltipDirective = {
  inserted (el, binding) {
    if (!binding.value) {
      return
    }

    el.vTooltipConfig = {}
    const trigger = binding.value.trigger || 'mouseover'

    if (!createdTooltip[trigger]) {
      const Component = Vue.extend(BaseToolTip)
      createdTooltip[trigger] = new Component()
      createdTooltip[trigger].$mount()
      document.getElementsByTagName('body')[0].appendChild(createdTooltip[trigger].$el)
    }

    const handleTrigger = () => {
      if (hideTimeoutId) {
        clearTimeout(hideTimeoutId)
        hideTimeoutId = false
      }

      const targetRect = el.getBoundingClientRect()

      createdTooltip[trigger].$props.invertAlignmentHorizontal = () => {
        const alignment = invertAlignment(binding.value.alignment, 'horizontal')
        createdTooltip[trigger].$props.alignment = alignment
        createdTooltip[trigger].$props.xPositionRegardingTarget = getXpositionRegardingTarget(
          alignment,
          targetRect,
        )
      }

      createdTooltip[trigger].$props.invertAlignmentVertical = () => {
        const alignment = invertAlignment(binding.value.alignment, 'vertical')
        createdTooltip[trigger].$props.alignment = alignment
        createdTooltip[trigger].$props.yPositionRegardingTarget = getYpositionRegardingTarget(
          alignment,
          targetRect,
        )
      }

      createdTooltip[trigger].$props.mainStyle = binding.value.mainStyle
      createdTooltip[trigger].$props.showTooltip = true
      createdTooltip[trigger].$props.alignment = binding.value.alignment
      createdTooltip[trigger].$props.targetRect = targetRect
      createdTooltip[trigger].$props.xPositionRegardingTarget = getXpositionRegardingTarget(
        binding.value.alignment,
        targetRect,
      )
      createdTooltip[trigger].$props.yPositionRegardingTarget = getYpositionRegardingTarget(
        binding.value.alignment,
        targetRect,
      )
    }

    const handleAntitrigger = () => {
      if (hideTimeoutId) {
        clearTimeout(hideTimeoutId)
        hideTimeoutId = false
      }

      hideTimeoutId = setTimeout(() => removeTooltip(trigger), 0)
    }

    el.vTooltipConfig.handleTrigger = handleTrigger
    el.vTooltipConfig.handleAntitrigger = handleAntitrigger
    el.vTooltipConfig.trigger = trigger
    el.vTooltipConfig.anitTrigger = antiTriggers[trigger]

    el.addEventListener(el.vTooltipConfig.trigger, handleTrigger, { capture: true })
    el.addEventListener(el.vTooltipConfig.anitTrigger, handleAntitrigger)
  },
  update (el, binding) {
    if (binding.value && binding.value.content !== undefined) {
      createdTooltip[el.vTooltipConfig.trigger].$props.content = binding.value.content
    }
  },
  unbind (el) {
    if (el.vTooltipConfig) {
      removeTooltip(el.vTooltipConfig.trigger)
      el.removeEventListener(el.vTooltipConfig.trigger, el.vTooltipConfig.handleTrigger)
      el.removeEventListener(el.vTooltipConfig.anitTrigger, el.vTooltipConfig.handleTrigger)
    }
  },
}

export default tooltipDirective
