import { TimezoneDate, TimezoneDateInterface } from "./UserNotificationModel"
import Decimal from "decimal.js-light"

// amount: "1000.000000000000000000"
// currency: "BTC"
// date: { date: "2019-08-29 16:37:26.000000", timezone_type: 3, timezone: "UTC" }
// days: 10
// pay_percent: "10.000"
// status: 20
// username: "p_shidlovsky"

export interface OrderRequestModelInterface {
    amount: string
    currency: Ticker
    date: TimezoneDateInterface
    days: number
    pay_percent: string
    status: number
    username: string
    id: number
}


export class OrderRequestModel {
    amount: string
    amount_string: string
    currency: Ticker
    date: TimezoneDate
    days: number
    days_string: string
    payPercent: string
    payPercent_string: string
    status: number
    username: string
    borrowId: number
    constructor(o: OrderRequestModelInterface) {
        this.amount = o.amount
        this.amount_string = parseFloat(new Decimal(o.amount).toFixed(2)) + ' $'
        this.currency = o.currency
        this.date = new TimezoneDate(o.date)
        this.days = o.days
        this.days_string = o.days + ' d'
        this.payPercent = o.pay_percent
        this.payPercent_string = parseFloat(new Decimal(o.pay_percent).toFixed(2)) + ' %'
        this.status = o.status
        this.username = o.username
        this.borrowId = o.id
    }
}