import { parseNumber, formatHistoryTime } from '@/lib/utils'

export class OrderModel {
    id: number
    price: string
    amount: string
    time: number
    pair: PairName
    createdAt: string
    finishedAt?: string
    tradeType: TradeType
    type: 'Limit' | 'Market' | 'Stop Limit' | 'OCO' | ''
    dealedAmount: string
    dealedMoney: string

    constructor (rawOrder: RawOrder) {
      this.id = rawOrder.id
      this.price = parseNumber(rawOrder.price)
      this.amount = parseNumber(rawOrder.amount)
      this.time = rawOrder.ftime || rawOrder.ctime // date
      this.pair = rawOrder.market
      this.createdAt = formatHistoryTime(rawOrder.ctime * 1000)
      this.finishedAt = rawOrder.ftime ? formatHistoryTime(rawOrder.ftime * 1000) : undefined
      this.tradeType = (rawOrder.side === 2 ? 'buy' : 'sell')
      if (rawOrder.algorithm) {
        this.type = rawOrder.algorithm === 1 ? 'Stop Limit' : rawOrder.type === 2 ? 'OCO' : ''
        // NOTE: maybe?
        // this.type = (rawOrder.type === 1 ? 'Stop Limit' : rawOrder.type === 2 ? 'OCO' : '')
      } else {
        this.type = (rawOrder.type === 1 ? 'Limit' : rawOrder.type === 2 ? 'Market' : '')
      }
      this.dealedAmount = parseNumber(rawOrder.deal_stock)
      this.dealedMoney = parseNumber(rawOrder.deal_money)
    }
}
