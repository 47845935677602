export class ListingCardModel {
  image: string
  name: string
  link: string
  pairs: string[]
  place: string

  constructor (d: ListingCardsResponse) {
    this.image = d.image
    this.name = d.name
    this.link = d.link
    this.pairs = d.pairs
    this.place = d.place
  }
}
