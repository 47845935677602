export default (function () {
  if (process.server) return { copy: () => {} }

  let textArea

  function isOS () {
    return navigator.userAgent.match(/ipad|iphone/i)
  }

  function createTextArea (text) {
    textArea = document.createElement('textArea')
    textArea.value = text
    textArea.readonly = true
    textArea.style = 'position:absolute;top:50vh'
    document.body.appendChild(textArea)
  }

  function selectText () {
    let range,
      selection

    if (isOS()) {
      range = document.createRange()
      range.selectNodeContents(textArea)
      selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      textArea.setSelectionRange(0, 999999)
    } else {
      textArea.select()
    }
  }

  function copyToClipboard () {
    document.execCommand('copy')
    document.body.removeChild(textArea)
  }

  function copy (text) {
    createTextArea(text)
    selectText()
    copyToClipboard()
  }

  return {
    copy,
  }
})()
