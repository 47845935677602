<template>
  <!-- eslint-disable vue/valid-v-on -->
  <!-- eslint-disable vue/no-parsing-error  -->
  <textarea
    :value="value"
    v-bind="$attrs"
    :class="classes"
    class="exc-base-textarea bor--all bor--transp-02 bor--radius-01 txt txt--16px txt--theme-08"
    v-on="{ ...$listeners, input: ev => $emit('input', ev.target.value) }"
  />
</template>

<script>
export default {
  name: 'BaseTextarea',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    resizable: { type: Boolean, default: false },
    withoutBorder: { type: Boolean, default: false }, // TODO: make this working
    hasError: { type: Boolean, default: false },
    hasSuccess: { type: Boolean, default: false },
  },
  computed: {
    classes () {
      return {
        'base-textarea--resizable': this.resizable,
        'bor--all bor--radius-01 txt txt--16px txt--theme-08': true,
        'bor--transp-02': !this.withoutBorder,
        'bor--transp sha--none base-input--without-border': this.withoutBorder,
        'bor--red': this.hasError,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: 0 toRem(12px);
  background-color: transparent;
  resize: none;
  padding: toRem(2px) toRem(12px);
  height: toRem(70px);

  &:focus:not(:disabled) {
    // TODO: refactor to mixin and add everywhere
    box-shadow: inset 0px 0px 0px 1px color(main);
    outline: none;
  }

  &.base-textarea--resizable {
    resize: auto;
  }
}

textarea:disabled {
  color: inherit;
  opacity: .5;
  cursor: default;
  background-color: transparent;
}

textarea::-webkit-outer-spin-button,
textarea::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  // -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: transparent;
}
</style>
