


















import Vue from 'vue'

export default Vue.extend({
  name: 'RdxArrow',
  props: {
    direction: {
      validator: v => v === 'top' || v === 'bottom',
      default: 'bottom',
    },
    colorMainIcon: {
      type: Boolean,
      default: false,
    },
    arrowDirection: {
      type: String,
      default: 'top',
    },
  },
  computed: {
    iconColor () {
      return this.colorMainIcon ? '#1C1D20' : '#191919'
    },
  },
})
