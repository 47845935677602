import { Module } from 'vuex'

import ws from '@/lib/websocket'
import { RootState } from '@/store'
// import { setLocale } from '@/lib/localization'
// import { AUTH_TOKEN_COOKIE_KEY, AUTH_ACCESS_TOKEN_LIFETIME, AUTH_TOKEN_COOKIE_OPTIONS } from '~/constants'
// import { clearCachedData, wait } from '~/lib/utils'

export interface AuthState {
  isLogined: boolean
  recaptcha: any
}

const AuthModule: Module<AuthState, RootState> = {
  namespaced: true,
  state: () => ({
    isLogined: process.client ? window.LOGINED || false : false,
    recaptcha: {},
  }),
  mutations: {
    setRecaptchaReference (state, recaptcha: any) {
      state.recaptcha = recaptcha
    },
    // setLoginedState (state, logined) {
    //   state.isLogined = logined
    //   if (!process.server) window.LOGINED = logined
    // },
  },
  actions: {
    async changeLoginedState ({ commit }, logined) {
      //   commit('setLoginedState', logined)

      if (logined && process.client) {
        // ws.setUrl(rootState.globals) // TODO: WS_ERROR
        ws.setToken(this.$cookies.get('socketToken'))
        ws.logout()
        await ws.authorize()
      }

      //   return Promise.resolve()
      // },

      // async setAuthTokens ({ dispatch, state }, tokens) {
      //   await wait(10)
      //   // if (!tokens || !Object.keys(tokens).length) return

      //   // await this.$cookies.set(AUTH_TOKEN_COOKIE_KEY, JSON.stringify({
      //   //   ...tokens,
      //   //   token_expire: new Date().getTime() + AUTH_ACCESS_TOKEN_LIFETIME,
      //   // }), AUTH_TOKEN_COOKIE_OPTIONS)

      //   // if (!state.isLogined) {
      //   //   await dispatch('changeLoginedState', true)
      //   // }
      // },

      // async restoreAuth ({ dispatch }) {
      //   // const authTokens = this.$cookies.get(AUTH_TOKEN_COOKIE_KEY)

      //   // if (authTokens) await dispatch('changeLoginedState', true)
      // },

      // checkAuth ({ state, dispatch }) {
      //   // const authTokens = this.$cookies.get(AUTH_TOKEN_COOKIE_KEY)

      //   // if (!authTokens) {
      //   //   dispatch('changeLoginedState', false)
      //   // } else if (!state.isLogined) {
      //   //   dispatch('restoreAuth')
      //   // }
    },

    async logout ({ commit }) {
      // const clearLocalData = () => {
      //   clearCachedData()
      //   // this.$cookies.remove(AUTH_TOKEN_COOKIE_KEY)
      //   // this.$cookies.remove('socketToken')

      //   if (process.client) {
      //     localStorage.removeItem('socketToken')
      //     ws.setToken(null)
      //     ws.close() // close current connectio and create new without auth token
      //   }

      //   // commit('setLoginedState', false)
      // }

      await this.$authentication.logout()
      ws.logout()
      // clearCachedData()
      // this.$cookies.remove(AUTH_TOKEN_COOKIE_KEY)
      // this.$cookies.remove('socketToken')

      // if (process.client) {
      //   localStorage.removeItem('socketToken')
      //   ws.setToken(null)
      //   ws.close() // close current connectio and create new without auth token
      // }
      commit('resetData', null, { root: true })

      // if (useWithoutApi) {
      //   clearLocalData()
      //   commit('resetData', null, { root: true })
      // } else {
      //   await this.$api.logout()
      //     .then(({ errors, status }) => {
      //       if (!errors || status === 401) {
      //         clearLocalData()
      //       }
      //       commit('resetData', null, { root: true })
      //     })
      // }
    },
  },
}

export default AuthModule
