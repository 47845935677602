<template>
  <div class="language-selector">
    <exc-base-dropdown
      class="language-selector__dropdown"
      :value="currentLocale"
      :options="langOptions"
      without-border
      open-down
      dropdown-align="right"
      only-theme="light"
      @input="o => MixinNavLangSwitch_onClickLang({ code: o.value })"
    >
      <template #item="{ option }">
        <div
          class="txt--weight-500 txt--16px txt--height-20px txt--color-04 pl-16 pr-16 pt-10 pb-10"
        >
          {{ option.label }}
        </div>
      </template>
      <template #selected="{ option }">
        <div
          class="language-selector__selected txt--weight-500 txt--16px txt--height-30px txt--color-04"
        >
          {{ option.label }}
        </div>
      </template>
    </exc-base-dropdown>
  </div>
</template>

<script>
import { ExcBaseDropdown } from '@/components/common'
import MixinNavLangSwitch from '@/mixins/MixinNavLangSwitch'

export default {
  name: 'LanguageSelector',
  components: {
    ExcBaseDropdown,
  },
  mixins: [MixinNavLangSwitch],
  computed: {
    currentLocale () {
      return this.langOptions.find(
        o => o.value === this.MixinNavLangSwitch_currentLocale,
      )
    },
    langOptions () {
      return this.MixinNavLangSwitch_languages.map(o => ({
        label: o.name,
        value: o.code,
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
.language-selector {
  &__selected {
    @include bp(l) {
      font-weight: 700;
      line-height: toRem(20px);
    }
  }

  &__dropdown::v-deep {
    height: auto;

    .exc-base-dropdown__form-control {
      padding-left: 0;
    }

    @include bp(0, l) {
      .exc-base-dropdown__options-list {
        min-width: toRem(160px);
        left: auto;
      }
      .exc-base-dropdown__open-icon {
        padding-right: 0;
      }
    }
  }
}
</style>
