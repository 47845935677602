import { actionWrapper, dateFormatter, formatHistoryTime, parseNumber } from '@/lib/utils'
import { Module } from 'vuex'
import { Decimal } from 'decimal.js-light'
import take from 'lodash/take'
import orderBy from 'lodash/orderBy'
import assign from 'lodash/assign'
import entries from 'lodash/entries'
import fromPairs from 'lodash/fromPairs'
import Vue from 'vue'
import { MARGIN_TRADE_SPOT } from '@/lib/constants'

// type TradeType = typeof MARGIN_TRADE_SPOT | typeof MARGIN_TRADE_CROSS

export interface TradeState {
  history: Hash<Hash<TradeEntry>>
  buyDepth: Hash<Hash<RawDepth>>
  sellDepth: Hash<Hash<RawDepth>>
  currentTradeType: TradePlatform
}

const TradeModule: Module<TradeState, any> = {
  namespaced: true,
  state: () => ({
    history: {},
    buyDepth: {},
    currentTradeType: MARGIN_TRADE_SPOT,
    sellDepth: {},
  }),
  mutations: {
    setHistoryByPair (state, { payload: { pairName, history } }) {
      const prevHistory = state.history[pairName] || ({} as Hash<TradeEntry>)

      state.history = {
        ...state.history,
        [pairName]: {
          ...prevHistory,
          ...history,
        },
      }

      // // clear old history in order to prevent memory leak
      // const maxLength = 200
      // const newHistory = state.history[pairName]
      // const currentLength = Object.keys(newHistory).length

      // if (currentLength > maxLength) {
      //     // sort by id and take couple of first ids (oldest)
      //     const idsToRemove = Object.keys(newHistory).sort().splice(0, currentLength - maxLength)
      //     idsToRemove.forEach(id => delete state.history[pairName][id])
      //     state.history = { ...state.history }
      // }
    },
    updatePairDepth (state, { pairName, orders, shouldReplace }) {
      Vue.set(
        state.buyDepth,
        pairName,
        shouldReplace
          ? orders.buy
          : mergeDepth(state.buyDepth[pairName], orders.buy),
      )
      Vue.set(
        state.sellDepth,
        pairName,
        shouldReplace
          ? orders.sell
          : mergeDepth(state.sellDepth[pairName], orders.sell),
      )
    },
    setMarginType (state, type: TradePlatform) {
      state.currentTradeType = type
    },
  },
  getters: {
    orderedTradeHistory (state, _, __, rootGetters) {
      const { pairName } = rootGetters['pairs/getCurrentPair']
      const history = state.history[pairName] || {}

      return orderBy(Object.values(history), ['id'], ['desc'])
    },
    tradeHistory (_, getters) {
      return take(getters.orderedTradeHistory, 50)
    },
    buyDepth: state => (pair) => {
      const orders = Object.values(state.buyDepth[pair] || {}).sort(
        sortDepth('buy'),
      )
      return orders
    },
    sellDepth: state => (pair) => {
      return Object.values(state.sellDepth[pair] || {}).sort(sortDepth('sell'))
    },
    buyDepthOld: state => (pair) => {
      const orders = Object.values(state.buyDepth[pair] || {}).sort(
        sortDepthOld('buy'),
      )
      return orders
    },
    sellDepthOld: state => (pair) => {
      return Object.values(state.sellDepth[pair] || {}).sort(
        sortDepthOld('sell'),
      )
    },
    lastPrice: (_, getters) => (
      pair: PairName,
      tradeType: TradeType,
    ) => {
      const firstEntry = getters[`${tradeType}DepthOld`](pair)[0]

      return firstEntry ? firstEntry[0] : '0'
    },
    // buyDepthTotal sellDepthTotal
    // ...["buy", "sell"].reduce((acc, tradeType) => {
    //     acc[`${tradeType}DepthTotal`] = (state, getters, rootState, rootGetters) => {
    //         const pair = rootGetters['pairs/getCurrentPair'].pairName;

    //         return Object.values(state[`${tradeType}DepthOld`][pair] || {})
    //             .reduce((acc: Decimal, rawOrder) => acc.add(rawOrder[1]), new Decimal("0"))
    //             .toString()
    //     };

    //     return acc;
    // }, {}),
  },
  actions: {
    // deals.update
    socketMarketHistoryUpdate: actionWrapper<TradeState>({
      beforeRequestHandler: ({ payload }) => {
        const pairName = payload[0]
        const history = parseRawTradeHistory(payload[1], pairName)
        payload.pairName = pairName
        payload.history = history
      },
      apiRequest: ({ pairName, history }) => ({ pairName, history }),
      mutationName: 'setHistoryByPair',
    }),
    // depth.update
    socketOrderBookUpdate ({ commit, dispatch }, payload) {
      const [shouldReplace, rawDepth, pairName]: DepthUpdateResponse = payload

      const orders = parseDepth(rawDepth)

      commit('updatePairDepth', { pairName, orders, shouldReplace })

      dispatch('pairs/updatePrice', { pairName }, { root: true })
    },
  },
}

function parseRawTradeHistory (
  history: RawTradeHistory[],
  pair: PairName,
): Hash<TradeEntry> {
  return history.reduce((acc, { id, time, type, price, amount }) => {
    const decimalAmount = new Decimal(amount)

    acc[id] = {
      id,
      key: `${pair}-${type}-${id}`,
      tradeType: type,
      pair,
      time,
      createdAt: dateFormatter(formatHistoryTime(time * 1000)),
      amount: parseNumber(decimalAmount),
      price: parseNumber(price || '0'),
      total: parseNumber(decimalAmount.times(price || '0')),
    }

    return acc
  }, {})
}

export function parseDepth (response: GetDepthResponse): Hash<RawDepth> {
  return Object.keys(response).reduce((acc, key) => {
    const newKey = key === 'bids' ? 'buy' : 'sell'

    acc[newKey] = response[key].reduce((acc, d) => {
      const price = new Decimal(d[0])
      const key = parseNumber(price)

      acc[key] = [
        key,
        parseNumber(d[1]),
        parseNumber(new Decimal(d[1]).times(price)),
      ]

      return acc
    }, {})

    return acc
  }, {})
}

function mergeDepth (
  prev: Hash<RawDepth>,
  next: Hash<RawDepth>,
): Hash<RawDepth> {
  return fromPairs(
    entries(assign(prev, next)).filter((v: [string, RawDepth]) =>
      Boolean(Number(v[1][1])),
    ),
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function sortDepth (tradeType: 'buy' | 'sell') {
  return (a: string[], b: string[]) => {
    const first = new Decimal(a[0])
    const second = new Decimal(b[0])

    return first.greaterThan(second) ? -1 : 1
    // return (first as any)[
    //   // tradeType === "sell" ? "greaterThan" : "lessThan"
    //   'greaterThan'
    // ](second)
    //   ? -1
    //   : 1
  }
}

function sortDepthOld (tradeType: 'buy' | 'sell') {
  return (a: string[], b: string[]) => {
    const first = new Decimal(a[0])
    const second = new Decimal(b[0])

    return (first as any)[
      tradeType === 'sell' ? 'greaterThan' : 'lessThan'
      // "greaterThan"
    ](second)
      ? 1
      : -1
  }
}

export default TradeModule
