import * as fecha from 'fecha'
import { Decimal } from 'decimal.js-light'
// import { i18n } from '@/lib/bootstrap';
import {
  DECIMAL_REGEXP,
  // EDR_TICKER,
  GENERAL_DECIMAL_PLACES,
  KYC_ADDRESS_INFO_KEY,
  KYC_PERSONAL_INFO_KEY,
} from '@/constants'
// import memoize from 'lodash/memoize'
import range from 'lodash/range'
// import * as WAValidator from 'wallet-address-validator'
import { ActionHandler, ActionContext } from 'vuex'
import { RootState } from '@/store'
import generator from 'generate-password'
import moment from 'moment'
// import { MarketListTab } from './data-models/pairs/MarketListTab'
// import { MarketListPair } from './data-models/pairs/MarketListPair'

import Clipboard from './clipboard'
import { notifyError } from './bus'
import { fillMetaTags } from './metaTags'

// export const getLocalizedimagePath = (
//   path,
//   langCode = 'en',
//   fallbackLangCode = 'en',
// ) => {
//   if (!path) return console.error('file name is null')

//   const _checkPathAvailable = (code) => {
//     const url = `${path}/${code}.png`
//     const http = new XMLHttpRequest()
//     http.open('HEAD', url, false)
//     http.send()
//     return http.status !== 404 ? url : null
//   }

//   let fileUrl = _checkPathAvailable(langCode)
//   if (fileUrl) return fileUrl

//   if (fallbackLangCode === langCode) {
//     return console.error(`Localized path not found: ${path} (${langCode})`)
//   }

//   fileUrl = _checkPathAvailable(fallbackLangCode)
//   if (fileUrl) return fileUrl
//   else {
//     console.error(
//       `Localized path not found: ${path} (${langCode}, ${fallbackLangCode})`,
//     )
//     return null
//   }
// }

export const downloadLocalizedFile = async (
  file: string,
  langCode: string = 'en',
  fallbackLangCode: string = 'en',
  version: string = '1.3',
) => {
  if (!file) {
    return console.error('file name is null')
  }

  const open = window.open(null, '_blank') // Fix from ios

  const _checkFileAvailable = (code) => {
    const url = `/files/${code}/${file}?v=${version}`
    return fetch(url, { method: 'HEAD', redirect: 'manual' })
      .then(res => (res.status === 200 ? url : null))
      .catch(_ => null)
  }

  const _downloadFile = (url) => {
    open.location = url
  }

  let fileUrl = await _checkFileAvailable(langCode)

  if (fileUrl) return _downloadFile(fileUrl)

  if (fallbackLangCode === langCode) {
    return console.error(`Localized file not found: ${file} (${langCode})`)
  }

  fileUrl = await _checkFileAvailable(fallbackLangCode)

  if (fileUrl) {
    return _downloadFile(fileUrl)
  } else {
    return console.error(
      `Localized file not found: ${file} (${langCode}, ${fallbackLangCode})`,
    )
  }
}

export function generateYears () {
  const currentYear = new Date().getFullYear()
  return range(1930, currentYear + 1 - 18).reverse()
}

export function leapYear (year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
}

export function escapeLocaleFromRoute (name) {
  return (name || '').replace('localized_', '')
}

// export function filterByLeftTicker (leftTicker) {
//   return (pair) => {
//     const [left, _] = pair.split('_')
//     if (left === leftTicker) return false
//     return true
//   }
// }

// export function filterByRightTicker (rightTicker) {
//   return (pair) => {
//     const [_, right] = pair.split('_')
//     return right !== rightTicker
//   }
// }

export function shortifyNumber (
  val: string | number | Decimal,
  roundingMode = Decimal.rounding,
  sigDig = 9,
) {
  const v = (() => {
    if (val instanceof Decimal) return val
    if (typeof val === 'string') return new Decimal(val.replace(',', ''))
    return new Decimal(val)
  })()

  const maxVal = '9'.repeat(sigDig - 2)

  if (v.lessThan(1) && typeof val === 'string') {
    return val.slice(0, sigDig + 2)
  }

  if (v.greaterThan(maxVal)) {
    return v.toDecimalPlaces(2, roundingMode).toString()
  }

  return v.toSignificantDigits(sigDig, roundingMode).toString()
}
// (fecha as any).i18n = {
//     dayNamesShort: i18n.t('date.dayNamesShort'),
//     dayNames: i18n.t('date.dayNames'),
//     monthNamesShort: i18n.t('date.monthNamesShort'),
//     monthNames: i18n.t('date.monthNames')
// };

// const ERCTokens = ['USDT']
// const ERCTokens = ['ETH', 'BLOK', 'CHE', 'CRBT', 'ECHT', 'ETC', 'QUINT', 'USDT'];

export const symbols = {
  BTC: '฿',
  USD: '$',
  ETH: 'Ξ',
}

// declare var twttr: {
//   widgets: {}
// };

export function getDefaultPairObject ({
  pairName,
  stockLabel = '',
  stockTickerImg = '',
  minAmountStock = '0',
  moneyLabel = '',
  moneyTickerImg = '',
  market = {
    volume: '0',
    volumeInMoney: '0',
    price: '0',
    change: '0%',
  },
  favorite = false,
}): IPairStateObject {
  const [stockTicker, moneyTicker] = pairName.split('_') as [Ticker, Ticker]
  const defaultImg = '/img/balance/coinDefaultIcon.png'
  return {
    pairName,
    stockTicker,
    stockLabel,
    stockTickerImg: stockTickerImg || defaultImg,
    minAmountStock,
    moneyTicker,
    moneyLabel,
    moneyTickerImg: moneyTickerImg || defaultImg,
    market: {
      ticker: stockTicker as Ticker,
      volume: market.volume || '0',
      volumeInMoney: market.volumeInMoney || '0',
      price: market.price || '0',
      change: market.change || '0%',
    },
    favorite: favorite || false,
  }
}

export function parseMinutes (valInMinutes: number | string) {
  const days = Math.floor(+valInMinutes / 60 / 24)
  const hours = Math.floor(+valInMinutes / 60 - days * 24)
  const minutes = Math.floor(+valInMinutes - days * 24 * 60 - hours * 60)

  return { days, hours, minutes }
}

export function minutesToTimeString (valInMinutes: number | string) {
  const { days, hours, minutes } = parseMinutes(valInMinutes)
  return [
    withFirstZero(days),
    withFirstZero(hours),
    withFirstZero(minutes),
  ].join(':')
}

export function formatHistoryTime (
  rawDate: number | Date,
  withSeconds = false,
): string {
  const today = moment()
  const date = moment(rawDate)

  let format = date.isSame(today, 'day')
    ? 'HH:mm'
    : date.isSame(today, 'year')
      ? 'D MMM, HH:mm'
      : 'D MMM YYYY, HH:mm'
  if (withSeconds) format += ':ss'
  return date.format(format)
  // const today: any = moment()
  // const format: string = moment(time).isBefore(today, 'day')
  //   ? 'MMM DD, HH:mm:ss'
  //   : 'HH:mm:ss'

  // return formatDate(time, format)
}

export const uiKitSizes = [
  'xxs',
  'xs',
  'xss',
  's',
  'm',
  'l',
  'xl',
  'lg',
  'md',
  'sm',
]
export const sizeValidator = (v: string) => uiKitSizes.includes(v)
export const defaultSize = 'm'
export const getPreviousUiKitSize = size =>
  uiKitSizes[uiKitSizes.indexOf(size) - 1]
export const getNextUiKitSize = size => uiKitSizes[uiKitSizes.indexOf(size) + 1]

export function withFirstZero (number: number | string) {
  return parseFloat(number as string) > 9 ? number + '' : '0' + number
}

// export function partial(func /*, 0..n args */) {
//   var args = Array.prototype.slice.call(arguments, 1)
//   return function() {
//       var allArguments = args.concat(Array.prototype.slice.call(arguments))
//       // @ts-ignore
//     return func.apply(this, allArguments)
//   }
// }

type ActionWrapperProps = { context?: any; response?: any; payload?: any }

export function actionWrapper<S> ({
  // this prevent to execute many same requests
  cacheTime = null,

  // when you want to collect many api request and commit them in array at once
  collect = 0, // ms

  apiRequest,
  mutationName,
  payloadParser = (pld: any) => pld,
  errorHandler = (obj: ActionWrapperProps, error: any) => null,
  successHandler = (obj: ActionWrapperProps) => null,
  beforeRequestHandler = (obj: Omit<ActionWrapperProps, 'respose'>) => null,
}): ActionHandler<S, RootState> {
  // payload as key and timeout as values
  const cache = new Map()

  // collect commit params and later execute commits in array at once
  const collection = {
    timeout: null,
    props: [],
  }

  return async function (
    context: ActionContext<S, RootState>,
    rawPayload: any,
  ): Promise<S> {
    const payload = payloadParser.call(this, rawPayload)

    // if cache time set, cache responses (on client only)
    if (cacheTime && process.client) {
      const pldString = JSON.stringify(payload)
      // if cache still has timeout specified, do not go further
      if (cache.has(pldString)) return context.state
      // set timeout as value which in some time will clear itself
      cache.set(
        pldString,
        setTimeout(() => cache.delete(pldString), cacheTime),
      )
    }

    beforeRequestHandler.call(this, { context, payload })

    const response = await apiRequest.call(this, payload, { context })

    if (!response) return undefined

    if (response.errors) {
      console.error(response)
      // if (Array.isArray(response.errors) && !response.errors.length) {
      //   console.error(response.warning || 'Empty errors array.')
      // } else {
      //   console.error(response.errors)
      // }

      errorHandler({ context, response, payload }, response)
      return context.state
    }

    successHandler({ context, response, payload })

    const commitPayload: { type: string; response: any; payload: any } = {
      type: mutationName,
      response: response.response,
      payload,
    }

    if (!collect) {
      mutationName && context.commit(commitPayload)
    } else {
      if (!collection.timeout && process.client) {
        collection.timeout = setTimeout(() => {
          // commit mutation all at once
          collection.props.map(p => context.commit(p))
          // reset props and timeout
          collection.props = []
          collection.timeout = null
        }, collect) // 10ms enough to collect 100+ ws data requests =)
      }
      collection.props.push(commitPayload)
    }

    return context.state
  }
}

/**
 * @deprecated
 */
export function parseResponseFromMarket (response: UpdateMarketResponseParams) {
  const [pairName, { volume, open, close, last, deal }] = response
  const [, money] = pairName.split('_')
  const openDecimal = new Decimal(open || '0')
  const closeDecimal = new Decimal(close || '0')
  const change = (() => {
    if (openDecimal.isZero() && closeDecimal.isZero()) return closeDecimal
    if (openDecimal.isZero() && !closeDecimal.isZero()) return new Decimal(100)
    return closeDecimal
      .div(openDecimal)
      .sub(1)
      .times(100)
  })()

  return {
    response: {
      market: {
        volume: parseNumber(volume || '0', 3),

        // parseNumber(new Decimal(volume || '0').times(last || '0'), 3)
        // TEST ws server - wss://localtrade.cc/ws (prod)
        // deal = coinmarket volume
        volumeInMoney: parseNumber(deal, 3),
        price: parseNumber(last || '0', money === 'USD' ? 4 : 8),
        change: change.isZero() ? '0%' : `${parseNumber(change, 2)}%`,
      },
      pairName,
    },
  }
}

/**
 * Goes to every tab and get all pairs from it. At the end conctat all pairs
 * to one array. Use it as first param in reduce.
 * @param pairs MarketListPair[]
 * @param tab MarketListTab
 * @returns MarketListPair[]
 */
export function getherPairsFromMarketTabs (
  pairs: RawMarketListPair[],
  tab: RawMarketListTab,
) {
  const morePairs = tab.pairs
  const evenMorePairs = tab.tabs.reduce(getherPairsFromMarketTabs, [])
  return pairs.concat(morePairs).concat(evenMorePairs)
}

export function fromServerValidation (fieldName: string) {
  return (_: any, vm: any) => !arrayWithLength(vm.mix_serverErrors[fieldName])
}

export function arrayWithLength (v) {
  // console.log('v:::', v)
  return Array.isArray(v) && v.length
}

export function constructForm (
  action,
  request: PostDepositUsdBody,
): HTMLFormElement {
  const form = document.createElement('form') as HTMLFormElement
  form.method = 'POST'
  if (!request.redirect_url) {
    Object.keys(request).forEach((k) => {
      const input = document.createElement('input')

      input.name = k
      input.value = request[k]

      form.appendChild(input)
    })
    form.action = action
  } else {
    form.action = request.redirect_url
  }
  // form.target = '_blank';
  form.classList.add('d-none')

  document.body.appendChild(form)

  return form
}

export const uniqueId = (function () {
  let num = 0
  return function (prefix) {
    prefix = String(prefix) || ''
    num += 1
    return prefix + num
  }
})()

export function handleErrors (errors) {
  if (typeof errors === 'object' && errors !== null) {
    for (const field in errors) {
      if (Array.isArray(errors[field])) {
        errors[field].map(err =>
          notifyError({
            header: 'Error',
            text: err,
          }),
        )
      }
    }
  }
  if (Array.isArray(errors) && errors.every(e => typeof e === 'string')) {
    errors.map(err =>
      notifyError({
        header: 'Error',
        text: err,
      }),
    )
  }
  console.error(errors)
  return true // in order to use directly in if condition
}

// type currentDateShiftParams = {
//   years?: number
//   months?: number
//   days?: number
//   hours?: number
//   minutes?: number
// }
// export function currentDateShift ({
//   years = 0,
//   months = 0,
//   days = 0,
//   hours = 0,
//   minutes = 0,
// }: currentDateShiftParams): Date {
//   const date = new Date()
//   date.setFullYear(date.getFullYear() + years)
//   date.setMonth(date.getMonth() + months)
//   date.setDate(date.getDay() + days)
//   date.setHours(date.getHours() + hours)
//   date.setMinutes(date.getMinutes() + minutes)
//   return date
// }

export function wait (time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

type DateParams = [number, number, number, number, number]

export const stringBySeparatorReverse = (st: string, sp: string) => {
  return st
    .split(sp)
    .map(s => parseInt(s))
    .reverse()
}

export function airDropVal (v) {
  const numb = parseFloat(v)
  return numb > 0 ? numb + '%' : null
}

// '15.07.2019 09:54' => [2019, 6, 15, 9, 54]
export function parseDateStringToArray (d: string): DateParams {
  const dayString = d.split(' ')[0]
  const timeString = d.split(' ')[1]

  const dayArr = stringBySeparatorReverse(dayString, '.')
  const timeArr = stringBySeparatorReverse(timeString, ':')

  const params = [...dayArr, ...timeArr] as DateParams
  params[1]-- // month starts at 0

  return params
}

export function formatNotifyErrorTitle (key: string): string {
  return capitalizeFirstLetter(
    // @ts-ignore for replaceAll
    key
      .replaceAll('_', ' ')
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/([A-Z][a-z])/g, ' $1')
      .toLowerCase(),
  )
}

export function parseInvestHistoryDate (d: string) {
  const params = parseDateStringToArray(d)
  const date = new Date(...params)

  return date
}

export function formatForRowDate (d: string) {
  const par = parseDateStringToArray(d)

  return `${par[2]}/${++par[1]} ${par[4]}:${par[3]}` // =>   25/12 13:12
}

export function formatDateMoment (time: number | Date): string {
  if (typeof time === 'number') {
    time = new Date(time)
  }
  return moment(time).format('MMM D, HH:mm')
}

// export const waitTwttr = (fn) => {
//   if (typeof twttr === 'object' && typeof twttr.widgets === 'object') {
//     fn()
//   } else {
//     setTimeout(() => waitTwttr(fn), 111)
//   }
// }

export function getRespError (resp) {
  if (resp.errors && Array.isArray(resp.errors.message)) {
    return resp.errors.message
  }
  return []
}

// export function jsonToString (str: string, def: any) {
//   try {
//     return JSON.parse(str)
//   } catch (e) {
//     console.warn(str)
//     console.error(e)
//     return def || []
//   }
// }

// export function jsonToString (str: string, def: any) {
//   try {
//     return JSON.parse(str)
//   } catch (e) {
//     console.warn(str)
//     console.error(e)
//     return def || []
//   }
// }

export function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const boolType = (v = false) => ({ type: Boolean, default: v })

// Format time and date
export function formatDate (
  time: number | Date,
  format: string = 'MMM D, HH:mm',
): string {
  if (typeof time === 'number') {
    time = new Date(time)
  }
  return fecha.format(time, format)
}

export function formatShortDateWithTime (time: number | Date): string {
  return formatDate(time, 'MMM DD, HH:mm:ss')
}

export function formatReverceHistoryTime (time: number | Date): string {
  return formatDate(time, 'YYYY-MM-DD HH:mm:ss')
}

// export function formatHistoryTime (time: number | Date): string {
//   return formatDate(time, 'HH:mm:ss MM-DD-YYYY')
// }

export function formatShortDate (time: number | Date): string {
  return formatDate(time, 'DD.MM.YYYY')
}

export function formatShortDateNoDots (time: number | Date): string {
  return formatDate(time, 'DD MMM YYYY')
}

// export function formatTime (time: number): string {
//   return formatDate(time * 1000, 'HH:mm:ss')
// }

export function formatChatTime (time: number): string {
  if (typeof time !== 'number') {
    time = new Date(time).getTime()
  }
  const currentTime = new Date().getTime()
  const day = 60 * 60 * 24 * 1000

  const _time: Date | number = time * 1000
  const _format: string = currentTime - time * 1000 <= day ? 'HH:mm' : 'MMM D'

  return formatDate(_time, _format)
}

export async function conditionallyAwaited (
  fn: Function,
  condition: boolean,
  ...args: any[]
) {
  if (condition) {
    fn(...args)
    /// TODO: what should be returned here?
  } else {
    return await fn(...args)
  }
}

export function parseNumber (
  number: number | string | Decimal,
  precision: number = 8,
): string {
  if (typeof number === 'number') {
    // const parsed = toString(new Decimal(number))
    // return parsed
    return number.toString()
  }

  return number instanceof Decimal
    ? toString(number as Decimal)
    : toString(new Decimal(number))

  function toString (decimal: Decimal) {
    return new Decimal(decimal.toFixed(precision || 8, 1))
      .times(1)
      .toFixed()
      .toString()
  }
}

// export function escapeNewLine (value: string): string {
//   return value.replace(/\n/g, '\\n')
// }

export function escapeComma (value: string | undefined | null) {
  return (value || '').toString().replace(',', '.') || '0'
}

export function isFloat (value): boolean {
  return /^\d*((\.|,)\d+)?$/.test(value)
}

const title = process.client
  ? (document.querySelector('title') as HTMLTitleElement)
  : null

// export function updateTitle (newTitle: string) {
//   if (title) {
//     title.text = `${'C-Patex'} — ${newTitle}`
//   }
// }

export function updateTitleAlternative (newTitle: string) {
  if (title) {
    title.text = `${'C-Patex'} — ${newTitle}`
  }
}

// const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

export async function copyToClipboard (str) {
  Clipboard.copy(str)
  await true
}

// export function isERC20Token (ticker) {
//   return ERCTokens.includes(ticker)
// }

// const memoizedBtcValidator = memoize(WAValidator.validate, (...rest) =>
//   rest.join('_'),
// )

// export function withdrawValidation (ticker: string, address: string) {
//   if (isERC20Token(ticker)) {
//     if (!memoizedBtcValidator(address, 'ETH')) {
//       return false
//     }
//   }
//   // case 'ATB':
//   //     if (!/^A{1}[1-9A-Za-z]{1}[1-9A-HJ-NP-Za-km-z]{32}$/.test(address)) {
//   //         return false;
//   //     }
//   //     break;
//   switch (ticker) {
//     case EDR_TICKER:
//     case 'USD':
//       return true
//     case 'BCH':
//       if (
//         !/^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|(bitcoincash:)?(q|p)[a-z0-9]{41}$/.test(
//           address,
//         )
//       ) {
//         return false
//       }
//       break
//     default:
//       if (!memoizedBtcValidator(address, ticker)) {
//         return false
//       }
//   }

//   return true
// }

export function valFromObj (v: any) {
  if (v instanceof Decimal) return v
  return typeof v === 'object' ? v.value : v
}

export function getSumFromStack ({ rows, row, columnFn }): Decimal {
  return rows.reduce((acc, r, index) => {
    if (!(index > rows.indexOf(row))) {
      // console.log(r)
      acc = acc.plus(valFromObj(columnFn(r)))
    }
    return acc
  }, new Decimal('0'))
}

// TODO: replace with lib
// export function valShorten (curVal: number): string {
//   if (curVal > 9999999999) {
//     return (curVal / 1000000000).toFixed(0) + 'B'
//   }
//   if (curVal > 1999999999) {
//     return (curVal / 1000000000).toFixed(0) + 'B'
//   }
//   if (curVal > 999999999) {
//     return (curVal / 1000000000).toFixed(1) + 'B'
//   }
//   if (curVal > 9999999) {
//     return (curVal / 1000000).toFixed(0) + 'M'
//   }
//   if (curVal > 1999999) {
//     return (curVal / 1000000).toFixed(0) + 'M'
//   }
//   if (curVal > 999999) {
//     return (curVal / 1000000).toFixed(1) + 'M'
//   }
//   if (curVal > 99999) {
//     return (curVal / 1000).toFixed(0) + 'K'
//   }
//   if (curVal > 9999) {
//     return (curVal / 1000).toFixed(0) + 'K'
//   }
//   if (curVal > 1999) {
//     return parseFloat((curVal / 1000).toFixed(0)) + 'K'
//   }
//   if (curVal > 999) {
//     return parseFloat((curVal / 1000).toFixed(1)) + 'K'
//   }
//   if (curVal > 99) {
//     return curVal.toFixed(0) + ''
//   }
//   if (curVal >= 10) {
//     return curVal.toFixed(0) + ''
//   }
//   if (curVal >= 1) {
//     return parseFloat(curVal.toFixed(1)) + ''
//   }
//   if (curVal > 0) {
//     return parseFloat(curVal.toFixed(2)) + ''
//   }
//   if (curVal === 0) {
//     return curVal.toFixed(0) + ''
//   } else return curVal + ''
// }

// export const getUnixDate = date => Math.round(new Date(date).getTime() / 1000)

export function valueSetterCutter (val: string | number) {
  if (!val) return val
  const vArr = val.toString().split('.')
  // if has decimals
  if (vArr.length > 1) {
    // cut all after 8s decimal place but keep zeroes
    val = vArr[0] + '.' + vArr[1].substring(0, GENERAL_DECIMAL_PLACES)
  }
  const value = val.toString().includes('e-')
    ? new Decimal(val).toDecimalPlaces(GENERAL_DECIMAL_PLACES).toString()
    : val.toString()

  return value
}

export function priceFromStack ({
  pair,
  stack,
  amount,
  ticker,
  buyOrSell,
}: {
  // user entered value
  amount: string
  // user selected currency ticker
  ticker: Ticker
  // ..of this pair
  pair: PairName
  // user wants buy or sell (receive or give) in selected currency

  // IMPORTANT: this parameter saying WHAT YOU ARE DOING WITH AMOUNT, not what
  // you want to achieve.
  buyOrSell: 'buy' | 'sell'

  // data source
  stack: { buy: string[][]; sell: string[][] }
}) {
  // const isSell = this.valueSell === this.amount
  const value = new Decimal(amount)

  // on which side of pair is ticker
  const direction = pair.split('_')[0] === ticker ? 1 : 2

  // user wants to ... amount
  const isSell = buyOrSell === 'sell'

  // this is changes regardless of buy/sell type and direction
  const columnArrangement = (() => {
    if (direction === 1) return isSell
    if (direction === 2) return !isSell
  })()

  const giveSide = isSell ? 1 : 2
  const receiveSide = isSell ? 2 : 1

  // get stack for user action
  const depth = (() => {
    if (direction === 1) return isSell ? stack.buy : stack.sell
    return stack[buyOrSell]
  })()
  // const depth = stack[buyOrSell]

  // debugger
  // indexes of appropriate columns in stack (depth)
  // const giveSide = isSell ? 1 : 2;
  // const receiveSide = isSell ? 2 : 1;

  // const depth = isSell ? this.buyDepth(this.selectedPair) : this.sellDepth(this.selectedPair)

  type Acc = {
    inputVal: Decimal
    calcVal: Decimal
  }

  type row = string[]

  const acc: Acc = {
    inputVal: new Decimal(0), // based on user input value
    calcVal: new Decimal(0), // value need to calculate (return value)
  }

  const result = depth.reduce((acc: Acc, row: row) => {
    // get value from column of depth with currency of input
    const inputValFromRow = getInputValueFromRow(value, acc, row)

    // do nothing if don't get more values from row
    if (inputValFromRow.isZero()) return acc

    // get percent of needed value from row
    // because at last needed row, most often we need only part of its value
    const inputPercentFromRow = getInputPercentFromRow(inputValFromRow, row)
    // console.log(inputPercentFromRow.toNumber())
    // having percent we get value from column with needed currency
    const calcValueFromRow = getCalcValueFromRow(inputPercentFromRow, row)

    // accumulate both values for latter use
    acc.inputVal = acc.inputVal.plus(inputValFromRow)
    acc.calcVal = acc.calcVal.plus(calcValueFromRow)
    return acc
  }, acc)
  // console.warn('--------------------')

  return result.calcVal.toFixed(8)

  function getInputAmount (row: row) {
    const v = columnArrangement ? row[giveSide] : row[receiveSide]
    // const v = columnArrangement ? row[1] : row[2]
    return new Decimal(v)
  }

  // get value to calculate from right column
  function getCalcAmount (row: row) {
    const v = columnArrangement ? row[receiveSide] : row[giveSide]
    // const v = columnArrangement ? row[2] : row[1]
    return new Decimal(v)
  }

  // returns value which we need from row of stack
  // may return 0 if we already accumulated full value
  // or return part of row value because we do not need whole
  // value on last row we need from this stack
  function getInputValueFromRow (value: Decimal, acc: Acc, row: row) {
    const rowAmount = getInputAmount(row)

    // if all values already filled in this stack
    if (value.lessThanOrEqualTo(acc.inputVal)) return new Decimal(0)

    // if this is last row of those where we want ot get value
    //  we need to get part of its value
    if (value.lessThan(acc.inputVal.plus(rowAmount))) {
      return value.minus(acc.inputVal)
    }

    // just return full value of this row in stack
    return rowAmount
  }

  // apply percent to value
  function getCalcValueFromRow (percent: Decimal, row: row) {
    const calcValFromRow = getCalcAmount(row)
    return calcValFromRow.dividedBy(100).times(percent)
  }

  // get percent of row's value we need (44% => 44, not 0.44)
  function getInputPercentFromRow (val: Decimal, row: row) {
    const rowAmount = getInputAmount(row)
    return val.dividedBy(rowAmount).times(100)
  }
}

export const dateFormatter = (date: string): string => {
  // TODO:
  // make date param Date type, use another moment function fromNow, remove
  // moment library and use date-fns, backend should
  // give date format as 'Sun Nov 22 2020 16:45:00 GMT+0000' everywhere
  if (typeof date === 'number') {
    return capitalizeFirstLetter(moment(date * 1000).fromNow())
  }
  const d = moment(new Date(date))
  const today = moment()
  const dateChunks: string[] = date.split(' ')
  if (today.diff(d, 'days') > 0) {
    return dateChunks[1]
  }
  return dateChunks[0]
}

export const generateStrongPassword = () => {
  return generator.generate({
    length: 10,
    numbers: true,
    symbols: true,
    lowercase: true,
    uppercase: true,
    strict: true,
  })
}

export const clearCachedData = () => {
  if (process.server) return

  localStorage.removeItem(KYC_PERSONAL_INFO_KEY)
  localStorage.removeItem(KYC_ADDRESS_INFO_KEY)
}

// evt - can be keypress, paste e.t.c., newValue - input value
export const preventNotNumberInput = (
  evt: Event,
  newValue: string | number,
  oldValue: number | string,
) => {
  if (!DECIMAL_REGEXP.test(`${oldValue ?? ''}${newValue}`)) {
    evt.preventDefault()
  }
}

// TODO: remake for all domain names
export const domainWithoutSubdomain = () => {
  if (process.client && window) {
    const array = window.location.hostname.split('.')
    return `.${array.slice(-2).join('.')}`
  }
}

export const scrollToBlock = (item: HTMLElement) => {
  window.scrollTo({
    left: 0,
    top:
      window.pageYOffset +
      item.getBoundingClientRect().top -
      (document.querySelector('#base-header') as HTMLElement).offsetHeight -
      24,
    behavior: 'smooth',
  })
}

export const getDefaultHeadData = (customMetaProps) => {
  return {
    title: customMetaProps.title,
    description: customMetaProps.description,
    meta: fillMetaTags(customMetaProps),
  }
}

export const formatShortNumber = (num) => {
  if (Number.isNaN(num)) return num

  return new Intl.NumberFormat('en-EN', {
    notation: 'compact',
    maximumSignificantDigits: 5,
  }).format(num)
}

export function noExponents (val) {
  const data = String(val).split(/[eE]/)
  if (data.length === 1) return data[0]

  const sign = val < 0 ? '-' : ''
  const str = data[0].replace('.', '')
  let z = ''
  let mag = Number(data[1]) + 1

  if (mag < 0) {
    z = sign + '0.'
    while (mag++) z += '0'
    return z + str.replace(/^-/, '')
  }
  mag -= str.length
  while (mag--) z += '0'
  return str + z
}

export function formatNumber (val, { decimal } = { decimal: 8 }) {
  if (isNaN(val)) return val
  const number = decimal
    ? Math.round(+val * 10 ** decimal) / 10 ** decimal
    : parseFloat(val + '') + ''
  const formatedNumber =
    number >= 1000 || number <= -1000
      ? parseFloat(number.toString()).toLocaleString()
      : number.toString()
  return noExponents(formatedNumber)
}

export function onlyThemePropValidator (def = false) {
  return {
    validator: v => v === 'dark' || v === 'light' || v === false,
    default: def,
  }
}

export function renameKey (obj: object, oldKey: string, newKey: string): object {
  delete Object.assign(obj, { [newKey]: obj[oldKey] })[oldKey]
  return obj
}

export const createAndSubmitPaymentForm = async (response, method = 'GET') => {
  const linkKey = 'redirectLink'
  const ignoredKeys = ['compileUrl'] // keys that are used in the application
  const form = document.createElement('form') as HTMLFormElement
  // form.target = '_blank'
  form.method = method
  Object.keys(response).forEach((k) => {
    if (k === linkKey || ignoredKeys.includes(k)) return
    const input = document.createElement('input')
    input.name = k
    input.value = response[k]
    form.appendChild(input)
  })
  form.action = response[linkKey]
  form.style.display = 'none'
  document.body.appendChild(form)

  form.submit()
  document.body.removeChild(form)
}
