<template>
  <div
    :class="[classes, `tdx-input-label-error-wrapper${errorMessage ? '--red' : ''}`]"
    class="tdx-input-label-error-wrapper"
  >
    <div class="tdx-input-label-error-wrapper__content">
      <label
        v-if="!labelDisabled"
        :for="uniqueId"
        :class="{
          'tdx-input-label-error-wrapper__label--hidden': !label,
        }"
        class="tdx-input-label-error-wrapper__label txt--height-20px txt--weight-500 txt--16px txt--color-03"
        v-html="label"
      />
      <slot v-bind="{ hasError: !!errorMessage, hasSuccess, id: uniqueId }" />

      <div class="tdx-input-label-error-wrapper__info txt--weight-500 mb-8">
        <transition name="fade">
          <span
            v-if="textMessage"
            :class="{ 'txt--color-07': errorMessage, 'txt--grey': !errorMessage }"
            class="txt txt--12px mt-4"
            v-html="textMessage"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqueId } from '@/lib/utils'

const isObj = v => typeof v === 'object' && !Array.isArray(v) && v !== null

export default {
  name: 'TdxInputLabelErrorWrapper',
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: [Object], // vuelidate field is Object, old form field is String
      default: null,
    },
    infoMessage: {
      type: String,
      default: '',
    },
    optionalErrors: {
      type: Object,
      default: () => ({}),
    },
    // consider to remove this props
    customError: {
      type: String,
      default: '',
    },
    // for old form
    formKey: {
      type: String,
      default: '',
    },
    // for old form
    name: {
      type: String,
      default: '',
    },
    labelDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasSuccess () {
      return isObj(this.field) && (!this.field.$invalid)
    },
    classes () {
      return {
        'tdx-input-label-error-wrapper--disabled': this.$attrs.disabled,
      }
    },
    messages () {
      const params = this.field.$params
      return {
        // FIXME: change error text for every error you need
        required: 'Field is required',
        requiredIf: 'Field error',
        requiredUnless: 'Field error',
        minLength: `Min length is ${params.minLength && params.minLength.min}`,
        maxLength: 'Field error',
        minValue: `Min value is ${params.minValue && params.minValue.min}`,
        maxValue: `Max value is ${params.maxValue && params.maxValue.max}`,
        between: `Between ${params.between && params.between.min} and ${params.between && params.between.max}`,
        alpha: 'Field error',
        alphaNum: 'Field error',
        numeric: 'Field error',
        integer: 'Field error',
        decimal: 'Field error',
        email: 'Wrong email address',
        ipAddress: 'Field error',
        macAddress: 'Field error',
        sameAs: 'Field error',
        url: 'Field error',
        or: 'Field error',
        and: 'Field error',
        not: 'Field error',
        withParams: 'Field error',
        ...this.optionalErrors,
      }
    },
    vuelidateErrorMessage () {
      const hasError = isObj(this.field) && this.field.$error && this.field.$invalid
      if (hasError) {
        return this.getErrorMessage()
      } return null
    },
    errorMessage () {
      return this.vuelidateErrorMessage || this.oldFormError || this.customError
    },
    textMessage () {
      return this.errorMessage || this.infoMessage || ''
    },
    oldFormError () {
      if (this.formKey) {
        if (typeof this.$store !== 'object') return ''
        return this.$store.getters[`${this.formKey}/error`](this.name)
      } return ''
    },
    uniqueId () {
      return uniqueId('input_')
    },
  },

  methods: {
    getErrorMessage () {
      const validatorNames = Object.keys(this.field).filter(key => key.charAt(0) !== '$')

      // object with status of every error
      const validatorsState = validatorNames.reduce((acc, name) => {
        acc[name] = this.field[name]
        return acc
      }, {})

      // array of error messages
      const errorMessages = Object.keys(validatorsState).reduce((acc, key) => {
        if (!validatorsState[key]) acc.push(this.messages[key])
        return acc
      }, [])

      // show only one (first) message
      return errorMessages[0]
    },
  },
}
</script>

<style lang="scss" scoped>
.tdx-input-label-error-wrapper {
  $root: &;

  display: block;
  border-radius: toRem(16px);
  border: 1px solid rgba(0, 0, 0, 0);

  &--red {
    border-color:#F33C3C;
  }

  &__label {
    display: block;
    position: absolute;
    top: toRem(21px);
    left: toRem(18px);
    line-height: 20px;
    font-weight: 500;
    transition: .3s ease;
  }

  &__content {
    position: relative;
  }

  &__info {
    position: absolute;
    bottom: toRem(-6px);
    left: toRem(20px);
    height: toRem(15px);
    line-height: toRem(9px);
    display: flex;
  }
}

.tdx-input {
  &--focused {
    .tdx-input-label-error-wrapper__content {
      .tdx-input-label-error-wrapper__label {
        top: toRem(12px);
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        transition: .3s ease;
      }
    }
  }

  &--not-empty {
    .tdx-input-label-error-wrapper__content {
      .tdx-input-label-error-wrapper__label {
        top: toRem(12px);
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        transition: .3s ease;
      }
    }
  }
}

.tdx-dropdown,
.tdx-dropdown--active {
  .tdx-input-label-error-wrapper__label {
    top: toRem(10px);
    left: toRem(20px);
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    transition: .3s ease;
  }
}
</style>
