export default {
  namespaced: true,
  state: () => ({
    currentPage: 1,
    lastPage: '',
  }),
  mutations: {
    setPagination (state, pld: OrdersResponse) {
      state.lastPage = pld.lastpage
      state.currentPage = pld.currentpage
    },
  },
}
