import Decimal from "decimal.js-light"

// "username": "shidlovsky1990",
// "amount_from": "1.000000000000000000",
// "amount_to": "1000.000000000000000000",
// "percent_from": "1.000",
// "percent_to": "1.000",
// "days_from": 1,
// "days_to": 6,
// "currency": "BTC",
// "lvt": "80.00"
export interface OrderDescriptionModelInterface {
    "username": string
    "amount_from": string
    "amount_to": string
    "free_amount": string
    "percent_from": string
    "percent_to": string
    "days_from": number,
    "days_to": number,
    "currency": string
    "lvt": { [key: string]: string }
    "price_usd": { [key: string]: string }
    "status": number
}

export class OrderDescriptionModel {
    username: string
    amountFrom: string
    amountFrom_string: string
    amountFrom_number: number
    amountTo: string
    amountTo_string: string
    amountTo_number: number
    freeAmount: number
    percentFrom: string
    percentTo: string
    percentFrom_number: number
    percentTo_number: number
    daysFrom: number
    daysTo: number
    currency: string
    lvt: {}
    priceUsd: {}
    status: number
    constructor(o: OrderDescriptionModelInterface) {
        this.username = o.username
        this.amountFrom = o.amount_from
        this.amountFrom_string = new Decimal(o.amount_from).toFixed(2).toString()
        this.amountFrom_number = parseFloat(o.amount_from)
        this.amountTo = o.amount_to
        this.amountTo_string = new Decimal(o.amount_to).toFixed(2).toString()
        this.amountTo_number = parseFloat(o.amount_to)
        this.freeAmount = parseFloat(new Decimal(o.free_amount).toFixed(2))
        this.percentFrom = o.percent_from
        this.percentFrom_number = parseFloat(o.percent_from)
        this.percentTo = o.percent_to
        this.percentTo_number = parseFloat(o.percent_to)
        this.daysFrom = o.days_from
        this.daysTo = o.days_to
        this.currency = o.currency
        this.lvt = o.lvt
        this.priceUsd = o.price_usd
        this.status = o.status
    }
}