import { actionWrapper } from '@/lib/utils'

const TdxManagerModule = {
  namespaced: true,
  state: () => ({
    tdxManagerData: null,
  }),
  getters: {
    tdxManagerExists (state) {
      return state.tdxManagerData && state.tdxManagerData.length
    },
  },
  mutations: {
    setTdxManagerData (state, { response }) {
      if (!response) return
      state.tdxManagerData = response
    },
  },
  actions: {
    getTdxManagerData (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getTdxMananger(),
        mutationName: 'setTdxManagerData',
      }).bind(this)(...args)
    },
  },

  // TODO: fetch history and claim
}

export default TdxManagerModule
