import { formatHistoryTime, parseNumber } from '@/lib/utils'

interface SocketRawUserTrade {
  amount: string
  ctime: number
  deal_fee: string
  deal_money: string
  deal_stock: string
  id: number
  left: string
  maker_fee: string
  market: PairName
  mtime: number
  price: string
  side: 1 | 2 // "sell" | "buy"
  source: string
  taker_fee: string
  type: number
  user: number
}

export class SocketTradeModel {
  id: number
  pair: PairName
  key: string
  total: string
  price: string
  amount: string
  tradeType: TradeType
  time: number
  fee: string
  createdAt: string

  constructor (rawTrade: SocketRawUserTrade, pair?: PairName) {
    this.id = rawTrade.id
    this.pair = rawTrade.market || pair
    this.key = `${rawTrade.id}:${rawTrade.side}`
    this.total = parseNumber(rawTrade.deal_money)
    this.price = parseNumber(rawTrade.price)
    this.amount = parseNumber(rawTrade.amount)
    this.tradeType = rawTrade.side === 2 ? 'buy' : 'sell'
    this.time = rawTrade.mtime
    this.fee = rawTrade.taker_fee
    this.createdAt = formatHistoryTime(rawTrade.mtime * 1000)
  }
}
