<template>
  <exc-input-label-error-wrapper
    v-bind="$attrs"
    :field="field"
    :label="label"
    :info-message="infoMessage"
    :custom-error="customError"
    :name="name"
    :form-key="formKey"
    class="input-simple"
  >
    <!-- eslint-disable vue/valid-v-on -->
    <!-- eslint-disable vue/no-parsing-error  -->
    <template #default="slotProps">
      <base-textarea
        ref="input"
        :value="value"
        v-bind="{ ...$attrs, ...slotProps }"
        class="textarea-simple__input"
        v-on="{ ...$listeners, input: val => $emit('input', val) }"
      />
    </template>
  </exc-input-label-error-wrapper>
</template>
<script>
import { ExcInputLabelErrorWrapper } from '@/components/common'

export default {
  name: 'TextareaSimpleNew',
  components: {
    ExcInputLabelErrorWrapper,
    BaseTextarea: () => import(/* webpackMode: "eager" */ '@/components/ui-kit/base/BaseTextarea.vue'),
    // InputLabelErrorWrapper: () => import(/* webpackMode: "eager" */  '@/components/ui-kit/containers/InputLabelErrorWrapper.vue'),
  },
  props: {
    value: {
      validator: v => ['string', 'number'].includes(typeof v) || v === null,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    field: {
      type: [Object], // vuelidate field
      default: null,
    },
    customError: {
      type: String,
      default: '',
    },
    infoMessage: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    formKey: {
      type: String,
      default: '',
    },
  },
  methods: {
    focus () {
      this.$refs.input && this.$refs.input.focus()
    },
    blur () {
      this.$refs.input && this.$refs.input.blur()
    },
  },
}
</script>
<style lang="scss" scoped>
.textarea-simple {
  $root: &;
  position: relative;
}
</style>
