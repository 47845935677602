import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { default as Scheme$90c7 } from '/web/lib/schemes/schemeBasic.ts'
import { default as Scheme$d487 } from '/web/lib/schemes/schemeDevice.ts'
import { default as Scheme$b160 } from '/web/lib/schemes/scheme2fa.ts'
import { default as Scheme$a56d } from '/web/lib/schemes/schemeDeviceAnd2fa.ts'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/login",
    "logout": "/",
    "home": false,
    "callback": "/login"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/",
      "expires": 30
    }
  },
  "localStorage": false,
  "defaultStrategy": "BASIC"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // BASIC
  $auth.registerStrategy('BASIC', new Scheme$90c7($auth, {
  "token": {
    "property": "tokens.access_token",
    "maxAge": 1800,
    "global": true
  },
  "refreshToken": {
    "property": "tokens.refresh_token",
    "data": "refresh_token",
    "maxAge": 2592000
  },
  "user": {
    "property": false,
    "autoFetch": true
  },
  "endpoints": {
    "refresh": {
      "url": "/api/v1/auth/refresh-token",
      "method": "post"
    },
    "user": {
      "url": "/v2/settings/general",
      "method": "get"
    },
    "logout": {
      "url": "/api/v1/auth/logout",
      "method": "post"
    },
    "login": {
      "url": "/api/v1/auth/login",
      "method": "post"
    }
  },
  "name": "BASIC"
}))

  // DEVICE
  $auth.registerStrategy('DEVICE', new Scheme$d487($auth, {
  "token": {
    "property": "tokens.access_token",
    "maxAge": 1800,
    "global": true
  },
  "refreshToken": {
    "property": "tokens.refresh_token",
    "data": "refresh_token",
    "maxAge": 2592000
  },
  "user": {
    "property": false,
    "autoFetch": true
  },
  "endpoints": {
    "refresh": {
      "url": "/api/v1/auth/refresh-token",
      "method": "post"
    },
    "user": {
      "url": "/v2/settings/general",
      "method": "get"
    },
    "logout": {
      "url": "/api/v1/auth/logout",
      "method": "post"
    },
    "login": {
      "url": "/api/v1/auth/login/validate-device-by-codes",
      "method": "post"
    }
  },
  "name": "DEVICE"
}))

  // TWO_FA
  $auth.registerStrategy('TWO_FA', new Scheme$b160($auth, {
  "token": {
    "property": "tokens.access_token",
    "maxAge": 1800,
    "global": true
  },
  "refreshToken": {
    "property": "tokens.refresh_token",
    "data": "refresh_token",
    "maxAge": 2592000
  },
  "user": {
    "property": false,
    "autoFetch": true
  },
  "endpoints": {
    "refresh": {
      "url": "/api/v1/auth/refresh-token",
      "method": "post"
    },
    "user": {
      "url": "/v2/settings/general",
      "method": "get"
    },
    "logout": {
      "url": "/api/v1/auth/logout",
      "method": "post"
    },
    "login": {
      "url": "/api/v1/auth/login/validate-device-by-codes",
      "method": "post"
    }
  },
  "name": "TWO_FA"
}))

  // DEVICE_AND_TWO_FA
  $auth.registerStrategy('DEVICE_AND_TWO_FA', new Scheme$a56d($auth, {
  "token": {
    "property": "tokens.access_token",
    "maxAge": 1800,
    "global": true
  },
  "refreshToken": {
    "property": "tokens.refresh_token",
    "data": "refresh_token",
    "maxAge": 2592000
  },
  "user": {
    "property": false,
    "autoFetch": true
  },
  "endpoints": {
    "refresh": {
      "url": "/api/v1/auth/refresh-token",
      "method": "post"
    },
    "user": {
      "url": "/v2/settings/general",
      "method": "get"
    },
    "logout": {
      "url": "/api/v1/auth/logout",
      "method": "post"
    },
    "login": {
      "url": "/api/v1/auth/login/validate-device-by-codes",
      "method": "post"
    }
  },
  "name": "DEVICE_AND_TWO_FA"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
