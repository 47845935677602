import { TApiStructure } from './api'

export default (function () {
  function setupFunc (data) {
    return (callbackFn) => {
      initGeetest({
        lang: 'en', // window.APP_LANGUAGE,
        width: '100%',
        gt: data.gt,
        challenge: data.challenge,
        new_captcha: data.new_captcha,
        product: 'bind', // 产品形式，包括：float，embed，popup。注意只对PC版验证码有效
        offline: !data.success, // 表示用户后台检测极验服务器是否宕机，一般不需要关注
        // 更多配置参数请参见：http://www.geetest.com/install/sections/idx-client-sdk.html#config
      }, (res) => {
        res._token = data._token
        callbackFn(res)
      })
    }
  }

  function loadGeetest ($api: TApiStructure) {
    return new Promise((resolve, reject) => {
      const head = document.getElementsByTagName('head')[0]
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = '/js/gt.js'
      script.onload = async function () {
        // initGeetest function becomes available after loading script
        const data = await $api.getGeetestCaptchaParamsFrom()
        resolve(setupFunc(data))
      }
      script.onerror = function () {
        reject('Error loading' + this.src)
      }
      head.appendChild(script)
    })
  }

  return {
    loadGeetest,
  }
})()
