<template>
  <div
    :id="$attrs.id || uniqueId"
    v-click-outside="onClickOutsideOfDropdown"
    :class="classes"
    class="exc-base-dropdown"
    @mouseleave="onHoverOnField('mouseleave')"
  >
    <div
      :class="{ 'exc-base-dropdown--disabled': disabled }"
      class="exc-base-dropdown__container"
      @click="onClickOnField"
      @mouseover="onHoverOnField('mouseover')"
    >
      <div
        v-show="!showInput"
        :class="{ 'is-invalid': $attrs.validation || errors[0] }"
        class="exc-base-dropdown__form-control txt txt--color-04 txt--16px"
      >
        <!-- for empty option -->
        <span
          v-if="!hasSelectedValue && !$scopedSlots.empty"
          class="pl-12 pr-12 txt txt--14px txt--grey"
        >
          {{ placeholder }}
        </span>
        <slot
          v-if="!hasSelectedValue"
          v-bind="{ size, disabled }"
          name="empty"
        />

        <!-- for selected option(s)  -->
        <slot
          v-if="hasSelectedValue"
          v-bind="{ option: value, labelsByComma, size, disabled }"
          name="selected"
        >
          <exc-dropdown-item
            class="txt txt--color-04"
            v-bind="{ option: value, labelsByComma, size, disabled }"
          />
        </slot>
      </div>

      <exc-base-input
        v-show="showInput"
        ref="searchInput"
        v-model="searchValue"
        without-border
        class="exc-base-dropdown__search"
      />

      <div
        v-if="disabled"
        class="exc-base-dropdown__disabled-area"
        :class="disabled ? 'bg--transp-00' : ''"
      />
      <small v-if="$attrs.validation || errors[0]" class="invalid-feedback">
        {{ ($attrs.validation && $attrs.validation.message) || errors[0] }}
      </small>

      <exc-input-side-icon
        class="exc-base-dropdown__open-icon"
        @click="onClickOnArrow"
      >
        <!-- <span
          :style="{'transform': `rotate(${isOpened ? '0' : '180'}deg)`}"
          class="flx"
        > -->
        <slot name="icon">
          <exc-dropdown-triangle
            :direction="isOpened ? 'bottom' : 'top'"
            :class="{
              'txt--color-09': onlyTheme === 'dark',
              'txt--color-04': onlyTheme === 'white',
              'txt--color-04': onlyTheme === false,
            }"
          />
          <!-- <arrow-icon-black v-else /> -->
        </slot>
        <!-- </span> -->
      </exc-input-side-icon>
    </div>
    <!-- :class="{'exc-base-dropdown--closed': !isOpened}" -->
    <transition name="fade">
      <div
        v-if="isOpened"
        class="exc-base-dropdown__dropdown"
        @mouseover="onHoverOnField('mouseover')"
      >
        <div
          class="exc-base-dropdown__options-list bor--radius-20 txt--10px mt-4 bg--color-01"
        >
          <!-- list of options -->
          <template v-if="outputOptions.length">
            <div
              v-for="option in outputOptions"
              :key="option.id"
              class="exc-base-dropdown__option txt--color-04"
              @click="onClickSelectOption(option)"
            >
              <!-- list item -->
              <slot v-bind="{ option, size, disabled }" name="item">
                <exc-dropdown-item
                  class="txt txt--color-04"
                  v-bind="{ option, size, disabled }"
                />
              </slot>
            </div>
          </template>
          <template v-else>
            <div
              class="exc-base-dropdown__option txt txt--grey txt--uppercase txt--center"
            >
              {{ $t('common.noItems') ? $t('common.noItems') : 'no items' }}
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import {
  sizeValidator,
  defaultSize,
  uniqueId,
  wait,
  onlyThemePropValidator,
} from '@/lib/utils'
import ClickOutside from 'vue-click-outside'
import ExcInputSideIcon from '@/components/common/form/elements/ExcInputSideIcon.vue'
import ExcDropdownTriangle from '@/components/common/svg/ExcDropdownTriangle.vue'
import ExcDropdownItem from '@/components/common/form/dropdown/ExcDropdownItem.vue'
import ExcBaseInput from '@/components/common/form/input/ExcBaseInput.vue'
const bool = { type: Boolean, default: false }
export default {
  name: 'ExcBaseDropdown',
  components: {
    ExcInputSideIcon,
    ExcDropdownTriangle,
    ExcDropdownItem,
    ExcBaseInput,
  },
  directives: {
    ClickOutside,
  },
  props: {
    value: {
      type: [Array, Object],
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    onlyTheme: onlyThemePropValidator(),
    disabled: bool,
    // searchable: bool,
    withoutBorder: bool,
    withoutArrow: bool,
    dropdownOnHover: bool,
    hasError: bool,
    openUp: bool,
    placeholder: { type: String, default: '' },
    searchBy: {
      type: [String, Array],
      default: '',
    },
  },
  data () {
    return {
      isOpened: false,
      searchValue: '',
    }
  },
  computed: {
    uniqueId () {
      return uniqueId('exc-base-dropdown_')
    },
    isMultiselect () {
      return Array.isArray(this.value)
    },
    classes () {
      return {
        // 'exc-base-dropdown--fixed-width': !this.autoWidth,
        [`exc-base-dropdown--${this.size}`]: true,
        'bor--all': true,
        'bor--transp': this.withoutBorder,
        'bor--transp-02 bor--radius-100': !this.withoutBorder && !this.isOpened,
        'bor--main sha--004-main bor--radius-100':
          !this.withoutBorder && this.isOpened,
        'exc-base-dropdown--without-arrow': this.withoutArrow,
        'exc-base-dropdown--dropdown-on-hover': this.dropdownOnHover,
        'exc-base-dropdown--open-up': this.openUp,
        'exc-base-dropdown--open-down': !this.openUp,
        'bor--red': !this.withoutBorder && this.hasError && !this.isOpened,
      }
    },
    labelsByComma () {
      if (this.isMultiselect && this.value.length) {
        // formats to "first, second (+3)"
        const labels = this.value.map(v => v.label)
        const moreString = labels.slice(2).length
          ? ` (+${labels.slice(2).length})`
          : ''
        return labels.slice(0, 2).join(', ') + moreString
      }
      return ''
    },
    hasSelectedValue () {
      const whenSingle = !this.isMultiselect && !!this.value
      const whenMultiple = this.isMultiselect && !!this.value.length
      return whenMultiple || whenSingle
    },
    showInput () {
      return this.searchBy ? this.isOpened : false
    },
    outputOptions () {
      if (this.searchBy && Array.isArray(this.searchBy)) {
        return this.options.filter(option =>
          this.searchBy.some(searchField =>
            option[searchField]
              .toLowerCase()
              .includes(this.searchValue.toLowerCase()),
          ),
        )
      }
      return this.searchBy
        ? this.options.filter(option =>
          option[this.searchBy]
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()),
        )
        : this.options
    },
  },
  watch: {
    value () {
      this.resetSearchValue()
    },
  },
  methods: {
    resetSearchValue () {
      this.searchValue = ''
    },
    onClickSelectOption (option) {
      if (!this.isMultiselect) {
        this.$emit('input', option)
        this.$emit('close')
        this.isOpened = false
      }
    },
    async onClickOnField () {
      if (!this.disabled) {
        // stay opened if clicked on search
        this.isOpened = this.searchBy ? true : !this.isOpened
        if (this.isOpened) {
          this.$emit('open')
        } else {
          return
        }
      }
      if (this.searchBy && this.isOpened) {
        await wait(444)
        this.$refs.searchInput.$el.focus()
      }
    },
    onClickOnArrow (ev) {
      // close on arrow only for searchable dropdown
      if (!this.disabled && this.searchBy) {
        if (this.isOpened) {
          ev.stopPropagation()
          this.$emit('close')
          this.isOpened = false
        } else {
          this.$emit('open')
          this.isOpened = true
        }
      }
    },
    onClickOutsideOfDropdown () {
      if (this.isOpened && !this.disabled) {
        this.$emit('close')
        this.isOpened = false
      }
    },
    onClickOnEmptyOption () {
      if (!this.disabled) {
        this.isOpened = false
        this.$emit('input', false)
      }
    },
    onHoverOnField (action) {
      // mouseover | mouseleave
      // TODO: fix when on mobile user have to click twice
      if (this.dropdownOnHover) {
        if (action === 'mouseover') {
          !this.isOpened && this.$emit('open')
          this.isOpened = true
        } else if (action === 'mouseleave') {
          this.$emit('close')
          this.isOpened = false
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.exc-base-dropdown {
  $root: &;
  $optionsHeight: toRem(200px);
  display: flex;
  flex-direction: column;

  &__search {
    max-width: toRem(95px);
  }

  // z-index: 999;

  &--disabled {
    position: relative;
  }

  &--without-arrow {
    #{$root}__open-icon {
      display: none;
    }
  }

  &--open-up {
    #{$root}__dropdown {
      order: 1;
    }
    #{$root}__options-list {
      bottom: 1px;
      .bor--width-0px & {
        bottom: 0;
      }
    }
  }

  &--open-down {
    #{$root}__dropdown {
      order: 3;
    }
    #{$root}__options-list {
      top: 1px;

      .bor--width-0px & {
        top: 0;
      }
    }
  }

  // &__label {}

  &__container {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    order: 2;
    position: relative;
    z-index: 1;
  }

  &__form-control {
    display: flex;
    align-items: center;
    width: calc(100% - 42px);

    span {
      padding-left: toRem(18px);
    }
  }

  &__open-icon {
    margin-left: auto;
    display: flex;
  }

  &__dropdown {
    position: relative;
    z-index: 100;
  }

  &__options-list {
    position: absolute;

    // this is when it has border
    // top: 1px;
    left: -1px;
    right: -1px;

    height: auto;
    max-height: $optionsHeight;
    overflow-y: auto;
    z-index: 100;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.3);
    @include webkit-scroll();

    ::-webkit-scrollbar-track, &::-webkit-scrollbar-track {
      margin: toRem(20px) 0 toRem(10px) 0;
    }

    // when dropdown without border
    .bor--width-0px & {
      // top: 0;
      left: 0;
      right: 0;
    }
  }

  &__option {
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: color(main-transp);
      color: color(main);
    }
  }

  &__disabled-area {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: not-allowed;
    // background-color: rgba(138, 138, 138, 0.1);
  }

  $sizes: (
    xl: (
      'height': toRem(map-get($uiKitSizes, xl)),
    ),
    l: (
      'height': toRem(map-get($uiKitSizes, l)),
    ),
    m: (
      'height': toRem(map-get($uiKitSizes, m)),
    ),
    s: (
      'height': toRem(map-get($uiKitSizes, s)),
    ),
    xs: (
      'height': toRem(map-get($uiKitSizes, xs)),
    ),
    // xxs: (
    //   'height': toRem(map-get($uiKitSizes, xxs)),
    // )
  );

  @each $size, $params in $sizes {
    &--#{$size} {
      @each $prop, $value in $params {
        #{$prop}: $value;

        // &#{$root}--open-up {
        //     & #{$root}__options-list {
        //         top: calc(-#{$optionsHeight} - #{$value});
        //     }
        // }
      }
      #{$root}__open-icon {
        padding-right: toRem(map-get($uiKitSizes, $size) / 3);
      }
    }
  }
}
</style>
