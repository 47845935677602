import { GetterTree, Module, MutationTree, ActionTree } from 'vuex'
import { actionWrapper } from '@/lib/utils'
import { UserStatManagementModel } from '@/lib/data-models/p2p/UserStatManagementModel'
import { LoanModel } from '@/lib/data-models/p2p/LoanModel'
import { CreditHistoryModel } from '@/lib/data-models/p2p/CreditHistoryModel'
import { RootState } from '..'

// This is set on backend
const LOANS_PER_PAGE = 10

interface LoansInterface {
  stats: Hash<UserStatManagementModel>
  currencies: P2PCurrency[]
  loans: P2PLoanResponse[]
  myApprovedBorrows: P2PLoanHistoryResponse[]
  myRequestedBorrows: P2PLoanHistoryResponse[]
  loansToShow: number
}

const state: LoansInterface = {
  stats: {},
  currencies: [],
  loans: [],
  myApprovedBorrows: [],
  myRequestedBorrows: [],
  loansToShow: 0,
}

const mutations: MutationTree<LoansInterface> = {
  setStatistics (state, { response }) {
    state.stats = response
  },
  setCurrencies (state, { response }) {
    state.currencies = response
  },
  setLoansList (state, { response, payload }) {
    state.loansToShow = response.count
    const isNextPage = payload && payload.offset
    if (isNextPage) {
      state.loans = [...state.loans, ...response.data]
      return
    }
    state.loans = response.data
  },
  setLoan (state, { response }) {
    const loan = response.data[0]
    if (!loan) return

    const currentLoanPosition = state.loans.findIndex(v => v.id === loan.id)
    if (currentLoanPosition === -1) return

    state.loans.splice(currentLoanPosition, 1, loan)
    state.loans = [...state.loans]
  },
  setMyApprovedBorrowList (state, { response }) {
    state.myApprovedBorrows = response.data
  },
  setMyRequestedBorrowList (state, { response, payload }) {
    state.loansToShow = response.count
    const isNextPage = payload && payload.offset
    if (isNextPage) {
      state.myRequestedBorrows = [...state.myRequestedBorrows, ...response.data]
      return
    }
    state.myRequestedBorrows = response.data
  },
  changeLoanStatus (state, { response, payload }) {
    state.myRequestedBorrows = state.myRequestedBorrows.map((borrow) => {
      if (+borrow.loan_id === +payload) return response

      return borrow
    })
  },
}

const actions: ActionTree<LoansInterface, RootState> = {
  getP2PStatistics (...args) {
    return actionWrapper({
      apiRequest: () => this.$api.p2pUserStatManagement(),
      mutationName: 'setStatistics',
    }).bind(this)(...args)
  },
  getP2PCurrencies (...args) {
    return actionWrapper({
      apiRequest: () => this.$api.p2pCurrencyList(),
      mutationName: 'setCurrencies',
    }).bind(this)(...args)
  },
  getP2PLoansList (...args) {
    return actionWrapper({
      apiRequest: payload => this.$api.getP2PLoansList(payload),
      mutationName: 'setLoansList',
    }).bind(this)(...args)
  },
  getP2PLoanById (...args) {
    return actionWrapper({
      apiRequest: payload => this.$api.getP2PLoansList(payload),
      mutationName: 'setLoan',
    }).bind(this)(...args)
  },
  getMyApprovedBorrowList (...args) {
    return actionWrapper({
      apiRequest: payload => this.$api.getP2PMyApprovedBorrowList(payload),
      mutationName: 'setMyApprovedBorrowList',
    }).bind(this)(...args)
  },
  getMyRequestedBorrowList (...args) {
    return actionWrapper({
      apiRequest: payload => this.$api.getP2PMyRequestedBorrowList(payload),
      mutationName: 'setMyRequestedBorrowList',
    }).bind(this)(...args)
  },
  repayLoan (...args) {
    return actionWrapper({
      apiRequest: ({ id }) => this.$api.repayLoan(id),
      mutationName: 'changeLoanStatus',
      successHandler ({ context, response, payload }) {
        if (payload.errorHandler && typeof payload.errorHandler === 'function') {
          payload.successHandler({ context, response, payload })
        }
      },
      errorHandler: ({ context, response, payload }, res) => {
        if (payload.errorHandler && typeof payload.errorHandler === 'function') {
          payload.errorHandler({ context, response, payload }, res)
        }
      },
    }).bind(this)(...args)
  },
}

const getters: GetterTree<LoansInterface, RootState> = {
  availableTickers: state => Object.entries(state.stats),
  p2pFiats: state => state.currencies.filter(currency => currency.isFiat),
  p2pCryptoCurrencies: state =>
    state.currencies.filter(currency => !currency.isFiat),
  loansList: state => state.loans.map(loan => new LoanModel(loan)),
  loanCurrencyPairs: (_, getters) => {
    const pairs = new Set()
    getters.loansList.forEach((loan) => {
      pairs.add(loan.cryptoTicker + '_' + loan.fiatTicker)
    })

    return Array.from(pairs)
  },
  myBorrowList: (state) => {
    const approvedBorrows = state.myApprovedBorrows.map(
      borrow => new CreditHistoryModel(borrow),
    )
    const requestedBorrows = state.myRequestedBorrows.map(
      borrow => new CreditHistoryModel(borrow),
    )

    // console.log('approved', approvedBorrows)
    // console.log('requested', requestedBorrows)
    return [...approvedBorrows, ...requestedBorrows]
  },
  hasMoreLoans: state => state.loansToShow > LOANS_PER_PAGE,
  // availableTickers: state => Object.keys(state.stats)
}

export const loans: Module<LoansInterface, RootState> = {
  namespaced: true,
  state: () => state,
  actions,
  mutations,
  getters,
}

export default loans
