import Vue from 'vue'
import { waitForCondition } from '@/lib/helpers'

const data = Vue.observable({
  confirmed: null,
  show: false,
  title: 'title',
  text: 'text',
  disclaimer: 'text',
  confirmationImg: '',
  cancelButtonText: '',
  confirmButtonText: '',
  contentClassName: '',
  hideCancelBtn: false,
  cryptoCard: false,
})

export class ConfirmationModal {
  static data () {
    return data
  }

  static confirm () {
    data.confirmed = true
    data.show = false
    return true
  }

  static reject () {
    data.confirmed = false
    data.show = false
    return true
  }

  static async open ({
    title,
    text,
    disclaimer,
    confirmationImg,
    cancelButtonText = '',
    confirmButtonText = '',
    contentClassName,
    cryptoCard,
    hideCancelBtn,
  }) {
    data.confirmed = null
    data.show = true
    data.title = title || ''
    data.text = text || ''
    data.disclaimer = disclaimer || ''
    data.confirmationImg = confirmationImg || ''
    data.cancelButtonText = cancelButtonText || ''
    data.confirmButtonText = confirmButtonText || ''
    data.contentClassName = contentClassName || ''
    data.cryptoCard = cryptoCard || false
    data.hideCancelBtn = hideCancelBtn || false

    await waitForCondition(
      () => data.confirmed === true || data.confirmed === false,
    )

    return data.confirmed
  }
}
