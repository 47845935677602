export class CreditHistoryModel {
  guaranteeBalance: string
  creditBalance: string
  // created_at: IDate
  cryptoTicker: string
  days: number
  fiatTicker: string
  id: number
  percent: string
  status: string
  price: string
  repayAmount: string
  dateEnd: number
  payments: P2PPaymentResponse[]
  approvedLoanId: number

  constructor (creditHistoryItem: P2PLoanHistoryResponse) {
    this.guaranteeBalance = creditHistoryItem.amount_crypto
    this.creditBalance = creditHistoryItem.amount_fiat
    this.cryptoTicker = creditHistoryItem.crypto_ticker
    this.days = creditHistoryItem.days
    this.fiatTicker = creditHistoryItem.fiat_ticker
    this.id = creditHistoryItem.id
    this.percent = creditHistoryItem.percent
    this.status = creditHistoryItem.status
    this.price = creditHistoryItem.price
    this.payments = creditHistoryItem.paymentHistory || []
    this.dateEnd = creditHistoryItem.date_end
    this.approvedLoanId = creditHistoryItem.loan_id
    this.repayAmount = creditHistoryItem.repay_amount
  }
}
