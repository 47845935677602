
<template>
  <div
    :class="classes"
    class="exc-with-sidebar-layout"
  >
    <!-- :name="name" -->
    <!-- :active-menu-group="activeMenuGroup" -->
    <!-- :hide-on-mobile="hideMobileSidebar" -->
    <exc-sidebar
      :items="sidebarLinks"
      :current-item="currentItem"
      @toggle="$emit('toggle', $event)"
    />
    <div class="exc-with-sidebar-layout__content pt-20 pl-20 pb-20 pr-64--l pr-20">
      <div class="exc-with-sidebar-layout__head">
        <div
          v-if="title"
          class="exc-with-sidebar-layout__content-title txt txt--24px txt--theme-08 txt--weight-300 mb-20"
        >
          {{ title }}
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import ExcSidebar from '@/components/common/navigation/sidebar/ExcSidebar.vue'

export default {
  name: 'ExcWithSidebarLayout',
  components: {
    ExcSidebar,
  },
  props: {
    sidebarLinks: { type: Array, required: true },
    currentItem: { type: Object, required: true },

    title: { type: String, default: '' },
    // name: { type: String, default: '' },
    // activeMenuGroup: { type: String, default: '' },
    // hideMobileSidebar: { type: Boolean, default: false },
    autoHeightContent: { type: Boolean, default: false },
  },
  computed: {
    classes () {
      return {
        'exc-with-sidebar-layout--fixed-height': !this.autoHeightContent,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-with-sidebar-layout {
  $root: &;
  overflow: hidden;

  @include bp(m) {
    display: flex;
  }

  &--fixed-height {
    @include bp(m) {
      max-height: calc(100vh - #{$h-Header});
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @include bp(m) {
      flex-direction: row;
    }
  }

  &__content {
    width: 100%;
    min-width: 0;
  }

  &__content-title {
    // text-align: center;
    text-transform: capitalize;

    @include bp(m) {
      // text-align: left;
    }
  }
}
</style>
