import { setGlobalDateI18n } from 'fecha'
import moment from 'moment'
// import { $t, $getCurrentLocale } from '@/lib/i18n'
// import { getActualRouterPath } from '@/lib/helpers'
// import { zendeskWidget } from "./zendeskWidget";

// const html = document.querySelector('html') as HTMLHtmlElement
// const i18n = new VueI18n({
//     silentTranslationWarn: true,
//     locale: "fallbackLocale",
//     fallbackLocale: "fallbackLocale",
//     messages: { fallbackLocale },
// });
// const subscribers: Function[] = []
// initLocales(i18n);
// initLocalization(); // moved to App.vue in order to eliminate recursive dependencies and use zendeskWidget after it

export function updateFechaLocalization (context) {
  /**
   * TODO: fix typescrypt errors
   */
  setGlobalDateI18n({
    /* @ts-ignore */
    dayNamesShort: context.$t('common.date.dayNamesShort'),

    /* @ts-ignore */
    dayNames: context.$t('common.date.dayNames'),

    /* @ts-ignore */
    monthNamesShort: context.$t('common.date.monthNamesShort'),

    /* @ts-ignore */
    monthNames: context.$t('common.date.monthNames'),
  })
}

const _MOMENT_I18N_LOCALES_DATA = {}
// NOTE: async moment i18n loader
export function updateMomentLocalization (locale = 'en') {
  // NOTE: Update this list if you need to add more languages
  let selectedLocale = {
    en: 'en-gb',
    ua: 'uk',
    ru: 'ru',
    ko: 'ko',
    fl: 'fi',
    hi: 'hi',
    id: 'id',
    fr: 'fr',
    vn: 'vi',
    zh: 'zh-cn',
    ar: 'ar',
    de: 'de',
    es: 'es',
    pt: 'pt',
    th: 'th',
    sr: 'sr',
    tr: 'tr',
  }[locale] // || $getCurrentLocale()

  if (!selectedLocale) {
    // || $getCurrentLocale()
    console.warn(
      `updateMomentLocalization: ${locale} locale not found! Please add this locale to config (localization.ts)`,
    )
    selectedLocale = 'en-gb'
  }

  if (!_MOMENT_I18N_LOCALES_DATA[selectedLocale]) {
    // NOTE: Async loading moment i18n
    _MOMENT_I18N_LOCALES_DATA[
      selectedLocale
    ] = require(`moment/locale/${selectedLocale}`)
  }
  moment.updateLocale(selectedLocale, _MOMENT_I18N_LOCALES_DATA[selectedLocale])
}

// export async function initLocalization () {
//   // i18n.locale = 'en' // temp
//   const locale = window.APP_LANGUAGE

//   const response = await api.getLocale()

//   if (response.errors) {
//     return
//   }

//   i18n.setLocaleMessage(locale, response)
//   i18n.locale = locale
//   updateFechaLocalization()
//   updateMomentLocalization(locale)
// }

export function setLocale (context, language: string) {
  if (process.client) window.APP_LANGUAGE = language

  // NOTE: vue-i18n in lang mixin

  updateFechaLocalization(context)
  updateMomentLocalization(language)

  // return new Promise(async (resolve, reject) => {
  //   const response = await api.postLocale(language)

  //   if (response.errors) {
  //     console.error(response.errors)
  //     reject(response.errors)
  //     return
  //   }

  //   if (!i18n.messages.hasOwnProperty(language)) {
  //     i18n.setLocaleMessage(language, response)
  //   }

  //   html.setAttribute('lang', language)
  //   window.APP_LANGUAGE = language
  //   i18n.locale = language
  //   updateFechaLocalization()
  //   updateMomentLocalization(language)

  //   setTimeout(() => {
  //     subscribers.forEach(c => c(language))
  //     const path = getActualRouterPath(
  //       router.currentRoute.fullPath || router.currentRoute.path,
  //     )
  //     router.push(
  //       path,
  //       () => {
  //         resolve(window.APP_LANGUAGE)
  //       },
  //       () => {
  //         // If locale was the same.
  //         // for example if user was NOT logged-in and chose "ru" language
  //         // and then logged-in, and then we got his saved language which we should set,
  //         // and if this language was same "ru" router think this is ERROR and we have this
  //         // function to handle this error. Basically this means that route was not changed.
  //         resolve(window.APP_LANGUAGE)
  //       },
  //     )
  //   }, 0)
  // })
}

// export function subscribeLocaleChange(callback: (locale: string) => any): void {
//   subscribers.push(callback)
// }

// export default i18n;
