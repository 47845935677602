export const enum AlignType {
  Left = 1,
  Top = 1,
  Center = 2,
  Middle = 2,
  Right = 3,
  Bottom = 3,
}

const oposites = {
  [AlignType.Left]: AlignType.Right,
  [AlignType.Right]: AlignType.Left,
  [AlignType.Middle]: AlignType.Middle,
  [AlignType.Center]: AlignType.Center,
  [AlignType.Top]: AlignType.Bottom,
  [AlignType.Bottom]: AlignType.Top,
}

export const invertAlignment = (alignObject, elementAlign) => {
  const align = Object.assign({}, alignObject)
  align.target = { ...align.target, [elementAlign]: oposites[align.target[elementAlign]] }
  align.overlay = { ...align.overlay, [elementAlign]: oposites[align.overlay[elementAlign]] }
  return align
}
