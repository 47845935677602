<template>
  <transition name="Modal">
    <div class="Modal__mask pt-24 pb-24">
      <div
        :class="computedContainerClasses"
        class="Modal__container bor--radius-20"
      >
        <!-- <font-awesome-icon
          class="Modal__close txt--theme-08"
          icon="times"
          @click="$emit('close')"
        /> -->
        <img
          :src="require(`@/assets/icons/rdx-delete-icon.svg`)"
          class="Modal__close"
          alt="close"
          @click="$emit('close')"
        >
        <div v-if="$slots.header" class="Modal__header">
          <slot name="header" />
        </div>
        <div
          :class="fullContent ? '' : 'container-new'"
          class="Modal__body px-0"
        >
          <slot
            v-bind="{ isRedesignedPage: currentPageIsRedesigned }"
            name="body"
          />
        </div>
        <div v-if="$slots.footer" class="Modal__footer">
          <slot
            v-bind="{ isRedesignedPage: currentPageIsRedesigned }"
            name="footer"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BaseModal',
  props: {
    containerClass: {
      type: String,
      default: 'col-6',
    },
    fullContent: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      layout: process.client ? document.querySelector('.base-layout') : '',
    }
  },
  computed: {
    ...mapState({
      isDark: state => state.darktheme.enabled,
    }),
    currentPageIsRedesigned () {
      return true
      // const redesignedPages = [
      //     this.$routes.excToken.name,
      //     this.$routes.buySellStable.name,
      //     this.$routes.plcToken.name,
      //     this.$routes.login.name,
      //     this.$routes.register.name,
      //     this.$routes.resetPassword.name,
      //     this.$routes.requestSuccessful.name,
      //     this.$routes.requestUnsuccessful.name,
      //     this.$routes.aboutUs.name,
      //     // this.$routes.codes.name,
      //     // this.$routes.exchange.name,
      //     // this.$routes.stakingPool.name
      //     this.$routes.otc.name,
      //     this.$routes.otcExchange.name,
      //     this.$routes.otcMyOrders.name,
      //     this.$routes.otcPair.name,
      //     this.$routes.otcAdmin.name,
      //     this.$routes.otcAdminMyOrders.name,
      //     this.$routes.otcAdminCreateQuotation.name,
      //     this.$routes.otcAdminUpdateQuotation.name,
      //     this.$routes.otcQuotationDetails.name,
      //     this.$routes.otcManual.name,
      //     this.$routes.burningStage.name,
      //     this.$routes.verifyAccount.name,
      //     this.$routes.historyOrders.name,
      //     this.$routes.historyActiveOrders.name,
      //     this.$routes.historyTrades.name,
      //     this.$routes.historyDeposit.name,
      //     this.$routes.historyCodes.name,
      //     this.$routes.historyWithdraw.name,
      //     this.$routes.codes.name,
      //     this.$routes.trade.name,
      //     this.$routes.tradeByPair.name,
      //     this.$routes.userSupport.name,
      // ]

      // return !!redesignedPages.find(n => n === escapeLocaleFromRoute(this.$route.name))
    },
    computedContainerClasses () {
      return {
        [this.containerClass]: true,
        'modal-full-content': this.fullContent,

        // Temp class. This background must be with old color on
        // old pages and with new color on new pages
        'bg--color-01': this.currentPageIsRedesigned,
        'Modal--old-bg': !this.currentPageIsRedesigned,
      }
    },
  },
  mounted () {
    document.addEventListener('keydown', this.handleCloseOnEscape)
    this.$store.commit('ui/scrollDisable')
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.handleCloseOnEscape)
    this.$store.commit('ui/scrollEnable')
  },
  methods: {
    handleCloseOnEscape (e) {
      if (e.keyCode === 27) {
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss">
.Modal {
  &--old-bg {
    background-color: var(--c-block-bg, #fff);
  }

  &__error {
    color: color(red);
  }

  &__success {
    color: $c-green;
  }

  &__status {
    font-size: 15px;
    font-weight: 300;

    @include var(color, c-green);

    &.red {
      color: color(red);
    }
  }

  &__header {
    margin-top: toRem(15px);

    h3 {
      font-size: 30px;
      font-weight: 300;

      @include var(color, c-main);

      text-align: center;
      margin-top: 0;
    }
  }

  &__body h3 {
    font-size: 30px;
    font-weight: 300;

    @include var(color, c-main);

    text-align: center;
    margin-top: 0;
  }

  &__container {
    min-width: 90%;

    @include bp(m) {
      max-width: 45%;
    }

    @include bp(l) {
      min-width: calc(#{toRem(400px)} + 10vw);
    }
  }

  &__footer {
    margin-bottom: 25px;
    // padding: 0 15px;

    & .BaseButton {
      padding: 1rem 2rem;
    }
  }
}
</style>
<style scoped lang="scss">
// .blue {
//     .base-layout.is-dark & {
//         background: #282635;
//     }
// }

.modal-full-content {
  body & {
    padding: 0;
    @include bp(0, m) {
      margin: 0 toRem(20px);
    }

    .Modal__header,
    .Modal__body,
    .Modal__footer {
      margin: 0;
    }
  }
}

.Modal {
  &-enter {
    opacity: 0;
  }

  &-leave-active {
    opacity: 0;
  }

  &-enter &__container,
  &-leave-active &__container {
    transform: scale(1.1);
  }

  &__mask {
    position: fixed;
    z-index: $z-Modal__mask;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.14);
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
  }

  &__container {
    position: relative;
    margin: 0 auto;
    padding: 20px 30px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    transition: all 0.3s ease;
    max-height: calc(100% - 10px);
    overflow-x: hidden;

    @include webkit-scroll();
  }

  &__close {
    position: absolute;
    right: toRem(16px);
    top: toRem(16px);
    font-size: 1.5rem;
    cursor: pointer;
    // @include var(color, c-detail);
    transition: 0.3s color, 0.3s transform;
    // @include bp(m) {
    //     top: toRem(28px);
    // }

    &:hover,
    &:focus {
      color: color(main);
      transform: scale(1.2);
    }
  }

  &__body {
    margin: toRem(20px) 0 toRem(35px);
  }
}
</style>
