<template>
  <div class="exchange-menu-footer">
    <div class="exchange-menu-footer__block">
      <div
        class="exchange-menu-footer__title txt txt--16px txt--weight-700"
        v-text="title"
      />
      <icon-arrow
        class="exchange-menu-footer__button"
        :class="{ 'exchange-menu-footer__button--active': showMenuMobile }"
        @click="openMenu"
      />
      <ul
        class="exchange-menu-footer__inner mt-10"
        :class="{ 'exchange-menu-footer__inner--show-mobile': showMenuMobile }"
      >
        <li
          v-for="(item, i) in list"
          :key="`footer-${i}`"
          class="exchange-menu-footer__item list-style-none"
        >
          <span
            v-if="item.withAction"
            class="exchange-menu-footer__link txt txt--color-04 txt--pointer"
            @click.prevent="item.action"
          >
            {{ item.name }}
          </span>
          <a
            v-else-if="item.href"
            class="exchange-menu-footer__link txt txt--color-04"
            :href="item.href"
            target="__blank"
          >
            {{ item.name }}
          </a>
          <ExcLink
            v-else
            class="exchange-menu-footer__link txt txt--color-04"
            :to="item.to"
          >
            {{ item.name }}
          </ExcLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ExcLink } from '@/components/common'

export default {
  name: 'ExchangeMenuFooter',
  components: {
    iconArrow: () => import('@/assets/icons/arrow-down.svg?inline'),
    ExcLink,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      showMenuMobile: false,
    }
  },

  methods: {
    openMenu () {
      this.showMenuMobile = !this.showMenuMobile
    },
  },
}
</script>

<style lang="scss">
.exchange-menu-footer {
  position: relative;

  &__title {
    @include bp(l) {
      margin: 0 0 toRem(16px);
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;

    @include bp(l) {
      gap: toRem(12px);
    }
  }

  &__item {
    list-style-type: none;

    @include bp(0, l) {
      margin: 0 0 toRem(12px);

      &:last-child {
        margin: 0;
      }
    }
  }

  &__link {
    font-size: 16px;
  }

  &__button {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 6px;
    right: 0;
    background: none;
    border: none;
    border-radius: 2px;

    &--active {
      transform: scale(-1);
    }

    @include bp(l) {
      display: none;
    }
  }

  @include bp(0, l) {
    &__inner {
      display: none;

      &--show-mobile {
        display: block;
      }
    }
  }
}
</style>
