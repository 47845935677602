import { mapState } from 'vuex'

const STORAGE_EVENT_KEY = 'auth-event'

export default {
  computed: {
    ...mapState({
      mix_loggedIn: state => state.auth.loggedIn,
    }),
  },

  watch: {
    mix_loggedIn () {
      if (!process.client) return

      const oldValue = window.localStorage.getItem(STORAGE_EVENT_KEY)
      const newValue = 'auth-event--' + Math.random()

      window.localStorage.setItem(STORAGE_EVENT_KEY, newValue)

      window.dispatchEvent(new StorageEvent('storage', {
        key: STORAGE_EVENT_KEY,
        oldValue,
        newValue,
      }))
    },
  },

  mounted () {
    if (!process.client) return

    window.addEventListener('storage', this.mix_tabSyncHandler)
  },

  beforeDestroy () {
    window.removeEventListener('storage', this.mix_tabSyncHandler)
  },

  methods: {
    mix_tabSyncHandler (event) {
      if (!document.hidden) return
      if (event && event.key === STORAGE_EVENT_KEY) {
        console.log('AUTH EVENT')
        location.reload()
      }
    },
  },
}
