<template>
  <component
    :is="componentName"
    v-bind="$attrs"
    :class="{
      'exc-link--no-decoration': noDecoration,
      'router-link-active': $attrs.to && isActive,
      'exc-link--no-decoration-active': noDecorationActive
    }"
    class="exc-link"
    v-on="$listeners"
    @click="checkCallback"
  >
    <slot v-bind="{ isActive }" />
  </component>
</template>

<script>
export default {
  name: 'ExcLink',
  props: {
    noDecoration: { type: Boolean, default: false },
    noDecorationActive: { type: Boolean, default: false },
    forceUseLink: { type: Boolean, default: false },
  },
  computed: {
    componentName () {
      if (this.$attrs.to) {
        return 'router-link'
      } else if (this.$attrs.href || this.forceUseLink) {
        return 'a'
      } else {
        return 'span'
      }
    },
    pathWithoutLocale () {
      const lang = this.$route.params.lang
      if (!lang) return this.$route.path
      return this.$route.path.slice(('/' + lang).length)
    },
    isActive () {
      if (typeof this.$attrs['is-active'] === 'boolean') return this.$attrs['is-active']

      const exact = this.$attrs.to && this.$attrs.exact

      const to = this.$attrs.to
      const currentRouteName = (this.$route.name || '').replace('localized_', '')
      const pathString = this.pathWithoutLocale
      if (typeof to === 'object') {
        if (exact) {
          return to.name === currentRouteName || to.path === pathString
        } else {
          return currentRouteName.startsWith(to.name) || pathString.startsWith(to.path)
        }
      } else if (typeof to === 'string') {
        if (exact) {
          return to === pathString || ('/' + to) === pathString
        } else {
          return pathString.startsWith(to) || pathString.startsWith('/' + to)
        }
      }
      return false
    },
  },
  methods: {
    checkCallback () {
      if (this.$attrs.callback) this.$attrs.callback()
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-link {
  transition: all 0.2s;

  &--no-decoration {
    // color: inherit; // TODO: maybe use another solution
    text-decoration: none;
  }

  &--no-decoration-active {
    // color: inherit; // TODO: maybe use another solution
    text-decoration: none;
  }
}

// without decoration
.exc-link:not(.exc-link--no-decoration) {
  // text-decoration: underline;

  // &:hover:not(svg) {
  //   color: color(main);
  //   text-decoration: none;
  // }
}

// without decoration for active link
.exc-link:not(.exc-link--no-decoration-active) {

  &.router-link-exact-active {
    color: color(main);
  }

  &.router-link-active {
    color: color(main);
  }
}
</style>
