var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('exc-link',_vm._g(_vm._b({staticClass:"exc-header-nav-item",class:{
    'txt--color-04': _vm.onlyTheme === 'dark',
    'txt--color-04': _vm.onlyTheme === 'light',
    'txt--color-04': !_vm.onlyTheme,
    'exc-header-nav-item__isHover': _vm.hoverName,
  },attrs:{"no-decoration":""}},'exc-link',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"exc-header-nav-item__container flx flx--items-center",on:{"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave,"touchstart":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('div',{staticClass:"exc-header-nav-item__name flx flx--items-center",class:{ 'pl-16 pr-16': !_vm.noPadding },on:{"mouseenter":function($event){_vm.hoverName = true},"mouseleave":function($event){_vm.hoverName = false},"touchstart":function($event){_vm.hoverName = !_vm.hoverName}}},[_c('span',{staticClass:"txt txt--16px"},[_vm._t("name",null,null,{ hoverName: _vm.hoverName, isOpen: _vm.isOpen })],2),_vm._v(" "),(_vm.$slots.dropdown && !_vm.withoutArrow)?_c('span',{staticClass:"rxd-header-nav-item__arrow-container flx flx--center"},[(!_vm.isMobileTrade)?[_c('rdx-arrow',{staticClass:"exc-header-nav-item__arrow ml-8",attrs:{"direction":_vm.isArrowToTop ? 'bottom' : 'top'}})]:[_c('exc-dropdown-triangle',{staticClass:"txt--color-04",attrs:{"direction":_vm.isArrowToTop ? 'bottom' : 'top'}})]],2):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.$slots.dropdown)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"exc-header-nav-item__dropdown sha--0420 bor--radius-12",class:[
          ("exc-header-nav-item--dropdown-position-" + _vm.dropdownPosition),
          ("exc-header-nav-item--dropdown-align-" + _vm.dropdownAlign),
          {
            'bg--color-01': _vm.onlyTheme === 'dark',
            'bg--color-01': _vm.onlyTheme === 'light',
            'bg--color-01  ': !_vm.onlyTheme,
          } ],on:{"click":function($event){_vm.isOpen = false}}},[_vm._t("dropdown",null,null,{ hoverName: _vm.hoverName, isOpen: _vm.isOpen })],2):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }