import TradingCompetitionBase from './TradingCompetitionBase'

export class TradingCompetitionPlanModel extends TradingCompetitionBase {
  id: number
  places: { ticker: Ticker, amount: string }[]
  startTime: Date
  condition: string

  constructor (d: TradingCompetitionPlanResponseItem) {
    super(d)

    this.id = d.id
    this.places = d.places
    this.startTime = new Date(d.start_time)
    this.condition = d.condition || 'No condition yet.'
  }
}
