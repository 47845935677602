// import { uniqByImmutable } from "@/lib/utils";
import { formatHistoryTime, parseNumber } from '@/lib/utils'
// import { BalancesState } from "@/store/profile/modules/balances";
// import { Stack, Map, isImmutable } from "immutable";
import groupBy from 'lodash/groupBy'
import { MARGIN_TRADE_CROSS, MARGIN_TRADE_SPOT, TRADE_ALGORITHM_NO, TRADE_ALGORITHM_OCO_ORDER, TRADE_ALGORITHM_STOP_LIMIT } from '~/lib/constants'
// import { Decimal } from "decimal.js";

// export function commonProfileGetters(key: string) {
//     return {
//         [`${key}ByPair`]: state => (pair: PairName) => {
//             if (isPlainObject(state.data)) {
//             // if (isObject(state.data) && !isImmutable(state.data)) {
//                 return state.data[pair] || []
//             }
//             console.error('Wrong type of state.data', state.data)
//             // return (state.data.get(pair) || Stack()).toArray();
//         },
//         [`all${capitalizeFirstLetter(key)}`](state) {
//             if (Array.isArray(state.allEntries)) {
//             // if (Array.isArray(state.allEntries) && !isImmutable(state.allEntries)) {
//                 return state.allEntries.sort(commontSortComparator)
//             }
//             console.error('Wrong type of state.allEntries', state.allEntries)
//             // return state.allEntries.toArray().sort(commontSortComparator);
//         },
//         [`fetched${capitalizeFirstLetter(key)}ByPair`]: (state, getters) => (
//             pair: PairName
//         ) => {
//             return getters[`${key}ByPair`](pair).length;
//         },
//         [`${key}IsLoaded`](state) {
//             return state.initialized;
//         },
//         [`${key}IncrementalLoading`]: state => pair => {
//             // if loadedAll is from immutable.js
//             // TODO: remove first case
//             // if (isImmutable(state.loadedAll)) {
//             //     return (
//             //         state.initialized &&
//             //         !state.loadedAll.get("All") &&
//             //         !state.loadedAll.get(pair)
//             //     );
//             //     // for simple objects
//             // } else {
//                 return (
//                     state.initialized &&
//                     !state.loadedAll["All"] &&
//                     !state.loadedAll[pair]
//                 );
//             // }

//         }
//     };
// }

export function commontSortComparator ({ time: a }: any, { time: b }: any) {
  return b - a
}

// export function mergeStacks<TEntry>(
//     prev: any,
//     next: any,
//     uniqBySelector: (el: TEntry) => any,
//     sortFunc: (a: TEntry, b: TEntry) => number,
//     isUpdate: boolean = false
// ) {
//     return uniqByImmutable<TEntry>(
//         (prev || Stack())[isUpdate ? "unshiftAll" : "pushAll"](next),
//         uniqBySelector
//     )
//         .toStack()
//         .sort(sortFunc);
// }

// TRADES
export function parseRawTrades (
  history: RawUserTrade[],
  pair?: PairName,
): TradeEntry[] {
  return history.map(
    ({
      id,
      time,
      deal_order_id,
      price,
      market,
      deal,
      amount,
      side,
      fee,
    }) => ({
      id: deal_order_id,
      pair: (market as PairName) || pair,
      key: `${deal_order_id}:${id}:${side}`,
      total: parseNumber(deal),
      price: parseNumber(price),
      amount: parseNumber(amount),
      tradeType: (side === 2 ? 'buy' : 'sell') as TradeType,
      time,
      fee,
      createdAt: formatHistoryTime(time * 1000),
    }),
  )
}

export function parseRawTradesByAllPairs (
  history: RawUserTrade[],
): { [key: string]: TradeEntry[] } {
// ): Map<PairName, Stack<TradeEntry>> {
  const hash = groupBy(parseRawTrades(history), 'pair')

  return hash

  // return Map(hash).map(Stack) as Map<PairName, Stack<TradeEntry>>;
}

// DEPOSITS AND WITHDRAWALS
export function parseRawDeposits (
  history: RawUserDepositWithdraw[],
): DepositWithdrawEntry[] {
  return history.map(({ amount, createdAt, currency, ticker, status, address, txHash, explorerLink }) => {
    return {
      ticker,
      createdAt: formatHistoryTime(createdAt * 1000),
      time: createdAt,
      currency,
      amount: parseNumber(amount),
      status,
      address,
      txHash,
      explorerLink,
    }
  })
}

export function parseRawDepositsByAllPairs (
  history: RawUserDepositWithdraw[],
): { [key: string]: DepositWithdrawEntry[] } {
// ): Map<Ticker, Stack<DepositWithdrawEntry>> {
  const hash = groupBy(parseRawDeposits(history), 'ticker')
  return hash

  // return Map(hash).map(Stack) as Map<Ticker, Stack<DepositWithdrawEntry>>;
}

// ORDERS
export function parseRawOrders (orders: RawOrder[]): Order[] {
  return orders.map(parseRawOrder)
}

export function parseRawOrder (order: RawOrder) {
  const {
    platform, // : 0 | 1, // 0 - spot, 1 - margin
    algorithm, // : 0 | 1 | 2, // 0 - without algorithm, 1 - stop limit, 2 - oco order
    id,
    amount,
    price,
    market,
    deal_money,
    deal_stock,
    side,
    ctime,
    ftime,
    type,
    stop_price,
    limit_price,
  } = order

  return {
    id,
    tradePlatform: platform === 1 ? MARGIN_TRADE_CROSS : MARGIN_TRADE_SPOT,
    tradeAlgorithm: {
      0: TRADE_ALGORITHM_NO,
      1: TRADE_ALGORITHM_STOP_LIMIT,
      2: TRADE_ALGORITHM_OCO_ORDER,
    }[algorithm],
    price: parseNumber(price),
    stopPrice: stop_price,
    limitPrice: limit_price,
    amount: parseNumber(amount),
    time: ftime || ctime,
    pair: market as PairName,
    // @TODO fix type error
    // @ts-ignore
    createdAt: formatHistoryTime(ctime * 1000),
    // @TODO fix type error
    // @ts-ignore
    finishedAt: ftime ? formatHistoryTime(ftime * 1000) : undefined,
    tradeType: (side === 2 ? 'buy' : 'sell') as TradeType,
    type: (type === 1 ? 'Limit' : type === 2 ? 'Market' : '') as OrderType,
    dealedAmount: parseNumber(deal_stock),
    dealedMoney: parseNumber(deal_money),
  }
}

export function parseRawOrdersByAllPairs (
  entries: RawOrder[],
): { [key: string]: Order[] } {
// ): Map<PairName, Stack<Order>> {
  const hash = groupBy(parseRawOrders(entries), 'pair')
  return hash

  // return Map(hash).map(Stack) as Map<PairName, Stack<Order>>;
}

export function parseRawMainBalances (
  balances: RawBalance[],
): { [key in Ticker]: Partial<UserBalance> } {
// ): Map<Ticker, Partial<UserBalance>> {
  return balances.reduce(
    (
      acc,
      {
        ticker,
        label,
        currency_id,
        is_withdrawal,
        is_fiat,
        main_balance,
        image,
        is_erc20,
        is_memo,
        is_int,
      },
    ) => {
      acc[ticker] = {
        // acc.set(ticker, {
        ticker: ticker as Ticker,
        id: currency_id,
        label,
        isInt: !!is_int,
        isFiat: !!is_fiat,
        isWithdrawal: !!is_withdrawal,
        mainBalance: main_balance,
        image: image || '',
        isErc20: !!parseFloat(is_erc20),
        isMemo: !!parseFloat(is_memo),
      }
      return acc
    },
    // }),
    {} as { [key in Ticker]: Partial<UserBalance> },
    // Map() as Map<Ticker, Partial<UserBalance>>
  )
}

// export function compareRows({ sortOrder, sortBy }: BalancesState) {
//     return (a: any, b: any) => {
//         switch (sortOrder) {
//             case 1:
//                 return Number(a[sortBy] || 0) - Number( b[sortBy] || 0); // ascending
//             case 2:
//                 return Number(b[sortBy] || 0) - Number( a[sortBy] || 0); // descending
//             default:
//                 return 0;
//         }
//     };
// }
