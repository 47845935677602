import geetestCaptcha from '@/lib/geetestCaptcha.ts'
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      mix_captchaObj: null,
    }
  },
  methods: {
    initCaptchaObj () {
      return new Promise((resolve) => {
        const asyncFunc = async () => {
        // if captcha already loaded
          if (this.mix_captchaObj) {
            resolve(this.mix_captchaObj)
            return
          }
          // if captcha not loaded
          const geetestHandler = await geetestCaptcha.loadGeetest(this.$api)
          geetestHandler((captchaObj) => {
          // save captcha object
            this.mix_captchaObj = captchaObj
            this.setRecaptchaReference(this.mix_captchaObj)
            // return promise when ready
            this.mix_captchaObj.onReady(() => {
              resolve(this.mix_captchaObj)
            })
          })
        }

        asyncFunc()
      })
    },
    async startCaptcha () {
      // load captcha if not loaded
      await this.initCaptchaObj()
      // add event hooks and verify
      return new Promise((resolve, reject) => {
        const asyncFunc = async () => {
          await this.mix_captchaObj.onSuccess(() => resolve(this.mix_captchaObj))
          await this.mix_captchaObj.onError(() => reject(this.mix_captchaObj))
          await this.mix_captchaObj.onClose(() => reject(this.mix_captchaObj))
          await this.mix_captchaObj.verify()
        }

        asyncFunc()
      })
    },
    ...mapMutations({
      setRecaptchaReference: 'profile/auth/setRecaptchaReference',
    }),
  },
}
