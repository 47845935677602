var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({staticClass:"exc-mob-nav-item"},_vm.$listeners),[_c('div',{staticClass:"exc-mob-nav-item__container"},[_c('exc-link',_vm._b({staticClass:"exc-mob-nav-item__item-content pl-12 pr-12",class:{
        'exc-mob-nav-item__item-content--is-active-menu-item': _vm.openDropdown,
      },attrs:{"no-decoration":""},nativeOn:{"click":function($event){return _vm.onClickItemName.apply(null, arguments)}}},'exc-link',_vm.$attrs,false),[_c('div',{staticClass:"exc-mob-nav-item__name"},[_c('span',{staticClass:"txt txt--14px txt--height-18px flx txt--color-04",class:{
            'txt--main': _vm.isMenuGroupSelected,
            'txt--color-04': !_vm.isMenuGroupSelected && _vm.onlyTheme === 'dark',
            'txt--color-04': !_vm.isMenuGroupSelected && _vm.onlyTheme === 'light',
            'txt--color-04': !_vm.isMenuGroupSelected && !_vm.onlyTheme,
            classes: _vm.classes,
          }},[_vm._t("mobile-menu-icon"),_vm._v(" "),_vm._t("name",null,null,{ isMenuGroupSelected: _vm.isMenuGroupSelected })],2)]),_vm._v(" "),(_vm.$slots.dropdown || _vm.$slots.icon)?_c('div',{staticClass:"exc-mob-nav-item__icon"},[_vm._t("icon"),_vm._v(" "),(_vm.$slots.dropdown && !_vm.$slots.icon)?_c('div',{staticClass:"exc-mob-nav-item__arrow-container pl-4"},[_c('rdx-arrow',{staticClass:"exc-mob-nav-item__arrow",class:{
              'txt--main': _vm.isMenuGroupSelected,
              'txt--color-04': !_vm.isMenuGroupSelected && _vm.onlyTheme === 'dark',
              'txt--color-04': !_vm.isMenuGroupSelected && _vm.onlyTheme === 'light',
              'txt--color-04': !_vm.isMenuGroupSelected && !_vm.onlyTheme,
            },attrs:{"direction":!_vm.openDropdown ? 'bottom' : 'top',"arrow-direction":"bottom"}})],1):_vm._e()],2):_vm._e()]),_vm._v(" "),(_vm.$slots.dropdown)?_c('div',{staticClass:"exc-mob-nav-item__dropdown",class:{ 'exc-mob-nav-item--open-dropdown': _vm.openDropdown }},[_vm._t("dropdown")],2):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }