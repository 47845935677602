import { Module, MutationTree } from 'vuex'
import { BorrowingApplicationItem } from '@/data-objects/p2p/BorrowingApplicationItem'
import { SHOW_MORE_STEP } from '@/constants'
import { RootState } from '..'

interface P2pAppBorrowInterface {
  offset: number
  borrowingApps: null | BorrowingApplicationItem[]
}
const state: P2pAppBorrowInterface = {
  offset: SHOW_MORE_STEP,
  borrowingApps: null,
}

const actions = {
  async getBorrowingApps ({
    commit,
    state,
  }): Promise<P2pAppBorrowInterface> {
    const resp = await this.$api.p2pBorrowUser()
    if (resp.errors) {
      console.error('api.p2pBorrowUser::', resp.errors)
      return
    }
    commit('resetOffset')
    commit('setBorrowingApps', resp.response)
    return state
  },
  // async showMoreBorrowingApps({ dispatch, commit, state, getters }, { isUser = false } = {}) {
  //     const resp = await api.p2pShowMoreBorrow({ offset: state.offset + SHOW_MORE_STEP })
  //     if (resp.errors) {
  //         console.error('api.p2pShowMoreBorrow::', resp.errors)
  //         return
  //     }
  //     commit('increaseOffset')
  // }
}

const mutations: MutationTree<P2pAppBorrowInterface> = {
  setBorrowingApps (state, apps) {
    state.borrowingApps = apps.map(o => new BorrowingApplicationItem(o))
  },
  increaseOffset (state) {
    state.offset = state.offset + SHOW_MORE_STEP
  },
  resetOffset (state) {
    state.offset = SHOW_MORE_STEP
  },
}

export const appBorrow: Module<P2pAppBorrowInterface, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
}
