// import { loadingGetters } from "@/store/lib/helpers";
import { Module } from 'vuex'
import { actionWrapper } from '@/lib/utils'
// import { notifyError, notifySuccess } from '@/lib/bus'
// import { $t } from '@/lib/i18n'
import { RootState } from '~/store'

export interface ApiPairsState {
  data: Hash<ApiPair>;
}

const ApiPairModule: Module<ApiPairsState, RootState> = {
  namespaced: true,
  state: () => ({
    data: {},
  }),
  mutations: {
    setData (state, { response }) {
      state.data = parseResponse(response)
    },
    toggleEnable (state, { payload }) {
      state.data = {
        ...state.data,
        [payload]: {
          ...state.data[payload],
          isEnabled: !state.data[payload].isEnabled,
        },
      }
    },
    remove (state, { payload, response: { revert } }) {
      state.data = {
        ...state.data,
        [payload]: {
          ...state.data[payload],
          deleted: !revert,
        },
      }
    },
    add (state, { response }) {
      state.data = {
        ...state.data,
        [response.id]: response,
      }
    },
  },
  getters: {
    formattedData (state) {
      return Object.values(state.data).filter(d => !d.deleted)
    },
  },
  actions: {

    init (...args) {
      return actionWrapper<ApiPairsState>({
        apiRequest: () => this.$api.getApiKeys(),
        mutationName: 'setData',
      }).bind(this)(...args)
    },

    toggle (...args) {
      return actionWrapper<ApiPairsState>({
        apiRequest: id => this.$api.toggleApiKey(id),
        mutationName: 'toggleEnable',
      }).bind(this)(...args)
    },

    remove (...args) {
      return actionWrapper<ApiPairsState>({
        apiRequest: id => this.$api.deleteApiKey(id),
        mutationName: 'remove',
        errorHandler: ({ context: { commit }, response: { errors }, payload }) => {
          if ((errors as any).status === 400) {
            return
          }

          commit('remove', { id: payload, revert: true })
        },
      }).bind(this)(...args)
    },
    create (...args) {
      return actionWrapper<ApiPairsState>({
        apiRequest: () => this.$api.generateNewApiKey(),
        mutationName: 'add',
        successHandler ({ context, response, payload }) {
          if (payload.errorHandler && typeof payload.errorHandler === 'function') {
            payload.successHandler({ context, response, payload })
          }
        },
        errorHandler: ({ context, response, payload }, res) => {
          if (payload.errorHandler && typeof payload.errorHandler === 'function') {
            payload.errorHandler({ context, response, payload }, res)
          }
        },
      }).bind(this)(...args)
    },
  },
}

function parseResponse (response: ApiPair[]): Hash<ApiPair> {
  return response.reduce((acc, pair) => {
    acc[pair.id] = pair

    return acc
  }, {} as Hash<ApiPair>)
}

export default ApiPairModule
