import {
  HTTPRequest,
  HTTPResponse,
  SchemePartialOptions,
} from '@nuxtjs/auth-next'
import {
  Auth,
  RefreshScheme,
  RefreshSchemeOptions,
} from '@nuxtjs/auth-next/dist/runtime'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import ws from '@/lib/websocket'
import { replaceResponseKeyWithData } from './schemeUtils'

const transformers = {
  endpoints: {
    login: { transformResponse: [replaceResponseKeyWithData] },
    refresh: { transformResponse: [replaceResponseKeyWithData] },
    user: { transformResponse: [replaceResponseKeyWithData] },
    logout: { transformResponse: [replaceResponseKeyWithData] },
  },
}

export default class BaseCustomScheme extends RefreshScheme {
  constructor (
    $auth: Auth,
    options: SchemePartialOptions<RefreshSchemeOptions>,
  ) {
    const optionsWithTransformers = merge(cloneDeep(options), transformers)
    super($auth, optionsWithTransformers)

    // console.log('options::', optionsWithTransformers.endpoints.login.transformResponse[0])
  }

  /**
   * Reuse common logic from original `LocalSchema` `login()` method but with additional functionality.
   */
  async login (
    endpoint: HTTPRequest,
    { reset = true } = {},
  ): Promise<HTTPResponse> {
    if (!this.options.endpoints.login) {
      return
    }

    // Ditch any leftover local tokens before attempting to log in
    if (reset) {
      this.$auth.reset({ resetInterceptor: false })
    }

    // Add client id to payload if defined
    if (this.options.clientId) {
      endpoint.data.client_id = this.options.clientId
    }

    // Add grant type to payload if defined
    if (this.options.grantType) {
      endpoint.data.grant_type = this.options.grantType
    }

    // Add scope to payload if defined
    if (this.options.scope) {
      endpoint.data.scope = this.options.scope
    }

    // Make login request
    const response = await this.$auth.request(
      endpoint,
      this.options.endpoints.login,
    )
    // debugger
    // merge(cloneDeep(this.options.endpoints.login), transformers.endpoints.)

    // @ts-ignore-next-line
    if (response?.data?.tokens) {
      // Update tokens
      this.updateTokens(response)

      // Initialize request interceptor if not initialized
      if (!this.requestHandler.interceptor) {
        this.initializeRequestInterceptor()
      }

      // Fetch user if `autoFetch` is enabled
      if (this.options.user.autoFetch) {
        await this.fetchUser()
      }

      // set websocket token_web_socket token
      if (process.client) {
        const socketToken = response.data.token_web_socket
        if (socketToken) {
          this.$auth.$storage.setUniversal('socketToken', socketToken)
          ws.setToken(socketToken)
          ws.logout()
          await ws.authorize()
        }
      }

      return response
    } else {
      return response
    }
  }

  async logout (endpoint: HTTPRequest = {}): Promise<void> {
    // Only connect to logout endpoint if it's configured
    if (this.options.endpoints.logout) {
      await this.$auth
        .requestWith(this.name, endpoint, this.options.endpoints.logout)
        .catch(() => {
          //
        })
    }

    if (process.client) {
      this.$auth.$storage.removeUniversal('socketToken')
      ws.setToken(null)
      ws.close()
    }

    // But reset regardless
    return this.$auth.reset()
  }
}
