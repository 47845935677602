<template>
  <div
    v-click-outside="closeSidebar"
    class="exc-header-mob"
  >
    <slot name="header-mobile-right-part" />
    <!-- hamburger button -->
    <div class="exc-header-mob__hamburger">
      <button
        :class="{ 'is-active': showSidebar }"
        class="hamburger hamburger--spring"
        aria-label="Menu"
        type="button"
        @click="toggleSidebar"
      >
        <hamburger-menu class="hamburger-box" />
      </button>
    </div>

    <!-- side menu background -->
    <transition name="fade">
      <div
        v-if="showSidebar"
        class="exc-header-mob__mob-nav-bg"
        @click="toggleSidebar"
      />
    </transition>

    <!-- side menu -->
    <div
      :class="{
        'exc-header-mob--opened': showSidebar,
        'bg--color-01': onlyTheme === 'dark',
        'bg--color-01': onlyTheme === 'light',
        'bg--theme-01': !onlyTheme
      }"
      class="exc-header-mob__mob-nav bor--bottom-left-radius-12 bor--top-left-radius-12"
    >
      <div class="exc-header-mob__mob-nav-container pt-24 pb-24">
        <!-- MOBILE AUTH ITEMS -->
        <slot
          name="mobile-auth"
          v-bind="{ toggleSidebar }"
        />

        <div @click="toggleSidebar">
          <slot
            name="close-icon"
          />
        </div>

        <!-- buy crypto -->
        <!-- finance -->
        <!-- stakingPool -->
        <!-- store -->
        <exc-mob-nav-item
          v-for="linksGroup in [ ...Object.values(navLinks) ]"
          :key="linksGroup.name"
          v-bind="{ ...linksGroup, name: undefined, icon: linksGroup.icon, label: undefined, menu: undefined, onlyTheme: onlyTheme }"
          @click="!linksGroup.menu && toggleSidebar()"
        >
          <template #mobile-menu-icon>
            <div class="exc-header-mob__mob-nav-icon">
              <img
                :src="linksGroup.icon"
                alt=""
              >
            </div>
          </template>
          <template #name>
            {{ linksGroup.name }}
            <exc-header-label-creator
              v-if="linksGroup.label"
              :label="linksGroup.label"
            />
          </template>
          <template #dropdown>
            <exc-mob-dropdown-item
              v-for="link in linksGroup.menu"
              :key="link.name"
              v-bind="{ ...link, name: undefined }"
              @click.native="toggleSidebar()"
            >
              <template #name>
                {{ link.name }}
                <exc-header-label-creator
                  v-if="link.label"
                  :label="link.label"
                />
              </template>
              <template #description>
                {{ link.description }}
              </template>
            </exc-mob-dropdown-item>
          </template>
        </exc-mob-nav-item>

        <!-- MOBILE CUSTOM ITEMS -->
        <slot
          name="mobile-custom"
          v-bind="{ toggleSidebar }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { onlyThemePropValidator } from '@/lib/utils'
import ExcMobNavItem from './ExcMobNavItem.vue'
import ExcMobDropdownItem from './ExcMobDropdownItem.vue'
import ExcHeaderLabelCreator from './ExcHeaderLabelCreator.vue'

export default {
  name: 'ExcHeaderMob',
  components: {
    ExcMobNavItem,
    ExcMobDropdownItem,
    ExcHeaderLabelCreator,
    HamburgerMenu: () => import(/* webpackMode: "eager" */ '@/assets/icons/header/hamburger.svg?inline'),
  },
  directives: {
    ClickOutside,
  },
  props: {
    onlyTheme: onlyThemePropValidator(),
    navLinks: { type: Array, required: true },
  },
  data () {
    return {
      showSidebar: false,
    }
  },
  methods: {
    toggleSidebar () {
      // TODO: remove prevent-scroll on window resize

      this.showSidebar = !this.showSidebar

      this.$emit('toggle', this.showSidebar)

      if (this.showSidebar) {
        document.body.classList.add('disable-scroll') // TODO: remove later when merged
      } else {
        document.body.classList.remove('disable-scroll') // TODO: remove later when merged
      }
    },
    closeSidebar () {
      this.showSidebar = false
      document.body.classList.remove('disable-scroll') // TODO: remove later when merged
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-header-mob {
  height: 100%;

  &__hamburger {
    height: $h-Header;
    display: flex;
    justify-content: center;
    align-items: center;

    .hamburger {
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(.8);
    }
  }

  &__mob-nav-bg {
    z-index: $z-Header + 1;
    background-color: rgba(15, 17, 22, 0.16);
    position: fixed;
    width: 100vw;
    height: 100vh;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
  }

  &__mob-nav {
    transition: all .3s ease;
    position: fixed;
    z-index: $z-header__navigationMobile;
    // max-height: calc(100vh - #{$h-Header});
    height: 100%;
    max-width: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @include webkit-scroll();
  }

  &__mob-nav-icon{
    width: 15px;
    height: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &--opened {
    max-width: 100%;
    width: 327px;
  }
}
</style>
