










































import Vue from 'vue'
import { sizeValidator, defaultSize } from '@/lib/utils'
import ExcLabel from '@/components/common/labels/ExcLabel.vue'
import ExcDropdownTriangle from '@/components/common/svg/ExcDropdownTriangle.vue'

interface Tab {
  value: string
  label: string
  badge?: { text: string }
}

const buttonKeys = ['value', 'label']

export default Vue.extend({
  name: 'ExcTabs',
  components: {
    ExcLabel,
    ExcDropdownTriangle,
  },
  props: {
    disabledTabIndexes: { type: Array, default: () => [] },
    selectedButton: { type: [String, Number], required: true },
    disabled: { type: Boolean, default: false },
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
    noPaddings: { type: Boolean, default: false },
    noActiveItemStyles: { type: Boolean, default: false },
    buttons: {
      type: Array,
      required: true,
      validator: v =>
        v.every(item => buttonKeys.every(button => button in (item as Tab))),
    },
    fullWidth: { type: Boolean, default: false },
    useScroll: { type: Boolean, default: false },
  },
  computed: {
    tabClasses () {
      return {
        'pl-20 pr-20': this.size === 'm' && !this.noPaddings,
        'pl-12 pr-12': this.size === 's' && !this.noPaddings,
        'pl-4 pr-4 pl-8--m pr-8--m': this.size === 'xs' && !this.noPaddings,
        'exc-tabs--full-width': this.fullWidth,
        // 'pt-8 pb-8 pl-8 pr-8': !this.noPaddings,
      }
    },
    mainClasses () {
      return {
        'exc-tabs--use-scroll': this.useScroll,
        'exc-tabs--no-active-item-styles': this.noActiveItemStyles,
      }
    },
  },
})
