import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '../../index'
import paginationInfo from './paginationInfo'

export interface OtcQuotationItem {
  buy_coeff: string
  buy_max: string
  buy_min: string
  button_type: string
  crypto_id: number
  crypto_logo: string
  crypto_name: string
  crypto_price: string
  crypto_ticker: string
  disable_buy: boolean
  deals: number
  fiat_id: number
  fiat_logo: string
  fiat_name: string
  fiat_price: string
  fiat_ticker: string
  id: number
  name: string
  payments: []
  sell_coeff: string
  sell_max: string
  sell_min: string
}

export interface OtcQuotationsState {
  activeQuotations: OtcQuotationItem[]
  allQuotations: OtcQuotationItem[]
  details: { [key in number]: OtcQuotationItem }
  ordersList: { [key in number]: OtcOrderItem[] }
}

const state = () => ({
  activeQuotations: [],
  allQuotations: [],
  details: {},
  ordersList: {},
}) as OtcQuotationsState

const actions: ActionTree<OtcQuotationsState, RootState> = {
  async getActiveQuotations ({ commit }) {
    const { response, errors } = await this.$api.otcGetActiveQuotationsList()

    if (errors) {
      console.error(errors)
      return
    }

    commit('setActiveQuotations', response)
  },
  async getAllQuotations ({ commit }) {
    const { response, errors } = await this.$api.otcGetQuotationsList()

    if (errors) {
      console.error(errors)
      return
    }

    commit('setAllQuotations', response)
  },
  async getQuotationInfo ({ commit }, id: number) {
    const { response, errors } = await this.$api.otcAdminGetQuotation(id)

    if (errors) {
      console.error(errors)
      return
    }

    commit('setQuotationInfo', { details: response, id })
  },
  async getQuotationOrders (
    { commit },
    { id, page, status }: { id: number, page: number, status?: string },
  ) {
    const { response, errors } = await this.$api.otcAdminGetQuotationOrders(
      id,
      page,
      status,
    )

    if (errors) {
      console.error(errors)
      return
    }

    page > 1
      ? commit('addOrders', { orders: response.orders, id })
      : commit('setOrders', { orders: response.orders, id })

    commit('paginationInfo/setPagination', response)
  },
}

const mutations: MutationTree<OtcQuotationsState> = {
  setActiveQuotations (state, pld: OtcQuotationItem[]) {
    state.activeQuotations = pld
  },
  setAllQuotations (state, pld: OtcQuotationItem[]) {
    state.allQuotations = pld
  },
  setQuotationInfo (state, { id, details }) {
    state.details[id] = details
    state.details = { ...state.details }
  },
  blockQuotationTrade (
    state,
    { id, shouldBlock }: { id: number, shouldBlock: boolean },
  ) {
    state.allQuotations = state.allQuotations.map((quotation) => {
      if (quotation.id === id) {
        return {
          ...quotation,
          disable_buy: shouldBlock,
        }
      }

      return quotation
    })
  },
  setOrders (state, { orders, id }) {
    // state.ordersList[id] = orders
    state.ordersList = {
      ...state.ordersList,
      [id]: orders,
    }
  },
  addOrders (state, { orders, id }) {
    state.ordersList[id] = [...state.ordersList[id], ...orders]
    state.ordersList = { ...state.ordersList }
  },
  setArbitrationStatus (state, { quotationId, orderId }) {
    if (state.ordersList[quotationId]?.length) {
      const orders = state.ordersList[quotationId].map((order) => {
        if (+order.id === +orderId) {
          return {
            ...order,
            button_type: 'arbitration',
            status: 'arbitration',
          }
        }
        return order
      })
      state.ordersList = {
        ...state.ordersList,
        [quotationId]: orders,
      }
    }
  },
}

const modules = { paginationInfo }

const getters: GetterTree<OtcQuotationsState, RootState> = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules,
} as Module<OtcQuotationsState, RootState>
