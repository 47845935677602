<template>
  <div class="exc-footer-mobile">
    <div class="exc-footer-mobile__container">
      <slot name="mobile-logo" />

      <rxd-footer-mob-nav-item
        v-for="column in navLinks"
        :key="column.title"
        :only-theme="onlyTheme"
      >
        <template #name>
          <span class="txt--weight-600">
            {{ column.title }}
          </span>
        </template>
        <template #dropdown>
          <rxd-footer-mob-dropdown-item
            v-for="link in column.list"
            :key="link.name"
            v-bind="{ ...link }"
          >
            <template #name>
              {{ link.name }}
            </template>
          </rxd-footer-mob-dropdown-item>
        </template>
      </rxd-footer-mob-nav-item>
      <!-- SLOT IS HERE -->
      <slot />
    </div>
  </div>
</template>

<script>
import RxdFooterMobNavItem from '@/components/common/header/parts/RxdFooterMobNavItem.vue'
import RxdFooterMobDropdownItem from '@/components/common/header/parts/RxdFooterMobDropdownItem.vue'
import { onlyThemePropValidator } from '@/lib/utils'

export default {
  name: 'ExcFooterMobile',
  components: {
    RxdFooterMobNavItem,
    RxdFooterMobDropdownItem,
  },
  props: {
    onlyTheme: onlyThemePropValidator(),
    navLinks: { type: Array, required: true },
  },
}
</script>
