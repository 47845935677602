







































import Vue from 'vue'
import { sizeValidator, defaultSize } from '@/lib/utils'

export default Vue.extend({
  name: 'RxdBlockHeadingRow',
  props: {
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    paddings () {
      return {
        'pl-12 pr-12': this.size === 's',
        'pl-20 pr-20': this.size === 'm',
        'pt-16 pb-16 pl-32--m pr-32--m pl-20 pr-20': this.size === 'l',
        'pt-20 pb-20 pl-32--m pr-32--m pl-20 pr-20': this.size === 'xl',
      }
    },
    classes () {
      return {
        'rxd-block-heading-row--wrap': !this.noWrap,
      }
    },
  },
})
