


































import Vue from 'vue'

export default Vue.extend({
  name: 'ExcSwitch',
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    compValue: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      },
    },
  },
})
