<template>
  <!-- eslint-disable vue/valid-v-on -->
  <!-- eslint-disable vue/no-parsing-error  -->
  <input
    :value="value"
    v-bind="$attrs"
    :class="[classes, `tdx-base-input--${size}`, $attrs.disabled ? 'bg--transp-00' : '']"
    class="tdx-base-input pl-16"
    v-on="{ ...$listeners, input: ev => $emit('input', ev.target.value), focus: onFocus, blur: onBlur }"
    @wheel="$event.target.blur()"
  >
</template>

<script>
import { sizeValidator, defaultSize } from '@/lib/utils'
export default {
  name: 'TdxBaseInput',
  props: {
    value: {
      validator: v => ['string', 'number'].includes(typeof v) || v === null,
      required: true,
    },
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
    withoutBorder: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    hasSuccess: { type: Boolean, default: false },
    resetEmpty: { type: Boolean, default: false },
  },

  computed: {
    classes () {
      return {
        'bor--all bor--radius-01 txt txt--16px txt--color-04': true,
        'bor--transp-02': !this.withoutBorder,
        'bor--transp sha--none tdx-base-input--without-border': this.withoutBorder,
        // 'bor--red': this.hasError,
        // 'bor--green': this.hasSuccess,
      }
    },
  },
  methods: {
    onFocus () {
      if (+this.value === 0 && this.resetEmpty) { this.$emit('input', '') }
    },
    onBlur () {
      if (!this.value && this.resetEmpty) {
        this.$emit('input', '0')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tdx-base-input {
  $root: &;

  width: 100%; // calc(100% - #{toRem(26px)}); // 26px is left and right padding (12 * 2) + 2px border
  min-width: 1px; // for correct adaptive width in mozilla
  background-color: transparent;
  transition: all .2s;
  box-shadow: map-get($shadows, 'rxd');

  &--without-border {
    border: none;
  }

  &:focus:not(:disabled) {
    outline: none;
  }

  &::placeholder {
    color: color(rgba(145, 148, 159, 0.2));
  }

  &:disabled {
    cursor: not-allowed;
    color: color(rgba(145, 148, 159, 0.2));
    -webkit-text-fill-color: color(rgba(145, 148, 159, 0.2));
  }

  &[type='number'] {
    -moz-appearance:textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: transparent;
  }

  $sizes: (
    xl: (
      'height': toRem(map-get($uiKitSizes, xl)),
      'padding': 20px 24px 20px 24px,
    ),
    l: (
      'height': toRem(map-get($uiKitSizes, l)),
      'padding': 0 0,
    ),
    m: (
      'height': toRem(map-get($uiKitSizes, m)),
      'padding': 35.5px 18px 11.5px,
    ),
    s: (
      'height': toRem(map-get($uiKitSizes, s)),
      'padding': 0 0,
    ),
    xs: (
      'height': toRem(map-get($uiKitSizes, xs)),
      'padding': 0 0,
    ),
  );

  @each $size, $params in $sizes {
    &--#{$size} {
      @each $prop, $value in $params {
        #{$prop}: $value;
      }
    }
  };
}

// this makes text white (on dark theme) when chrome autofills inputs
// input {
//   .is-dark & {
//     color: white;
//     &:-webkit-autofill,
//     &:-webkit-autofill:hover,
//     &:-webkit-autofill:focus {
//       -webkit-text-fill-color: white;
//       caret-color: white;
//     }
//   }
// }
</style>
