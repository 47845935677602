import { parseNumber } from "@/lib/utils";
import Decimal from "decimal.js-light";

export interface CryptoItemInterface {
    currency_id: number
    main_balance: string
    main_balance_usdt: number
    ticker: Ticker
}

export class CryptoItem {
    currencyId: number
    // mainBalance: string
    mainBalanceParsed: string
    // mainBalanceUsdt: number
    mainBalanceUsdFixed: string
    ticker: Ticker
    constructor(obj: CryptoItemInterface) {
        this.currencyId = obj.currency_id
        // this.mainBalance = obj.main_balance
        this.mainBalanceParsed = parseNumber(obj.main_balance, 5)
        // this.mainBalanceUsdt = obj.main_balance_usdt
        this.mainBalanceUsdFixed = new Decimal(obj.main_balance_usdt).toFixed(2)
        // this.mainBalanceUsdFixed = parseNumber(obj.main_balance_usdt, 2)
        // this.mainBalanceUsdFixed = parseFloat(obj.main_balance_usdt.toFixed(2))
        this.ticker = obj.ticker
    }
}