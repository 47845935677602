import { Module } from 'vuex'
import { StakingPoolItemModel } from '@/lib/data-models/staking/StakingPoolItemModel'
import InvestmentPlanModel from '@/lib/data-models/investment/InvestmentPlanModel'
import { StakingPlanDetailsModel } from '@/lib/data-models/staking/StakingPlanDetailsModel'
import { actionWrapper } from '@/lib/utils'
import { RootState } from '~/store/index'
// import router from '@/router'

type StakingTicker = { logo: string, name: string, ticker: Ticker }

export interface StakingGroupState {
  groups: StakingGroupResponseData[],
  plans: { [key in Ticker]: StakingPlanResponseItem } | {},
  planDetails: { [key in number]: StakingPoolItemModel } | {},
  history: StakingPlanResponseItem[]
  plansTickers: StakingTicker[]
}

const StakingGroupStateModule: Module<StakingGroupState, RootState> = {
  namespaced: true,
  state: () => ({
    groups: [],
    plans: {},
    planDetails: {},
    history: [],
    plansTickers: [],
  }),
  actions: {
    async getStakingGroup (...args) {
      await actionWrapper<StakingGroupState>({
        apiRequest: () => this.$api.getStakingGroup(),
        mutationName: 'setStakingGroup',
        cacheTime: 1000 * 60,
      }).bind(this)(...args)
    },

    updateStakingGroup (...args) {
      actionWrapper<StakingGroupState>({
        apiRequest: () => this.$api.getStakingGroup(),
        mutationName: 'setStakingGroup',
      }).bind(this)(...args)
    },

    getStakingPlans (...args) {
      actionWrapper<StakingGroupState>({
        apiRequest: payload => this.$api.getStakingPlansByTicker(payload),
        mutationName: 'setPlans',
      }).bind(this)(...args)
    },

    getStakingHistory (...args) {
      actionWrapper<StakingGroupState>({
        apiRequest: () => this.$api.getStakingPlanHistory(),
        mutationName: 'setHistory',
        cacheTime: 1000 * 60,
      }).bind(this)(...args)
    },

    getStakingPlanTickers (...args) {
      actionWrapper<StakingGroupState>({
        apiRequest: () => this.$api.getStakingPlansTickers(),
        mutationName: 'setPlansTickers',
      }).bind(this)(...args)
    },

    getStakingPlanDetails (...args) {
      actionWrapper<StakingGroupState>({
        apiRequest: id => this.$api.getStakingPlanDetails(id),
        // @TODO fix router
        // errorHandler: (_, resp) => {
        //   if (resp.status === 404) router.push(routes.notFound.path)
        // },
        mutationName: 'setPlanDetails',
        cacheTime: 1000 * 60 * 5,
      }).bind(this)(...args)
    },

    updateStakingPlanDetails (...args) {
      actionWrapper<StakingGroupState>({
        apiRequest: id => this.$api.getStakingPlanDetails(id),
        mutationName: 'setPlanDetails',
      }).bind(this)(...args)
    },
  },
  mutations: {
    setStakingGroup (state, { response }) {
      state.groups = response
    },
    setPlans (state, { payload, response }) {
      state.plans[payload] = response
      state.plans = { ...state.plans }
    },
    setHistory (state, { response }) {
      state.history = response
    },
    setPlansTickers (state, { response }) {
      state.plansTickers = response.map(p => ({
        logo: p.image || '/img/balance/coinDefaultIcon.png',
        name: p.name,
        ticker: p.ticker,
      }))
    },
    setPlanDetails (state, { response, payload }) {
      state.planDetails[payload] = response
      state.planDetails = { ...state.planDetails }
    },
  },
  getters: {
    getStakingGroups: state => state.groups.map(d => new InvestmentPlanModel(d)),
    getStakingPlans: state => (ticker: Ticker) => {
      if (!state.plans[ticker]) return null
      return state.plans[ticker].map(d => new StakingPoolItemModel(d))
    },
    getStakingPlanDetails: state => (id: string) => {
      if (!state.planDetails[id]) return null
      return new StakingPlanDetailsModel(state.planDetails[id])
    },
    getStakingHistory: state => state.history.map(d => new StakingPoolItemModel(d)),
    getInvestmentByTicker: state => (ticker: Ticker) => {
      const group = state.groups.find(inv => inv.ticker === ticker)
      if (!group) return {}
      return new InvestmentPlanModel(group)
    },
  },
}

export default StakingGroupStateModule
