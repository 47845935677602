import {
  actionWrapper,
  getDefaultPairObject,
  // parseNumber,
  parseResponseFromMarket,
} from '@/lib/utils'
import { Module } from 'vuex'
// import api from '@/lib/api'
// import router from '@/router'
// import { Map, merge } from "immutable"; //
// import { merge, assign } from 'lodash'
// import { Decimal } from 'decimal.js-light'
// import { routes, escapeLocaleFromRoute, wait } from '@/lib/helpers'
// import { compareRows } from "./profile/helpers";
// import { routes, escapeLocaleFromRoute } from "@/lib/helpers";
// import { compareRows } from "./profile/helpers";
import { MarketListPair } from '@/lib/data-models/pairs/MarketListPair'

export const WELCOME_MARKET_KEY = 'welcomeMarket'

interface Market {
  fetchStatus: FetchStatus
  name: PairName
  volume: string
  change: string
  price: string
}

type Markets = {[key in PairName]: Market} | {}

export interface WelcomeState {
  markets: Markets
  // markets: Map<PairName, Market>;
  news: News[]
  partners: getFrontPageResponse[]
  currencies: getFrontPageResponse[]
  battleNews: getFrontPageResponse[]
  marketCap: CoinMarketCap[]
}

const WelcomeModule: Module<WelcomeState, any> = {
  namespaced: true,
  state: () => ({
    markets: {},
    news: [],
    partners: [],
    currencies: [],
    battleNews: [],
    marketCap: [],
  }),
  mutations: {
    updateWelcomeMarket (state, { response: { pairName, market } }) {
      state.markets[pairName] = {
        ...state.markets[pairName],
        ...market,
      }
    },
    setMarketFetchStatus (state, { pairName, status }) {
      state.markets[pairName].fetchStatus = status
      state.markets = { ...state.markets }
    },
    setFrontCurrencies (state, { response }) {
      state.currencies = response
    },
    setBattleNews (state, { response }) {
      state.battleNews = response
    },
    setMarketCup (state, { response }) {
      state.marketCap = response
    },
  },
  getters: {
    marketsLoading (state) {
      return Object.keys(state.markets).every(k =>
        ['init', 'loading'].includes(state.markets[k].fetchStatus),
      )
    },
    marketList: (state, _, rootState) => {
      // TODO: use another getter from pairs ?
      const defaultMarkets = initMarkets(rootState.globals.marketList)
      return {
        ...defaultMarkets,
        ...state.markets,
      }
    },
    getCurrenciesForPlace: (state, getters) => (place) => {
      const pairsOnlyInSortedRows = pair =>
        getters.sortedRows.find(r => r.value[0] === pair)

      return state.currencies
        .filter(c => c.place === place) // filter which only for top row
        .map(c => c.name)
        .filter(pairsOnlyInSortedRows) // bacause it will get data from that array
    },

    allRows (state, _, rootState, rootGetters) {
      const pairs = rootGetters['pairs/marketPairs'] as MarketListPair[]
      const currenciesPairNames = state.currencies.map(k => k.name)
      const pairsForMain = pairs.filter(p =>
        currenciesPairNames.includes(p.key),
      )
      const withData = state.currencies.map((k) => {
        const pair = pairsForMain.find(p => p.key === k.name)

        if (!pair || !pair.change) return null

        const className =
          parseFloat(pair.change) < 0
            ? 'is-redColorText'
            : parseFloat(pair.change) > 0
              ? 'is-greenColorText'
              : ''

        const img = rootState.pairs.currencyImages[pair.key.split('_')[0]]

        return {
          place: k.place,
          priority: k.priority,
          value: [
            k.name,
            pair.volume,
            {
              value: `${pair.change}`,
              class: className,
            },
            // price
            parseFloat(parseFloat(pair.price).toFixed(6)),
            k.klineData || [],
          ],
          pairWithLogo: {
            logo: img || require('@/assets/img/balance/coinDefaultIcon.png'),
            pair: {
              left: pair.key.split('_')[0] || 'left',
              right: pair.key.split('_')[1] || 'right',
            },
          },
        }
      }).filter(item => item)

      const withBiggerVolumeOnTop = withData.sort(({ value: [a, volPr] }, { value: [b, volNx] }) => {
        const prev = parseFloat(volPr.toString())
        const next = parseFloat(volNx.toString())
        if (prev < next) return 1
        if (prev > next) return -1
        return 0
      })

      // move all rows with Cahnge 0% under every Change !== 0%
      const val = withBiggerVolumeOnTop.sort(({ value: [a, b, pr] }, { value: [c, d, nx] }) => {
        const prev = parseFloat((pr as any).value)
        const next = parseFloat((nx as any).value)
        if (prev === 0 && next !== 0) return 1
        if (prev !== 0 && next === 0) return -1
        return 0
      })

      return val
    },
    getMarketCap: ({ marketCap }) => marketCap.reduce((all, curr) => ({
      ...all,
      [curr.name]: curr,
    }), {}),
  },
  actions: {
    getFrontCurrencies (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getFrontCurrencies(),
        mutationName: 'setFrontCurrencies',
      }).bind(this)(...args)
    },
    getMarketCap (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getMarketCap(),
        mutationName: 'setMarketCup',
        cacheTime: 60 * 1000 * 10, // 10 min
      }).bind(this)(...args)
    },
    getBattleNews (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getBattleNews(),
        mutationName: 'setBattleNews',
      }).bind(this)(...args)
    },
    socketPairsStateUpdate: actionWrapper({
      apiRequest: pld => parseResponseFromMarket(pld),
      mutationName: 'updateWelcomeMarket',
      collect: 20,
    }),
  },
}

function initMarkets (marketList: PairName[]): Markets {
  return marketList.reduce((acc, m) => {
    const obj = {
      ...getDefaultPairObject({ pairName: m }),
      // name: m.replace("_", "/"),
      // fetchStatus: "init",
    }
    acc[m] = obj

    return acc
  }, {})
}

export default WelcomeModule
