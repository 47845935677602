export class SessionComponentMapper {
  private static readonly comps = {
    mobile: 'mobile-default',
    iOS: 'mobile-apple',
    mobileAndroid: 'mobile-android',
    tablet: 'tablet-default',
    AndroidOS: 'tablet-android',
    iPadOS: 'tablet-apple',
    'OS X': 'desktop-apple',
    Linux: 'desktop-default',
    Windows: 'desktop-windows',
    default: 'desktop-default',
  }

  static mapPlatformToComponent (platform: SessionPlatform) {
    return SessionComponentMapper.comps[platform] || SessionComponentMapper.comps.default
  }
}
