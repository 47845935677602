// @TODO divide this constants to separate files
export const DEPOSIT = 1
export const WITHDRAW = 2
export const ORDER_SELL = 1
export const ORDER_BUY = 2
export const EDR_TICKER = 'EDR'
export const ADK_TICKER = 'ADK'
export const ACTIVE_PAIR_KEY = 'activePair2'

export const GENERAL_DECIMAL_PLACES = 8
export const DECIMAL_REGEXP = /^\d*(\.|,)?\d*$/m

export const TYPE_STATUS_NEW = 1 // Тип статуса новый
export const TYPE_STATUS_CONFIRM = 2 // Тип статуса в работе
export const TYPE_STATUS_REJECT = 3 // Тип статуса отклонено
export const TYPE_STATUS_PERCENT_PAID = 4 // Тип статуса начисление процентов
export const TYPE_STATUS_PERCENT_RECEIVE = 5 // Тип статуса процент начисления
export const TYPE_STATUS_LOAN_FINISHED = 6 // Тип статуса завершение кредита
export const TYPE_STATUS_LOAN_CLOSED = 7 // Тип статуса закрытие кредита

export const TYPE_STATUS_FINISH = 20 // Тип статуса окончен
export const TYPE_STATUS_CLOSE = 10 // Тип статуса закрыт

export const TYPE_NOTIFICATION_LEND = 1 // тип давать в долг
export const TYPE_NOTIFICATION_BORROW = 2 // тип одолжить
export const TYPE_NOTIFICATION_PAYPERCENT = 3 // тип одолжить

export const TYPE_ACTION_APPROVE = 1 // Заявка на кредит подтверждена
export const TYPE_ACTION_REJECT = 2 // Заявка на кредит откланена

export const RESTRICTED_COUNTRIES_TO_REGISTER = ['US']

// special tickers which is not a market but shows on left side as market
// and we should use it as currency
export const BLOCKED_BUY_SELL_PRICE_TICKERS = ['PLC', 'USDN']

// Withdraw limit for unverified user
export const WITHDRAW_LIMIT = 2

// AUTH TOKENS
export const AUTH_TOKEN_COOKIE_EXPIRE = 60 * 60 * 24 * 30 // 30 days
export const AUTH_TOKEN_COOKIE_KEY = 'auth_tokens'
export const AUTH_TOKEN_COOKIE_OPTIONS = {
  path: '/',
  maxAge: AUTH_TOKEN_COOKIE_EXPIRE,
}
export const AUTH_ACCESS_TOKEN_LIFETIME = 30 * 1000

// SYSTEM OPTIONS
export const BASE_COOKIE_EXPIRE = 60 * 60 * 24 * 30 // 30 days
export const BASE_COOKIE_OPTIONS = {
  path: '/',
  maxAge: BASE_COOKIE_EXPIRE,
}

export default {
  TYPE_STATUS_NEW,
  TYPE_STATUS_CONFIRM,
  TYPE_STATUS_REJECT,
  TYPE_STATUS_PERCENT_PAID,
  TYPE_STATUS_PERCENT_RECEIVE,
  TYPE_STATUS_LOAN_FINISHED,
  TYPE_STATUS_LOAN_CLOSED,
  TYPE_STATUS_FINISH,
  TYPE_STATUS_CLOSE,

  TYPE_NOTIFICATION_LEND,
  TYPE_NOTIFICATION_BORROW,
  TYPE_NOTIFICATION_PAYPERCENT,

  TYPE_ACTION_APPROVE,
  TYPE_ACTION_REJECT,

  RESTRICTED_COUNTRIES_TO_REGISTER,

  DEPOSIT,
  WITHDRAW,
  EDR_TICKER,
  ACTIVE_PAIR_KEY,
  WITHDRAW_LIMIT,
}

export const SHOW_MORE_STEP = 10

export const MOBILE_BREAKPOINT = 768

export const MARKET_LIST_PAIRS_DATA = 'MARKET_LIST_PAIRS_DATA'
export const CURRENT_MARKET_LIST_TAB_KEY = 'CURRENT_MARKET_LIST_TAB_KEY'

export const REFERRAL_CODE = 'referral_code'

export const REMEMBER_ME = 'remember_me'

export const EMAIL = 'EMAIL'

export const AIRDROP_STATUSES = {
  PENDING: 'pending',
  PROGRESS: 'progress',
  FINISH: 'finish',
}

// face detect
export const FACE_DETECT_HYPOTENUSE_MIN_ROTATE = 0.2
export const FACE_DETECT_HYPOTENUSE_MAX_ROTATE = 0.8
export const FACE_DETECT_HYPOTENUSE_MAX_SELFIE = 0.2
export const FACE_DETECT_DISPLACEMENT = 90 / 3
export const FACE_DETECT_DISTANCE = { MIN: 0.3, MAX: 0.6 }
export const FACE_DETECT_SIMILARITY_MIN = 0.6

export const FACE_DETECT_ERRORS = {
  CAMERA_PERMISSION_DENIED: 'CAMERA_PERMISSION_DENIED',
}
