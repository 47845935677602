<template>
  <tdx-input-label-error-wrapper
    :field="field"
    :label="label"
    :info-message="infoMessage"
    :custom-error="customError"
    :optional-errors="optionalErrors"
    :name="name"
    :form-key="formKey"
    input-in-chat
    :class="[
      'tdx-input',
      `tdx-input${isFocused ? '--focused': ''}`,
      `tdx-input${notEmpty || $attrs.placeholder ? '--not-empty': ''}`,
    ]"
  >
    <!-- eslint-disable vue/valid-v-on -->
    <!-- eslint-disable vue/no-parsing-error  -->
    <template #default="slotProps">
      <tdx-base-input
        ref="input"
        :value="value"
        without-border
        v-bind="{ ...$attrs, disabled, ...slotProps }"
        :class="{'mr-8': $slots.bottomRight}"
        class="tdx-input__input"
        v-on="{ ...$listeners, input: val => $emit('input', val) }"
        @focus.capture="focus"
        @blur.capture="blur($event.target.value)"
      />
      <slot name="bottomRight" />
    </template>
  </tdx-input-label-error-wrapper>
</template>
<script>
import TdxInputLabelErrorWrapper from '@/components/common/form/validation/TdxInputLabelErrorWrapper.vue'
import TdxBaseInput from '@/components/common/form/input/TdxBaseInput.vue'

export default {
  name: 'TdxInput',
  components: {
    TdxBaseInput,
    TdxInputLabelErrorWrapper,
  },
  props: {
    value: {
      validator: v => ['string', 'number'].includes(typeof v) || v === null,
      required: true,
    },
    label: { type: String, default: '' },
    field: {
      type: [Object], // vuelidate field
      default: null,
    },
    customError: { type: String, default: '' },
    infoMessage: { type: String, default: '' },
    name: { type: String, default: '' },
    formKey: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    optionalErrors: { type: Object, default: () => ({}) },
    inputInChat: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isFocused: false,
    }
  },

  computed: {
    notEmpty () {
      return this.value
    },
  },

  methods: {
    focus () {
      this.isFocused = true
      this.$refs.input && this.$refs.input.onFocus()
    },
    blur (val) {
      if (!val) {
        this.isFocused = false
      }
      this.$refs.input && this.$refs.input.onBlur()
    },
  },
}
</script>
<style lang="scss" scoped>
.tdx-input {
  position: relative;

  &__input {
    height: toRem(62px);
    box-sizing: border-box;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
    border-radius: toRem(12px);
    color: $c-color-04;
    padding: toRem(30px) toRem(18px) toRem(12px);
  }

  &--focused {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  }
}
</style>
