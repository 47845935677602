import Decimal from "decimal.js-light"


// available: "4444.000000000000000000"
// available_usd: 805213.62182932
// deposit: 0
// deposit_usd: 0
// payout: 0
// payout_usd: 0
// profit: 0
// profit_usd: 0
// total: 4444
// total_usd: 181.19118403

interface UserStatManagementModelInterface {
    available: string
    available_usd: number
    deposit: number
    deposit_usd: number
    payout: number
    payout_usd: number
    profit: number
    profit_usd: number
    total: number
    total_usd: number
}

export class UserStatManagementModel {
    available: number
    availableUsd: number
    deposit: number
    depositUsd: number
    payout: number
    payoutUsd: number
    profit: number
    profitUsd: number
    total: number
    totalUsd: number
    constructor(o: UserStatManagementModelInterface) {
        this.available = parseFloat(new Decimal(o.available).toFixed(4))
        this.availableUsd = parseFloat(new Decimal(o.available_usd).toFixed(2))
        this.deposit = parseFloat(new Decimal(o.deposit).toFixed(4))
        this.depositUsd = parseFloat(new Decimal(o.deposit_usd).toFixed(2))
        this.payout = parseFloat(new Decimal(o.payout).toFixed(4))
        this.payoutUsd = parseFloat(new Decimal(o.payout_usd).toFixed(2))
        this.profit = parseFloat(new Decimal(o.profit).toFixed(4))
        this.profitUsd = parseFloat(new Decimal(o.profit_usd).toFixed(2))
        this.total = parseFloat(new Decimal(o.total).toFixed(4))
        this.totalUsd = parseFloat(new Decimal(o.total_usd).toFixed(2))
    }
}