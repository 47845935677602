import { Module } from 'vuex'
import { actionWrapper } from '@/lib/utils'
import { ListingCardModel } from '@/lib/data-models/listing/ListingCardModel'

export interface ListingCardsState {
  listings: ListingCardsResponse[],
}

const ListingCardsStateModule: Module<ListingCardsState, any> = {
  namespaced: true,
  state: () => ({
    listings: [],
  }),
  actions: {
    getListings (...args) {
      actionWrapper<ListingCardsState>({
        apiRequest: () => this.$api.getListing(),
        mutationName: 'setListingCards',
        cacheTime: 1000 * 60,
      }).bind(this)(...args)
    },
  },
  mutations: {
    setListingCards (state, { response }) {
      state.listings = response
    },
  },
  getters: {
    getLatest: state => state.listings
      .filter(l => l.place === 'LATEST')
      .map(l => new ListingCardModel(l)),

    getComing: state => state.listings
      .filter(l => l.place === 'SOON')
      .map(l => new ListingCardModel(l)),
  },
}

export default ListingCardsStateModule
