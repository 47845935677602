import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
// import { $t } from '@/lib/i18n'
import { RootState } from '../index'
// import {
//     Form,
//     required,
//     email,
//     custom,
//     DefaultValues
// } from '@/store/lib/vuex-form'

const sidesData = {
  buy: {
    word: 'buy',
    type: 'buy', // key in translations
    coefficientSide: 'buy_coeff',
    get youWillOnOtcCard () { return 'otc.text.youWillPay' },
    amountBuySell: 'amount_buy',
    otcCardBuySellMax: 'buy_max',
    otcCardBuySellMin: 'buy_min',
    otcCardAmountValue: 'amount_buy',
    otcCardAmountTicker: 'crypto_ticker',
  },

  sell: {
    word: 'sell',
    type: 'sell', // key in translations
    coefficientSide: 'sell_coeff',
    get youWillOnOtcCard () { return 'otc.text.youWillReceive' },
    amountBuySell: 'amount_sell',
    otcCardBuySellMax: 'sell_max',
    otcCardBuySellMin: 'sell_min',
    otcCardAmountValue: 'amount_sell',
    otcCardAmountTicker: 'fiat_ticker',
  },
}

type OtcBuySellSide = 'buy' | 'sell'

export interface OtcBuySellState {
  buySellSide: OtcBuySellSide[]
}

const state = () => ({
  buySellSide: ['buy', 'sell'],
})

const actions: ActionTree<OtcBuySellState, RootState> = {
  //
}

const mutations: MutationTree<OtcBuySellState> = {
  toggleSide (state) {
    state.buySellSide = state.buySellSide.reverse()
  },
  setSide (state, side) {
    state.buySellSide = side === 'buy' ? ['buy', 'sell'] : ['sell', 'buy']
  },
}

const modules = {}

const getters: GetterTree<OtcBuySellState, RootState> = {
  getSideData (state) {
    return state.buySellSide.map(key => sidesData[key])
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules,
} as Module<OtcBuySellState, RootState>
