var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exc-dot",class:{
    'exc-dot--s': _vm.size === 's',
    'exc-dot--l': _vm.size === 'l',
    'bg--red exc-dot--red': _vm.color === 'red',
    'bg--green exc-dot--green': _vm.color === 'green',
    'bg--main exc-dot--main': _vm.color === 'main',
    'bg--grey exc-dot--grey': _vm.color === 'grey',
    'bor--circle': !_vm.square,
  }})}
var staticRenderFns = []

export { render, staticRenderFns }