import shortNumberModule from 'short-number'

export class MarketListPair implements RawMarketListPair {
  id: string
  minAmountStock: string
  price: string
  pairName: PairName | string
  key: string
  name: string
  rowClass: string
  hot: boolean
  label: RawMarketListPair['label']
  order: number
  // volume: string
  volumeShort: string
  volumeInMoneyShort: string
  // change: string

  change: string
  close: string
  deal: string
  high: string
  last: string
  low: string
  market: string
  open: string
  volume: string
  volumeInMoney: string
  isFavorite: boolean | undefined

  stockTickerImg: string
  stockLabel: string
  stockTicker: string
  moneyTickerImg: string
  moneyLabel: string
  moneyTicker: string

  isDemo: boolean

  constructor (
    p: RawMarketListPair,
    mPair = {} as ISocketPair,
    favIdsForMarket?: string[],
  ) {
    const volume = mPair.volume || p.volume || '0'
    const last = mPair.last || '0'
    // const volumeInMoney = new Decimal(volume).times(last).toPrecision(8)
    const volumeInMoney = (+volume * +last).toFixed(8)

    // stock -> left in pair
    this.stockTickerImg = p.stockTickerImg
    this.stockLabel = p.stockLabel
    this.stockTicker = p.stockTicker
    // money -> right in pair
    this.moneyTickerImg = p.moneyTickerImg
    this.moneyLabel = p.moneyLabel
    this.moneyTicker = p.moneyTicker

    this.id = p.id
    this.minAmountStock = p.minAmountStock || 'minAmountStock'
    this.price = mPair.last || p.price || '0'
    this.pairName = p.key
    this.key = p.key || 'key'
    this.name = p.name
    this.rowClass = '' // p.rowClass
    this.hot = p.hot || false
    this.label = p.label || {}
    this.order = p.order || 0
    // this.volume = p.volume
    this.volumeShort = shortNumberModule(+volume) // shortNumberModule(+parseNumber(volume)) // p.volumeShort
    // this.volumeInMoneyShort = shortNumberModule(parseFloat(volume)) // 'VinM' // p.volumeInMoneyShort
    this.volumeInMoney = volumeInMoney
    this.volumeInMoneyShort = shortNumberModule(parseFloat(volumeInMoney)) // shortNumberModule(+parseNumber(volumeInMoney.toString())) // 'VinM' // p.volumeInMoneyShort
    // this.volumeInMoneyShort = '0.9999' // 'VinM' // p.volumeInMoneyShort
    // this.change = p.change

    this.change = mPair.change || p.change || '0'
    this.close = mPair.close || '0'
    this.deal = mPair.deal || '0'
    this.high = mPair.high || '0'
    this.last = last
    this.low = mPair.low || '0'
    this.market = mPair.market || p.key
    this.open = mPair.open || '0'
    this.volume = volume

    // is Demo
    this.isDemo = p.stockTicker.charAt(0) === 'd' // === p.moneyTicker.charAt(0)

    this.isFavorite = favIdsForMarket
      ? favIdsForMarket.includes(p.id)
      : undefined
    // if (p.key === 'XRP_BTC') {
    //     console.log('volume::XRP_BTC::', mPair.volume, p.volume, this.volume)
    // }
  }
}
