import { Module } from 'vuex'
import { RootState } from '@/store'
import { BLOCKED_BUY_SELL_PRICE_TICKERS } from '@/constants'
import { actionWrapper } from '~/lib/utils'

export interface GlobalsState {
  marketTabs: any // RawMarketListTab[]
  marginList: any
  defaultPair: PairName
  defiList: PairName[]
  appName: string
  marketList: PairName[]
  fiatTickers: Ticker[]
  stableTickers: Ticker[]
  marketListPairHighlight: MarketListRowsPreferences
  tickerList: Ticker[]
  websocketToken: string
  languages: string[]
  links: {
    twitterUrl: string
    facebookUrl: string
    telegramNewsUrl: string
    telegramUrl: string
    telegramRuUrl: string
    coinmarketcapUrl: string
    coingeckoUrl: string
    redditUrl: string
    instagramUrl: string
    linkedInUrl: string
    mediumUrl: string
    youtubeUrl: string
    zendeskUrl: string
    tiktokUrl: string
    discordUrl: string
    apiUrl: string
  }
  echoPort: string
  otcUser: boolean
  dynamicImages: { [key: string]: string }
  isMaintenance: boolean
  currencyCount: number
  paymentsCount: number
  cmcDayVolume: number
  cmcMonthVolume: number
  cmcExchangeRank: number
  cgExchangeRank: number
  yearsOfHeritage: number
  users: number
  monthlyVisits: number
  systemTicker: string
}

const GlobalsStateModule: Module<GlobalsState, RootState> = {
  namespaced: true,
  state: () => ({
    marketTabs: [],
    marginList: [],
    wsUrl: process.env.WS_URL || '',
    websocketToken: process.env.WEBSOCKET_TOKEN || '',
    defaultPair: (process.env.DEFAULT_PAIR || 'BTC_USDT') as PairName,
    defiList: (process.env.DEFI_LIST || []) as PairName[],
    appName: process.env.APP_NAME || '',
    marketList: (process.env.MARKET_LIST || []) as PairName[],
    fiatTickers: (process.env.FIAT_TICKERS || []) as Ticker[],
    stableTickers: (process.env.STABLE_TICKERS || []) as Ticker[],
    marketListPairHighlight: {
      ...((process.env
        .MARKET_LIST_PAIR_HIGHLIGHT as MarketListRowsPreferences) || {}),
      ...((process.env.MARGIN_LIST as any) || []).reduce((acc, pairName) => {
        acc[pairName] = { label: { type: 'label', word: 'x3' }, position: '1' }
        return acc
      }, {} as MarketListRowsPreferences),
    },
    tickerList: (process.env.TICKER_LIST || []) as Ticker[],
    languages: [],
    links: {
      twitterUrl: process.env.TWITTER_URL || '',
      facebookUrl: process.env.FACEBOOK_URL || '',
      telegramNewsUrl: process.env.TELEGRAM_NEWS_URL || '',
      telegramUrl: process.env.TELEGRAM_URL || '',
      telegramRuUrl: process.env.TELEGRAM_RU_URL || '',
      telegramPtUrl: process.env.TELEGRAM_PT_URL || '',
      telegramEsUrl: process.env.TELEGRAM_ES_URL || '',
      coinmarketcapUrl: process.env.COINMARKETCAP_URL || '',
      coingeckoUrl: process.env.COINGECKO_URL || '',
      redditUrl: process.env.REDDIT_URL || '',
      instagramUrl: process.env.INSTAGRAM_URL || '',
      linkedInUrl: process.env.LINKED_IN_URL || '',
      mediumUrl: process.env.MEDIUM_URL || '',
      youtubeUrl: process.env.YOUTUBE_URL || '',
      zendeskUrl: process.env.ZENDESK_URL || '',
      tiktokUrl: process.env.TIKTOK_URL || '',
      discordUrl: process.env.DISCORD_URL || '',
      apiUrl: process.env.API_URL || '',
    },
    appLinks: {
      ios: 'https://apps.apple.com/ua/app/c-patex/id6450421544',
      android: 'https://play.google.com/store/apps/details?id=com.cpatex.exchange.app',
    },
    echoPort: process.env.ECHO_PORT || '',
    otcUser: (process.env.OTC_USER || false) as boolean,
    dynamicImages: (process.env.DYNAMIC_IMAGES || {}) as {
      [key: string]: string
    },
    isMaintenance: false,
    welcomeInfo: {},
    currencyCount: 0,
    paymentsCount: 0,
    cmcDayVolume: 0,
    cmcMonthVolume: 0,
    cmcExchangeRank: 0,
    cgExchangeRank: 0,
    yearsOfHeritage: 9,
    users: 670000000,
    monthlyVisits: 2000000,
    systemTicker: 'PATEX', // TODO: add this token to /systemParams
  }),
  actions: {
    /**
     * @deprecated to remove
     */
    // getMarketList (...args) {
    //   return actionWrapper({
    //     apiRequest: () => this.$api.getMarketList(),
    //     mutationName: '',
    //     successHandler ({ context, response }) {
    //       context.commit(
    //         'setMarketList',
    //         response.response.marketList.result.map(e => e.name),
    //       )
    //     },
    //   }).bind(this)(...args)
    // },
    getSystemParams: actionWrapper({
      async apiRequest () {
        return this.$api.getSystemParams().catch((err) => {
          if (err && err.status >= 500) this.commit('setIsMaintenance', true)
          throw err
        })
      },
      mutationName: 'setSystemParams',
      // cacheTime: 1000 * 10,
      successHandler: ({ context: { commit }, response: { response } }) => {
        commit('pairs/setMarketTabs', response.MARKET_TABS, { root: true })
      },
    }),
    /**
     * @deprecated to remove
     */
    // getTickerList (...args) {
    //   return actionWrapper({
    //     apiRequest: () => this.$api.getSystemParams()
    //       .catch((err) => {
    //         if (err && err.status >= 500) args[0].commit('setIsMaintenance', true)
    //         throw err
    //       }),
    //     mutationName: '',
    //     successHandler ({ context, response }) {
    //       context.commit('setTickerList', response.response.TICKER_LIST)
    //     },
    //   }).bind(this)(...args)
    // },
  },
  mutations: {
    /**
     * @deprecated to remove
     */
    // setTickerList (state, payload) {
    //   state.tickerList = payload
    // },
    // setMarketList (state, payload) {
    //   state.marketList = payload
    // },
    // setStableTickers (state, payload) {
    //   state.stableTickers = payload
    // },
    setIsMaintenance (state, value) {
      state.isMaintenance = value || true
    },
    setSystemParams (state, payload) {
      const _setGlobalValue = (key, valueKey) => {
        const value = (payload?.response || {})[valueKey]
        if (value) state[key] = value
      }
      _setGlobalValue('marketTabs', 'MARKET_TABS')
      _setGlobalValue('marginList', 'MARGIN_LIST')
      _setGlobalValue('wsUrl', 'WS_URL')
      _setGlobalValue('websocketToken', 'WEBSOCKET_TOKEN')
      _setGlobalValue('defaultPair', 'DEFAULT_PAIR')
      _setGlobalValue('defiList', 'DEFI_LIST')
      _setGlobalValue('appName', 'APP_NAME')
      _setGlobalValue('marketList', 'MARKET_LIST')
      _setGlobalValue('fiatTickers', 'FIAT_TICKERS')
      _setGlobalValue('stableTickers', 'STABLE_TICKERS')
      _setGlobalValue('marketListPairHighlight', 'MARKET_LIST_PAIR_HIGHLIGHT')
      _setGlobalValue('tickerList', 'TICKER_LIST')
      _setGlobalValue('languages', 'LANGUAGES')

      _setGlobalValue('currencyCount', 'CURRENCY_COUNT')
      _setGlobalValue('paymentsCount', 'PAYMENTS_COUNT')
      _setGlobalValue('cmcDayVolume', 'CMC_DAY_VOLUME')
      _setGlobalValue('cmcMonthVolume', 'CMC_MONTH_VOLUME')
      _setGlobalValue('cmcExchangeRank', 'CMC_EXCHANGE_RANK')
      _setGlobalValue('cgExchangeRank', 'CG_EXCHANGE_RANK')

      // links
      const _setGlobalLink = (key, valueKey) => {
        const value = (payload?.response || {})[valueKey]
        if (value) state.links[key] = value
      }
      _setGlobalLink('twitterUrl', 'TWITTER_URL')
      _setGlobalLink('facebookUrl', 'FACEBOOK_URL')
      _setGlobalLink('telegramNewsUrl', 'TELEGRAM_NEWS_URL')
      _setGlobalLink('telegramUrl', 'TELEGRAM_URL')
      _setGlobalLink('telegramRuUrl', 'TELEGRAM_RU_URL')
      _setGlobalLink('telegramPtUrl', 'TELEGRAM_PT_URL')
      _setGlobalLink('telegramEsUrl', 'TELEGRAM_ES_URL')
      _setGlobalLink('coinmarketcapUrl', 'COINMARKETCAP_URL')
      _setGlobalLink('coingeckoUrl', 'COINGECKO_URL')
      _setGlobalLink('redditUrl', 'REDDIT_URL')
      _setGlobalLink('instagramUrl', 'INSTAGRAM_URL')
      _setGlobalLink('linkedInUrl', 'LINKED_IN_URL')
      _setGlobalLink('mediumUrl', 'MEDIUM_URL')
      _setGlobalLink('youtubeUrl', 'YOUTUBE_URL')
      _setGlobalLink('zendeskUrl', 'ZENDESK_URL')
      _setGlobalLink('tiktokUrl', 'TIKTOK_URL')
      _setGlobalLink('discordUrl', 'DISCORD_URL')
      _setGlobalLink('apiUrl', 'API_URL')
    },
  },
  getters: {
    stablePairs: (state) => {
      return state.marketList.filter((t) => {
        const [leftTicker, rightTicker] = t.split('_') as [Ticker, Ticker]
        return BLOCKED_BUY_SELL_PRICE_TICKERS.includes(leftTicker)
          ? state.stableTickers.includes(leftTicker)
          : state.stableTickers.includes(rightTicker)
      })
    },
  },
}

export default GlobalsStateModule
