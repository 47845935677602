<template>
  <div class="tdx-navigation-layout">
    <div class="tdx-navigation-layout__content container-new">
      <div
        v-if="title"
        :class="headerClass"
        class="tdx-navigation-layout__head mb-8 mb-28--l"
      >
        <div class="flx">
          <h1
            class="
              tdx-navigation-layout__content-title
              txt--font-heading
              txt--36px
              txt--height-46px
              txt--52px--l
              txt--height-62px--l
              txt--bold
              txt--theme-08
              m-0
            "
          >
            {{ title }}
          </h1>
          <slot name="label" />
        </div>
        <p
          v-if="description"
          class="
            tdx-navigation-layout__content-description
            txt--22px
            txt--height-26px
            txt--theme-06 mt-16 mb-0"
        >
          {{ description }}
        </p>
      </div>
    </div>
    <tdx-scroll-container
      v-bind="$attrs"
      :navigation-links="navigationLinks"
      class="container-new"
    />
  </div>
</template>

<script>
import TdxScrollContainer from '@/components/common/navigation/TdxScrollContainer.vue'

export default {
  name: 'TdxNavigation',
  components: {
    TdxScrollContainer,
  },
  inheritAttrs: false,
  props: {
    navigationLinks: { type: Array, required: true },

    title: { type: String, default: '' },
    description: { type: String, default: '' },
    headerClass: { type: String, default: '' },
  },
}
</script>

<style lang="scss" scoped>
.tdx-navigation-layout {
  overflow: hidden;

  &__content-description {
    max-width: toRem(785px);
  }
}
</style>
