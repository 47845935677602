export class LoansStatModel {
    credit: number
    guarantee: number
    inProgress: number
    profit: number

    constructor(stats: P2PLoansStatResponse) {
        this.credit = stats.credit
        this.guarantee = stats.guarantee
        this.inProgress = stats.in_progress
        this.profit = stats.profit
    }
}