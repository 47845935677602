
<template>
  <div class="tdx-scroll-container flx pt-14 pb-14">
    <tdx-navigation-link
      v-for="(link, index) in navigationLinks"
      :key="index"
      v-bind="$attrs"
      :to="link.to"
      :icon="link.icon"
      :hover-icon="link.hoverIcon"
      :title="link.title"
      :subtitle="link.subtitle"
      class="flx--shrink-0"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import TdxNavigationLink from '@/components/common/navigation/link/TdxNavigationLink.vue'

export default {
  name: 'TdxScrollContainer',
  components: {
    TdxNavigationLink,
  },
  inheritAttrs: false,
  props: {
    navigationLinks: { type: Array, required: true },
  },
}
</script>

<style lang="scss" scoped>
.tdx-scroll-container {
  gap: toRem(8px);
  overflow: auto;

  @include bp(l) {
    gap: toRem(16px);
  }
}
</style>
