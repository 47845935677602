import Decimal from 'decimal.js-light'
import { StakingPoolItemModel } from './StakingPoolItemModel'
// import { getI18n } from '~/plugins/extend-context'

export class StakingPlanDetailsModel {
    investData: StakingPoolItemModel
    stakingHistory: StakingPlanDetailsStakingHistoryModel
    payHistory: StakingPlanDetailsPayHistoryModel
    userData: StakingPlanDetailsUserDataModel

    constructor ({ investdata, stakinghistory, payhistory, userdata }) {
      this.investData = new StakingPoolItemModel(investdata)
      this.stakingHistory = stakinghistory.map(o => new StakingPlanDetailsStakingHistoryModel(o))
      this.payHistory = payhistory.map(o => new StakingPlanDetailsPayHistoryModel(o))
      this.userData = new StakingPlanDetailsUserDataModel(userdata)
    }
}

type Status = 'OPEN' | 'CLOSE'

export class StakingPlanDetailsStakingHistoryModel {
    id: number
    amount: Decimal
    fee: number
    date: string // Date
    status: Status
    type: 'IN' | 'OUT'

    constructor (d: { id:number, amount: string, commision: number, date: string, status: Status, type: 'IN' | 'OUT' }) {
      this.id = d.id
      this.amount = new Decimal(d.amount)
      this.fee = d.commision
      this.date = d.date // new Date(Date.parse(d.date))
      this.status = d.status
      this.type = d.type
    }

    get isTypeIn () {
      return this.type === 'IN'
    }

    get isTypeOut () {
      return this.type === 'OUT'
    }

    get typeName () {
      return `staking.type.${this.type.toLocaleLowerCase()}`
    }
}

export class StakingPlanDetailsPayHistoryModel {
    id: number
    amount: Decimal
    date: Date
    stakedAmount: Decimal
    status: Status
    type: 'PERCENT' | 'NOACTION'
    constructor (d: { id:number, amount: string, date: number, stakedamount: string, status: Status, type: 'PERCENT' | 'NOACTION' }) {
      this.id = d.id
      this.amount = new Decimal(d.amount)
      this.date = new Date(d.date)
      this.stakedAmount = new Decimal(d.stakedamount)
      this.status = d.status
      this.type = d.type
    }

    get isStatusOpen () {
      return this.status === 'OPEN'
    }

    get isStatusClose () {
      return this.status === 'CLOSE'
    }
}

export class StakingPlanDetailsUserDataModel {
    nextPayDate: Date
    nextPayMillisecondsLeft: number
    openOrders: number
    profit: Decimal
    stakedSum: Decimal
    constructor (d: { nextpay: string, openorders: number, profit: number, stakedsum: number }) {
      const left = new Date(d.nextpay).getTime() - Date.now()
      this.nextPayDate = new Date(d.nextpay)
      this.nextPayMillisecondsLeft = left < 0 ? 0 : left
      this.openOrders = d.openorders
      this.profit = new Decimal(d.profit)
      this.stakedSum = new Decimal(d.stakedsum)
    }
}
