import { Context } from '@nuxt/types'
import { EAuthSchemeType, replaceDataKeyWithResponse } from '~/lib/schemes/schemeUtils'

export default function (ctx: Context, inject: Function) {
  const plugin = authPlugin(ctx)
  inject('authentication', plugin)
}

type TBasicPayload = {
  email: string
  password: string
  geetest_challenge: string // captcha
  geetest_seccode: string // captcha
  geetest_validate: string // captcha
  _token: string // captcha
  a_t_access_token?: string // for e2e testing taken from window
}

type TDevicePayload = any // TODO: specify props

type TTwoFaPayload = any // TODO: specify props

type TDeviceAndTwoFaPayload = any // TODO: specify props

function authPlugin (ctx: Context) {
  return {
    loginWithBasic: (pld: TBasicPayload) => ctx.$auth.loginWith(EAuthSchemeType.BASIC, { data: pld }),
    loginWithDevice: (pld: TDevicePayload) => ctx.$auth.loginWith(EAuthSchemeType.DEVICE, { data: pld }),
    loginWithTwoFa: (pld: TTwoFaPayload) => ctx.$auth.loginWith(EAuthSchemeType.TWO_FA, { data: pld }),
    loginWithDeviceAndTwoFa: (pld: TDeviceAndTwoFaPayload) => ctx.$auth.loginWith(EAuthSchemeType.DEVICE_AND_TWO_FA, { data: pld }),
    logout: (...args: unknown[]) => ctx.$auth.logout(...args),
    fetchUser: (...args: unknown[]) => ctx.$auth.fetchUser(...args),
  }
}

export type TAuthPlugin = ReturnType<typeof authPlugin>

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $authentication: TAuthPlugin
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $authentication: TAuthPlugin
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $authentication: TAuthPlugin
  }
}
