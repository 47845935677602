<template>
  <client-only>
    <div class="exchange-footer bg bg--color-01">
      <div class="container-new">
        <div class="exchange-footer__inner">
          <div
            v-if="isMobile"
            class="exchange-footer__column exchange-footer__column--logo"
          >
            <exc-link
              class="exchange-footer__info-link"
              :to="localePath('/')"
              no-decoration
              aria-label="C-Patex"
            >
              <exc-logo
                class="exchange-footer__logo"
                width="125"
                height="28"
                aria-label="C-Patex"
              />
            </exc-link>
            <div
              v-if="isMobile"
              class="exchange-footer__column exchange-footer__column--lang"
            >
              <language-selector />
            </div>
          </div>
          <div class="exchange-footer__column exchange-footer__column--menu">
            <exchange-footer-menu
              v-for="(menu, i) in navLinks"
              :key="`menu-${i}`"
              v-bind="{ ...menu }"
            />
          </div>
          <div class="exchange-footer__column exchange-footer__column--info">
            <div>
              <language-selector v-if="!isMobile" class="mb-16" />
              <exchange-footer-apps />
            </div>
          </div>
        </div>
        <div class="exchange-footer__bottom">
          <div class="exchange-footer__bottom-inner">
            <div
              v-if="!isMobile"
              class="exchange-footer__column exchange-footer__column--logo"
            >
              <exc-link
                class="exchange-footer__info-link"
                :to="localePath('/')"
                no-decoration
                aria-label="C-Patex"
              >
                <exc-logo
                  class="exchange-footer__logo"
                  width="125"
                  height="28"
                  aria-label="C-Patex"
                />
              </exc-link>
            </div>
            <div
              class="exchange-footer__column exchange-footer__column--social"
            >
              <exc-social background rows-in-mobile />
            </div>
          </div>
          <div class="exchange-footer__bottom-copyright">
            <!-- <div class="exchange-footer__add-menu">
              <nuxt-link
                v-for="(link, i) in additionalLinks"
                :key="`add-menu-item-${i}`"
                :to="link.to"
                class="txt txt--14px txt--color-03"
                v-text="link.name"
              />
            </div> -->
            <span class="txt txt--10px txt--14px--l txt--color-03 txt--center">
              {{ $t('footer.copyright', { date: '2022-2023' }) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </client-only>
</template>

<script>
import { ExcLink } from '@/components/common'
import { downloadLocalizedFile } from '@/lib/utils'

export default {
  name: 'ExchangeFooter',
  components: {
    ExcLink,
    LanguageSelector: () =>
      import(
        /* webpackMode: "eager" */ '@/components/footer/parts/LanguageSelector.vue'
      ),
    ExchangeFooterMenu: () =>
      import(
        /* webpackMode: "eager" */ '@/components/footer/parts/ExchangeFooterMenu.vue'
      ),
    ExchangeFooterApps: () =>
      import(
        /* webpackMode: "eager" */ '@/components/footer/parts/ExchangeFooterApps.vue'
      ),
    ExcSocial: () =>
      import(/* webpackMode: "eager" */ '@/components/common/footer/ExcSocial'),
    ExcLogo: () =>
      import(/* webpackMode: "eager" */ '@/assets/img/logo-full.svg?inline'),
  },
  computed: {
    systemTicker () {
      return this.$store.state.globals.systemTicker
    },
    navLinks () {
      // TODO translate
      return [
        {
          title: this.$t('footer.company'),
          list: [
            {
              name: this.$t('footer.aboutUs'),
              to: this.localePath('/about-us'),
            },
            {
              name: this.$t('footer.termsOfService'),
              to: this.localePath('/terms-of-service'),
            },
            {
              name: this.$t('footer.risksDisclosureStatement'),
              to: this.localePath('/risks-disclosure-statement'),
            },
            {
              name: this.$t('footer.privacyNotice'),
              to: this.localePath('/privacy-notice'),
            },
            {
              name: this.$t('footer.legalNotice'),
              to: this.localePath('/legal-notice'),
            },
          ],
        },
        {
          title: this.$t('footer.learn'),
          list: [
            {
              name: 'FAQ',
              to: this.localePath('/faq'),
            },
            {
              name: this.$t('footer.feeSchedule'),
              to: this.localePath('/fees'),
            },
            {
              name: this.$t('footer.marketInfo'),
              to: this.localePath('/market-info'),
            },
            {
              name: this.$t('footer.business'),
              to: this.localePath('/business'),
            },
            // {
            //   name: `${this.systemTicker} Whitepaper`,
            //   action: () => {
            //     this.downloadDocument('wp.pdf')
            //   },
            //   withAction: true,
            // },
            // {
            //   name: `${this.systemTicker} ${this.$t(
            //     'tokenNew.topBlock.tokenomicsBtn',
            //   )}`,
            //   action: () => {
            //     this.downloadDocument('tokenomics.pdf')
            //   },
            //   withAction: true,
            // },
          ],
        },
        {
          title: this.$t('footer.products'),
          list: [
            // {
            //   name: this.$t('header.launchpad.title'),
            //   to: this.localePath('/launchpad'),
            // },
            // {
            //   name: this.$t('header.codes'),
            //   to: this.localePath('/codes'),
            // },
            // {
            //   name: 'OTC',
            //   to: this.localePath('/otc'),
            // },
            // {
            //   name: 'P2P',
            //   to: this.localePath('/p2p-loans'),
            // },
            // {
            //   name: this.$t('header.airdrop'),
            //   to: this.localePath('/airdrop'),
            // },
            {
              name: this.$t('header.quickExchange'),
              to: this.localePath('/quick-exchange'),
            },
            {
              name: this.$t('footer.buySellCrypto'),
              to: this.localePath('/buy-sell-crypto'),
            },
            {
              name: this.$t('header.tradingCompetition.title'),
              to: this.localePath('/trading-competition'),
            },
            {
              name: this.$t('footer.earnSystem'),
              to: this.localePath('/c-freezer'),
            },
            {
              name: this.$t('footer.cryptoCampus'),
              to: this.localePath('/crypto-campus'),
            },
            {
              name: this.$t('footer.token'),
              to: this.localePath('/network'),
            },
            {
              name: this.$t('footer.claimSystem'),
              to: this.localePath('/claim-system'),
            },
            {
              name: this.$t('footer.levels'),
              to: this.localePath('/user/ticket'),
            },
            {
              name: this.$t('footer.cryptoCards'),
              to: this.localePath('/crypto-cards'),
            },
          ],
        },
        {
          title: this.$t('footer.support'),
          list: [
            {
              name: this.$t('footer.supportCenter'),
              to: this.localePath('/support'),
            },
            {
              name: this.$t('footer.contactUs'),
              to: this.localePath('/contact-us'),
            },
          ],
        },
        {
          title: this.$t('footer.resources'),
          list: [
            {
              name: this.$t('header.bugBounty'),
              to: this.localePath('/bug-bounty'),
            },
            {
              name: this.$t('header.listing'),
              to: this.localePath('/listing'),
            },
            {
              name: this.$t('footer.apiDocumentation'),
              href: 'https://github.com/PatexGit/api-docs/blob/main/API.md',
            },
          ],
        },
      ]
    },

    additionalLinks () {
      return [
        {
          name: this.$t('footer.privacyPolicy'),
          to: this.localePath('/privacy-policy'),
        },
        {
          name: this.$t('footer.termsOfUse'),
          to: this.localePath('/terms-of-use'),
        },
      ]
    },

    isMobile () {
      return this.$mq?.resize && this.$mq?.below(991)
    },
  },
  methods: {
    downloadDocument (file) {
      if (process.client) {
        downloadLocalizedFile(file, this.$i18n.locale)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.exchange-footer {
  padding: toRem(48px) 0;

  &__inner {
    display: grid;
    grid-template-columns: 1fr;

    @include bp(m) {
      gap: toRem(20px);
    }

    @include bp(l) {
      margin: 0 0 toRem(28px);
      grid-template-columns: 11fr 1fr;
    }
  }

  &__logo {
    width: 121px;
    height: 40px;
  }

  &__column {
    &--logo {
      @include bp(0, l) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &--lang {
      position: relative;
      z-index: 2;
    }

    &--info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include bp(0, l) {
        width: 100%;
        align-items: flex-start;
        margin: toRem(24px) 0;
      }
    }

    &--menu {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;

      @include bp(0, l) {
        padding: toRem(20px) 0;
        border-bottom: 1px solid color(transp-02);
      }

      @include bp(l) {
        grid-template-columns: repeat(5, 1fr);
      }
    }

    @include bp(0, l) {
      &--social {
        width: 100%;
      }
    }
  }

  &__bottom-inner {
    display: flex;
    justify-content: space-between;
  }

  &__text {
    display: block;
  }

  &__bottom-copyright {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: toRem(24px) 0 0;
    gap: toRem(16px);

    @include bp(0, l) {
      flex-direction: column;
      justify-content: center;
      margin: toRem(24px) 0 0;
      padding: toRem(32px) 0 0;
      border-top: 1px solid color(transp-02);
    }
  }

  &__add-menu {
    display: flex;
    gap: toRem(24px);
  }
}
</style>
