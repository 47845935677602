import { formatHistoryTime } from '@/lib/utils'

export class CodeModel {
  date: string
  code: string
  ticker: Ticker
  amount: string
  status: string

  constructor (rawCode: RawCode) {
    // @TODO fix type error
    // @ts-ignore
    this.date = formatHistoryTime(rawCode.date * 1000)
    this.code = rawCode.code
    this.ticker = rawCode.ticker
    this.amount = rawCode.amount
    this.status = rawCode.status
  }
}
