import { Module } from 'vuex'
// import { Map, Stack } from "immutable"; //

import { DEFAULT_LIMIT } from '@/lib/api'
import { RootState } from '@/store/index'
// import { ProfileModules } from "@/store/profile";
// import {
//     commonProfileGetters,
//     commontSortComparator,
//     // mergeStacks,
//     parseRawTrades,
//     parseRawTradesByAllPairs
// } from "@/store/profile/helpers";
import { actionWrapper } from '@/lib/utils'
import { TradeModel } from '@/lib/data-models/trade/TradeModel'
import { SocketTradeModel } from '@/lib/data-models/trade/SocketTradeModel'

export type TradesMap = { [key in PairName]: TradeEntry[] } | {}
// export type TradesMap = Map<PairName, Stack<TradeEntry>>;

export interface TradesState {
  allEntries: TradeEntry[]
  showLoadMore: boolean
}

const TradesModule: Module<TradesState, RootState> = {
  namespaced: true,
  state: () => ({
    allEntries: [],
    showLoadMore: false,
  }),
  mutations: {
    setShowLoadMore (state, pld: boolean) {
      state.showLoadMore = pld
    },

    setTrades (
      state: TradesState,
      pld: { formatedRecords: TradeEntry[]; nextPage: boolean },
    ) {
      state.allEntries = pld.formatedRecords
      state.showLoadMore = !!pld.nextPage
    },

    addTrades (
      state: TradesState,
      pld: { formatedRecords: TradeEntry[] | TradeEntry; nextPage: boolean },
    ) {
      if (Array.isArray(pld.formatedRecords)) {
        state.allEntries = [...state.allEntries, ...pld.formatedRecords]
      } else {
        state.allEntries.unshift(pld.formatedRecords)
      }
      state.showLoadMore = !!pld.nextPage || state.showLoadMore
    },
  },
  actions: {
    fetchAllTrades (...args) {
      return actionWrapper({
        beforeRequestHandler: ({ payload }) => {
          const offset = (payload.page - 1) * DEFAULT_LIMIT
          payload.offset = offset
        },
        apiRequest: ({ offset }) =>
          this.$api.getTrades({
            offset,
            limit: DEFAULT_LIMIT,
          }),
        mutationName: '',
        successHandler: ({
          context: { commit },
          response: { response },
          payload: { page },
        }) => {
          const { records, nextpage } = response

          const formatedRecords = records.length
            ? records.map(trade => new TradeModel(trade))
            : []

          if (page > 1) {
            commit('addTrades', { formatedRecords, nextPage: nextpage })
          } else {
            commit('setTrades', { formatedRecords, nextPage: nextpage })
          }

          const length = records.length

          if (length === 0 || length < DEFAULT_LIMIT) {
            commit('setShowLoadMore', false)
          } else {
            commit('setShowLoadMore', true)
          }
        },
      }).bind(this)(...args)
    },
    addTradeOrderBySocket ({ commit }, payload) {
      // action for trades update via sockets
      const formatedRecords = new SocketTradeModel(payload[1])

      if (payload[0] === 3) commit('addTrades', { formatedRecords })
    },
    fetchTradesByPair (...args) {
      return actionWrapper({
        beforeRequestHandler: ({ payload }) => {
          const offset = (payload.page - 1) * DEFAULT_LIMIT
          payload.offset = offset
        },
        apiRequest: ({ offset, pair }) =>
          this.$api.getHistoryByPair({
            pair,
            offset,
            limit: DEFAULT_LIMIT,
          }),
        mutationName: '',
        successHandler: ({
          context: { commit },
          response: { response },
          payload: { page },
        }) => {
          const { records, nextpage } = response

          const formatedRecords = records.length
            ? records.map(trade => new TradeModel(trade))
            : []

          if (page > 1) {
            commit('addTrades', { formatedRecords, nextPage: nextpage })
          } else {
            commit('setTrades', { formatedRecords, nextPage: nextpage })
          }

          const length = records.length

          if (length === 0 || length < DEFAULT_LIMIT) {
            commit('setShowLoadMore', false)
          } else {
            commit('setShowLoadMore', true)
          }
        },
      }).bind(this)(...args)
    },
  },
  getters: {
    getAllEntries (state) {
      return state.allEntries
    },
  },
}

export default TradesModule
