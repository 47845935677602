import { Module } from 'vuex'
import { RootState } from '@/store/index'
import { actionWrapper } from '@/lib/utils'

export interface LatamexCurrencyRatePair {
  Buy?: LatamexCurrencyRate;
  Sell?: LatamexCurrencyRate;
}

export interface LatamexExchangeState {
  exhangeData: LatamexDataResponse
  latamexCurrencyRates: { [key in LatamexCurrencyRate['pair']] : LatamexCurrencyRatePair }
  history: LatamexHistoryRow[]
  loadMoreHistory: boolean
}

const LatamexModule: Module<LatamexExchangeState, RootState> = {
  namespaced: true,
  state: () => ({
    exhangeData: {} as LatamexDataResponse,
    latamexCurrencyRates: {},
    history: [],
    loadMoreHistory: false,
  }),
  mutations: {
    resetData (state) {
      state.history = []
      state.loadMoreHistory = false
    },
    setExchangeData (state, data) {
      state.exhangeData = { ...data }
    },
    setCurrencyRates (state, { pair, side, data }) {
      state.latamexCurrencyRates = {
        ...state.latamexCurrencyRates,
        [pair]: {
          ...state.latamexCurrencyRates[pair],
          [side]: {
            ...data
          }
        }
      }
    },
    setHistoryRows (state, { records }) {
      state.history = records
    },
    addHistoryRows (state, { records }) {
      state.history = [...state.history, ...records]
    },
    setLoadMoreHistory (state, value: boolean) {
      state.loadMoreHistory = value
    },
  },
  actions: {
    getLatamexHistory (...args) {
      return actionWrapper({
        apiRequest: ({ page }) => this.$api.getLatamexHistory({ page }),
        mutationName: '',
        successHandler: ({ context: { commit }, response: { response }, payload: { page }}) => {
          const { records, count, limit } = response

          const loadMore = Math.ceil(count / limit) > page
          commit('setLoadMoreHistory', loadMore)

          if (page > 1) {
            commit('addHistoryRows', { records })
          } else {
            commit('setHistoryRows', { records })
          }
        },
      }).bind(this)(...args)
    },
    getLatamexTickers (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getLatamexDetails(),
        mutationName: '',
        successHandler: ({ context: { commit }, response: { response }}) => {
          commit('setExchangeData', response)
        },
      }).bind(this)(...args)
    },
    getLatamexCurrencyRate (...args) {
      return actionWrapper({
        apiRequest: (data) => this.$api.getLatamexCurrencyRate(data),
        mutationName: '',
        successHandler: ({ context: { commit }, response: { response }, payload: { side }}) => {
          commit('setCurrencyRates', {
            side,
            pair: response.pair,
            data: response
          })
        },
      }).bind(this)(...args)
    }
  },
}

export default LatamexModule
