import locale24bebaa3 from '../../locales/languages/_defaultLanguageLoader'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","silentTranslationWarn":true,"silentFallbackWarn":true},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","name":"English","file":"_defaultLanguageLoader"},{"code":"ua","iso":"ua","name":"Українська","file":"_defaultLanguageLoader"},{"code":"ru","iso":"ru-RU","name":"Русский","file":"_defaultLanguageLoader"},{"code":"ko","iso":"ko","name":"한국어","file":"_defaultLanguageLoader"},{"code":"fl","iso":"fl","name":"Filipino","file":"_defaultLanguageLoader"},{"code":"hi","iso":"hi","name":"हिंदी","file":"_defaultLanguageLoader"},{"code":"id","iso":"id","name":"Bahasa Indonesia","file":"_defaultLanguageLoader"},{"code":"vn","iso":"vn","name":"Tiếng việt","file":"_defaultLanguageLoader"},{"code":"zh","iso":"zh-CN","name":"中文","file":"_defaultLanguageLoader"},{"code":"ar","iso":"ar","name":"العربية","file":"_defaultLanguageLoader"},{"code":"de","iso":"de","name":"Deutsch","file":"_defaultLanguageLoader"},{"code":"es","iso":"es","name":"Español","file":"_defaultLanguageLoader"},{"code":"pt","iso":"pt","name":"Português","file":"_defaultLanguageLoader"},{"code":"th","iso":"th","name":"ภาษาไทย","file":"_defaultLanguageLoader"},{"code":"sr","iso":"sr","name":"Srpski","file":"_defaultLanguageLoader"},{"code":"tr","iso":"tr","name":"Türkçe","file":"_defaultLanguageLoader"},{"code":"fr","iso":"fr","name":"Français","file":"_defaultLanguageLoader"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_and_default",
  lazy: true,
  langDir: "/web/locales/languages",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":true,"syncMessages":true,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  skipNuxtState: false,
  manifest: {"lang":"en"},
  normalizedLocales: [{"code":"en","iso":"en-US","name":"English","file":"_defaultLanguageLoader"},{"code":"ua","iso":"ua","name":"Українська","file":"_defaultLanguageLoader"},{"code":"ru","iso":"ru-RU","name":"Русский","file":"_defaultLanguageLoader"},{"code":"ko","iso":"ko","name":"한국어","file":"_defaultLanguageLoader"},{"code":"fl","iso":"fl","name":"Filipino","file":"_defaultLanguageLoader"},{"code":"hi","iso":"hi","name":"हिंदी","file":"_defaultLanguageLoader"},{"code":"id","iso":"id","name":"Bahasa Indonesia","file":"_defaultLanguageLoader"},{"code":"vn","iso":"vn","name":"Tiếng việt","file":"_defaultLanguageLoader"},{"code":"zh","iso":"zh-CN","name":"中文","file":"_defaultLanguageLoader"},{"code":"ar","iso":"ar","name":"العربية","file":"_defaultLanguageLoader"},{"code":"de","iso":"de","name":"Deutsch","file":"_defaultLanguageLoader"},{"code":"es","iso":"es","name":"Español","file":"_defaultLanguageLoader"},{"code":"pt","iso":"pt","name":"Português","file":"_defaultLanguageLoader"},{"code":"th","iso":"th","name":"ภาษาไทย","file":"_defaultLanguageLoader"},{"code":"sr","iso":"sr","name":"Srpski","file":"_defaultLanguageLoader"},{"code":"tr","iso":"tr","name":"Türkçe","file":"_defaultLanguageLoader"},{"code":"fr","iso":"fr","name":"Français","file":"_defaultLanguageLoader"}],
  localeCodes: ["en","ua","ru","ko","fl","hi","id","vn","zh","ar","de","es","pt","th","sr","tr","fr"],
}

export const localeMessages = {
  '_defaultLanguageLoader': () => Promise.resolve(locale24bebaa3),
}
