<template>
  <div class="exc-social" :class="classes">
    <!--    <slot />-->
    <a
      v-for="item in items"
      :key="`social-${item.id}`"
      :href="item.link"
      target="_blank"
      class="exc-social__item"
      :class="{
        'exc-social__item--selected-icon': selectedIcons.includes(item.id),
      }"
    >
      <img
        v-if="item.url"
        :src="item.url"
        width="24px"
        height="24px"
        loading="lazy"
        alt="social icon"
      >
      <component
        :is="item.icon"
        v-else
        class="exc-social__icon"
        :aria-label="'social-' + item.id"
      />
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ExcSocial',

  components: {
    iconInstagram: () => import('@/assets/icons/social/mono/icon-instagram.svg?inline'),
    IconCoingecko: () => import('@/assets/icons/social/mono/icon-coingecko.svg?inline'),
    IconCoinmarket: () => import('@/assets/icons/social/mono/icon-coinmarket.svg?inline'),
    IconFacebook: () => import('@/assets/icons/social/mono/icon-facebook.svg?inline'),
    IconWeibo: () => import('@/assets/icons/social/mono/icon-weibo.svg?inline'),
    IconTelegramPortugal: () => import('@/assets/icons/social/mono/pt.svg?inline'),
    IconTelegramSpanish: () => import('@/assets/icons/social/mono/es.svg?inline'),
    IconTelegram: () => import('@/assets/icons/social/mono/icon-telegram.svg?inline'),
    IconTiktok: () => import('@/assets/icons/social/mono/icon-tiktok.svg?inline'),
    IconX: () => import('@/assets/icons/social/mono/icon-x.svg?inline'),
    IconYoutube: () => import('@/assets/icons/social/mono/icon-youtube.svg?inline'),
    IconLinkedin: () => import('@/assets/icons/social/mono/icon-linkedin.svg?inline'),
    IconDiscord: () => import('@/assets/icons/social/mono/icon-discord.svg?inline'),
  },

  props: {
    background: {
      type: Boolean,
      default: false,
    },

    border: {
      type: Boolean,
      default: false,
    },

    rowsInMobile: {
      type: Boolean,
      default: false,
    },

    selectedIcons: {
      type: Array,
      default: () => [],
    },

    disableIds: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState({
      links: state => state.globals.links,
    }),
    items () {
      return [
        {
          id: 'telegram',
          icon: 'icon-telegram',
          link: this.links.telegramUrl,
        },
        {
          id: 'telegram-pt',
          icon: 'icon-telegram-portugal',
          link: this.links.telegramPtUrl,
        },
        {
          id: 'telegram-es',
          icon: 'icon-telegram-spanish',
          link: this.links.telegramEsUrl,
        },
        {
          id: 'x',
          icon: 'icon-x',
          link: this.links.twitterUrl,
        },
        {
          id: 'youtube',
          icon: 'icon-youtube',
          link: this.links.youtubeUrl,
        },
        {
          id: 'discord',
          icon: 'icon-discord',
          link: this.links.discordUrl,
        },
        {
          id: 'facebook',
          icon: 'icon-facebook',
          link: this.links.facebookUrl,
        },
        {
          id: 'instagram',
          icon: 'icon-instagram',
          link: this.links.instagramUrl,
        },
        {
          id: 'linkedin',
          icon: 'icon-linkedin',
          link: this.links.linkedInUrl,
        },
        {
          id: 'coingecko',
          icon: 'icon-coingecko',
          link: this.links.coingeckoUrl,
        },
        {
          id: 'coinmarket',
          icon: 'icon-coinmarket',
          link: this.links.coinmarketcapUrl,
        },
      ].filter(item => !!item.link && !this.disableIds.includes(item.id))
    },
    classes () {
      return {
        'exc-social--border': this.border,
        'exc-social--background': this.background,
        'exc-social--row': this.rowsInMobile,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-social {
  $this: &;
  display: flex;
  flex-wrap: wrap;
  gap: toRem(18px);

  &--row {
    @include bp(0, l) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }
  }

  @include bp(l) {
    gap: toRem(24px);
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: toRem(40px);
    height: toRem(40px);
    padding: toRem(8px);
    background: color(color-01);
    border-radius: 50%;
    color: color(color-04);
    transition: color 0.3s ease, background 0.3s ease;

    #{$this}--border & {
      border: toRem(1px) solid color(main);
    }

    #{$this}--background & {
      background: color(color-02);
    }

    &--selected-icon {
      #{$this}--background &,
      & {
        background: color(color-10);
        color: color(color-05);

        &:hover {
          background: color(color-02) !important;
        }
      }
    }

    // &:hover {
    //   color: color(main);
    //   background: color(color-10);
    // }
  }

  &__icon {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
