import { Module } from 'vuex'
import { RootState } from '@/store/index'
// import Echo from 'laravel-echo'
import { formatChatTime } from '@/lib/utils'

let echo

// TODO: fix sockets

// init echo only after user goes to page with chat
// function initEcho ({ port }) {
//   echo = new Echo({
//     broadcaster: 'socket.io',
//     host: process.client ? window.location.hostname + `:${port}` : '',
//   })
// }

export const CHAT_COMPONENTS_KEY = 'chatList'

type Message = {
  username: string;
  text: string;
  time: string;

  // front
  rejected?: boolean;
}

export interface ChatState {
  fetchStatus: FetchStatus
  messages: Message[]
}

const ChatModule: Module<ChatState, RootState> = {
  namespaced: true,
  state: () => ({
    fetchStatus: 'init',
    messages: [],
  }),
  mutations: {
    setFetchStatus (state, status: FetchStatus) {
      state.fetchStatus = status
    },
    setMessages (state, messages) {
      state.messages = messages
    },
    setMessage (state, message) {
      state.messages.push(message)
    },
    leaveLastChannel (state) {
      echo && echo.leave('channel.chat.en')
      // echo.leave(`channel.chat.${state.previousTabLang}`)
    },
  },
  actions: {
    async initChat ({ state, commit, getters, rootState }) {
      commit('setFetchStatus', 'loading')

      commit('leaveLastChannel')

      // !echo && initEcho({ port: rootState.globals.echoPort })
      // echo.leave(`channel.chat.${state.previousTabLang}`)
      const { response, errors } = await this.$api.getMessages({ languageCode: 'en' })

      if (errors) {
        console.error(errors)
        return
      }
      const messages = response!.messages.map(msg => parseChatMessage(msg))

      commit('setMessages', messages)

      // echo
      //   // .private(`channel.chat.${languageCode}`)
      //   .private('channel.chat.en')
      //   .listen('.chat.add_message', (msg) => {
      //     commit('setMessage', parseChatMessage(msg))
      //   })

      // commit('setPreviousLang', languageCode)
      commit('setFetchStatus', 'ok')
    },
  },
}

export default ChatModule

function parseChatMessage (message) {
  return {
    username: message.userName,
    text: message.message,
    time: formatChatTime(message.dateAt),
  }
}
