<template>
  <div
    :class="{
      'exc-header__is-trade': isTrade,
    }"
    class="exc-header"
  >
    <div
      :class="{
        // 'bg--color-01': onlyTheme === 'dark',
        // 'bg--color-08': onlyTheme === 'light',
        // 'bg--color-01': !onlyTheme,
        'bg--color-01': isTrade,
        'bg--color-08': !isTrade,
        'sha--4': !isTrade,
        'hidden-header-on-mobile': isSideBarOpen
      }"
      class="exc-header__container"
    >
      <slot name="logo">
        <exc-header-nav-item :to="'/'">
          <template #name>
            <div class="exc-header__logo pl-32 pr-16">
              <exc-full-logo />
            </div>
          </template>
        </exc-header-nav-item>
      </slot>

      <div class="exc-header__desk-left">
        <div class="exc-header__desk-left-container">
          <div class="exc-header__desk-left-nav">
            <exc-header-desk-nav-creator
              v-for="link in navLinksFiltered"
              :key="link.name"
              :link="link"
              :only-theme="onlyTheme"
            />
          </div>

          <slot name="desktop-left" />
        </div>
      </div>

      <div class="exc-header__desk-right ml-auto">
        <div class="exc-header__desk-right-container">
          <slot name="desktop" />
        </div>
      </div>

      <exc-header-mob
        :nav-links="navLinksFiltered"
        :only-theme="onlyTheme"
        class="exc-header__mobile ml-auto"
        @toggle="toggleSidebar"
      >
        <template #header-mobile-right-part>
          <slot
            name="header-mobile-right-part"
          />
        </template>
        <template #close-icon="{toggleSidebar}">
          <slot
            name="close-icon"
            v-bind="{toggleSidebar}"
          />
        </template>
        <template #mobile-auth="{toggleSidebar}">
          <slot
            name="mobile-auth"
            v-bind="{toggleSidebar}"
          />
        </template>
        <template #mobile-custom="{toggleSidebar}">
          <slot
            name="mobile-custom"
            v-bind="{toggleSidebar}"
          />
        </template>
        <template #mobile-menu-icon>
          <slot
            name="mobile-menu-icon"
          />
        </template>
      </exc-header-mob>
    </div>
  </div>
</template>

<script>
import ExcFullLogo from '@/components/common/svg/ExcFullLogo.vue'
import { onlyThemePropValidator } from '@/lib/utils'
import ExcHeaderDeskNavCreator from './parts/ExcHeaderDeskNavCreator.vue'
import ExcHeaderMob from './parts/ExcHeaderMob.vue'
import ExcHeaderNavItem from './parts/ExcHeaderNavItem.vue'

export default {
  name: 'ExchangeHeader',
  components: {
    ExcHeaderDeskNavCreator,
    ExcHeaderMob,
    ExcHeaderNavItem,
    ExcFullLogo,
  },
  props: {
    onlyTheme: onlyThemePropValidator(),
    navLinks: { type: Array, required: true },
    isTrade: { type: Boolean, default: false },
  },
  data () {
    return {
      isSideBarOpen: false,
    }
  },
  computed: {
    navLinksFiltered () {
      // if condition truthy or not specified
      return this.navLinks.filter(l => l.condition === undefined || l.condition)
    },
  },

  methods: {
    toggleSidebar (value) {
      this.isSideBarOpen = value
      this.$emit('toggle', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-header {
  height: $h-Header;

  // & * {
  //     outline: 1px solid red;
  // }

  &__container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__logo {
    height: 14px;
    display: flex;

    svg, img { height: 100% }
  }

  &__desk-left,
  &__desk-right {
    height: 100%;
    align-items: center;
    display: none;

    @include bp($headerBreakpoint) {
      display: block;
    }
  }

  // &__desk-left {}

  &__desk-left-container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__desk-left-nav {
    display: flex;
    height: 100%;
    gap: toRem(16px);

    &::v-deep {
      .exc-header-nav-item__name {
        padding: 0 !important;
      }
    }
  }

  &__desk-right-container {
    display: flex;
    height: 100%;
  }

  &__mobile {
    display: block;
    @include bp($headerBreakpoint) {
      display: none;
    }
  }

}
</style>
