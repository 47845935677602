import Vue from 'vue'
import { Module } from 'vuex'
import { actionWrapper } from '@/lib/utils'

export interface VestingModuleState {
  fundsByTicker: {},
}

const VestingModule: Module<VestingModuleState, any> = {
  namespaced: true,
  state: () => ({
    fundsByTicker: {},
  }),
  actions: {
    fetchFundsByTicker (...args) {
      actionWrapper({
        apiRequest: ticker => this.$api.getVestingFundsByTicker(ticker),
        mutationName: 'setListingCards',
        cacheTime: 1000 * 60,
      }).bind(this)(...args)
    },
  },
  mutations: {
    setListingCards (state, { response, payload: ticker }) {
      if (!ticker) return
      Vue.set(state.fundsByTicker, ticker, response)
    },
  },
}

export default VestingModule
