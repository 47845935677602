<template>
  <div class="exc-footer-nav">
    <h4
      v-if="title"
      :class="{
        'txt--color-08': onlyTheme === 'dark',
        'txt--color-04': onlyTheme === 'light',
        'txt--color-04': !onlyTheme,
      }"
      class="txt--bold txt--16px txt--height-20px mb-8"
    >
      {{ title }}
    </h4>
    <div
      v-if="list.length"
      class="exc-footer-nav__list"
    >
      <div
        v-for="(footerItem, ind) in list"
        :key="ind"
        :class="{ 'mt-10': ind !== 0 }"
      >
        <slot v-bind="{ footerItem }" />
      </div>
    </div>

    <slot v-if="!list.length" />
  </div>
</template>

<script>
import { onlyThemePropValidator } from '@/lib/utils'

export default {
  name: 'ExcFooterNav',
  props: {
    onlyTheme: onlyThemePropValidator(),
    title: { type: String, default: '' },
    list: { type: Array, default: () => ([]) },
  },
}
</script>

<style lang="scss" scoped>
.exc-footer-nav {

}
</style>
