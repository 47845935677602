<template>
  <div
    class="exc-tabs-buttons flx"
    :class="{ 'exc-tabs-buttons--no-border': noBorder }"
  >
    <button
      v-for="button in buttons"
      :key="button.value"
      v-bind="$attrs"
      class="exc-tabs-buttons__button txt--16px txt--height-20px txt--bold"
      :class="{
        'exc-tabs-buttons__button--active': currentButton === button.value,
      }"
      @click="$emit('click', button)"
      v-text="button.label"
    />
  </div>
</template>

<script>
export default {
  name: 'ExcTabsButtons',

  props: {
    buttons: {
      type: Array,
      default: () => [],
    },

    currentButton: {
      type: [String, Number],
      default: '',
    },

    noBorder: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.exc-tabs-buttons {
  gap: toRem(6px);

  &--no-border {
    border: none;
  }

  &__button {
    padding: toRem(10px) toRem(20px);
    border: 1px solid color('transp-02');
    border-radius: toRem(30px);
    color: color('color-03');
    background: unset;

    &--active {
      color: color('color-01');
      background: color('main');
      border: color('main');
    }
  }
}
</style>
