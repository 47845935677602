<template>
  <div
    class="notification-general sha--0116 bor--radius-12 ml-4"
    :class="bgColor"
    v-on="$listeners"
  >
    <div
      class="notification-general__container flx bg--color-01 bor--radius-12 txt--pointer"
      @click="$emit('close')"
    >
      <div class="notification-general__colored-sign flx flx--items-center">
        <div class="notification-general__colored-sign-icon">
          <component
            :is="{
              'success': 'status-icon-success',
              'error': 'status-icon-error',
              'warn': 'status-icon-warning',
              'info': 'status-icon-info',
            }[item.type || 'success']"
          />
        </div>
      </div>

      <div class="notification-general__content flx flx--flex-1 flx--col flx--content-center">
        <div
          v-if="item.title"
          :class="[titleColor]"
          class="notification-general__title txt txt--weight-500 txt--14px txt--height-18px txt--bold"
          v-html="item.title"
        />
        <div
          v-if="item.text"
          class="notification-general__text txt txt--color-04 txt--weight-400 txt--12px txt--height-14px"
          v-html="item.text"
        />
      </div>

      <div class="notification-general__close">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.6436 9.9802C12.1188 10.4554 12.1188 11.1683 11.6436 11.6436C11.4059 11.8812 11.1089 12 10.8119 12C10.5149 12 10.2178 11.8812 9.9802 11.6436L6 7.66337L2.0198 11.6436C1.78218 11.8812 1.48515 12 1.18812 12C0.891089 12 0.594059 11.8812 0.356436 11.6436C-0.118812 11.1683 -0.118812 10.4554 0.356436 9.9802L4.33663 6L0.356436 2.0198C-0.118812 1.54455 -0.118812 0.831683 0.356436 0.356436C0.831683 -0.118812 1.54455 -0.118812 2.0198 0.356436L6 4.33663L9.9802 0.356436C10.4554 -0.118812 11.1683 -0.118812 11.6436 0.356436C12.1188 0.831683 12.1188 1.54455 11.6436 2.0198L7.66337 6L11.6436 9.9802Z" fill="currentColor" fill-opacity="0.4" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { ExcTickerLogosBlock } from '@/components/common'

export default {
  name: 'NotificationGeneral',
  components: {
    ExcTickerLogosBlock,
    StatusIconSuccess: () => import(/* webpackMode: "eager" */ '@/assets/icons/statuses/StatusIconSuccess.svg?inline'),
    StatusIconError: () => import(/* webpackMode: "eager" */ '@/assets/icons/statuses/StatusIconError.svg?inline'),
    StatusIconWarning: () => import(/* webpackMode: "eager" */ '@/assets/icons/statuses/StatusIconWarning.svg?inline'),
    StatusIconInfo: () => import(/* webpackMode: "eager" */ '@/assets/icons/statuses/StatusIconInfo.svg?inline'),
  },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    bgColor () {
      return {
        success: 'bg--color-06',
        error: 'bg--color-07',
        warn: 'bg--color-09',
        info: 'bg--color-05',
      }[this.item.type || 'success']
    },

    titleColor () {
      return {
        success: 'txt--green',
        error: 'txt--red',
        warn: 'txt--color-10',
        info: 'txt--color-10',
      }[this.item.type || 'success']
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-general {
  @include bp(m) {
    margin-left: toRem(30px);
  }

  &__container {
    padding: toRem(16px);
    position: relative;
    display: grid;
    grid-template-columns: toRem(22px) 1fr toRem(12px);
    gap: toRem(10px);
  }

  &__content {
    gap: toRem(2px);
  }

  &__colored-sign {
    max-width: toRem(22px);
    max-height: toRem(22px);
    width: 100%;
    height: 100%;
    padding-top: toRem(11px);
  }

  &__close {
    transition-duration: .25s;
    color: color('color-03');
    margin-top: toRem(3px);
    height: fit-content;

    &:hover {
      color: color('color-04');
    }
  }

  // &__close {
  //   position: absolute;
  //   top: toRem(14px);
  //   right: toRem(10px);

  //   &-icon {
  //     width: toRem(14px);
  //     height: toRem(14px);
  //   }
  // }
}
</style>
