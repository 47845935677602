import { Module, MutationTree, ActionTree } from 'vuex'
import { minValue, maxValue, required } from 'vuelidate/lib/validators'
// import Vue from 'vue'
// import api from '@/lib/api'
import { LabeledValue } from '@/data-objects/props/LabeledValue'
import { InputWithSelect } from '@/data-objects/props/InputWithSelect'
// import RadioInputItemModel from '@/lib/data-models/inputs/RadioInputItemModel'
import {
  notifySuccess,
  notifyInfo,
  showP2pError,
} from '@/lib/bus'
// import { i18n } from '@/lib/bootstrap'
// import { $t } from '@/lib/i18n'
import { CurrencyBalanceModel } from '@/lib/data-models/p2p/CurrencyBalanceModel'
import {
  destructAndMapFields,
  destructAndMapFieldsDefaults,
  destructAndMapFieldsEndValues,
  FieldsSetupStructureInterface,
  fromToValidator,
  clearPropsFromEmptyFields,
} from '../lib/helpers'
import { Form, custom } from '../lib/vuex-form'
// import { commonProfileGetters } from '../profile/helpers'
// import { BorrowingApplicationItem } from '@/data-objects/p2p/BorrowingApplicationItem'
import { RootState } from '..'
// import { getI18n } from '~/plugins/extend-context'

// const i18n = getI18n()

export const tabs = [
  new LabeledValue('p2p.button.lend', 1), // LEND SHOULD HAVE ID 1
  new LabeledValue('p2p.button.borrow', 2),

  // new LabeledValue('p2p.button.lend', 1), // LEND SHOULD HAVE ID 1
  // new LabeledValue('p2p.button.borrow', 2),
]

export const formFieldsProps: FieldsSetupStructureInterface = {
  amount_from: {
    default: new InputWithSelect(
      '',
      new LabeledValue(
        (process.client && window.P2P_FIAT && window.P2P_FIAT[0] && window.P2P_FIAT[0].tiker) || 'BTC',
        (process.client && window.P2P_FIAT && window.P2P_FIAT[0] && window.P2P_FIAT[0].tiker) || 'BTC',
      ),
    ),
    // default: new InputWithSelect('', new LabeledValue(firstFiatTicker, firstFiatTicker)),
    type: String,
    assignValueFn: val => val.input,
    validators: lendOrBorrowValidators(
      [
        // minValue(50), TODO: min value is 50
        custom((value, fields, rootState, rootGetters) => {
          const requiredMess = required()(
            value.input,
            fields,
            rootState,
            rootGetters,
          )
          if (requiredMess) return requiredMess
          const min = minValue(50)(value.input, fields, rootState, rootGetters)
          if (min) return min
          return fromToValidator(
            value.input,
            fields.amount_to.input,
            'p2p.field.amountTo',
            // $t('p2p.field.amountTo'),
          )
        }),
      ],
      [
        // minValue(50), TODO: min value is 50
        custom((value, fields, rootState, rootGetters) => {
          const min = minValue(50)(value.input, fields, rootState, rootGetters)
          if (min) return min
          return fromToValidator(
            value.input,
            fields.amount_to.input,
            'p2p.field.amountTo',
            // $t('p2p.field.amountTo'),
          )
        }),
      ],
    ),
  },
  amount_to: {
    default: new InputWithSelect(
      '',
      new LabeledValue(
        (process.client && window.P2P_FIAT && window.P2P_FIAT[0] && window.P2P_FIAT[0].tiker) || 'BTC',
        (process.client && window.P2P_FIAT && window.P2P_FIAT[0] && window.P2P_FIAT[0].tiker) || 'BTC',
      ),
    ),
    type: String,
    assignValueFn: val => val.input,
    validators: lendOrBorrowValidators(
      [
        // minValue(0), TODO: min value is 50
        custom((value, fields, rootState, rootGetters) => {
          const requiredMess = required()(
            value.input,
            fields,
            rootState,
            rootGetters,
          )
          if (requiredMess) return requiredMess
          const min = minValue(50)(value.input, fields, rootState, rootGetters)
          if (min) return min
          return fromToValidator(
            fields.amount_from.input,
            value.input,
            'p2p.field.amountFrom',
            // $t('p2p.field.amountFrom'),
          )
        }),
      ],
      [
        // minValue(0), TODO: min value is 50
        custom((value, fields, rootState, rootGetters) => {
          const min = minValue(50)(value.input, fields, rootState, rootGetters)
          if (min) return min
          return fromToValidator(
            fields.amount_from.input,
            value.input,
            'p2p.field.amountFrom',
            // $t('p2p.field.amountFrom'),
          )
        }),
      ],
    ),
  },
  days_from: {
    default: '',
    type: String,
    assignValueFn: val => val,
    validators: lendOrBorrowValidators(
      [
        required(),
        minValue(3),
        maxValue(90),
        custom((value, fields) => {
          return fromToValidator(
            value,
            fields.days_to,
            'p2p.field.daysTo',
            // $t('p2p.field.daysTo'),
          )
        }),
      ],
      [
        minValue(3),
        maxValue(90),
        custom((value, fields) => {
          return fromToValidator(
            value,
            fields.days_to,
            'p2p.field.daysTo',
            // $t('p2p.field.daysTo'),
          )
        }),
      ],
    ),
  },
  days_to: {
    default: '',
    type: String,
    assignValueFn: val => val,
    validators: lendOrBorrowValidators(
      [
        required(),
        minValue(3),
        maxValue(90),
        custom((value, fields) => {
          return fromToValidator(
            fields.days_from,
            value,
            'p2p.field.daysFrom',
            // $t('p2p.field.daysFrom'),
          )
        }),
      ],
      [
        minValue(3),
        maxValue(90),
        custom((value, fields) => {
          return fromToValidator(
            fields.days_from,
            value,
            'p2p.field.daysFrom',
            // $t('p2p.field.daysFrom'),
          )
        }),
      ],
    ),
  },
  percent_from: {
    default: '',
    type: String,
    assignValueFn: val => val,
    validators: lendOrBorrowValidators(
      [
        required(),
        minValue(0.01),
        maxValue(5),
        custom((value, fields) => {
          // const isLess = percentMinFrom(value, 0.01)
          // if (isLess) return isLess
          return fromToValidator(
            value,
            fields.percent_to,
            'p2p.field.pledgeRateTo',
            // $t('p2p.field.pledgeRateTo'),
          )
        }),
      ],
      [
        minValue(0.01),
        maxValue(5),
        custom((value, fields) => {
          // const isLess = percentMinFrom(value, 0.01)
          // if (isLess) return isLess
          return fromToValidator(
            value,
            fields.percent_to,
            'p2p.field.pledgeRateTo',
            // $t('p2p.field.pledgeRateTo'),
          )
        }),
      ],
    ),
  },
  percent_to: {
    default: '',
    type: String,
    assignValueFn: val => val,
    validators: lendOrBorrowValidators(
      [
        required(),
        minValue(0.01),
        maxValue(5),
        custom((value, fields) => {
          // const isHigher = percentMaxTo(value, 2)
          // if (isHigher) return isHigher
          return fromToValidator(
            fields.percent_from,
            value,
            'p2p.field.pledgeRateFrom',
            // $t('p2p.field.pledgeRateFrom'),
          )
        }),
      ],
      [
        minValue(0.01),
        maxValue(5),
        custom((value, fields) => {
          // const isHigher = percentMaxTo(value, 2)
          // if (isHigher) return isHigher
          return fromToValidator(
            fields.percent_from,
            value,
            'p2p.field.pledgeRateFrom',
            // $t('p2p.field.pledgeRateFrom'),
          )
        }),
      ],
    ),
  },
  fiat_currency: {
    default: '',
    type: String,
    assignValueFn: val => val,
  },
  currency: {
    default: [],
    type: String,
    assignValueFn: arr =>
      arr
        .reduce((acc, val) => {
          acc.push(val.value)
          return acc
        }, [])
        .join(','),
    validators: lendOrBorrowValidators(
      [
        custom((value, fields, rootState, rootGetters) => {
          // if array of selected currencies is empty, return error message
          return required()(!!value.length, fields, rootState, rootGetters)
        }),
      ],
      [],
    ),
  },
}

export interface LendBorrowOrderInterface {
  currencyBalances: any
  tabs: LabeledValue[]
  currentTab: LabeledValue
}

const state: LendBorrowOrderInterface = {
  currencyBalances: null,
  tabs,
  currentTab: tabs[0],
}

const actions: ActionTree<LendBorrowOrderInterface, RootState> = {
  async getActualCurrencyBalance ({ commit, state }) {
    const { response, errors } = await this.$api.p2pGetActualCurrencyBalance()
    if (errors) {
      console.error('api.p2pGetActualCurrencyBalance()::', errors)
      // notifyError({ text: errors[0], title: 'api.p2pGetActualCurrencyBalance()' })
      showP2pError(errors)
      return
    }

    commit('setActualCurrencyBalance', response)
    console.log('state.actualCurrencyBalances::', state.currencyBalances)
    return state
  },
}

const mutations: MutationTree<LendBorrowOrderInterface> = {
  setTab (state, tab: LabeledValue) {
    state.currentTab = tab
  },
  setActualCurrencyBalance (state, pld) {
    if (!pld.FIAT || !pld.CRYPTO) {
      console.error('!pld.FIAT || !pld.CRYPTO')
      return
    }
    state.currencyBalances = {
      crypto: pld.CRYPTO.map(o => new CurrencyBalanceModel(o)),
      fiat: pld.FIAT.map(o => new CurrencyBalanceModel(o)),
    }
  },
}

const getters = {
  isLendingTab: state => state.currentTab.value === 1,
  balancesForTab: (state, getters) => {
    if (!state.currencyBalances) return null
    if (getters.isLendingTab) return state.currencyBalances.fiat
    return state.currencyBalances.crypto
  },
}

const lendBorrowForm = new Form({
  throttle: 300,
  async onSubmit ({ commit, dispatch, getters, rootGetters }) {
    const fields = getters.allFields

    const formObj = destructAndMapFieldsEndValues(fields, formFieldsProps)
    formObj.fiat_currency = fields.amount_from.select.value
    const params = clearPropsFromEmptyFields(formObj)
    // const

    commit('setFetchStatus', 'loading')

    const isLendingTab = JSON.parse(
      JSON.stringify(rootGetters['p2p/lendBorrowOrder/isLendingTab']),
    )

    // if Lending tab is active
    if (isLendingTab) {
      await placingOrderProcess({ dispatch, commit, rootGetters }, params)
    } else {
      // if Borrowing tab is active
      params.offset = 0
      await dispatch('p2p/appLend/getLendingAllApps', params, { root: true })
    }

    commit('setFetchStatus', 'ok')
  },
  defaultValues: destructAndMapFieldsDefaults(formFieldsProps),
  fields: destructAndMapFields(formFieldsProps),
})

export const lendBorrowOrder: Module<LendBorrowOrderInterface, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    lendBorrowForm,
  },
}

function lendOrBorrowValidators (validatorsForLend, validatorsForBorrow) {
  return function (_, __, ___, rootGetters) {
    const isLendingTab = rootGetters['p2p/lendBorrowOrder/isLendingTab']
    if (isLendingTab) return validatorsForLend
    return validatorsForBorrow
  }
}

async function placingOrderProcess ({ dispatch, commit, rootGetters }, params) {
  // check if kyk verified
  if (!rootGetters.kycIsVerified) {
    notifyInfo({
      text: 'p2p.notifications.passVerification',
      // text: $t('p2p.notifications.passVerification'),
    })
    return
  }

  const { response, errors } = await this.$api.p2pCreateOrder(params)

  if (errors) {
    console.error('api.p2pCreateOrder or api.:::', errors)
    commit('setErrors', { errors })
    commit('setFetchStatus', 'error')
    showP2pError(errors)
    return { errors }
  }

  await Promise.all([
    // update lending table (it is with your new order)
    dispatch('p2p/appLend/getLendingAllApps', null, { root: true }),
    // update users balances
    dispatch('p2p/lendBorrowOrder/getActualCurrencyBalance', null, {
      root: true,
    }),
  ])

  notifySuccess({
    text: 'p2p.notifications.createdLending', // i18n.$t('p2p.notifications.createdLending')
  })

  const { fiat } = response
  if (fiat) {
    notifyInfo({
      text: `${fiat.amount} ${fiat.ticker} p2p.notifications.deducted`,
      // text: `${fiat.amount} ${fiat.ticker} ${$t(
      //   'p2p.notifications.deducted',
      // )}`,
    })
  }

  commit('reset', { clearFields: true })

  return { response, errors }
}
