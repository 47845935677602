export const DEPOSIT = 1
export const WITHDRAW = 2
export const ORDER_SELL = 1
export const ORDER_BUY = 2
export const EDR_TICKER = 'EDR'
export const ADK_TICKER = 'ADK'
export const ACTIVE_PAIR_KEY = 'activePair2'

export const GENERAL_DECIMAL_PLACES = 8
export const DECIMAL_REGEXP = /^\d*(\.|,)?\d*$/m

export const TYPE_STATUS_NEW = 1 // Тип статуса новый
export const TYPE_STATUS_CONFIRM = 2 // Тип статуса в работе
export const TYPE_STATUS_REJECT = 3 // Тип статуса отклонено
export const TYPE_STATUS_PERCENT_PAID = 4 // Тип статуса начисление процентов
export const TYPE_STATUS_PERCENT_RECEIVE = 5 // Тип статуса процент начисления
export const TYPE_STATUS_LOAN_FINISHED = 6 // Тип статуса завершение кредита
export const TYPE_STATUS_LOAN_CLOSED = 7 // Тип статуса закрытие кредита

export const TYPE_STATUS_FINISH = 20 // Тип статуса окончен
export const TYPE_STATUS_CLOSE = 10 // Тип статуса закрыт

export const TYPE_NOTIFICATION_LEND = 1 // тип давать в долг
export const TYPE_NOTIFICATION_BORROW = 2 // тип одолжить
export const TYPE_NOTIFICATION_PAYPERCENT = 3 // тип одолжить

export const TYPE_ACTION_APPROVE = 1 // Заявка на кредит подтверждена
export const TYPE_ACTION_REJECT = 2 // Заявка на кредит откланена

export const RESTRICTED_COUNTRIES_TO_REGISTER = ['US']

// special tickers which is not a market but shows on left side as market
// and we should use it as currency
export const BLOCKED_BUY_SELL_PRICE_TICKERS = ['USDN'] /* 'PLC', */

// Withdraw limit for unverified user
export const WITHDRAW_LIMIT = 2

export default {
  TYPE_STATUS_NEW,
  TYPE_STATUS_CONFIRM,
  TYPE_STATUS_REJECT,
  TYPE_STATUS_PERCENT_PAID,
  TYPE_STATUS_PERCENT_RECEIVE,
  TYPE_STATUS_LOAN_FINISHED,
  TYPE_STATUS_LOAN_CLOSED,
  TYPE_STATUS_FINISH,
  TYPE_STATUS_CLOSE,

  TYPE_NOTIFICATION_LEND,
  TYPE_NOTIFICATION_BORROW,
  TYPE_NOTIFICATION_PAYPERCENT,

  TYPE_ACTION_APPROVE,
  TYPE_ACTION_REJECT,

  RESTRICTED_COUNTRIES_TO_REGISTER,

  DEPOSIT,
  WITHDRAW,
  EDR_TICKER,
  ACTIVE_PAIR_KEY,
  WITHDRAW_LIMIT,
}

export const SHOW_MORE_STEP = 10

export const TRADE_ALGORITHM_NO = 'TRADE_ALGORITHM_NO'
export const TRADE_ALGORITHM_STOP_LIMIT = 'TRADE_ALGORITHM_STOP_LIMIT'
export const TRADE_ALGORITHM_OCO_ORDER = 'TRADE_ALGORITHM_OCO_ORDER'

export const MARGIN_TRADE_CROSS = 'margin_x3'
export const MARGIN_TRADE_SPOT = 'spot'
export const MOBILE_BREAKPOINT = 768

export const MARKET_LIST_PAIRS_DATA = 'MARKET_LIST_PAIRS_DATA'
export const CURRENT_MARKET_LIST_TAB_KEY = 'CURRENT_MARKET_LIST_TAB_KEY'

export const BUY_SELL_FORM_LIMIT = 'limit'
export const BUY_SELL_FORM_STOP_LIMIT = 'stopLimit'
export const BUY_SELL_FORM_OCO = 'oco'
export const BUY_SELL_FORMS = [
  BUY_SELL_FORM_LIMIT,
  BUY_SELL_FORM_STOP_LIMIT,
  BUY_SELL_FORM_OCO,
]

export const ROUTE_WITH_NOT_FOUND_ERROR = 'ROUTE_WITH_NOT_FOUND_ERROR'

/**
 * Object.freeze for immutable
 * Getters for access self properties
 * @type {Readonly<{HOUR: number, SECOND: number, MINUTE: number, DAY: number}>}
 */
export const TIME = Object.freeze({
  get SECOND () {
    return 1000
  },
  get MINUTE () {
    return this.SECOND * 60
  },
  get HOUR () {
    return this.MINUTE * 60
  },
  get DAY () {
    return this.HOUR * 24
  },
})

/**
 * @type {Readonly<{BUY: 'buy', SELL: 'sell'}>}
 */
export const TRADE_TYPE = Object.freeze({
  BUY: 'buy',
  SELL: 'sell',
})

export const HTTP_STATUS_CODE = Object.freeze({
  METHOD_NOT_ALLOWED: 405,
  AUTHENTICATION_TIMEOUT: 419,
})
