<template>
  <div
    :class="classes"
    class="exc-ticker-logos-block"
  >
    <!-- for single logo -->
    <div
      v-if="!fiatLogo && !cryptoLogo && tickerLogo"
      class="exc-ticker-logos-block__container mt-auto mb-auto exc-ticker-logos-block--percent-img-bg"
    >
      <div class="exc-ticker-logos-block__right-bottom">
        <exc-ticker-logo
          :src="tickerLogo"
          :transparent-border="transparentBorder"
          size="s"
        />
      </div>
    </div>

    <!-- for double logos -->
    <div
      v-if="fiatLogo && cryptoLogo && !tickerLogo"
      class="exc-ticker-logos-block__container mt-auto mb-auto"
    >
      <div class="exc-ticker-logos-block__left-top">
        <exc-ticker-logo
          :src="fiatLogo"
          :transparent-border="transparentBorder"
          size="m"
        />
      </div>
      <div class="exc-ticker-logos-block__right-top">
        <!-- TODO: fix svg icon -->
        <!-- <icon-arrow-curve /> -->
        <img
          src="https://coinsbit.io/img/icons/arrow_curve.svg"
          alt="arrow"
        >
      </div>
      <div class="exc-ticker-logos-block__right-bottom">
        <exc-ticker-logo
          :src="cryptoLogo"
          :transparent-border="transparentBorder"
          size="m"
        />
      </div>
      <div class="exc-ticker-logos-block__left-bottom">
        <!-- TODO: fix svg icon -->
        <!-- <icon-arrow-curve style="transform:rotate(180deg)" /> -->
        <img
          src="https://coinsbit.io/img/icons/arrow_curve.svg"
          alt="arrow"
          style="transform:rotate(180deg)"
        >
      </div>
    </div>

    <!-- pass here any other thing via slot -->
    <div
      v-if="$slots['content']"
      class="exc-ticker-logos-block__content"
    >
      <slot name="content" />
    </div>

    <div class="exc-ticker-logos-block__block-title ml-16">
      <slot name="mobile-block" />
    </div>
  </div>
</template>
<script>
// import adminPageCheker from '@/mixins/adminPageChecker'
import ExcTickerLogo from '@/components/common/ticker/ExcTickerLogo.vue'
// import IconArrowCurve from 'src/icons/arrows/icon-arrow-curve.svg'

export default {
  name: 'ExcTickerLogosBlock',
  components: {
    // IconArrowCurve,
    ExcTickerLogo,
  },
  // mixins: [adminPageCheker],
  props: {
    // for single logo with percent image
    tickerLogo: { type: String, default: '' },

    // for double logos with two arrows
    fiatLogo: { type: String, default: '' },
    cryptoLogo: { type: String, default: '' },
    color: {
      validator: v => ['green', 'red', 'main', 'grey'].includes(v),
      default: 'green',
    },
    lineOnLeft: { type: Boolean, default: false },
    transparentBorder: { type: Boolean, default: false },
  },
  computed: {
    classes () {
      return {
        'exc-ticker-logos-block--green': this.color === 'green',
        'exc-ticker-logos-block--red': this.color === 'red',
        'exc-ticker-logos-block--main': this.color === 'main',
        'exc-ticker-logos-block--grey': this.color === 'grey',
        'exc-ticker-logos-block--line-on-left': this.lineOnLeft,
        'exc-ticker-logos-block--line-left-and-top': !this.lineOnLeft,
        // 'exc-ticker-logos-block--line-on-top': this.lineOnTop,
        // 'exc-ticker-logos-block--line-on-left': !this.lineOnTop,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.exc-ticker-logos-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @include bp(s) {
    align-items: initial;
  }

  &--line-left-and-top,
  &--line-left-and-top:before {
    border-top-right-radius: toRem(3px);
    border-top-left-radius: toRem(3px);

    @include bp(s) {
      border-top-right-radius: 0;
      border-bottom-left-radius: toRem(3px);
      border-bottom-left-radius: toRem(3px);
    }
  }

  &--line-on-left,
  &--line-on-left:before {
    border-top-left-radius: toRem(3px);
    border-bottom-left-radius: toRem(3px);
  }

  &--line-left-and-top:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: toRem(4px);

    @include bp(s) {
      top: 0;
      bottom: 0;
      left: 0;
      width: toRem(4px);
      height: 100%;
    }
  }

  &--line-on-left:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: toRem(4px);
    height: 100%;
  }

  $logosColors: (
    'main': color(main),
    'grey': $c-grey,
    'green': $c-green,
    'red': $c-red
  );

  @each $name, $color in $logosColors {
    &--#{$name} {
      background-color: color(#{$name}-transp);

      &:before {
        background: $color;
      }
    }
  }

  &--percent-img-bg {
    background: url('https://coinsbit.io/img/background/percent-with-coin.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  &--default {
    background-color: color(main-transp);

    &:before {
      background: color(green);
    }
  }

  &__container {
    $sizeMobile: 18px;
    $sizeDesktop: 25px;
    display: grid;
    // grid-gap: 8px;
    // grid-template-rows: $sizeMobile $sizeMobile;
    // grid-template-columns: $sizeMobile $sizeMobile;
    // padding-top: toRem(4px);

    // @include bp(s) {
    grid-gap: 3px;
    grid-template-rows: $sizeDesktop $sizeDesktop;
    grid-template-columns: $sizeDesktop $sizeDesktop;
    padding-top: 0;
    // }
    grid-template-areas:
      'left-top right-top'
      'left-bottom right-bottom';

    & > * {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & img, svg {
      max-width: 100%;
      height: auto;
    }
  }

  &__content {
    display: flex;
    width: 100%;
  }

  &__left-top {
    grid-area: left-top;
  }

  &__right-top {
    grid-area: right-top;
  }

  &__right-bottom {
    grid-area: right-bottom;
  }

  &__left-bottom {
    grid-area: left-bottom;
  }

  &__left-top,
  &__right-bottom {
    border-radius: 999px;

    img, svg {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__block-title {
    flex: 1;
    @include bp(s) {
      display: none;
    }
  }

}
</style>
