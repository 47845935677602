import { Module } from 'vuex'
import { RootState } from './index'
// import { actionWrapper } from '@/lib/utils'
// import { WalletBalanceModel } from '@/lib/data-models/wallet/WalletBalanceModel'
// import { WalletDepositWithdrawDataModel } from '@/lib/data-models/wallet/WalletDepositWithdrawDataModel'
// import { WalletSavedPaymentAddress } from '@/lib/data-models/wallet/WalletSavedPaymentAddress'
// import { ListingCardModel } from '@/lib/data-models/listing/ListingCardModel'

export interface StableState {
  balances: WalletBalancesResponseItem[],
  stableTicker: Ticker | null,
  currencyTicker: Ticker | null,
}

const StableModule: Module<StableState, RootState> = {
  namespaced: true,
  state: () => ({
    balances: [],
    stableTicker: null,
    currencyTicker: null,
  }),
  actions: {
    // getWalletBalances: actionWrapper<StableState>({
    //     apiRequest: () => this.$api.getWalletBalances(),
    //     mutationName: 'setBalances',
    //     cacheTime: 1000 * 60, // increase in prod
    // }),
  },
  mutations: {
    setStableTicker (state, ticker: Ticker) {
      state.stableTicker = ticker
    },
    setCurrencyTicker (state, ticker: Ticker) {
      state.currencyTicker = ticker
    },
  },
  getters: {
    stableSelectedPair: (state, getters, rootState) => {
      const currentPair = rootState.globals.marketList.map(pair => pair.split('_')).find(([left, right]) => {
        return (left === state.stableTicker && right === state.currencyTicker) ||
          (left === state.currencyTicker && right === state.stableTicker)
      })

      return currentPair ? currentPair.join('_') : null
    },
    // balanceByTicker: state => (ticker: Ticker) => {
    //     const balance = state.balances.find(b => b.ticker === ticker)
    //     if (balance) return new WalletBalanceModel(balance)
    //     return  WalletBalanceModel.getDefault(ticker)
    // },
    // getCurrencyAvailableBalance: (state, getters) => (ticker: Ticker) => {
    //   return getters.balanceByTicker(ticker).tradeAvailable
    // },
    // getWalletBalances: state => {
    //     return state.balances.map(b => new WalletBalanceModel(b))
    // },
    // getWalletDepositData: state => (ticker: Ticker) => {
    //     const deposit = state.depositData[ticker]
    //     if (!deposit) return null
    //     return new WalletDepositWithdrawDataModel(deposit)
    // },
    // getWalletWithdrawData: state => (ticker: Ticker) => {
    //     const withdraw = state.withdrawData[ticker]
    //     if (!withdraw) return null
    //     return new WalletDepositWithdrawDataModel(withdraw)
    // },
    // getSavedAddresses: state => state.savedAddresses.map(
    //     addr => new WalletSavedPaymentAddress(addr)
    // )
  },
}

export default StableModule
