import { MarketListPair } from './MarketListPair'

export class MarketListTab implements RawMarketListTab {
  label: string
  id: string
  value: string
  pairs: MarketListPair[]
  tabs: MarketListTab[]

  constructor (
    t: RawMarketListTab,
    mPairs: Hash<ISocketPair>,
    favIdsForMarket: string[],
  ) {
    this.label = t.label
    this.id = t.id
    this.value = t.id
    this.pairs = t.pairs.map(
      p => new MarketListPair(p, mPairs[p.key], favIdsForMarket),
    )
    this.tabs = t.tabs.map(t => new MarketListTab(t, mPairs, favIdsForMarket))
  }
}
