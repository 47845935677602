























import Vue from 'vue'
import { sizeValidator, defaultSize, uiKitSizes } from '@/lib/utils'
import ExcLoader from '@/components/common/svg/ExcLoader.vue'

const bool = {
  type: Boolean,
  default: false,
}

export default Vue.extend({
  name: 'ExcButton',
  components: {
    ExcLoader,
  },
  props: {
    color: {
      // validator: v => ['red', 'warning', 'green', 'neutral', 'grey', 'dark-grey'].includes(v),
      validator: v =>
        ['red', 'main', 'green', 'grey', 'white', 'dark', 'blue'].includes(v),
      default: 'main',
    },
    borderRadius: {
      type: String,
      default: '56',
    },
    forceUseLink: {
      type: Boolean,
      default: false,
    },
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
    mobileSize: {
      validator: (v: string) => uiKitSizes.includes(v) || v === null,
      default: null,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    soon: {
      type: Boolean,
      default: false,
    },
    /* eslint-disable vue/require-default-prop */
    // noPaddings: bool,
    // noBorder: bool,
    text: bool,
    fullWidth: bool,
    noHover: bool,
    opaque: bool,
    loading: bool,
    square: bool,
    customSizes: bool,
    onlyDark: bool,
    // visible: bool,
  },
  computed: {
    componentName () {
      if (this.$attrs.to) {
        return 'router-link'
      } else if (this.$attrs.href || this.forceUseLink) {
        return 'a'
      } else {
        return 'button'
      }
    },
    classes () {
      return [
        `bor--radius-${this.borderRadius}`,
        `exc-button--mob-${
          this.customSizes ? 'custom' : this.mobileSize || this.size
        }`,
        `exc-button--${this.customSizes ? 'custom' : this.size}`,
        {
          'exc-button--disabled':
            this.soon || this.$attrs.disabled || this.$attrs.disabled === '',
          // 'exc-button--no-paddings': this.noPaddings,
          'exc-button--no-hover': this.noHover,
          'exc-button--full-width': this.fullWidth,
          // 'exc-button--no-border': this.noBorder,
          'exc-button--text': this.text,
          // 'exc-button--visible': this.visible,
          // colors
          'exc-button--red': this.color === 'red',
          'exc-button--main': this.color === 'main',
          'exc-button--green': this.color === 'green',
          // 'exc-button--neutral': this.color === 'neutral',
          'exc-button--grey': this.color === 'grey',
          // 'exc-button--dark-gray': this.color === 'dark-gray',
          'exc-button--dark': this.color === 'dark',
          'exc-button--white': this.color === 'white',
          'exc-button--blue': this.color === 'blue',
          // outline
          'exc-button--outline': this.outline,
          // opaque
          'exc-button--opaque': this.opaque,
          // loading
          'exc-button--loading': this.loading,
          // square
          'exc-button--square': this.square,
          // only dark
          'exc-button--only-dark': this.onlyDark,
        },
      ]
    },
  },
})
