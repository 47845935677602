<template>
  <confirm-modal-container
    v-if="show"
    class="apply-for-listing-modal"
    @close="close"
  >
    <template #header>
      <div class="apply-for-listing-modal__title">
        {{ $t('applyForListingModal.title') }}
      </div>
    </template>

    <template #content>
      <form
        novalidate
        autocomplete="off"
        class="mt-16"
        @submit.prevent="onFormSubmit"
      >
        <exc-input
          v-model="$v.form.website.$model"
          :field="$v.form.website"
          :optional-errors="{
            required: $t('validators.required'),
            maxLength: $t('validators.maxLength', { max: 255 }),
            email: $t('validators.email'),
            url: $t('validators.url'),
            ...mix_getServerError('website'),
          }"
          size="s"
          :label="$t('applyForListingModal.website')"
          placeholder="URL"
          @input="mix_clearServerError('website')"
        />

        <exc-input
          v-model="$v.form.telegram.$model"
          :field="$v.form.telegram"
          :optional-errors="{
            required: $t('validators.required'),
            maxLength: $t('validators.maxLength', { max: 255 }),
            ...mix_getServerError(`telegram`),
          }"
          size="s"
          :label="$t('applyForListingModal.telegram')"
          placeholder="@nickname"
          @input="mix_clearServerError(`telegram`)"
        />

        <exc-input
          v-model="$v.form.email.$model"
          :field="$v.form.email"
          :optional-errors="{
            required: $t('validators.required'),
            maxLength: $t('validators.maxLength', { max: 255 }),
            email: $t('validators.email'),
            ...mix_getServerError('email'),
          }"
          size="s"
          :label="$t('applyForListingModal.email')"
          :placeholder="$t('applyForListingModal.emailPlaceholder')"
          @input="mix_clearServerError('email')"
        />

        <textarea-simple
          v-model="$v.form.comment.$model"
          :field="$v.form.comment"
          :optional-errors="{
            maxLength: $t('validators.maxLength', { max: 2000 }),
            ...mix_getServerError('comment'),
          }"
          :label="$t('applyForListingModal.comment')"
          @input="mix_clearServerError('comment')"
        />

        <exc-button
          full-width
          :loading="isPending"
          type="submit"
        >
          {{ $t('applyForListingModal.submit') }}
        </exc-button>
      </form>
    </template>
  </confirm-modal-container>
</template>

<script>
import { ExcButton, ExcInput } from '@/components/common'
import AuthMixinCaptcha from '@/mixins/auth/AuthMixinCaptcha'
import AuthFormSubmit from '@/mixins/auth/AuthFormSubmit'
import { validationMixin } from 'vuelidate'
import { serverFormMixin } from '@/mixins/serverFormMixin'
import { required, email, maxLength, url } from 'vuelidate/lib/validators'
import { notifyError, notifySuccess } from '@/lib/bus'

export default {
  name: 'ApplyForListingModal',

  components: {
    ExcInput,
    ExcButton,
    ConfirmModalContainer: () =>
      import(
        /* webpackMode: "eager" */ '@/components/containers/ConfirmModalContainer.vue'
      ),
    TextareaSimple: () =>
      import(
        /* webpackMode: "eager" */ '@/components/ui-kit/text-input/TextareaSimpleNew.vue'
      ),
  },
  mixins: [
    AuthMixinCaptcha,
    AuthFormSubmit,
    validationMixin,
    serverFormMixin,
  ],

  props: {
    show: { type: Boolean, default: false },
  },

  data () {
    return {
      form: {
        website: '',
        email: '',
        telegram: '',
        comment: '',
      },
      isPending: false,
    }
  },

  computed: {
    /*  */
  },

  methods: {
    close () {
      this.$emit('close')
      this.reset()
    },
    reset () {
      this.form.website = ''
      this.form.email = ''
      this.form.telegram = ''
      this.form.comment = ''
      this.$v.$reset()
    },
    async submit () {
      const payload = {
        ...this.form,
        ...this.mix_captchaData,
      }

      try {
        const { errors } = await this.$api.applyToListing(payload)

        if (errors) throw errors

        notifySuccess({
          title: this.$t('common.notify.success'),
        })

        this.close()
      } catch (e) {
        notifyError({
          title: this.$t('common.notify.uhOh'),
          text: this.$t('common.notify.somethingWentWrong'),
        })
      } finally {
        this.isPending = false
      }
    },
  },

  validations () {
    return this.mix_validationsServerWrapper({
      form: {
        website: {
          required,
          url,
          maxLength: maxLength(255),
        },
        email: {
          required,
          email,
          maxLength: maxLength(255),
        },
        telegram: {
          required,
          maxLength: maxLength(255),
        },
        comment: {
          maxLength: maxLength(2000),
        },
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.apply-for-listing-modal {
  &__title {
    font-size: toRem(22px);
    line-height: toRem(30px);
    font-weight: 700;
  }

  &::v-deep {
    .Modal__container {
      max-width: toRem(356px) !important;
      min-width: unset !important;
      min-height: unset !important;
      width: 100%;
      height: fit-content;
    }
  }
}
</style>
