import { Module, MutationTree } from 'vuex'
// import api from '@/lib/api'
import {
  notifySuccess,
  notifyInfo,
  showP2pError,
} from '@/lib/bus'
// import { $t } from '@/lib/i18n'
// import { LabeledValue } from '@/data-objects/props/LabeledValue'
// import router from '@/router'
import { Form, required, custom, minValue, maxValue } from '../lib/vuex-form'
import {
  FieldsSetupStructureInterface,
  destructAndMapFields,
  destructAndMapFieldsDefaults,
  destructAndMapFieldsEndValues,
} from '../lib/helpers'
import { RootState } from '..'
// import { getRouter } from '~/plugins/extend-context'

// const router = getRouter()

interface P2pOrderDetailsStateInterface {}
const state: P2pOrderDetailsStateInterface = {}
const actions = {}
const mutations: MutationTree<P2pOrderDetailsStateInterface> = {}

const formFieldsProps: FieldsSetupStructureInterface = {
  // TODO: temporary
  volatility_percent: {
    default: '1',
    // type: String,
    assignValueFn: val => val,
  },
  // TODO: temporary
  amount_crypto: {
    default: '1',
    // type: String,
    assignValueFn: val => val,
  },

  currency: {
    default: null,
    assignValueFn: val => val.value,
    validators: [required()],
  },
  amount: {
    default: '',
    assignValueFn: val => val,
    validators: [
      required(),
      minValue(50),
      custom((value, _, __, rootGetters) => {
        return fromToValidator(value, rootGetters, {
          fromName: 'amountFrom_number',
          toName: 'amountTo_number',
        })
      }),
    ],
  },
  days: {
    default: '',
    assignValueFn: val => val,
    validators: [
      required(),
      minValue(3),
      maxValue(90),
      custom((value, _, __, rootGetters) => {
        return fromToValidator(value, rootGetters, {
          fromName: 'daysFrom',
          toName: 'daysTo',
        })
      }),
    ],
  },
  pay_percent: {
    default: '',
    // type: String,
    assignValueFn: val => val,
    validators: [
      required(),
      minValue(0.01),
      maxValue(5),
      custom((value, _, __, rootGetters) => {
        return fromToValidator(value, rootGetters, {
          fromName: 'percentFrom_number',
          toName: 'percentTo_number',
        })
      }),
    ],
  },
}

const orderRequestForm = new Form({
  fields: destructAndMapFields(formFieldsProps),
  defaultValues: destructAndMapFieldsDefaults(formFieldsProps),
  async onSubmit ({ commit, getters }, id) {
    commit('setFetchStatus', 'loading')
    const fields = getters.allFields
    const formObj = destructAndMapFieldsEndValues(fields, formFieldsProps)

    console.log('FORM_OBJ::', formObj, id)

    const { response, errors } = await this.$api.p2pTakeCredit(id, formObj)
    if (errors || !response) {
      console.error('api.p2pTakeCredit(id, formObj)::', errors)
      commit('setFetchStatus', 'error')
      showP2pError(errors)
      // notifyError({ text: errors && errors[0] || 'Some error'})
      return
    }

    commit('setFetchStatus', 'ok')
    notifySuccess({
      title: 'common.notify.success',
      text: 'p2p.notifications.createdBorrowing',
      // title: $t('common.notify.success'),
      // text: $t('p2p.notifications.createdBorrowing'),
      // title: i18n.t('notify.applyToList.title'),
      // text: i18n.t('notify.applyToList.text'),
    })

    const { crypto } = response
    if (crypto) {
      notifyInfo({
        // title: 'Note!',
        text: `${crypto.amount} ${crypto.ticker} ${'p2p.notifications.deducted'}`,
        // text: `${crypto.amount} ${crypto.ticker} ${i18n.t(
        //   'p2p.notifications.deducted'
        // )}`
      })
    }
    console.log('response::', response)
    commit('reset', { clearFields: true })
  },
})

export const orderRequest: Module<P2pOrderDetailsStateInterface, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: { orderRequestForm },
}

function fromToValidator (value, rootGetters, { fromName, toName }) {
  const id = 'ID_ID_ID' // router.currentRoute.params.loanId
  const order = rootGetters['p2p/orderDescription/descriptionDataById'](id)
  const min = (order || {})[fromName]
  const max = (order || {})[toName]
  const val = parseFloat(value)
  // debugger
  if (min > val || max < val) return `Minimum ${min}, maximum ${max}`
}
