import { formatDate } from '~/lib/utils'
import { SessionComponentMapper } from './SessionComponentMapper'

export class SessionDataModel {
  readonly id: string
  readonly ipAddress: string
  readonly userAgent: string
  readonly platform: SessionPlatform
  readonly lastActivity: number
  readonly isCurrent: boolean
  readonly createdAt: string

  readonly deleted?: boolean;

  constructor (o: Session) {
    this.id = o.id
    this.ipAddress = o.ipAddress
    this.userAgent = o.userAgent
    this.platform = o.platform
    this.lastActivity = o.lastActivity
    this.isCurrent = o.isCurrent
    this.createdAt = formatDate(o.lastActivity * 1000)

    this.deleted = o.deleted ? o.deleted : false
  }

  getImageComponent () {
    return SessionComponentMapper.mapPlatformToComponent(this.platform)
  }
}
