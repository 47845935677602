import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '../../index'
import paginationInfo from './paginationInfo'

export interface OtcAdminOrdersState {
  ordersList: OtcOrderItem[]
}

const state = () => ({
  ordersList: [],
}) as OtcAdminOrdersState

const actions: ActionTree<OtcAdminOrdersState, RootState> = {
  async getOrders (
    { commit },
    { page, status }: { page: number, status?: string },
  ) {
    const { response, errors } = await this.$api.otcAdminGetOrderHistory(
      page,
      status,
    )

    if (errors) {
      console.error(errors)
      return
    }

    page > 1
      ? commit('addOrders', response.orders)
      : commit('setOrders', response.orders)
    commit('paginationInfo/setPagination', response)
  },
}

const mutations: MutationTree<OtcAdminOrdersState> = {
  setOrders (state, pld: OtcOrderItem[]) {
    state.ordersList = pld
  },
  addOrders (state, pld: OtcOrderItem[]) {
    state.ordersList = [...state.ordersList, ...pld]
  },
}

const getters: GetterTree<OtcAdminOrdersState, RootState> = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: { paginationInfo },
} as Module<OtcAdminOrdersState, RootState>
