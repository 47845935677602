<template>
  <exc-input-label-error-wrapper
    :field="field"
    :label="label"
    :info-message="infoMessage"
    :custom-error="customError"
    :optional-errors="optionalErrors"
    :name="name"
    :form-key="formKey"
    class="exc-input"
  >
    <!-- eslint-disable vue/valid-v-on -->
    <!-- eslint-disable vue/no-parsing-error  -->
    <template #default="slotProps">
      <exc-base-input
        ref="input"
        :value="value"
        v-bind="{ ...$attrs, disabled, ...slotProps }"
        class="exc-input__input"
        v-on="{ ...$listeners, input: val => $emit('input', val) }"
      />
    </template>
  </exc-input-label-error-wrapper>
</template>
<script>
import ExcInputLabelErrorWrapper from '@/components/common/form/validation/ExcInputLabelErrorWrapper.vue'
import ExcBaseInput from '@/components/common/form/input/ExcBaseInput.vue'

export default {
  name: 'ExcInput',
  components: {
    ExcBaseInput,
    ExcInputLabelErrorWrapper,
  },
  props: {
    value: {
      validator: v => ['string', 'number'].includes(typeof v) || v === null,
      required: true,
    },
    label: { type: String, default: '' },
    field: {
      type: [Object], // vuelidate field
      default: null,
    },
    customError: { type: String, default: '' },
    infoMessage: { type: String, default: '' },
    name: { type: String, default: '' },
    formKey: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    optionalErrors: { type: Object, default: () => ({}) },
  },

  methods: {
    focus () {
      this.$refs.input && this.$refs.input.focus()
    },
    blur () {
      this.$refs.input && this.$refs.input.blur()
    },
  },
}
</script>
<style lang="scss" scoped>
.exc-input {
  position: relative;
}
</style>
