export const MAIL_NOT_CONFIRMED = '1001'
export const USER_IS_BLOCKED = '1002'
export const DEVICE_NOT_CONFIRMED = '1003'
export const TWO_FA_NOT_CONFIRMED = '1004'
export const LOGOUT_ERROR = '1007'
export const DEVICE_AND_TWO_FA_NOT_CONFIRMED = '1012'

export const STATUS_CODE = {
  MAIL_NOT_CONFIRMED,
  USER_IS_BLOCKED,
  DEVICE_NOT_CONFIRMED,
  TWO_FA_NOT_CONFIRMED,
  LOGOUT_ERROR,
  DEVICE_AND_TWO_FA_NOT_CONFIRMED,
}
