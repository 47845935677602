import { Context } from '@nuxt/types'

export default function ({ store, redirect }: Context) {
  // TODO: fix state before redirect

  // store.dispatch('profile/getGeneralSettings').then(() => {
  //   if (!store.state.auth.user.isOtc) redirect('/not-found')
  // })

  // await app.$auth.fetchUser()
  if (!store.state.auth.user.isOtc) redirect('/not-found')
}
