<template>
  <component
    :is="componentName"
    v-bind="$attrs"
    :class="{
      'router-link-active tdx-navigation-link--active': $attrs.to && isActive,
    }"
    class="tdx-navigation-link bor--radius-12 txt--no-decoration"
    @mouseover.native="upHere = true"
    @mouseleave.native="upHere = false"
    v-on="$listeners"
  >
    <div
      class="
        tdx-navigation-link__content
        pt-8
        pb-8
        pl-14
        pr-14
        pt-12--l
        pb-12--l
        pr-16--l pr-16--l
        flx
      "
    >
      <component
        :is="imageData.is"
        v-if="imageData"
        :src="imageData.src"
        class="tdx-navigation-link__icon mr-8"
        width="28"
        height="28"
      />
      <div class="flx flx--col">
        <h5
          class="
            tdx-navigation-link__title
            txt txt--14px txt--height-18px txt--18px--l txt--height-20px--l
            mt-0
            mb-4
          "
        >
          {{ title }}
        </h5>
        <span
          class="
            tdx-navigation-link__subtitle
            txt txt--12px txt--height-16px txt--14px--l txt--height-18px--l
          "
        >
          {{ subtitle }}
        </span>
      </div>
    </div>
  </component>
</template>

<script>
export default {
  name: 'TdxNavigationLink',
  props: {
    forceUseLink: { type: Boolean, default: false },
    icon: { type: [Object, String], required: true },
    hoverIcon: { type: [Object, String], required: false },
    title: { type: String, required: true },
    subtitle: { type: String, required: true },
  },
  data () {
    return {
      upHere: false,
    }
  },
  computed: {
    componentName () {
      if (this.$attrs.to) {
        return 'router-link'
      } else if (this.$attrs.href || this.forceUseLink) {
        return 'a'
      } else {
        return 'span'
      }
    },
    imageData () {
      const iconData = (this.upHere ? this.hoverIcon : this.icon) || this.icon
      if (!iconData) return null
      if (typeof iconData === 'string') {
        return {
          is: 'img',
          src: iconData,
        }
      }
      return { is: iconData }
    },
    pathWithoutLocale () {
      const lang = this.$route.params.lang
      if (!lang) return this.$route.path
      return this.$route.path.slice(('/' + lang).length)
    },
    isActive () {
      if (typeof this.$attrs['is-active'] === 'boolean') { return this.$attrs['is-active'] }

      const exact = this.$attrs.to && this.$attrs.exact

      const to = this.$attrs.to
      const currentRouteName = (this.$route.name || '').replace(
        'localized_',
        '',
      )
      const pathString = this.pathWithoutLocale
      if (typeof to === 'object') {
        if (exact) {
          return to.name === currentRouteName || to.path === pathString
        } else {
          return (
            currentRouteName.startsWith(to.name) ||
            pathString.startsWith(to.path)
          )
        }
      } else if (typeof to === 'string') {
        if (exact) {
          return to === pathString || '/' + to === pathString
        } else {
          return pathString.startsWith(to) || pathString.startsWith('/' + to)
        }
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.tdx-navigation-link {
  $root: &;

  display: inline-block;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
  min-width: toRem(175px);
  transition: 0.25s;
  background: map-get($backgroundColors, "theme-02");

  &--active,
  &:hover {
    background: map-get($backgroundColors, "violet-gradient");

    #{$root}__title,
    #{$root}__icon {
      color: color("color-08");
    }

    #{$root}__subtitle {
      color: color("color-06");
    }
  }

  &__title,
  &__icon {
    color: color("theme-08");
  }

  &__subtitle {
    color: color("theme-06");
  }
}
</style>
