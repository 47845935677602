
















import Vue from 'vue'

export default Vue.extend({
  name: 'ExcDot',
  props: {
    size: {
      validator: v => v === 's' || v === 'l',
      default: 's',
    },
    color: {
      validator: v => ['green', 'red', 'main', 'grey'].includes(v),
      default: 'grey',
    },
    square: { type: Boolean, default: false },
  },
})
