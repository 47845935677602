import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '../index'

export interface OtcPaymentsState {
  payments: OtcPaymentMethod[]
}

const state = () => ({
  payments: null,
}) as OtcPaymentsState

const actions: ActionTree<OtcPaymentsState, RootState> = {
  async getPayments ({ commit }) {
    const { response, errors } = await this.$api.otcAdminGetPaymentList()
    if (errors) {
      console.error(errors)
      return
    }
    commit('setPaymentMethods', response)
  },
}

const mutations: MutationTree<OtcPaymentsState> = {
  setPaymentMethods (state, pld) {
    state.payments = pld
  },
}

const modules = {}

const getters: GetterTree<OtcPaymentsState, RootState> = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules,
} as Module<OtcPaymentsState, RootState>
