import { TimezoneDateInterface, TimezoneDate } from "./UserNotificationModel"
import Decimal from "decimal.js-light"

// amount_from: "10000.000000000000000000"
// amount_to: "11000.000000000000000000"
// currency: "ETH,LTC"
// date: { date: "2019-09-06 08:38:39.000000", timezone_type: 3, timezone: "UTC" }
// days: 100
// percent_from: "1.000"
// percent_to: "2.000"
// status: 1
// username: "p_shidlovsky"

export interface OrderLendModelInterface {
    amount_from: string
    amount_to: string
    currency: string
    date: TimezoneDateInterface
    days: number
    percent_from: string
    percent_to: string
    status: number
    username: string
}

export class OrderLendModel {
    amountFrom: number
    amountTo: number
    currency: string
    currencyArray: Ticker[]
    date: TimezoneDate
    days: number
    days_string: string
    percentFrom: number
    percentTo: number
    status: number
    username: string
    percentDiapason: string
    amountFromTo_string: string
    constructor(o: OrderLendModelInterface) {
        this.amountFrom = parseFloat(new Decimal(o.amount_from).toFixed(2))
        this.amountTo = parseFloat(new Decimal(o.amount_to).toFixed(2))
        this.currency = o.currency
        this.currencyArray = o.currency.split(',') as Ticker[]
        this.date = new TimezoneDate(o.date)
        this.days = o.days
        this.days_string = o.days + ' d'
        this.percentFrom = parseFloat(new Decimal(o.percent_from).toFixed(2))
        this.percentTo = parseFloat(new Decimal(o.percent_to).toFixed(2))
        this.status = o.status
        this.username = o.username
        this.percentDiapason = this.percentFrom + ' - ' + this.percentTo + ' %'
        this.amountFromTo_string = this.amountFrom + ' - ' + this.amountTo + ' $'
    }
}