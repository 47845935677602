export class LoanModel {
    amount: string
    amountMax: string
    amountMin: string
    available: string
    // created_at: {date: "2021-02-02 13:45:12.000000", timezone_type: 3, timezone: "UTC"}
    cryptoTicker: string
    dailyCharged: number
    dayMax: number
    dayMin: number
    estimated: number
    fiatTicker: string
    id: number
    oksPercent: number
    oksValue: number
    percent: string
    status: string
    totalFreeze: number
    loans: number
    totalAmount: string
    price: string

    constructor (loan: P2PLoanResponse) {
      this.amountMax = loan.amount_max
      this.amountMin = loan.amount_min
      this.cryptoTicker = loan.crypto_ticker
      this.dayMax = loan.day_max
      this.dayMin = loan.day_min
      this.oksPercent = loan.oks_percent
      this.fiatTicker = loan.fiat_ticker
      this.id = loan.id
      this.percent = loan.percent
      this.status = loan.status
      this.available = loan.available
      this.oksValue = loan.oks_value
      this.estimated = loan.estimated
      this.dailyCharged = loan.daily_charged
      this.totalFreeze = loan.total_freeze
      this.loans = loan.loans
      this.totalAmount = loan.amount
      this.price = loan.price
    }
}
