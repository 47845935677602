<template>
  <div
    :class="[classes, bgColor[themeColor]]"
    class="tdx-info-block bor--radius-12 p-10"
  >
    <div class="tdx-info-block__container">
      <div :class="textColor[iconTheme ? iconTheme : themeColor]" class="tdx-info-block__icon">
        <slot name="icon" />
      </div>
      <div class="tdx-info-block__text txt--14px txt--height-24px ml-14">
        <slot name="content">
          {{ text }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TdxInfoBlock',
  props: {
    text: {
      type: String,
      default: '',
    },
    themeColor: {
      validator: v => ['green', 'red', 'main'].includes(v),
      default: 'main',
    },
    iconPosition: {
      validator: v => ['top', 'center', 'bottom'].includes(v),
      default: 'center',
    },
    iconTheme: {
      validator: v => ['green', 'red', 'main'].includes(v),
    },
  },
  computed: {
    classes () {
      return {
        // [`tdx-info-block--theme-${this.themeColor}`]: this.themeColor,
        [`tdx-info-block--icon-position-${this.iconPosition}`]:
          this.iconPosition,
      }
    },
    textColor () {
      return {
        main: 'txt--theme-08',
        red: 'txt--red',
        green: 'txt--theme-08',
      }
    },
    bgColor () {
      return {
        main: 'bg--theme-04 sha--3',
        red: 'bg--color-07-light',
        green: 'bg--green-transp',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tdx-info-block {
  $root: &;
  $colors: 'green', 'red', 'main', 'grey';
  $icon-positions: (
    top: flex-start,
    bottom: flex-end,
    center: center,
  );

  position: relative;
  overflow: hidden;

  &__container {
    display: flex;
  }

  &.bg--color-07-light {
    background-color: #fce9e5;
  }

  @each $position, $flex-position in $icon-positions {
    &--icon-position-#{$position} {
      #{$root}__container {
        @include bp(m) {
          align-items: $flex-position;
        }
      }
    }
  }
}
</style>
