import { Module } from 'vuex'

import { RootState } from '@/store/index'
import security, { SecuritySettingsState } from './security'
// import general, { GeneralSettingsState } from "./general";
import kyc, { KycState } from './kyc'
import api, { ApiPairsState } from './api'
import referral, { ReferralState } from './referral'

export interface SettingsModules {
  kyc: KycState;
  // general: GeneralSettingsState;
  security: SecuritySettingsState;
  api: ApiPairsState;
  referral: ReferralState;
}

const SettingsModule: Module<any, RootState> = {
  namespaced: true,
  modules: {
    kyc,
    // general,
    security,
    api,
    referral,
  },
}

export default SettingsModule
