<template>
  <div
    :class="classes"
    class="exc-coin-with-logo"
    v-on="$listeners"
  >
    <div class="exc-coin-with-logo__container">
      <div class="exc-coin-with-logo__logo">
        <exc-ticker-logo
          :src="img"
          :alt="label"
          :size="size"
          :is-active="isActive"
          :transparent-border="transparentBorder"
        />
      </div>
      <div class="exc-coin-with-logo__body">
        <div
          class="exc-coin-with-logo__name-logo txt--16px txt--height-20px txt--weight-500"
          :class="{'exc-coin-with-logo--horizontal': horizontal}"
        >
          <div
            :class="{
              'txt--color-main': isActive,
              'mr-8': horizontal,
            }"
            class="exc-coin-with-logo__name txt--16px txt--height-20px txt--weight-500"
          >
            <slot name="tickerName">
              {{ name }}
            </slot>
          </div>
          <div
            v-if="label"
            class="exc-coin-with-logo__label txt--16px txt--height-20px txt--weight-500 txt--color-03"
          >
            <!--            :class="{-->
            <!--              'txt&#45;&#45;grey txt&#45;&#45;12px': horizontal,-->
            <!--              'txt&#45;&#45;16px txt&#45;&#45;height-20px txt&#45;&#45;weight-500': !horizontal,-->
            <!--            }"-->
            <!--          >-->
            <slot name="tickerLabel">
              {{ label }}
            </slot>
          </div>
        </div>
        <div
          v-if="balance"
          class="exc-coin-with-logo__balance"
        >
          <span class="txt txt--10px txt--dir">
            <span>
              {{ $t('header.balance') ? $t('header.balance') : 'Balance' }}:
            </span>
            <span
              class="txt--on-one-row txt--color-main"
            >
              {{ balance }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { sizeValidator, defaultSize } from '@/lib/utils'
import { sizeValidator, defaultSize } from '@/lib/utils'
import ExcTickerLogo from './ExcTickerLogo.vue'

export default {
  name: 'ExcCoinWithLogo',
  components: {
    ExcTickerLogo,
  },
  props: {
    name: { type: String, required: true },
    img: { type: String, required: true },
    label: { type: String, default: '' },
    balance: { type: [String, Number], default: '' },
    size: { validator: sizeValidator, default: defaultSize },
    isActive: { type: Boolean, default: false },
    horizontal: { type: Boolean, default: false },
    transparentBorder: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
  },

  computed: {
    classes () {
      return [
        `exc-coin-with-logo--${this.size}`,
        {
          'exc-coin-with-logo--is-active': this.isActive,
          'exc-coin-with-logo--clickable': this.$listeners.click,
          'exc-coin-with-logo--no-padding': this.noPadding,
        },
      ]
    },
    hasOnBalance () {
      return !!parseFloat(this.balance)
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-coin-with-logo {
  $root: &;
  padding: toRem(4px) toRem(6px);
  transition: all .2s;
  // border: 2px solid transparent;
  $bgColor: color(main-transp);
  border-radius: toRem(50px);
  align-self: flex-start;
  // margin-left: toRem(-4px);

  &--is-active {
    background-color: color(main-transp); // rgba($bgColor, .1);
    // border: 2px solid rgba($bgColor, 1);
    // border: 1px solid rgba(0, 0, 0, 0.1);
  }

  &--no-padding {
    padding: 0;
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      background-color: rgba($c-main, 0.2);
    }
  }

  &--is-active#{$root}--clickable {
    &:hover {
      background-color: rgba($c-main, 0.3); // rgba($bgColor, 0.7);
    }
  }

  &--horizontal {
    display: flex;
  }

  &__container {
    // display: grid;
    // grid-template-columns: auto 1fr;
    // grid-template-rows: 1fr auto auto;
    // grid-template-areas:
    //     'logo name'
    //     'logo label'
    //     'logo balance';
    display: flex;
    transition: all .2s;
    align-items: center;
    // padding: 0px;

    // #{$root}--is-active & {
    //     padding: 10px;
    // }
  }

  &__logo {
    margin-right: toRem(8px);
    //     grid-area: logo;
    //     // width: 30px;
    //     height: 30px;
    //     border-radius: 50%;
    //     margin-right: 5px;

    //     #{$root}--lg & {
    //         width: 60px;
    //         height: 60px;
    //         margin-right: 15px;
    //     }

    //     img {
    //         // width: 100%;
    //         height: 100%;
    //     }
  }

  &__body {
    align-self: center;
  }

  &__name {
    display: inline-block;
  }

  &__label {
    display: inline-block;
  }

  &__balance {
    grid-area: balance;
    display: flex;
  }
}
</style>
