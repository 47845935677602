<template>
  <!-- eslint-disable vue/valid-v-on -->
  <!-- eslint-disable vue/no-parsing-error  -->
  <input
    :value="value"
    v-bind="$attrs"
    :class="[classes, `exc-base-input--${size}`, $attrs.disabled ? 'bg--transp-00' : '']"
    class="exc-base-input"
    v-on="{ ...$listeners, input: ev => $emit('input', ev.target.value), focus: onFocus, blur: onBlur }"
    @wheel="$event.target.blur()"
  >
</template>

<script>
import { sizeValidator, defaultSize } from '@/lib/utils'
export default {
  name: 'ExcBaseInput',
  props: {
    value: {
      validator: v => ['string', 'number'].includes(typeof v) || v === null,
      required: true,
    },
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
    dashedBorder: { type: Boolean, default: false },
    withoutBorder: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    hasSuccess: { type: Boolean, default: false },
    resetEmpty: { type: Boolean, default: false },
  },

  computed: {
    classes () {
      return {
        'bor--all txt txt--14px txt--color-04': true,
        'bor--transp-02': !this.withoutBorder,
        'bor--transp sha--none exc-base-input--without-border': this.withoutBorder,
        'bor--red': this.hasError,
        'bor--dashed': this.dashedBorder,
        // 'bor--green': this.hasSuccess,
      }
    },
  },
  methods: {
    onFocus () {
      if (+this.value === 0 && this.resetEmpty) { this.$emit('input', '') }
    },
    onBlur () {
      if (!this.value && this.resetEmpty) {
        this.$emit('input', '0')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-base-input {
  $root: &;
  border-radius: toRem(100px);
  width: 100%; // calc(100% - #{toRem(26px)}); // 26px is left and right padding (12 * 2) + 2px border
  min-width: 1px; // for correct adaptive width in mozilla
  background-color: transparent;
  transition: all .2s;

  // &--without-border {}

  &:focus:not(:disabled):not(:read-only) {
    outline: none;
    box-shadow: map-get($shadows, 'rxd');
    // @extend %sha--004-main;
    // border: 1px solid color(main);
    // @extend %bor--all;
    // @extend %bor--main;
  }
  &:focus:not(:disabled):not(.bor--red) {
    border: 1px solid color(main);

    &:is(.bor--dashed) {
      border-style: dashed;
    }

    &:is(:read-only) {
      border-color: color(transp-02);
    }
  }

  &.exc-base-input--without-border:focus:not(:disabled) {
    box-shadow: none;
    border: 1px solid transparent;
    // @extend %sha--none;
    // @extend %bor--transp;
  }

  &::placeholder {
    color: color(grey);
  }

  &:disabled {
    // opacity: .5;
    opacity: 1;
    cursor: not-allowed;
    color: color(grey);
    -webkit-opacity: 1;
    -webkit-text-fill-color: color(grey);
    border: 1px solid map-get($borderColors, 'transp-02');
    background: map-get($backgroundColors, 'transp-00')
    // @extend %bor--all;
    // @extend %bor--transp-02;
  }

  &[type='number'] {
    -moz-appearance:textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: transparent;
  }

  $sizes: (
    xl: (
      'height': toRem(map-get($uiKitSizes, xl)),
      'padding': 0 toRem(20px),
    ),
    l: (
      'height': toRem(map-get($uiKitSizes, l)),
      'padding': 0 toRem(16px),
    ),
    m: (
      'height': toRem(map-get($uiKitSizes, m)),
      'padding': 0 toRem(12px),
    ),
    s: (
      'height': toRem(map-get($uiKitSizes, s)),
      'padding': 0 toRem(12px),
    ),
    xss: (
      'height': toRem(map-get($uiKitSizes, xss)),
      'padding': 0 toRem(12px),
    ),
    xs: (
      'height': toRem(map-get($uiKitSizes, xs)),
      'padding': 0 toRem(12px),
    ),
    // xxs: (
    //   'height': toRem(map-get($uiKitSizes, xxs)),
    //   'padding': 0 toRem(12px),
    // )
  );

  @each $size, $params in $sizes {
    &--#{$size} {
      @each $prop, $value in $params {
        #{$prop}: $value;
      }
    }
  };
}

// this makes text white (on dark theme) when chrome autofills inputs
// input {
//   .is-dark & {
//     color: white;
//     &:-webkit-autofill,
//     &:-webkit-autofill:hover,
//     &:-webkit-autofill:focus {
//       -webkit-text-fill-color: white;
//       caret-color: white;
//     }
//   }
// }
</style>
