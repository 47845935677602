// import { helpers } from 'vuelidate/lib/validators'
import { Translator, Validator } from '@/store/lib/vuex-form/form'
import { LabeledValue } from '@/data-objects/props/LabeledValue'
// import { i18n } from '@/lib/bootstrap'
import {
  required as vualidateRequired,
  minLength as vualidateMinLength,
  maxLength as vualidateMaxLength,
} from 'vuelidate/lib/validators'
// import { getI18n } from '~/plugins/extend-context'

// const i18n = getI18n()

const valIsEmpty = v =>
  v === '' || v === 0 || v === undefined || v === null || v === false

export const custom = (func: Validator) => (
  value,
  fields,
  rootState,
  rootGetters,
) => {
  return func(value, fields, rootState, rootGetters)
}

export const required = () => (value) => {
  if (valIsEmpty(value)) {
    // return 'validators.required'
    return 'validators.required'
  }
}

export const length = (length: number) => (value) => {
  if (value && value.length !== length) {
    // return `validators.length ${length}`
    return ['validators.length', { length }]
  }
}

export const minLength = (min: number) => (value) => {
  if (value.length < min) {
    // return `validators.minLength ${min}`
    return ['validators.minLength', { min }]
  }
}

export const maxLength = (max: number) => (value) => {
  if (!valIsEmpty(value) && value.length > max) {
    // return `validators.maxLength ${max}`
    return ['validators.maxLength', { max }]
  }
}

export const minValue = (min: number | string) => (
  value: string,
) => {
  if (value === '') return
  if (Number(value) < min) {
    // return `validators.minValue ${min}`
    return ['validators.minValue', { min }]
  }
}

export const maxValue = (max: number) => (value: string) => {
  if (value === '') return
  if (Number(value) > max) {
    // return `validators.maxValue ${max}`
    return ['validators.maxValue', { max }]
  }
}

export const letters = () => (value: string) => {
  if (!/^[A-Za-zА-Яа-яЁё\-\s]*$/.test(value)) {
    // return 'validators.letters'
    return ['validators.letters']
  }
}

export const digits = () => (value: string) => {
  if (!/^[0-9]*$/.test(value)) {
    // return 'validators.digits'
    return ['validators.digits']
  }
}

export const alpha = () => (value: string) => {
  if (!/^[a-zA-Z]*$/.test(value)) {
    // return 'validators.alpha'
    return ['validators.alpha']
  }
}

export const alphanumeric = () => (value: string) => {
  if (!/^[a-zA-Z0-9_]*$/.test(value)) {
    // return 'validators.alphanumeric'
    return ['validators.alphanumeric']
  }
}

export const float = () => (value: string) => {
  if (!/^\d+((\.|,)\d+)?$/.test(value)) {
    // return 'validators.float'
    return ['validators.float']
  }
}

export const email = () => (value: string) => {
  if (
    !/^(([^<>()[\]\\.,:\s@']+(\.[^<>()[\]\\.,:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      value,
    )
  ) {
    // return 'validators.email'
    return ['validators.email']
  }
}

export const url = () => (value: string) => {
  if (
    !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,=.]+$/i.test(
      value,
    )
  ) {
    // return 'validators.url'
    return ['validators.url']
  }
}

export const sameAs = (field: string, translate?: Translator): Validator => (
  value: string,
  fields,
) => {
  if (value !== fields[field]) {
    return `validators.sameAs ${typeof translate === 'function' ? translate() : field}`
    // return i18n.t('validators.sameAs', {
    //   label: typeof translate === 'function' ? translate() : field,
    // })
  }
}

export const notSameAs = (field: string, label?: string): Validator => (
  value: string,
  fields,
) => {
  if (value === fields[field]) {
    return `validators.notSameAs ${label || field}`
    // return i18n.t('validators.notSameAs', { label: label || field })
  }
}

export const when = (
  condition: (value, fields, rootState, rootGetters) => boolean,
  func: Validator,
) => (value, fields, rootState, rootGetters) => {
  if (condition(value, fields, rootState, rootGetters)) {
    return func(value, fields, rootState, rootGetters)
  }
}

// Vuelidate custom validators

export const requiredDropdownValue = (dropdownValue: LabeledValue | null) =>
  !!(dropdownValue && !!dropdownValue.value)

export const alphaWithSpaces = (value: string): boolean =>
  /^([a-zA-Z.,]+\s)*[a-zA-Z.,]+$/.test(value)
export const alphaWithSpacesWithoutSymbols = (value: string): boolean =>
  /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(value)

export const alphaNumWithSpaces = (value: string): boolean =>
  /^[a-zA-Z0-9\s-.,]*$/.test(value)
export const alphaNumWithSpacesAndSlash = (value: string): boolean =>
  /^[a-zA-Z0-9/\s-.,]*$/.test(value)

export const atLeastOneLowercase = (v: string) => /(?=.*[a-z])/.test(v)
export const atLeastOneUppercase = (v: string) => /(?=.*[A-Z])/.test(v)
export const atLeastOneNumeric = (v: string) => /(?=.*[0-9])/.test(v)
export const atLeastOneSpecial = (v: string) =>
  /(?=.*[!@#$%^&*()+_\-=}{[\]|:'/?.><,`'~\\])/.test(v)

export const userNameValidator = (value: string): boolean =>
  /^[\S\d_]*$/.test(value)

export const passwordValidations = {
  required: vualidateRequired,
  minLength: vualidateMinLength(8),
  maxLength: vualidateMaxLength(20),
  atLeastOneLowercase,
  atLeastOneUppercase,
  atLeastOneNumeric,
  atLeastOneSpecial,
}
