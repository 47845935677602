import { Module, ModuleTree } from 'vuex'
import { RootState } from '..'
import otcBuySell, { OtcBuySellState } from './otcBuySell'
import otcCurrency, { OtcCurrenciesState } from './otcCurrency'
import otcPayments, { OtcPaymentsState } from './otcPayments'
import otcAdmin, { OtcAdminState } from './otcAdmin'
import otcQuotations, { OtcQuotationsState } from './otcQuotations/index'
import otcOrders, { OtcOrdersState } from './otcOrders/index'
import otcAdminOrders, { OtcAdminOrdersState } from './otcAdminOrders/index'

export interface OtcState {
  otcBuySell: OtcBuySellState
  otcCurrency: OtcCurrenciesState
  otcPayments: OtcPaymentsState
  otcAdmin: OtcAdminState
  otcQuotations: OtcQuotationsState
  otcOrders: OtcOrdersState
  OtcAdminOrders: OtcAdminOrdersState
}

const modules: ModuleTree<RootState> = {
  otcBuySell,
  otcCurrency,
  otcPayments,
  otcAdmin,
  otcQuotations,
  otcOrders,
  otcAdminOrders,
}

export const otc: Module<OtcState, RootState> = {
  namespaced: true,
  modules,
}
