import { Module } from 'vuex'
import { RootState } from '@/store/index'
import { actionWrapper } from '~/lib/utils'

const LOGGER = false

export interface AutoKycState {
  kycRequestInitData: any,

  personalInfo: {
    firstName: string,
    lastName: string,
    middleName: string,
    gender: string,
    birthDate: string,
    registrationCountry: string,
    personalNumber: string | null,
  },

  addressInfo: {
    registrationCity: string,
    registrationStreet: string,
    registrationAddressHouseNumber: string | number | null,
    registrationAddressApartment: string | number | null,
    registrationAddressBuilding: string | number | null,

    addressOfStay: boolean | number,

    country: string | null,
    city: string | null,
    street: string | null,
    addressHouseNumber: string | number | null,
    addressApartment: string | number | null,
    addressBuilding: string | number | null,
  },

  documentsInfo: any,
}

const AutoKycModule: Module<AutoKycState, RootState> = {
  namespaced: true,
  state: () => ({
    kycRequestInitData: {},

    personalInfo: {
      firstName: null,
      lastName: null,
      middleName: null,
      gender: null,
      birthDate: null,
      registrationCountry: null,
      personalNumber: null,
    },

    addressInfo: {
      registrationCity: null,
      registrationStreet: null,
      registrationAddressHouseNumber: null,
      registrationAddressApartment: null,
      registrationAddressBuilding: null,

      addressOfStay: true,

      country: null,
      city: null,
      street: null,
      addressHouseNumber: null,
      addressApartment: null,
      addressBuilding: null,
    },

    documentsInfo: {
      documentType: 'ID', // default selected document
      documentAvers: null as File | null, // front side
      documentRevers: null as File | null, // back side
    },
  }),

  mutations: {
    setKycRequestInitData (state, { response }) {
      // console.log(response)
      state.kycRequestInitData = response
    },

    // setters of user data
    setPersonalInfo (state, data = {}) {
      LOGGER && console.log('SETTING PERSONAL INFO:', data)
      state.personalInfo = data
    },
    setAddressInfo (state, data = {}) {
      LOGGER && console.log('SETTING ADDRESS INFO:', data)
      state.addressInfo = data
    },
    setDocumentType (state, type) {
      state.documentsInfo.documentType = type
    },
    setDocumentsItems (state, items = {}) {
      state.documentsInfo = {
        documentType: state.documentsInfo.documentType, // save prev document type
        ...items,
      }
    },
  },

  getters: {
    kycRequestInitData: state => state.kycRequestInitData,
    kycWebVerificationLinks: state => state.kycRequestInitData?.partialVerificationUrls || {},
    kycRequestDeepLink: state => state.kycRequestInitData?.partialVerificationUrls?.deepLink || {},
    kycProgramId: state => state.kycRequestInitData?.programId || null,
    kycSelfie: state => state.kycRequestInitData?.partialVerificationUrls?.selfie ?? 'wrong_route_for_selfie',
    kycSubmit: state => state.kycRequestInitData?.partialVerificationUrls?.submit ?? 'wrong_route_for_submit',
  },

  actions: {
    // init verification (get program_id and submit links)
    initAutoKycVerification: actionWrapper({
      apiRequest () { return this.$api.initAutoKycVerification() },
      mutationName: 'setKycRequestInitData',
    }),

    // submit info
    postKycInfo ({ state, getters }) {
      const bodyData = {
        firstName: state.personalInfo.firstName,
        lastName: state.personalInfo.lastName,
        middleName: state.personalInfo.middleName,
        gender: state.personalInfo.gender,
        birthDate: state.personalInfo.birthDate,
        registrationCountry: state.personalInfo.registrationCountry,

        ...(state.personalInfo.personalNumber ? { personalNumber: state.personalInfo.personalNumber } : {}),

        registrationCity: state.addressInfo.registrationCity,
        registrationStreet: state.addressInfo.registrationStreet,

        // do not send if not specified
        ...(state.addressInfo.registrationAddressHouseNumber ? { registrationAddressHouseNumber: state.addressInfo.registrationAddressHouseNumber } : {}),
        ...(state.addressInfo.registrationAddressApartment ? { registrationAddressApartment: state.addressInfo.registrationAddressApartment } : {}),
        ...(state.addressInfo.registrationAddressBuilding ? { registrationAddressBuilding: state.addressInfo.registrationAddressBuilding } : {}),
        ...(state.addressInfo.addressHouseNumber && !state.addressInfo.addressOfStay ? { addressHouseNumber: state.addressInfo.addressHouseNumber } : {}),
        ...(state.addressInfo.addressApartment && !state.addressInfo.addressOfStay ? { addressApartment: state.addressInfo.addressApartment } : {}),
        ...(state.addressInfo.addressBuilding && !state.addressInfo.addressOfStay ? { addressBuilding: state.addressInfo.addressBuilding } : {}),

        addressOfStay: state.addressInfo.addressOfStay ? 0 : 1,
        ...(state.addressInfo.addressOfStay
          // disabled (null is required!)
          ? {
              country: null,
              city: null,
              street: null,
              // do not send this fields
              // addressHouseNumber: null,
              // addressApartment: null,
              // addressBuilding: null,
            }
          // enabled
          : {
              country: state.addressInfo.country,
              city: state.addressInfo.city,
              street: state.addressInfo.street,
              // do not send this fields if null
              // addressHouseNumber: state.addressInfo.addressHouseNumber,
              // addressApartment: state.addressInfo.addressApartment,
              // addressBuilding: state.addressInfo.addressBuilding,
            }
        ),
      }

      const body = new FormData()
      Object.entries(bodyData).forEach(([k, v]) => body.append(k, v as any))

      return this.$api.postKycInfo(body, getters.kycWebVerificationLinks.info)
    },

    // send documents
    postKycDocuments ({ state, getters }) {
      const bodyData = {
        documentType: state.documentsInfo.documentType,
        documentAvers: state.documentsInfo.documentAvers,
        documentRevers: state.documentsInfo.documentRevers,
      }

      const body = new FormData()
      Object.entries(bodyData).forEach(([k, v]) => body.append(k, v as any))

      return this.$api.postKycDocuments(body, getters.kycWebVerificationLinks.documents)
    },
  },
}

export default AutoKycModule
