import { render, staticRenderFns } from "./ExcNoItems.vue?vue&type=template&id=5fd6c621&scoped=true&"
import script from "./ExcNoItems.vue?vue&type=script&lang=ts&"
export * from "./ExcNoItems.vue?vue&type=script&lang=ts&"
import style0 from "./ExcNoItems.vue?vue&type=style&index=0&id=5fd6c621&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fd6c621",
  null
  
)

export default component.exports