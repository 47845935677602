<template>
  <div
    :class="classes"
    class="exc-input-label-error-wrapper"
  >
    <label
      v-if="!labelDisabled"
      :for="uniqueId"
      :class="{
        'exc-input-label-error-wrapper__label--hidden': !label,
      }"
      class="exc-input-label-error-wrapper__label txt--12px txt--color-03 mb-0 pb-4"
      v-html="label"
    />
    <div class="exc-input-label-error-wrapper__content mb-20">
      <slot v-bind="{ hasError: !!errorMessage, hasSuccess, id: uniqueId }" />

      <div class="exc-input-label-error-wrapper__info mb-8">
        <transition name="fade">
          <span
            v-if="textMessage"
            :class="{ 'txt--red': errorMessage, 'txt--grey': !errorMessage }"
            class="txt txt--10px mt-4"
            v-html="textMessage"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqueId } from '@/lib/utils'

const isObj = v => typeof v === 'object' && !Array.isArray(v) && v !== null

export default {
  name: 'ExcInputLabelErrorWrapper',
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: [Object], // vuelidate field is Object, old form field is String
      default: null,
    },
    infoMessage: {
      type: String,
      default: '',
    },
    optionalErrors: {
      type: Object,
      default: () => ({}),
    },
    // consider to remove this props
    customError: {
      type: String,
      default: '',
    },
    // for old form
    formKey: {
      type: String,
      default: '',
    },
    // for old form
    name: {
      type: String,
      default: '',
    },
    labelDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasSuccess () {
      return isObj(this.field) && (!this.field.$invalid)
    },
    classes () {
      return {
        'exc-input-label-error-wrapper--disabled': this.$attrs.disabled,
      }
    },
    messages () {
      const params = this.field.$params
      return {
        // FIXME: change error text for every error you need
        required: 'Field is required',
        requiredIf: 'Field error',
        requiredUnless: 'Field error',
        minLength: `Min length is ${params.minLength && params.minLength.min}`,
        maxLength: 'Field error',
        minValue: `Min value is ${params.minValue && params.minValue.min}`,
        maxValue: `Max value is ${params.maxValue && params.maxValue.max}`,
        between: `Between ${params.between && params.between.min} and ${params.between && params.between.max}`,
        alpha: 'Field error',
        alphaNum: 'Field error',
        numeric: 'Field error',
        integer: 'Field error',
        decimal: 'Field error',
        email: 'Wrong email address',
        ipAddress: 'Field error',
        macAddress: 'Field error',
        sameAs: 'Field error',
        url: 'Field error',
        or: 'Field error',
        and: 'Field error',
        not: 'Field error',
        withParams: 'Field error',
        ...this.optionalErrors,
      }
    },
    vuelidateErrorMessage () {
      const hasError = isObj(this.field) && this.field.$error && this.field.$invalid
      if (hasError) {
        return this.getErrorMessage()
      } return null
    },
    errorMessage () {
      return this.vuelidateErrorMessage || this.oldFormError || this.customError
    },
    textMessage () {
      return this.errorMessage || this.infoMessage || ''
    },
    oldFormError () {
      if (this.formKey) {
        if (typeof this.$store !== 'object') return ''
        return this.$store.getters[`${this.formKey}/error`](this.name)
      } return ''
    },
    uniqueId () {
      return uniqueId('input_')
    },
  },

  methods: {
    getErrorMessage () {
      const validatorNames = Object.keys(this.field).filter(key => key.charAt(0) !== '$')

      // object with status of every error
      const validatorsState = validatorNames.reduce((acc, name) => {
        acc[name] = this.field[name]
        return acc
      }, {})

      // array of error messages
      const errorMessages = Object.keys(validatorsState).reduce((acc, key) => {
        if (!validatorsState[key]) acc.push(this.messages[key])
        return acc
      }, [])

      // show only one (first) message
      return errorMessages[0]
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-input-label-error-wrapper {
  $root: &;

  display: block;

  &__label {
    display: block;
    // min-height: 20px;

    // Fix to pass Lighthouse
    &--hidden {
      position: absolute;
      top: -1000px;
      left: -1000px;
    }
  }

  &__content {
    position: relative;
  }

  &__info {
    position: absolute;
    top: 100%;
    left: 0;
    height: toRem(15px);
    line-height: toRem(9px);
    display: flex;
  }
}
</style>
