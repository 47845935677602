export default {
  methods: {
    data () {
      return {
        mix_captchaData: null,
      }
    },
    async onFormSubmit () {
      this.$v.$touch()

      this.mix_clearAllServerErrors && this.mix_clearAllServerErrors()

      if (this.$v.$invalid) return

      try {
        this.loading = true
        if (process.client && window.A_T_ACCESS_TOKEN) {
          this.mix_captchaData = {
            a_t_access_token: window.A_T_ACCESS_TOKEN,
          }
          return await this.submit()
        }
        const captchaObject = await this.startCaptcha()
        this.mix_captchaData = captchaObject.getValidate()
        this.mix_captchaData._token = captchaObject._token // _token
        await this.submit()
        captchaObject.reset()
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
  },
}
