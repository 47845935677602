import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { TranslateResult } from 'vue-i18n'
import {
  maxLength as vualidateMaxLength,
  minLength as vualidateMinLength,
  required as vualidateRequired,
} from 'vuelidate/lib/validators'
import { LabeledValue } from '@/data-objects/props/LabeledValue'

type Validator = (
  value: any,
  fields: Hash<any>,
  rootState: any,
  rootGetters: any,
) => void | string | TranslateResult;

Vue.use(Vuelidate)

const custom = (func: Validator) => (value, fields, rootState, rootGetters) => {
  return func(value, fields, rootState, rootGetters)
}

const requiredDropdownValue = (dropdownValue: LabeledValue | null) => !!(dropdownValue && !!dropdownValue.value)
const alphaWithSpaces = (value: string): boolean => /^([a-zA-Z\.,]+\s)*[a-zA-Z\.,]+$/.test(value)
const alphaWithSpacesWithoutSymbols = (value: string): boolean => /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(value)
const alphaWithSpacesWithoutSymbolsAndHyphen = (value: string): boolean => /^([a-zA-Z]+[\s-])*[a-zA-Z]+$/.test(value)
const alphaNumWithSpaces = (value: string): boolean => /^[a-zA-Z0-9\s-\.,]*$/.test(value)
const alphaNumWithSpacesAndSlash = (value: string): boolean => /^[a-zA-Z0-9\/\s-\.,]*$/.test(value)
const atLeastOneLowercase = (v: string) => /(?=.*[a-z])/.test(v)
const atLeastOneUppercase = (v: string) => /(?=.*[A-Z])/.test(v)
const atLeastOneNumeric = (v: string) => /(?=.*[0-9])/.test(v)
const atLeastOneSpecial = (v: string) => /(?=.*[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`'~\\])/.test(v)
const userNameValidator = (value: string): boolean => /^[\S\d_]*$/.test(value)
const onlyLatinLettersOrNumbersAndChars = (value: string): boolean => /^[a-zA-Z0-9!@#$%^&*()+_\\\-=}{[\]|:;"\/?.><,`~]+$/.test(value)
const numbersSpacesHyphenSlash = (value: string): boolean => /^[0-9\s\-\/]*$/.test(value)

const passwordValidations = {
  required: vualidateRequired,
  minLength: vualidateMinLength(8),
  maxLength: vualidateMaxLength(20),
  atLeastOneLowercase,
  atLeastOneUppercase,
  atLeastOneNumeric,
  atLeastOneSpecial,
}

const validators = {
  custom,

  requiredDropdownValue,
  alphaWithSpaces,
  alphaWithSpacesWithoutSymbols,
  alphaWithSpacesWithoutSymbolsAndHyphen,
  alphaNumWithSpaces,
  alphaNumWithSpacesAndSlash,
  atLeastOneLowercase,
  atLeastOneUppercase,
  atLeastOneNumeric,
  atLeastOneSpecial,
  userNameValidator,
  onlyLatinLettersOrNumbersAndChars,
  numbersSpacesHyphenSlash,

  passwordValidations,
}

export default (_, inject) => {
  inject('validators', validators)
}
