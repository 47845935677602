import { DEFAULT_LIMIT } from '@/lib/api'
import { formatNumber, actionWrapper } from '@/lib/utils'
import { bus, notifyError, notifySuccess } from '@/lib/bus'
import { RootState } from '@/store/index'
import { parseRawOrder, parseRawOrders } from '@/store/profile/helpers'
import { Module } from 'vuex'
// import { Map, Stack, merge } from "immutable"; //
// import { Decimal } from 'decimal.js-light'
import debounce from 'lodash/debounce'
import take from 'lodash/take'
import assign from 'lodash/assign'
// import { debounce, take } from "lodash";
// import { $t } from '@/lib/i18n'

export type OrdersMap = { [key in PairName]: Order[] }
// export type OrdersMap = Map<PairName, Stack<Order>>;

type OrderType = {
  tradeType: string
  amount: string
  amountTicker: string
  dealMoney: string
  totalTicker: string
}

export interface OrdersState {
  total: { [key in PairName]: number } | {}
  // total: Map<PairName, number>;
  data: OrdersMap | {}

  allEntries: Order[]
  // allEntries: Stack<Order>;
  // fetchedAllCount: number;

  finishedOrders: OrderType[]

  // loading: boolean;

  showLoadMore: boolean
}

const finishedOrdersNotify = debounce((finishedOrders, i18n) => {
  // // side = 2 -> ПОКУПКА
  // if (order.side === 2) {
  //     // если left = amount, то делать всплывашку крассную что ордер не выполнен
  //     const left = new Decimal(order.left).toFixed(15)
  //     const amount = new Decimal(order.amount).toFixed(15)
  //     if (left === amount) {
  //         bus.$notify({
  //             type: 'error',
  //             title: 'Buy order failed',
  //         })
  //         return
  //     }
  // }

  // // side = 1 -> ПРОДАЖА
  // // Во время продажи Amount = 1 = Deals = left то есть это одна величина
  // if (order.side === 1) {
  //     const left = new Decimal(order.left).toFixed(15)
  //     const amount = new Decimal(order.amount).toFixed(15)
  //     if (left === amount) {
  //         bus.$notify({
  //             type: 'error',
  //             title: 'Sell order failed',
  //         })
  //         return
  //     }
  // }

  // const left = new Decimal(order.left).toFixed(8)
  const parsedText = finishedOrders
    .map(order =>
      i18n.t(`common.notify.finishedOrders.${order.tradeType}`, {
        amount: formatNumber(order.amount),
        amountTicker: order.amountTicker,
        // dealMoney: formatNumber(order.tradeType === 'sell' ? order.dealMoney : order.amount),
        dealMoney: order.dealMoney,
        totalTicker: order.totalTicker,
      }),
    )
    .slice(0, 1)

  // debugger

  if (parsedText.length > 1) {
    parsedText.splice(
      1,
      0,
      '',
      `<b>${i18n.t('common.notify.finishedOrders.historyTitle')}:</b>`,
    )
  }

  // parsedText.splice(1, 0, '', `<b>Left:</b> ${left}`, )
  // console.log('order::', JSON.stringify(order, null, 2))

  // bus.$notify({group: "trade", clean: true} as any);
  bus.$notify({
    title: `<b>${i18n.t('common.notify.finishedOrders.title')}:</b>`,
    text: parsedText.join('<br />'),
    // group: "trade",
    type: 'info',
    duration: 3333,
  })

  // if (
  //   document.visibilityState === 'hidden' &&
  //   Notification.permission === 'granted'
  // ) {
  //   new Notification($t('common.notify.finishedOrders.title') as string, {
  //     body: parsedText[0],
  //     image: `/img/icons/${'C-Patex'}/og-image.png`,
  //   })
  // }
}, 200)

const OrderModule: Module<OrdersState, RootState> = {
  namespaced: true,
  state: () => ({
    // fetchStatus: "init",
    // initialized: false,

    // loadedAll: {},
    // loadedAll: Map(),
    total: {},
    // total: Map(),
    data: {},
    // data: Map(),

    allEntries: [],
    // allEntries: Stack(),
    // fetchedAllCount: 0,

    finishedOrders: [],

    // loading: false,

    showLoadMore: false,
  }),
  mutations: {
    // setProfileDataTotal(state, {total, key, pairName}: { total: number; pairName: PairName; key: keyof ProfileModules }) {
    //     if (key !== "orders") {
    //         return;
    //     }

    //     state.total[pairName] = total;
    //     state.total = { ...state.total }
    //     // state.total = state.total.set(pairName, total);
    // },
    // setFetchStatusProfileData(
    //     state: OrdersState,
    //     { status, key }: { status: FetchStatus; key: keyof ProfileModules }
    // ) {
    //     if (key !== "orders") {
    //         return;
    //     }

    //     state.fetchStatus = status;
    //     state.initialized = state.initialized || (status === "ok" || status === "error") && state.allEntries.length !== 0;
    //     // state.initialized = state.initialized || (status === "ok" || status === "error") && state.allEntries.size !== 0;
    // },
    // setLoadedAllProfileData(state, { pairName, key }: { pairName: PairName | "All", key: keyof ProfileModules }) {
    //     if (key !== "orders") {
    //         return;
    //     }

    //     state.loadedAll[pairName] = true;
    //     state.loadedAll = { ...state.loadedAll }
    //     // state.loadedAll = state.loadedAll.set(pairName, true);
    // },

    // Could be removed after old Trade page remove
    updateOrders (state, { pairName, stack, reset, add }) {
      // when refetch orders
      if (reset) {
        state.data[pairName] = []
      }
      // with pagination
      if (add) {
        state.data[pairName].push(stack)
      } else {
        // without pagination or 0 offset
        state.data[pairName] = stack
      }

      state.data = { ...state.data }

      // if (reset) {
      //     state.data = state.data.set(pairName, stack);
      //     return;
      // }

      // state.data = state.data.update(pairName, prev =>
      //     mergeStacks<Order>(prev, stack, t => t.id, commontSortComparator, isUpdate)
      // );
    },
    // updateAllOrders(state: OrdersState, ordersMap: OrdersMap) {
    //     state.data = ordersMap
    //     // state.data = state.data.merge(
    //     //     ordersMap.map((stack: Stack<Order>, pairName: PairName) =>
    //     //         mergeStacks<Order>(
    //     //             state.data.get(pairName),
    //     //             stack,
    //     //             t => t.id,
    //     //             commontSortComparator
    //     //         )
    //     //     )
    //     // );

    //     const arr = Object.keys(ordersMap).reduce((acc, k) => acc.concat(ordersMap[k]), [])

    //     // const arr = ordersMap.valueSeq().flatten(1).toArray() as Order[];

    //     state.allEntries = state.allEntries.concat(arr.sort(commontSortComparator))
    //     // state.allEntries = state.allEntries.update(prev =>
    //     //     mergeStacks<Order>(
    //     //         prev,
    //     //         arr,
    //     //         t => t.id,
    //     //         commontSortComparator
    //     //     )
    //     // );
    //     state.fetchedAllCount += arr.length;
    //     state.initialized = true;
    // },
    deleteOrder (state: OrdersState, { id, pair, revert }) {
      ;(state.data[pair] || []).forEach((o) => {
        if (o.id === id) o.deleted = !revert
      })

      state.allEntries = state.allEntries.filter(order => order.id !== id)
      // state.data = state.data.update(
      //     pair,
      //     prev => (prev || Stack()).map(o => {
      //         if (o.id === id) {
      //             o.deleted = !revert;
      //         }

      //         return o;
      //     })
      // );
    },
    putOrder (state, { order }) {
      const pair = order.market
      const parsedOrder = parseRawOrder(order)

      const pairOrders = state.data[pair] || []
      pairOrders.unshift(parsedOrder)
      state.data[pair] = pairOrders
      state.data = { ...state.data }

      // state.data = state.data.update(
      //     pair,
      //     stack => (stack || Stack()).unshift(parsedOrder)
      // );

      const total = state.total[pair]
      state.total[pair] = total + 1
      state.total = { ...state.total }
      // state.total = state.total.update(pair, total => total + 1);
      state.allEntries = [parsedOrder, ...state.allEntries] // TODO fix pagination logic and remove all Entries
      // state.allEntries = state.allEntries.unshift(parsedOrder); // TODO fix pagination logic and remove all Entries
    },
    updateOrder (state, { order }) {
      const pair = order.market
      const parsedOrder = parseRawOrder(order)
      const orders = state.data[pair] || []

      orders.forEach((order: Order, ind: number) => {
        if (order.id === parsedOrder.id) {
          orders[ind] = assign(order, parsedOrder)
        }
      })
      state.data = { ...state.data }

      // state.data = state.data.update(
      //     pair,
      //     prev => (prev || Stack()).map(prev => {
      //         if (prev.id === parsedOrder.id) {
      //             return merge(prev, parsedOrder);
      //         }

      //         return prev;
      //     })
      // );
      state.allEntries = state.allEntries.map((prev) => {
        if (prev.id === parsedOrder.id) {
          return assign(prev, parsedOrder)
        }

        return prev
      })
    },
    finishOrder (state, { order }) {
      const pair = order.market
      const parsedOrder = parseRawOrder(order)
      const prevStack = state.data[pair] || ([] as Order[])
      // const prevStack = state.data.get(pair);

      if (prevStack && !prevStack.find(o => o.id === order.id)) {
        return
      }

      prevStack.forEach((order: Order, ind: number) => {
        if (order.id === parsedOrder.id) {
          prevStack[ind] = assign(order, parsedOrder, { deleted: true })
        }
      })
      state.data = { ...state.data }

      // state.data = state.data.update(
      //     pair,
      //     prev => (prev || Stack()).map(prevOrder => {
      //         if (prevOrder.id === parsedOrder.id) {
      //             return merge(prevOrder, parsedOrder, {deleted: true});
      //         }

      //         return prevOrder;
      //     })
      // );
      state.allEntries = state.allEntries.map((prevOrder) => {
        if (prevOrder.id === parsedOrder.id) {
          return assign(prevOrder, parsedOrder, { deleted: true })
        }

        return prevOrder
      })

      const total = state.total[pair]
      state.total[pair] = total ? total - 1 : 0
      state.total = { ...state.total }
      // state.total = state.total.update(pair, total => total ? total - 1 : 0);
    },
    setFinishedOrdersNotification (state, { order }: { order: RawOrder }) {
      console.log('order::', order)
      const tradeType = order.side === 2 ? 'buy' : 'sell'
      const [amountTicker, totalTicker] = order.market.split('_')

      state.finishedOrders.unshift({
        tradeType,
        amount: order.deal_stock,
        amountTicker,
        dealMoney: order.deal_money,
        totalTicker,
      })
    },

    // New mutations
    setShowLoadMore (state, pld: boolean) {
      state.showLoadMore = pld
    },

    setOrders (
      state: OrdersState,
      pld: { formatedRecords: Order[]; nextPage: boolean },
    ) {
      state.allEntries = pld.formatedRecords
    },

    addOrders (
      state: OrdersState,
      pld: { formatedRecords: Order[]; nextPage: boolean },
    ) {
      state.allEntries = [...state.allEntries, ...pld.formatedRecords]
    },
    // toggleLoading(state: OrdersState, pld: boolean) {
    //     state.loading = pld
    // }
  },
  actions: {
    async initProfileOrders ({ dispatch, rootGetters }) {
      const pairName = rootGetters['pairs/getCurrentPairName']
      await Promise.all([
        dispatch('fetchWsProfileOrders', { pairName }),
        this.$api.$subscribeOrders([pairName]),
      ])
    },
    socketOrderUpdate ({ commit, getters }, params: UpdateOrderResponseParams) {
      const order = params[1]

      switch (params[0]) {
        case 1:
          commit('putOrder', { order })
          break
        case 2:
          commit('updateOrder', { order })
          break
        case 3:
          commit('finishOrder', { order })

          commit('setFinishedOrdersNotification', { order })
          finishedOrdersNotify(getters.lastFiveFinishedOrders, this.$i18n)

          break
      }
    },
    fetchWsProfileOrders (...args) {
      return actionWrapper({
        beforeRequestHandler: ({ payload }) => {
          payload.key = 'orders'
        },
        apiRequest: ({ pairName, offset }) =>
          this.$api.$getOrders({ pairName, offset }),
        mutationName: '',
        successHandler: ({
          context: { commit },
          response: { response },
          payload: { pairName, reset, offset },
        }) => {
          const { records } = response

          commit('updateOrders', {
            pairName,
            stack: parseRawOrders(records),
            reset,
            add: offset > 0,
          })
        },
      }).bind(this)(...args)
    },
    deleteAllOrders (...args) {
      return actionWrapper({
        apiRequest: ({ pair }) => this.$api.deleteAllPairOrders(pair || '%20'), // %20 to delete all orders
        mutationName: '',
        successHandler: ({ context: { dispatch }, payload: { pair } }) => {
          // notifySuccess({
          //   title: this.$i18n.t('common.notify.order.deletedAll'),
          // })
          notifySuccess({
            title: pair
              ? this.$i18n.t('trade.orders.ordersOfPairWereDeleted', {
                pair: pair.replace('_', '/'),
              })
              : this.$i18n.t('trade.orders.ordersWereDeleted'),
          })
          dispatch('fetchAllActiveOrders', { pair })
        },
        errorHandler: ({ response }) => {
          notifyError({
            title:
              this.$i18n.t(response?.errors?.message[0]) ||
              'Error deleting orders',
          })
        },
      }).bind(this)(...args)
    },
    deleteOrder (...args) {
      return actionWrapper({
        beforeRequestHandler: ({ context: { commit }, payload }) =>
          commit('deleteOrder', payload),
        apiRequest: payload => this.$api.deleteOrder(payload),
        mutationName: 'deleteOrder',
        successHandler: ({ payload: { pair } }) => {
          // const pairName = pair ? pair.replace('_', '/') : ''
          // const translationProp = pairName
          //   ? this.$i18n.t('common.notify.order.deletedPair', { pairName })
          //   : this.$i18n.t('common.notify.order.deletedAll')
          // notifySuccess({
          //   title: translationProp,
          // })
          notifySuccess({
            title: pair
              ? this.$i18n.t('trade.orders.orderWasDeleted', {
                pair: pair.replace('_', '/'),
              })
              : this.$i18n.t('trade.orders.undefinedOrderWasDeleted'),
          })
        },
        errorHandler: ({ context: { commit }, payload }) => {
          commit('deleteOrder', { ...payload, revert: true })
        },
      }).bind(this)(...args)
    },
    fetchAllActiveOrders (...args) {
      return actionWrapper({
        beforeRequestHandler: ({ context: { state }, payload }) => {
          const offset = payload.page > 1 ? state.allEntries.length : 0
          payload.offset = offset
        },
        apiRequest: ({ pair, offset }) =>
          this.$api.$getOrders({
            pairName: pair || 'all',
            offset,
            limit: DEFAULT_LIMIT,
          }),
        mutationName: '',
        successHandler: ({
          context: { commit },
          response: { response },
          payload: { page },
        }) => {
          const { records, nextpage } = response as any
          // console.log(JSON.stringify(records, null, 2))

          const formatedRecords = records.length ? parseRawOrders(records) : []

          if (page > 1) {
            commit('addOrders', { formatedRecords, nextPage: nextpage })
          } else {
            commit('setOrders', { formatedRecords, nextPage: nextpage })
          }

          const length = records.length

          if (length === 0 || length < DEFAULT_LIMIT) {
            commit('setShowLoadMore', false)
          } else {
            commit('setShowLoadMore', true)
          }
        },
      }).bind(this)(...args)
    },
  },
  getters: {
    lastFiveFinishedOrders (state) {
      return take(state.finishedOrders, 5) || []
    },
    getAllEntries (state) {
      return state.allEntries
    },
  },
}

export default OrderModule
