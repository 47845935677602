












































import Vue from 'vue'

export default Vue.extend({
  name: 'ExcLabeledValue',
  props: {
    label: {
      type: String,
      default: '',
    },
    note: {
      type: String,
      default: '',
    },
    direction: {
      validator: v => v === 'row' || v === 'column',
      default: 'column',
    },
    align: {
      validator: v => ['left', 'center', 'right'].includes(v),
      default: 'left',
    },
  },
})
