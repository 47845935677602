import Decimal from "decimal.js-light"


// currency_id: 2
// main_balance: "444.000000000000000000"
// main_balance_usdt: 4636590.8920092
// ticker: "BTC"

interface CurrencyBalanceModelInterface {
    currency_id: number
    main_balance: string
    main_balance_usdt: number
    ticker: Ticker
    image: string
}

export class CurrencyBalanceModel {
    currencyId: number
    mainBalance: string
    mainBalance_string: string
    mainBalanceUsdt: number
    ticker: Ticker
    image: string
    constructor(o: CurrencyBalanceModelInterface) {
        this.currencyId = o.currency_id
        this.mainBalance = o.main_balance
        this.mainBalance_string = new Decimal(o.main_balance).toFixed(8).toString()
        this.mainBalanceUsdt = o.main_balance_usdt
        this.ticker = o.ticker
        this.image = o.image
    }
}