import Decimal from 'decimal.js-light'
import { WalletBalanceBaseModel } from './WalletBalanceBaseModel'

export class WalletBalanceModel extends WalletBalanceBaseModel {
  fullBalanceBtc: Decimal // 812516.5635262324
  tradePairs: Pair[]

  constructor (o: WalletBalancesResponseItem) {
    super(o)

    this.fullBalanceBtc = new Decimal(o.full_balance_btc || 0)
    this.tradePairs = o.tradePairs || []
  }

  static getDefault ({
    ticker,
    image,
    label,
  }: {
    ticker: Ticker
    image?: string,
    label?: string,
  }) {
    return new WalletBalanceModel({ ticker, image, label } as WalletBalancesResponseItem)
  }
}

export class WalletMarginBalanceModel {
  borrowBalance: Decimal
  currencyId: Decimal
  // debtBalance: Decimal
  platformFee: Decimal
  fullBalance: Decimal
  fullBalanceBtc: Decimal
  image: string // "http://localhost/storage/currency/NLVotAH6RaiUBscU1iPWVnW5nvweiG9SVJnGhYIa.png"
  label: string // "Tether USD"
  mainBalance: Decimal
  marginBalance: Decimal
  creditBalance: Decimal
  ticker: Ticker
  tradeAvailable: Decimal
  marginAvailable: Decimal
  fullBorrowBalance: Decimal
  borrowAvailable: Decimal

  constructor (o: IWalletMarginBalanceItem) {
    this.borrowAvailable = new Decimal(o.borrow_available || 0)
    this.currencyId = new Decimal(o.currency_id || 0)
    this.fullBalance = new Decimal(o.full_balance || 0)
    this.fullBalanceBtc = new Decimal(o.full_balance_btc || 0)
    this.image = o.image || ''
    this.label = o.label || ''
    this.tradeAvailable = new Decimal(o.trade_available || 0)

    this.mainBalance = new Decimal(o.main_balance || 0)
    this.marginBalance = new Decimal(o.margin_balance || 0)
    this.creditBalance = new Decimal(o.credit_balance || 0)
    this.borrowBalance = new Decimal(o.borrow_balance || 0)
    this.platformFee = new Decimal(o.platform_fee || 0)
    this.marginAvailable = new Decimal(o.margin_available || 0)
    this.fullBorrowBalance = new Decimal(o.full_borrow_balance || 0)

    this.ticker = o.ticker
  }

  static getDefault (ticker: Ticker) {
    return new WalletMarginBalanceModel({ ticker } as IWalletMarginBalanceItem)
  }
}

export function getDefaultWalletMarginBalanceModel (ticker: Ticker) {
  return new WalletMarginBalanceModel({ ticker } as IWalletMarginBalanceItem)
}
