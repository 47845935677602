<template>
  <tdx-input-label-error-wrapper
    :field="field"
    :label="label"
    :info-message="infoMessage"
    :custom-error="customError"
    :name="name"
    :form-key="formKey"
    :optional-errors="optionalErrors"
    :label-disabled="labelDisabled"
    :class="`tdx-dropdown${isOpened ? '--active' : ''}`"
  >
    <template #default="slotProps">
      <!-- eslint-disable vue/valid-v-on -->
      <!-- eslint-disable vue/no-parsing-error  -->
      <tdx-base-dropdown
        ref="base-dropdown"
        :value="value"
        :without-border="true"
        :is-opened="isOpened"
        v-bind="{ ...$attrs, ...slotProps, size, options }"
        v-on="{ ...$listeners, input: val => $emit('input', val) }"
        @isOpen="isOpen"
      >
        <!-- Pass through all slots -->
        <!-- Available slots: "empty", "selected", "icon", "item". -->
        <!-- "selected" and "icon" has defaults -->
        <!-- See in BaseDropdown.vue -->
        <template
          v-for="(_, slot) of $scopedSlots"
          #[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </tdx-base-dropdown>
    </template>
  </tdx-input-label-error-wrapper>
</template>

<script>
import { sizeValidator, defaultSize } from '@/lib/utils'
import TdxInputLabelErrorWrapper from '@/components/common/form/validation/TdxInputLabelErrorWrapper.vue'
import TdxBaseDropdown from './TdxBaseDropdown.vue'

export default {
  name: 'DropdownSimple',
  components: {
    TdxBaseDropdown,
    TdxInputLabelErrorWrapper,
  },
  props: {
    value: {
      type: [String, Object],
      default: () => Object(),
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    customError: {
      type: String,
      default: '',
    },
    field: {
      type: Object, // vuelidate field
      default: null,
    },
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
    infoMessage: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => ([]),
    },
    name: {
      type: String,
      default: '',
    },
    formKey: {
      type: String,
      default: '',
    },
    optionalErrors: {
      type: Object,
      default: () => ({}),
    },
    labelDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isOpened: false,
    }
  },
  methods: {
    resetSearchValue () {
      this.$refs['base-dropdown'].resetSearchValue()
    },
    isOpen (val) {
      this.isOpened = val
    },
  },
}
</script>

<style lang="scss" scoped>
.tdx-dropdown {
  &--active {
    border-color: map-get($borderColors, 'transp-004') !important;
  }
}
</style>
