<template>
  <div
    class="exc-two-side-block-layout"
    :class="{'exc-two-side-block-layout--vertical': vertical}"
  >
    <div class="exc-two-side-block-layout__left-side bg--transp-00   pt-20 pr-20 pb-32 pl-20    pt-20--m pr-32--m pl-32--m pb-32--m   pr-64--l pl-64--l">
      <slot name="left" />
    </div>
    <div class="exc-two-side-block-layout__right-side bg--main-10   pt-32 pr-20 pb-32 pl-20    pt-20--m pr-32--m pl-32--m pb-32--m   pr-64--l pl-64--l">
      <div
        :style="!vertical ? `top: ${iconVerticalPosition}%` : ''"
        class="exc-two-side-block-layout__centered-block"
        :class="{ 'exc-two-side-block-layout--with-hover': $listeners && $listeners['click-on-centered-block'] }"
        @click="$emit('click-on-centered-block')"
      >
        <span class="exc-two-side-block-layout__light sha--0005-main">

          <transition name="fade">
            <icon-arrow-reverse v-if="!loading" />
            <exc-loader
              v-else
              class="txt--12px txt--color-08"
            />
          </transition>

        </span>
      </div>
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import ExcLoader from '@/components/common/svg/ExcLoader.vue'
// import IconArrowReverse from 'src/icons/arrows/icon-arrows-reverse.svg'

export default {
  name: 'ExcTwoSideBlockLayout',
  components: {
    ExcLoader,
    // IconArrowReverse,
  },
  props: {
    iconVerticalPosition: {
      type: Number,
      default: 40,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    vertical: { type: Boolean, default: false },
  },
}
</script>
<style lang="scss" scoped>
.exc-two-side-block-layout {
  $root: &;
  width: 100%;
  flex-direction: column;
  display: flex;

  &--vertical {
    @include bp(m) {
      flex-direction: column !important;
    }

    #{$root}__centered-block {
      top: 0 !important;
      transform: translate(-50%,-50%);
      left: 50%;
    }

    #{$root}__left-side,
    #{$root}__right-side {
      width: 100%;
    }
  }

  &--with-hover:hover {
    filter: brightness(1.1);
    cursor: pointer;
  }

  @include bp(m) {
    flex-direction: row;
    text-align: left;
  }

  &__left-side {
    width: 100%;
    flex-direction: column;

    @include bp(m) {
      flex-direction: row;
      width: 50%;
    }
  }

  &__right-side {
    position: relative;
    width: 100%;
    flex-direction: column;

    @include bp(m) {
      width: 50%;
    }

    @include bp(l) {
      flex-direction: row;
    }
  }

  &__light {
    display: flex;
    justify-content: center;
    align-items: center;
    background: color(main);
    width: toRem(28px);
    height: toRem(28px);
    border-radius: 50%;
  }

  &__centered-block {
    position: absolute;
    left: 0;
    transform: translateX(-50%);

    @include bp(0, m) {
      top: 0 !important;
      transform: translate(-50%,-50%);
      left: 50%;
    }
  }
}
</style>
