<template>
  <div
    :id="$attrs.id || uniqueId"
    v-click-outside="onClickOutsideOfDropdown"
    :class="classes"
    class="tdx-base-dropdown"
    @mouseleave="onHoverOnField('mouseleave')"
  >
    <div
      :class="{'tdx-base-dropdown--disabled': disabled}"
      class="tdx-base-dropdown__container pl-20 pr-20"
      @click="onClickOnField"
      @mouseover="onHoverOnField('mouseover')"
    >
      <div
        v-show="!showInput"
        :class="{'is-invalid': $attrs.validation || errors[0]}"
        class="tdx-base-dropdown__form-control txt txt--color-04 txt--16px txt--height-20px pt-20"
      >
        <!-- for empty option -->
        <span
          v-if="!hasSelectedValue && !$scopedSlots.empty"
          class="pr-12 txt txt--14px txt--grey"
        >
          {{ placeholder }}
        </span>
        <slot
          v-if="!hasSelectedValue"
          v-bind="{ size, disabled }"
          name="empty"
        />

        <!-- for selected option(s)  -->
        <slot
          v-if="hasSelectedValue"
          v-bind="{ option: value, labelsByComma, size, disabled }"
          name="selected"
        >
          <tdx-dropdown-item
            class="txt txt--color-04"
            v-bind="{ option: value, labelsByComma, size, disabled }"
          />
        </slot>
      </div>

      <tdx-base-input
        v-show="showInput"
        ref="searchInput"
        v-model="searchValue"
        without-border
        class="tdx-base-dropdown__search"
      />

      <div
        v-if="disabled"
        class="tdx-base-dropdown__disabled-area"
        :class="disabled ? 'bg--transp-00' : ''"
      />
      <small
        v-if="$attrs.validation || errors[0]"
        class="invalid-feedback"
      >
        {{ ($attrs.validation && $attrs.validation.message) || errors[0] }}
      </small>

      <tdx-input-side-icon
        class="tdx-base-dropdown__open-icon"
        @click="onClickOnArrow"
      >
        <!-- <span
          :style="{'transform': `rotate(${isOpened ? '0' : '180'}deg)`}"
          class="flx"
        > -->
        <slot name="icon">
          <rdx-arrow
            :direction="!isOpened ? 'bottom' : 'top'"
            arrow-direction="bottom"
            class="exc-mob-nav-item__arrow"
          />
          <!-- <arrow-icon-black v-else /> -->
        </slot>
        <!-- </span> -->
      </tdx-input-side-icon>
    </div>
    <!-- :class="{'tdx-base-dropdown--closed': !isOpened}" -->
    <transition name="fade">
      <div
        v-if="isOpened"
        class="tdx-base-dropdown__dropdown"
        @mouseover="onHoverOnField('mouseover')"
      >
        <div class="tdx-base-dropdown__options-list txt--color--004 txt--10px pt-8 pb-8 bg--color-01 bor--radius-12">
          <!-- list of options -->
          <div class="tdx-base-dropdown__options-inner">
            <template v-if="outputOptions.length">
              <div
                v-for="option in outputOptions"
                :key="option.id"
                class="tdx-base-dropdown__option txt--color-04 txt--16px txt--weight-500 txt--height-20px"
                @click="onClickSelectOption(option)"
              >
                <!-- list item -->
                <slot
                  v-bind="{ option, size, disabled }"
                  name="item"
                >
                  <tdx-dropdown-item
                    class="txt txt--theme-08 txt--16px txt--weight-500 txt--height-20px"
                    v-bind="{ option, size, disabled }"
                  />
                </slot>
              </div>
            </template>
            <template v-else>
              <div class="tdx-base-dropdown__option txt txt--grey txt--uppercase txt--center">
                {{ $t('common.noItems') ? $t('common.noItems') : 'no items' }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { sizeValidator, defaultSize, uniqueId, wait, onlyThemePropValidator } from '@/lib/utils'
import ClickOutside from 'vue-click-outside'
import TdxInputSideIcon from '@/components/common/form/elements/TdxInputSideIcon.vue'
import RdxArrow from '@/components/common/svg/RdxArrow.vue'
import TdxDropdownItem from '@/components/common/form/dropdown/TdxDropdownItem.vue'
import TdxBaseInput from '@/components/common/form/input/ExcBaseInput.vue'

const bool = {
  type: Boolean,
  default: false,
}
export default {
  name: 'TdxBaseDropdown',
  components: {
    TdxInputSideIcon,
    TdxDropdownItem,
    TdxBaseInput,
    RdxArrow,
  },
  directives: {
    ClickOutside,
  },
  props: {
    value: {
      type: [String, Object],
      default: () => Object(),
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
    onlyTheme: onlyThemePropValidator(),
    disabled: bool,
    // searchable: bool,
    withoutBorder: bool,
    withoutArrow: bool,
    dropdownOnHover: bool,
    hasError: bool,
    openUp: bool,
    placeholder: {
      type: String,
      default: '',
    },
    searchBy: {
      type: [String, Array],
      default: '',
    },
  },
  data () {
    return {
      searchValue: '',
    }
  },
  computed: {
    uniqueId () {
      return uniqueId('tdx-base-dropdown_')
    },
    isMultiselect () {
      return Array.isArray(this.value)
    },
    classes () {
      return {
        // 'tdx-base-dropdown--fixed-width': !this.autoWidth,
        [`tdx-base-dropdown--${this.size}`]: true,
        'bor--all': true,
        'bor--transp': this.withoutBorder,
        'bor--transp-02 bor--radius-01': !this.withoutBorder && !this.isOpened,
        'bor--main sha--004-main bor--radius-01': !this.withoutBorder && this.isOpened,
        'tdx-base-dropdown--without-arrow': this.withoutArrow,
        'tdx-base-dropdown--dropdown-on-hover': this.dropdownOnHover,
        'tdx-base-dropdown--open-up': this.openUp,
        'tdx-base-dropdown--open-down': !this.openUp,
        // 'bor--red': !this.withoutBorder && this.hasError && !this.isOpened,
      }
    },
    labelsByComma () {
      if (this.isMultiselect && (this.value).length) {
        // formats to "first, second (+3)"
        const labels = (this.value).map(v => v.label)
        const moreString = labels.slice(2).length ? ` (+${labels.slice(2).length})` : ''
        return labels.slice(0, 2).join(', ') + moreString
      }
      return ''
    },
    hasSelectedValue () {
      const whenSingle = !this.isMultiselect && !!this.value
      const whenMultiple = this.isMultiselect && !!(this.value).length
      return whenMultiple || whenSingle
    },
    showInput () {
      return this.searchBy ? this.isOpened : false
    },
    outputOptions () {
      if (this.searchBy && Array.isArray(this.searchBy)) {
        return this.options.filter(option => this.searchBy.some(searchField => option[searchField].toLowerCase()
          .includes(this.searchValue.toLowerCase())))
      }
      return this.searchBy
        ? this.options.filter(option => option[this.searchBy].toLowerCase()
          .includes(this.searchValue.toLowerCase()))
        : this.options
    },
  },
  watch: {
    value () {
      this.resetSearchValue()
    },
    isOpened (v) {
      if (v && this.showInput) {
        this.$nextTick(() => this.$refs.searchInput.$el.focus())
      }
    },
  },
  methods: {
    resetSearchValue () {
      this.searchValue = ''
    },
    onClickSelectOption (option) {
      if (!this.isMultiselect) {
        this.$emit('input', option)
        this.$emit('close')
        this.$emit('isOpen', false)
      }
    },
    async onClickOnField () {
      if (!this.disabled) {
        // stay opened if clicked on search
        this.$emit('isOpen', this.searchBy ? true : !this.isOpened)
        if (this.isOpened) {
          this.$emit('open')
        } else {
          return
        }
      }
      if (this.searchBy && this.isOpened) {
        await wait(444)
        this.$refs.searchInput.$el.focus()
      }
    },
    onClickOnArrow (ev) {
      // close on arrow only for searchable dropdown
      if (!this.disabled && this.searchBy) {
        if (this.isOpened) {
          ev.stopPropagation()
          this.$emit('close')
          this.$emit('isOpen', false)
        } else {
          this.$emit('open')
          this.$emit('isOpen', true)
        }
      }
    },
    onClickOutsideOfDropdown () {
      if (this.isOpened && !this.disabled) {
        this.$emit('close')
        this.$emit('isOpen', false)
      }
    },
    onClickOnEmptyOption () {
      if (!this.disabled) {
        this.$emit('isOpen', false)
        this.$emit('input', false)
      }
    },
    onHoverOnField (action) {
      // mouseover | mouseleave
      // TODO: fix when on mobile user have to click twice
      if (this.dropdownOnHover) {
        if (action === 'mouseover') {
          !this.isOpened && this.$emit('open')
          this.$emit('isOpen', true)
        } else if (action === 'mouseleave') {
          this.$emit('close')
          this.$emit('isOpen', false)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.tdx-base-dropdown {
  $root: &;
  $optionsHeight: toRem(200px);
  display: flex;
  flex-direction: column;

  &__search {
    max-width: toRem(95px);
  }

  // z-index: 999;

  &--disabled {
    position: relative;
  }

  &--without-arrow {
    #{$root}__open-icon {
      display: none;
    }
  }

  &--open-up {
    #{$root}__dropdown {
      order: 1;
    }

    #{$root}__options-list {
      bottom: 1px;

      .bor--width-0px & {
        bottom: 0;
      }
    }
  }

  &--open-down {
    #{$root}__dropdown {
      order: 3;
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      z-index: 10;
    }

    #{$root}__options-list {
      top: 1px;

      .bor--width-0px & {
        top: 0;
      }
    }
  }

  // &__label {}

  &__container {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    order: 2;
  }

  &__form-control {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }

  &__open-icon {
    margin-left: auto;
    display: flex;
  }

  &__dropdown {
    // position: relative;
  }

  &__options-list {
    position: absolute;

    // this is when it has border
    // top: 1px;
    left: -1px;
    right: -1px;
    height: auto;
    z-index: 100;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.3);

    // when dropdown without border
    .bor--width-0px & {
      // top: 0;
      left: 0;
      right: 0;
    }
  }

  &__options-inner {
    overflow-y: auto;
    height: auto;
    max-height: $optionsHeight;
    @include webkit-scroll();
  }

  &__option {
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: color(main-transp);
      color: color(main);
    }
  }

  &__disabled-area {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: not-allowed;
    // background-color: rgba(138, 138, 138, 0.1);
  }

  $sizes: (
    xl: (
      'height': toRem(map-get($uiKitSizes, xl)),
    ),
    l: (
      'height': toRem(map-get($uiKitSizes, l)),
    ),
    m: (
      'height': toRem(map-get($uiKitSizes, m)),
    ),
    s: (
      'height': toRem(map-get($uiKitSizes, s)),
    ),
    xs: (
      'height': toRem(map-get($uiKitSizes, xs)),
    ),
    // xxs: (
    //   'height': toRem(map-get($uiKitSizes, xxs)),
    // )
  );

  @each $size, $params in $sizes {
    &--#{$size} {
      @each $prop, $value in $params {
        #{$prop}: $value;

        // &#{$root}--open-up {
        //     & #{$root}__options-list {
        //         top: calc(-#{$optionsHeight} - #{$value});
        //     }
        // }
      }

      #{$root}__open-icon {
        // padding-right: toRem(map-get($uiKitSizes, $size) / 3);
      }
    }
  };
}
</style>
