<template>
  <confirm-modal-container
    v-if="data.show"
    :container-class="'col-4'"
    class="confirmation-modal"
    @close="handleCancel"
  >
    <template v-if="data.confirmationImg" #info>
      <div class="confirmation-modal__img-container flx flx flx--center">
        <img
          :src="require(`@/assets/img/confirmation/${data.confirmationImg}`)"
        >
      </div>
    </template>

    <template #header>
      {{ data.title || $t('common.confirmationModal.emptyTitle') }}
    </template>

    <template v-if="data.disclaimer" #disclaimer>
      <div
        class="confirmation-modal__content mb-26 mt-6 txt--weight-400 txt--color-04 txt--16px txt--height-20px"
        :class="data.contentClassName"
        v-html="data.text"
      />

      <tdx-info-block
        icon-position="top"
        class="confirmation-modal__disclaimer pt-10 pb-10 pl-20 pr-20"
        theme-color="red"
        icon-theme="main"
      >
        <template #icon>
          <info-icon />
        </template>
        <template #content>
          <div>
            <!--            <span class="mb-4 txt&#45;&#45;theme-08 txt&#45;&#45;height-20px txt&#45;&#45;weight-600 txt txt&#45;&#45;18px">-->
            <!--              {{ $t('common.confirmationModal.note') }}-->
            <!--            </span>-->
            <p
              class="txt--weight-400 txt--color-04 txt--14px txt--height-18px mb-0"
            >
              {{ data.disclaimer || $t('common.confirmationModal.emptyText') }}
            </p>
          </div>
        </template>
      </tdx-info-block>
    </template>

    <template #content>
      <div
        v-if="data.text && !data.disclaimer"
        class="confirmation-modal__content txt--color-04 txt--16px txt--height-20px"
        :class="[data.className, data.cryptoCard ? 'mb-10' : 'mb-26']"
        v-html="data.text"
      />
      <div v-if="data.cryptoCard" class="confirmation-modal__crypto-card-content bg--color-01 bor--all bor--transp-02 bor--radius-100 pl-10 pr-10 pt-8 pb-8 flx flx--items-center">
        <span class="old-price txt--transp-01 txt--14px txt--weight-700 txt--height-20px mr-6">
          20 PATEX
        </span>
        <span class="txt--color-06 txt--18px txt--weight-800 txt--height-20px mr-6">
          5 PATEX
        </span>
        <div class="bg--color-09 bor--radius-90 pt-8 pb-8 pl-12 pr-12 flx flx--items-center flx--content-center ml-auto">
          <span class="txt--color-04 txt--14px txt--weight-700 txt--height-20px">
            75% {{ $t('cryptoCards.topSection.confirmationModal.discount') }}
          </span>
        </div>
      </div>
    </template>

    <template #buttons>
      <exc-button
        v-if="!data.hideCancelBtn"
        class="confirmation-modal__button"
        type="button"
        size="s"
        color="dark"
        outline
        @click.native="handleCancel"
      >
        <span>
          {{
            data.cancelButtonText || $t('common.confirmationModal.btn.cancel')
          }}
        </span>
      </exc-button>
      <exc-button
        class="confirmation-modal__button"
        type="button"
        size="s"
        @click.native="handleOk"
      >
        <span>
          {{
            data.confirmButtonText || $t('common.confirmationModal.btn.confirm')
          }}
        </span>
      </exc-button>
    </template>
  </confirm-modal-container>
</template>

<script>
import { ConfirmationModal } from '@/lib/ConfirmationModal.ts'
import { ExcButton } from '@/components/common'

export default {
  name: 'ConfirmationModal',
  components: {
    InfoIcon: () =>
      import(
        /* webpackMode: "eager" */ '@/assets/icons/errors/rdx-info-icon.svg?inline'
      ),
    TdxInfoBlock: () =>
      import(
        /* webpackMode: "eager" */ '@/components/ui-kit/info-blocks/TdxInfoBlock.vue'
      ),
    ConfirmModalContainer: () =>
      import(
        /* webpackMode: "eager" */ '@/components/containers/ConfirmModalContainer.vue'
      ),
    ExcButton,
  },
  data () {
    return {
      data: ConfirmationModal.data(),
    }
  },

  methods: {
    handleCancel () {
      ConfirmationModal.reject()
    },
    handleOk () {
      ConfirmationModal.confirm()
    },
  },
}
</script>
<style lang="scss" scoped>
.confirmation-modal {
  &__img-container img {
    width: 100%;
  }

  &__modal-content {
    display: flex;
    font-size: toRem(17px);
  }

  &__disclaimer {
    span {
      display: inline-block;
    }
  }

  &__button {
    max-width: 100%;
  }

  ::v-deep .tdx-info-block__container {
    align-items: center !important;
  }

  .old-price {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      bottom: 50%;
      margin: auto 0;
      left: 0;
      right: 0;
      width: 100%;
      height: toRem(1px);
      background: #E27373;
    }
  }
}

::v-deep .Modal__container {
  min-width: toRem(374px);
}
</style>
