<template>
  <div
    :class="{
      'is-dark': darkTheme,
    }"
    class="base-layout"
    :style="$store.state.darktheme.variableStyles"
  >
    <div v-if="!isHiddenHeader" id="base-header" class="base-layout__header">
      <exchange-header
        :is-transparent="isTransparentLayout"
        :is-trade="isExchange"
        only-theme="light"
        :class="{
          'base-layout__header-header--application-available': mobileApplicationAvailable,
        }"
        @select-language="() => (selectLanguageModalShown = true)"
        @show-listing-modal="() => (isShowListingModal = true)"
      />
    </div>

    <main
      :class="{
        'base-layout__main--no-header': isHiddenHeader,
        'bg--color-01': footerWithoutShadow,
        'bg--color-02': !footerWithoutShadow,
      }"
      class="base-layout__main"
    >
      <div class="base-layout__container">
        <client-only>
          <notifications
            class="base-layout__notifications"
            position="top right"
            width="min(400px, 98vw)"
          >
            <template #body="{ item, close }">
              <notification-general
                :item="item"
                class="mb-8 mr-4"
                @close="close"
              />
            </template>
          </notifications>
          <notifications
            group="bottom"
            ignore-duplicates
            position="bottom center"
            width="100%"
          >
            <template #body="{ item, close }">
              <notification-cookies
                :item="item"
                class=""
                @close="onClickCloseCookies(close)"
              />
            </template>
          </notifications>
        </client-only>

        <nuxt />

        <div
          v-show="showScrollBtn"
          class="base-layout__scroll-btn"
          @click="scrollToTop"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 7L12 21"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4 3L20 3"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.99998 12L12.001 6.999L17.001 12"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <!-- <base-footer /> -->
      <div
        v-if="!isHiddenFooter"
        class="base-layout__footer-container"
        :class="{
          'base-layout__footer-container--lifted-up': footerIsliftedUp,
          // 'sha--03': isExchange,
          'bor bor--top bor--transp-02': isExchange,
        }"
      >
        <exchange-footer
          :class="{
            'sha--03': !isExchange,
          }"
          class="base-layout__footer"
          only-theme="light"
        />
      </div>
    </main>

    <confirmation-modal />

    <select-language-modal
      :show="selectLanguageModalShown"
      @close="() => (selectLanguageModalShown = false)"
    />

    <apply-for-listing-modal
      :show="isShowListingModal"
      @close="() => (isShowListingModal = false)"
    />

    <!-- <zendesk-chat-footer-bumper
      v-if="!isHiddenZendeskChat"
    >
      <zendesk-chat-launcher
        :class="{'base-layout--zendesk-on-trade': true}"
      />
    </zendesk-chat-footer-bumper> -->
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { setLocale } from '@/lib/localization'
import tabSyncMixin from '@/mixins/tabSyncMixin'
import ws from '@/lib/websocket'
import '@/lib/setupAppConfig'

if (process.client) {
  window.WebFontConfig = {
    google: { families: ['Mulish:300,400,500,700'] }, // 'Open+Sans:300,400,700'
  }
  ;(() => {
    const wf = document.createElement('script')
    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js'
    wf.type = 'text/javascript'
    wf.async = 'true'
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(wf, s)
  })()
}

export default {
  name: 'Default',
  components: {
    SelectLanguageModal: () =>
      import('@/components/modals/SelectLanguageModal.vue'),
    NotificationGeneral: () =>
      import(
        /* webpackMode: "eager" */ '@/components/notifications/NotificationGeneral.vue'
      ),
    NotificationCookies: () =>
      import(
        /* webpackMode: "eager" */ '@/components/notifications/NotificationCookies.vue'
      ),
    ExchangeHeader: () =>
      import(
        /* webpackMode: "eager" */ '@/components/header/ExchangeHeader.vue'
      ),
    ExchangeFooter: () =>
      import(
        /* webpackMode: "eager" */ '@/components/footer/ExchangeFooter.vue'
      ),
    ConfirmationModal: () =>
      import(
        /* webpackMode: "eager" */ '@/components/modals/ConfirmationModal.vue'
      ),
    // ZendeskChatLauncher: () => import(/* webpackMode: "eager" */ '@/components/chat/ZendeskChatLauncher.vue'),
    // ZendeskChatFooterBumper: () => import(/* webpackMode: "eager" */ '@/components/chat/ZendeskChatFooterBumper.vue'),
    ApplyForListingModal: () => import(/* webpackMode: "eager" */ '@/components/modals/ApplyForListingModal.vue'),
  },
  mixins: [tabSyncMixin],
  data () {
    return {
      isShowListingModal: false,
      selectLanguageModalShown: false,
      mobileApplicationAvailable: false,
    }
  },
  async fetch () {
    // await this.$store.dispatch('profile/auth/restoreAuth')
    await this.$store.dispatch('globals/getSystemParams')
    // await this.$store.dispatch('pairs/getInitialPairsData')
    if (
      !this.$store.state.globals.languages.find(
        locale => locale.code === this.$i18n.locale,
      )
    ) {
      this.$i18n.setLocale(this.$i18n.defaultLocale)
    }
  },
  head () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
        prefix: 'og: http://ogp.me/ns#',
      },
    }
  },
  computed: {
    ...mapState({
      isLogined: state => state.auth.loggedIn,
      darkTheme: state => state.darktheme.enabled,
    }),
    isTransparentLayout () {
      const items = ['index']
      return items.includes(this.getRouteBaseName())
    },
    isHiddenFooter () {
      const items = [
        // 'not-found',
        'trading-view-left-right',
        'maintenance',
        // 'login',
        // 'register',
        // 'reset-password',
      ]
      return items.includes(this.getRouteBaseName())
    },
    footerIsliftedUp () {
      const items = [
        'index',
        'airdrop',
        'about-us',
        'token',
        'crypto-campus',
        'latin-america',
        'business',
        'network',
        'crypto-cards',
      ]
      return items.includes(this.getRouteBaseName())
    },
    footerWithoutShadow () {
      const items = ['exchange-left-right', 'demo-exchange-left-right']
      return items.includes(this.getRouteBaseName())
    },
    isExchange () {
      return this.footerWithoutShadow
    },
    showScrollBtn () {
      const items = [
        'terms-of-service',
        'risks-disclosure-statement',
        'legal-notice',
        'terms-of-use',
        'privacy-policy',
        'privacy-notice',
        'airdrop-terms',
        'airdrop-privacy',
      ]
      return items.includes(this.getRouteBaseName())
    },
    isHiddenHeader () {
      const items = ['merchant', 'trading-view-left-right', 'maintenance']
      return items.includes(this.getRouteBaseName())
    },
    isHiddenZendeskChat () {
      const items = ['merchant', 'trading-view-left-right', 'maintenance']
      return items.includes(this.getRouteBaseName())
    },
    isHiddenMobileBar () {
      const items = ['merchant', 'trading-view-left-right']
      return items.includes(this.getRouteBaseName())
    },
    isHiddenCookiesNotification () {
      return [].includes(this.getRouteBaseName())
    },
  },
  watch: {
    '$store.state.globals.isMaintenance' (value) {
      if (value && process.client) this.$router.push('/maintenance')
    },
    isLogined: {
      async handler (v) {
        if (v && process.client) {
          // TODO: Find better place?
          const socketToken = this.$auth.$storage.getUniversal('socketToken')
          if (socketToken && process.client) {
            ws.setToken(socketToken)
            ws.logout()
            ws.authorize()
          }

          await Promise.all([
            this.$store.dispatch('wallet/getWalletBalances'),
            this.$store.dispatch('wallet/getWalletMarginBalances'),
            this.$authentication.fetchUser().then((error) => {
              console.log('IS LOGINED', error)
              if (error && error.status === 401) this.logout()
            }),
            this.$store.dispatch('settings/verification/actionKycStatus'),
          ])

          // // TODO: Find better place?
          // const socketToken = this.$auth.$storage.getUniversal('socketToken')
          // if (socketToken && process.client) {
          //   ws.setToken(socketToken)
          //   ws.logout()
          //   await ws.authorize()
          // }
        }
      },
      immediate: true,
    },
    '$i18n.locale': {
      immediate: true,
      handler (locale) {
        setLocale(this, locale)
      },
    },
  },
  beforeCreate () {
    if (this.$store.state.globals.isMaintenance) {
      this.$router.push('/maintenance')
    }
  },
  created () {
    if (this.$route.path === this.localePath('/user')) {
      return this.localePush('/user/profile')
    }

    if (!process.client) return

    this.getInitialPairsData()
    document.addEventListener('visibilitychange', this.checkIsLoggedIn)
    if (this.$store.state.globals.isMaintenance) {
      this.$router.push('/maintenance')
    }
  },
  mounted () {
    if (
      process.client &&
      !this.isHiddenCookiesNotification &&
      !localStorage.getItem('cookies-is-closed')
    ) {
      setTimeout(() => {
        this.$notify({
          group: 'bottom',
          duration: 9999999999,
        })
      })
    }

    // setTimeout(() => {
    //   this.$notify({
    //     type: 'success',
    //     title: 'ERROR message',
    //     text: '1312313123',
    //     duration: 9999999999,
    //   })
    // }, 2000)
  },
  methods: {
    ...mapActions({
      getInitialPairsData: 'pairs/getInitialPairsData',
      getTickerList: 'globals/getTickerList',
      logout: 'profile/auth/logout',
    }),
    checkIsLoggedIn () {
      if (!document.hidden) {
        this.$authentication.fetchUser().then((error) => {
          console.log('CHECK IS LOGINED', error)
          if (error && error.status === 401) this.logout()
        })
      }
    },
    onClickCloseCookies (close) {
      localStorage.setItem('cookies-is-closed', true)
      close()
    },
    scrollToTop () {
      if (!window) return
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss">
html {
  font-family: $defaultFontFamily;
  font-display: swap;
  scrollbar-width: thin; // for mozilla

  font-size: 100vw / (375 / 16); // for iphone 5 same as on 375

  @include bp(s) {
    font-size: 16px;
  }

  @media (min-width: 1366px) and (max-width: 1440px) {
    font-size: 100vw / (1440 / 16); // for low quality notebooks with 1366 - same as for 1440
  }

  @include bp(xl) {
    font-size: 16px;
  }
}

body {
  font-family: $defaultFontFamily;

  &.disable-scroll {
    position: relative;
    overflow: hidden;
  }

  // hide default zendesk activate button
  #launcher {
    visibility: hidden;
  }

  // we are adding small scroll for page scroll
  @include webkit-scroll();
  // + add color from myself
  // background-color: var(--c-block-bg);
  overflow-x: hidden;

  @include var(background-color, --c-block-bg);

  caret-color: currentColor;
}

// delete ios styles
button,
a,
input,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: none !important;
}

// вот где эта сука TODO: remove this a styles
a {
  color: color('color-04');
  transition: all 0.2s;

  &:hover,
  &:focus,
  &:active {
    color: color(main);
    text-decoration: none;
  }
}

ul,
ol {
  padding-left: 0;
  margin: 0;
}

h2 {
  font-size: 20px;
  margin-bottom: 1rem;

  @include var(color, c-main-text);
}

h3 {
  font-size: 15px;
  margin-bottom: 15px;

  @include var(color, c-main-text);
}

.inline-block {
  display: inline-block;
}
</style>

<style lang="scss" scoped>
.base-layout {
  $root: &;

  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include var(background-color, c-main-bg);

  &--zendesk-on-trade {
    //display: none;

    @include bp(m) {
      display: block;
    }
  }

  &__notifications {
    z-index: $z-notifications;
  }

  &__header {
    position: fixed;
    width: 100%;
    z-index: $z-Header;
  }

  &__main {
    padding-top: calc(#{$h-Header} - 1px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-size: cover;
    min-height: 100vh;

    &--no-header {
      padding-top: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__scroll-btn {
    cursor: pointer;
    width: toRem(54px);
    height: toRem(54px);
    border-radius: 50%;
    background: color('color-04');
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 5000;
    right: toRem(16px);
    bottom: toRem(20px);

    @include bp(l) {
      display: none;
    }
  }

  &__footer-container {
    &--lifted-up {
      margin-top: toRem(-20px);

      @include bp(l) {
        margin-top: toRem(-60px);
      }
    }
  }

  &__footer {
    border-radius: toRem(30px) toRem(30px) 0 0;

    @include bp(l) {
      border-radius: toRem(60px) toRem(60px) 0 0;
    }
  }

  ::v-deep {
    .vue-notification-group {
      margin-top: calc(#{$h-Header} + 5px);
    }

    #{$root}__header-header--application-available {
      .exc-header-mob--opened {
        max-height: calc(100vh - #{$h-Header} - #{$h-MobileAvailableBar});
      }

      .exc-header-mob__mob-nav-bg {
        // height: calc(100vh - #{$h-Header} - #{$h-MobileAvailableBar});
        // top: calc(#{$h-Header} + #{$h-MobileAvailableBar});
      }
    }
  }
}

#copy-input-wrapper {
  position: fixed;
  top: 50vh;
  z-index: -1;
}
</style>
