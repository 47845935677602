<template>
  <div
    class="exc-footer"
  >
    <div>
      <div class="exc-footer__container flx--content-between pb-40 pt-40 pt-0--l pb-0--l">
        <div class="exc-footer__logo">
          <div class="exc-footer__logo-container">
            <slot name="logo">
              <exc-link
                no-decoration
                :to="localePath('/')"
              >
                <exc-full-logo aria-label="C-Patex" />
              </exc-link>
            </slot>
          </div>
          <slot name="logo-content" />
        </div>
        <exc-footer-nav
          v-for="column in navLinks"
          :key="column.title"
          :title="column.title"
          :list="column.list"
          :only-theme="onlyTheme"
        >
          <template #default="{ footerItem }">
            <exc-link
              v-bind="{ ...footerItem, name: undefined }"
              no-decoration
              class="exc-footer__link txt--color-04 flx txt--16px txt--height-20px"
            >
              {{ footerItem.name }}
            </exc-link>
          </template>
        </exc-footer-nav>
        <!-- DESKTOP SLOT -->
        <slot name="desktop" />
      </div>

      <div class="exc-footer__mobile-logo">
        <slot name="logo">
          <exc-mob-nav-item
            :to="'/'"
            class="exc-footer-mobile__logo mt-8 txt--color-09"
          >
            <template #name>
              <exc-full-logo aria-label="C-Patex" />
            </template>
          </exc-mob-nav-item>
        </slot>
        <slot name="logo-content" />
      </div>

      <!-- MOBILE SLOT -->
      <div class="exc-footer__mobile-apps">
        <slot name="mobile" />
      </div>

      <exc-footer-mobile
        :only-theme="onlyTheme"
        :nav-links="navLinks"
        class="exc-footer__mobile"
      >
        <template #mobile-logo />
      </exc-footer-mobile>
    </div>

    <div>
      <slot name="bottomBlock">
        <span
          class="txt--color-03 txt--16px txt--height-20px"
        >

          © Copyright {{ new Date().getFullYear() }} EarnBit.com All rights reserved
        </span>
      </slot>
    </div>
  </div>
</template>

<script>
import ExcLink from '@/components/common/navigation/link/ExcLink.vue'
import ExcFooterNav from '@/components/common/footer/ExcFooterNav.vue'
import ExcFooterMobile from '@/components/common/footer/ExcFooterMobile.vue'
import ExcFullLogo from '@/components/common/svg/ExcFullLogo.vue'
import ExcMobNavItem from '@/components/common/header/parts/ExcMobNavItem.vue'
import { onlyThemePropValidator } from '@/lib/utils'

export default {
  name: 'ExcFooter',
  components: {
    ExcLink,
    ExcFooterNav,
    ExcFooterMobile,
    ExcFullLogo,
    ExcMobNavItem,
  },
  props: {
    onlyTheme: onlyThemePropValidator(),
    navLinks: { type: Array, required: true },
  },
}
</script>

<style lang="scss" scoped>
.exc-footer {
  &__container {
    display: none;
    @include bp(l) {
      display: flex;
      gap: toRem(16px);
    }
  }

  &__mobile {
    @include bp(l) {
      display: none;
    }
  }

  &__mobile-logo {
    @include bp(l) {
      display: none;
    }
  }

  &__mobile-apps {
    @include bp(l) {
      display: none;
    }
  }

  .exc-link:hover {
    color: color(main);
    cursor: pointer;
  }
}
</style>
