import Decimal from 'decimal.js-light'

export class WalletBalanceBaseModel {
  currencyId: number
  image: string //  "http://localhost/storage/currency/MQ4YqynHxjo3simPrUg3dsErS10XvKMAEwIehXsq.png"
  label: string // "Bitcoin"
  shortLabel: string // "Bitcoin..."
  ticker: Ticker //  "BTC"
  isDefi: boolean //  0 // для фильтра
  isErc20: boolean //  0 // для фильтра
  isFiat: boolean //  0
  isCrypto: boolean
  isInt: boolean //  0 // только целые числа в форме можно воодить
  isMemo: boolean //  0 // выводить второе поле для мемо
  isTop: boolean //  1 // для списка главных монет для быстрого поиска
  isDemo: boolean
  isDepositOn: boolean //  1 // можно вносить или нет
  isWithdrawOn: boolean //  1 // возможен вывод или нет
  fullBalance: Decimal //  "811979.363840672381026205" // Total Balance:
  mainBalance: Decimal // "499566.253373402381026205" // Ready to withdraw:
  tradeAvailable: Decimal //  "312413.11046727" // On trade:
  tradeBalance: Decimal //  "312413.110467270000000000" // сумма аваилабл и фриз
  tradeFreeze: Decimal //  "0" // In Orders:
  withdrawalLimit?: Decimal | Boolean //  "0" // Available withdrawper 24h for not verified users
  isUnverifiedAbleWithdraw: boolean
  action: PaymentActionType

  constructor (o: IWalletBalanceItem) {
    const label = o.label || ''
    this.action = o.action || 'DEPOSIT'
    this.currencyId = o.currency_id || 0
    this.label = label || ''
    this.shortLabel = label.length > 15 ? `${label.substr(0, 14)}...` : label
    this.image = o.image || '/img/balance/coinDefaultIcon.png'
    this.ticker = o.ticker || '' as Ticker
    this.isDefi = !!o.is_defi //  0 // для фильтра
    this.isErc20 = !!o.is_erc20 //  1 // можно вносить или нет
    this.isFiat = !!o.is_fiat //  0 // для фильтра
    this.isCrypto = !o.is_fiat
    this.isInt = !!o.is_int //  0
    this.isMemo = !!o.is_memo //  0 // только целые числа в форме можно воодить
    this.isTop = !!o.is_top //  0 // выводить второе поле для мемо
    this.isDemo = o.ticker.charAt(0) === 'd'
    this.isDepositOn = !!o.is_depositable //  1 // для списка главных монет для быстрого поиска
    this.isWithdrawOn = !!o.is_withdrawal //  1 // возможен вывод или нет
    this.fullBalance = new Decimal(o.full_balance || 0) //  "811979.363840672381026205" // Total Balance:
    this.mainBalance = new Decimal(o.main_balance || 0) // "499566.253373402381026205" // Ready to withdraw:
    this.tradeAvailable = new Decimal(o.trade_available || 0) //  "312413.11046727" // On trade:
    this.tradeBalance = new Decimal(o.trade_balance || 0) //  "312413.110467270000000000" // сумма аваилабл и фриз
    this.tradeFreeze = new Decimal(o.trade_freeze || 0) //  "0" // In Orders:
    this.withdrawalLimit = new Decimal(o.withdrawalLimit || 0) //  "0" // Available withdrawper 24h for not verified users
    this.isUnverifiedAbleWithdraw = !!o.isUnverifiedAbleWithdraw
  }
}
