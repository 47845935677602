export default function (to, from, savedPosition) {
  const toName = to.name.split('___')[0]
  const fromName = from.name.split('___')[0]

  let rv = savedPosition || (toName !== fromName
    ? { x: 0, y: 0, behavior: 'smooth' }
    : null)

  if (to.hash) {
    rv = {
      selector: to.hash,
      behavior: to.query._behavior,
    }
  }

  if (!process.client) return rv

  const timeout = to.query._timeout
  const isMobile = window.innerWidth < 768
  // eslint-disable-next-line eqeqeq
  return new Promise(resolve => setTimeout(() => resolve(rv), timeout || (isMobile ? 250 : 0)))
}
