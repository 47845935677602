import {
  actionWrapper,
  getDefaultPairObject,
  getherPairsFromMarketTabs,
  // parseNumber,
  // parseResponseFromMarket,
  // updateTitle,
  wait,
} from '@/lib/utils'
import { Module } from 'vuex'
// import api from '@/lib/api'
// import { bus } from '@/lib/bus'
// import { parseResponseFromMarket } from "./welcome";
import Vue from 'vue'
// import { i18n } from "@/lib/bootstrap";
import { ACTIVE_PAIR_KEY, MARKET_LIST_PAIRS_DATA } from '@/constants'
import { filterByLeftTicker, filterByRightTicker } from '@/lib/helpers'
// import currency from "@/store/currency";
// import { LabeledValue } from '@/data-objects/props/LabeledValue'
// import { BLOCKED_BUY_SELL_PRICE_TICKERS } from '@/constants'

import merge from 'lodash/merge'
import get from 'lodash/get'
import set from 'lodash/set'
import uniqBy from 'lodash/uniqBy'

// import { PairModel } from '@/lib/data-models/pairs/PairModel'
// import memoize from "fast-memoize";
// import shortNumberModule from 'short-number'
import { MarketListPair } from '@/lib/data-models/pairs/MarketListPair'
import { MarketListTab } from '@/lib/data-models/pairs/MarketListTab'
import { RootState } from '.'
import {
  BUY_SELL_FORM_LIMIT,
  BUY_SELL_FORM_OCO,
  BUY_SELL_FORM_STOP_LIMIT,
} from '~/lib/constants'
// import Decimal from "decimal.js-light";
// import { MarketListItemModel } from "@/lib/data-models/pairs/MarketListItemModel";
const lockr = require('lockr')

export const MARKET_COMPONENTS_KEY = 'marketList'
export const ZERO_BALANCES_KEY = 'zeroBalances'
export const FAVORITE_TAB_KEY = 'favorite'
export const OTHER_TAB_KEY = 'alts'
export const STABLE_TAB_KEY = 'stable'
export const FIAT_TAB_KEY = 'fiat'
// export const TICKERS_IN_OTHER_TAB: Ticker[] = ['BTG', 'LTC', 'BCH']
// export const PAIRS_IN_OTHER_TAB: PairName[] = ['E2C_BTG', 'E2C_LTC', 'E2C_BCH']

export const FAVORITE_TAB_ID = 'FAVORITE'

export const MARKET_LIST_MARKET_TABS = ['BTC', 'ETH']

// let count = 0

export const TABS_ORDERS = {
  BTC: 10,
  ETH: 20,
  USD: 30,
  EUR: 40,
  [FIAT_TAB_KEY]: 150,
  [STABLE_TAB_KEY]: 180,
  [OTHER_TAB_KEY]: 200,
  [FAVORITE_TAB_KEY]: 222,
}

// const STABLE_DROPDOWN_ORDERS = {
//   PLC: 1,
//   USDA: 2,
// }

export interface PairsState {
  currentPair: PairName
  /**
   * @deprecated use mPairs instead
   */
  pairs: Hash<IPairStateObject>
  // defiPairs: Hash<Pair>;
  currencyImages: {}
  currencyNames: {}
  topCurrencies: Ticker[]
  stableFilter: Ticker | ''
  fiatFilter: Ticker | ''
  currentPairPriceLoaded: boolean
  marketTabs: RawMarketListTab[]
  mFavorites: RawMarketListTab[]
  mPairs: Hash<ISocketPair>
  mFavoritePairs: PairName[]
  // subscribePairs: PairName[]
}

// const initialActivePair = getActivePairFromRoute(location.pathname) || lockr.get(ACTIVE_PAIR_KEY) || window.DEFAULT_PAIR || "";

const PairsModule: Module<PairsState, RootState> = {
  namespaced: true,
  state: () => ({
    // pairs: lockr.get(MARKET_LIST_PAIRS_DATA) || {},
    /**
     * @deprecated use mPairs instead
     */
    pairs: {},
    // defiPairs: initPairs(window.DEFI_LIST),// initPairs(window.DEFI_LIST),
    currencyImages: {},
    currencyNames: {},
    currentPair: null,
    topCurrencies: [],
    currentPairPriceLoaded: false, // when price of selected pair on trade received
    stableFilter: '',
    fiatFilter: '',

    marketTabs: [],
    mFavorites: [],
    mPairs: {},
    mFavoritePairs: [],
    // subscribePairs: [], // keep all pairs to subscribe on state.subscribe
  }),
  mutations: {
    setMarketTabs (state, tabs) {
      if (!state.marketTabs.length) {
        state.marketTabs = tabs
      }
      // console.log(tabs, state.marketTabs)
    },
    setStableFilter (state, ticker) {
      state.stableFilter = ticker
    },
    // setFiatFilter(state, ticker) {
    //     state.fiatFilter = ticker
    // },
    setCurrentPair (state, { pairName }) {
      if (process.client) lockr.set(ACTIVE_PAIR_KEY, pairName)
      state.currentPair = pairName
    },
    marketPairs (state, pld: ISocketPair[]) {
      const objPairs = pld.reduce((acc, p) => {
        acc[p.market] = p
        return acc
      }, {})
      // merge(state.mPairs, objPairs)
      state.mPairs = { ...state.mPairs, ...objPairs }
    },
    setPairs (state, { response }) {
      const pairs = parseResponseToPairs(response!)
      // console.log(JSON.stringify(pairs, null, 2))
      state.pairs = merge({ ...state.pairs }, pairs)
      state.pairs = { ...state.pairs }
      if (process.client) lockr.set(MARKET_LIST_PAIRS_DATA, state.pairs)
    },
    setCurrentPairPriceReceived (state, pld: boolean) {
      state.currentPairPriceLoaded = pld
    },
    setPairPriceReceived (state, { pairName, received }) {
      const prev = state.pairs[pairName] || getDefaultPairObject({ pairName })

      Vue.set(state.pairs, pairName, set(prev, 'priceReceived', received))
    },
    setFavoritePairs (state, { response }: { response }) {
      // const favorites = response!.filter(f => window.MARKET_LIST.includes(f));
      // const hash = response.reduce((acc, pairName) => {
      //     acc[pairName] = {
      //         favorite: true,
      //     };

      //     return acc;

      // state.pairs = merge({ ...state.pairs }, hash);
      // state.pairs = { ...state.pairs }
      state.mFavorites = response
      state.mFavoritePairs = response
      // state.mFavoritePairs = response
      // .reduce(getherPairsFromMarketTabs, [])
      // .map(p => p.key as PairName)
    },
    // toggleFavorite(state, { pairName }) {
    //     const prev = state.pairs[pairName] || getDefaultPairObject({ pairName });
    //     state.pairs[pairName] = assign(prev, { favorite: !prev.favorite })
    //     state.pairs = { ...state.pairs }
    // },
    updatePairMarket (
      state,
      { payload: [pairName, pld] }: { payload: [PairName, ISocketPair] },
    ) {
      const prev = state.mPairs[pairName] || {}
      // console.log('updatePairMarket', prev, pld)
      Vue.set(state.mPairs, pairName, { ...prev, ...pld })

      // const prev = state.pairs[pairName] || getDefaultPairObject({ pairName });
      // // const prev = state.mPairs[pairName] || getDefaultPairObject({ pairName });

      // Vue.set(
      //     state.pairs,
      //     pairName,
      //     merge({...prev}, { marketReceived: true, market }),
      // );
    },
    setCurrencyImages (state, { response: { currenciesImages } }) {
      state.currencyImages = currenciesImages
    },
    setTopCurrencies (state, { response: { currenciesTopList } }) {
      state.topCurrencies = currenciesTopList
    },
    setCurrencyNames (state, { response: { currenciesMap } }) {
      state.currencyNames = currenciesMap
    },
  },
  getters: {
    isPairMarginTradeable ({ currentPair }, _, rootState) {
      return rootState.globals.marginList.includes(currentPair)
    },
    getCurrencyFullName (state) {
      return function (ticker) {
        return state.currencyNames[ticker]
      }
    },
    marketTabs: (state, _, rootState) => {
      // console.time('SHOULD BE AS LOW AS POSSIBLE::marketTabs')
      const tabs = rootState.globals.marketTabs
      const fav = state.mFavorites

      const v = tabs.map((t, i) => {
        const favTabs = (fav[i] && fav[i].tabs) || []
        const tabs = t.tabs.concat(favTabs)
        // top level (market) tabs
        const tab = {
          ...t,
          tabs,
        }
        // list of favorite ids
        return new MarketListTab(tab, state.mPairs, state.mFavoritePairs)
      })
      // console.timeEnd('SHOULD BE AS LOW AS POSSIBLE::marketTabs')
      return v
    },
    // use raw in order to speed up performance
    rawMarketPairs (_, __, rootState) {
      return rootState.globals.marketTabs.reduce(getherPairsFromMarketTabs, [])
    },
    marketPairs (state, getters) {
      // console.time('SHOULD BE AS LOW AS POSSIBLE::marketPairs')
      const rawPairs = getters.rawMarketPairs as RawMarketListPair[]
      const v = uniqBy(rawPairs, 'id').map(
        p => new MarketListPair(p, state.mPairs[p.key], state.mFavoritePairs),
      )
      // console.timeEnd('SHOULD BE AS LOW AS POSSIBLE::marketPairs')
      return v
    },
    mFavorites (state, getters) {
      // console.time('SHOULD BE AS LOW AS POSSIBLE::marketPairs')
      const rawPairs = getters.rawMarketPairs as RawMarketListPair[]
      const fPairs = rawPairs.filter(pair =>
        (state.mFavoritePairs as string[]).includes(pair.id),
      )
      const v = uniqBy(fPairs, 'id').map(
        p => new MarketListPair(p, state.mPairs[p.key], state.mFavoritePairs),
      )
      // console.timeEnd('SHOULD BE AS LOW AS POSSIBLE::marketPairs')
      return v
    },
    marketTabsByMarket (_, getters) {
      return (marketTab: MarketListTab) =>
        getters.marketTabs.find(t => t.id === marketTab.id)
    },
    defaultPairs: (_, __, rootState) => initPairs(rootState.globals.marketList),
    pairsList (state, getters) {
      // console.log(JSON.stringify(getters.defaultPairs, null, 2))
      // console.log(Object.values(getters.defaultPairs).length)
      // console.log(Object.values(state.pairs).length)
      // console.warn('-------------------')

      // const problem = Object.values({ ...getters.defaultPairs, ...state.pairs }).find((p:any) => !p.pairName)
      // if (problem) {
      //     console.error(problem)
      //     throw new Error(`There is problem ${problem}`)
      // }

      return {
        ...getters.defaultPairs,
        ...state.pairs,
      }
    },
    getCoinGroups: (_, { marketPairs }) =>
      marketPairs.reduce(
        (all, curr) => ({
          ...all,
          [curr.stockTicker]: [...(all[curr.stockTicker] || []), curr],
        }),
        {},
      ),
    /**
     * @deprecated
     */
    // pairs(state, getters, rootState, rootGetters) {
    //     // console.time('count_'+ count)
    //     // console.log('getters.pairsList.length::', Object.values(getters.pairsList).length)
    //     const v = Object.keys(getters.pairsList).reduce((acc, k) => {
    //         // check if data structure is ok (when pair is deleted but it is still in favorites)
    //         if (!getters.pairsList[k].pairName) return acc

    //         acc[k] = new PairModel(
    //             getters.pairsList[k],
    //             {
    //                 loggedIn: (rootState.profile as any).auth.isLogined,
    //                 defiList: rootState.globals.defiList,
    //                 fiatTickers: rootState.globals.fiatTickers,
    //                 stablePairs: rootGetters['globals/stablePairs'],
    //                 marketListPairHighlight: rootState.globals.marketListPairHighlight // rootState.globals.marketListPairHighlight
    //             }
    //         )
    //         return acc
    //     }, {} as {[key in PairName]: PairModel})
    //     // console.timeEnd('count_' + count)
    //     // count++
    //     return v
    // },
    amountCurrency (_, { getCurrentPair }) {
      return getCurrentPair.stockTicker
    },
    totalCurrency (_, { getCurrentPair }) {
      return getCurrentPair.moneyTicker
    },
    getCurrentPair (state, getters, rootState) {
      const rawPairs = getters.rawMarketPairs as RawMarketListPair[]
      const findPair = (p: RawMarketListPair) =>
        p.key === (state.currentPair || rootState.globals.defaultPair)
      const rawPair = rawPairs.find(findPair)
      if (!rawPair) {
        console.log('rawPairs::', rawPairs)
        console.log(
          'rootState.globals.defaultPair::',
          rootState.globals.defaultPair,
        )
        console.log('state.currentPair::', state.currentPair)
      }
      return new MarketListPair(
        rawPair,
        state.mPairs[rawPair.key],
        state.mFavoritePairs,
      )
      // return getters.pairsList[currentPair || rootState.globals.defaultPair]
    },
    // getTabFromCurrentPair(state, getters) {
    //     const pairs = Object.keys(state.pairs).map(k => state.pairs[k])
    //     const currentPair = pairs.find(p => p.pairName === getters.getCurrentPairName || '')
    //     if (!currentPair || !Array.isArray(currentPair.marketTabs)) return ''
    //     const currentTab = currentPair.marketTabs.map(t => t).reverse()[0].value
    //     return currentTab
    // },
    isFavoriteExist (state) {
      return Object.values(state.pairs).some(({ favorite }) => favorite)
    },
    defaultPairName: (_, __, rootState) => {
      if (!process.client) return ''
      return (
        getActivePairFromRoute(
          window.location.pathname,
          rootState.globals.marketList,
        ) ||
        lockr.get(ACTIVE_PAIR_KEY) ||
        rootState.globals.defaultPair ||
        ''
      )
    },
    getCurrentPairName (state, getters) {
      return state.currentPair || getters.defaultPairName
    },
    getMarketTabs (_, getters) {
      const obj = Object.values(getters.pairs).reduce((acc, pair: any) => {
        pair.marketTabs.forEach((t) => {
          acc[t.value] = t
        })
        return acc
      }, {})

      const pairTabs = Object.values(obj)

      const sorted = pairTabs.sort((pr, nx) => pr.order - nx.order)

      return sorted
    },
    // showMarketUpdates(state) {
    //     return Object.values(state.pairs).every(p => Boolean(p.marketReceived));
    // },
    getMarketPriceInUSDByTicker: state => pairWithUSD =>
      pairWithUSD in state.pairs
        ? state.pairs[pairWithUSD].market.price || 0
        : 0,
  },
  actions: {
    // changeTab(ctx, { id }) {
    //     if (id === MARKET_COMPONENTS_KEY) {
    //         bus.$emit(`${MARKET_COMPONENTS_KEY}__reset`);
    //     }
    // },
    socketMarketUpdate ({ commit }, pld) {
      // console.log('socketMarketUpdate::', pld)
      commit('marketPairs', pld)
    },
    async getMarketQuery () {
      // const resp = await api.$marketQuery()
      // // console.log('getMarketInit::', resp)
    },
    async toggleFavorite ({ dispatch }, pairRow: MarketListPair) {
      // const { favorite } = state.pairs[pairName]!;
      // commit("toggleFavorite", { pairName });

      // if (!getters.isFavoriteExist) {
      //     dispatch("changeTab", {
      //         id: MARKET_COMPONENTS_KEY,
      //         tab: getters.getTabFromCurrentPair,
      //     });
      // }

      const pairName = pairRow.pairName as PairName

      const request = pairRow.isFavorite
        ? this.$api.deleteFavoritePair.bind(this.$api)
        : this.$api.postFavoritePair.bind(this.$api)

      await request({ pair: pairName })
      await dispatch('fetchFavoritePairs')

      // if (response.errors) {
      //     console.error(response.errors);
      //     commit("toggleFavorite", { pairName });
      //     // dispatch("changeTab", {
      //     //     id: MARKET_COMPONENTS_KEY,
      //     //     tab: FAVORITE_TAB_KEY,
      //     // });
      // }
    },
    // Extract change pair logic from buySellForm usage. Eventually maybe should replace changePair action
    async subscribeOnPairChange (_, { pair: pairName }) {
      await Promise.all([
        this.$api.$unsubscribeDepth(),
        this.$api.$unsubscribeDealsHistory(),
        this.$api.$subscribeDepth({
          pair: pairName,
          limit: 100,
        }),
        this.$api.$subscribeDealsHistory([pairName]),
      ])
    },
    async changePair ({ commit, getters }, { pair: pairName }) {
      commit('setCurrentPair', { pairName })

      if (getters.getCurrentPairName === pairName) {
        return
      }

      commit('setPairPriceReceived', {
        pairName: getters.getCurrentPairName,
        received: false,
      })

      // updateTitle(`Trade ${pairName.replace("_", "/")}`);

      commit('buySell/buyForm/reset', { clearFields: true })
      commit('buySell/sellForm/reset', { clearFields: true })

      // forget that input was changed by user for sell and buy forms,
      // basically overwrite user input in future
      ;['buySell/sellForm/setCustom', 'buySell/buyForm/setCustom'].forEach(
        (mutation) => {
          commit(mutation, {
            field: 'price',
            value: { changedByUser: false },
          })
        },
      )

      // when pair changes we need receive price of new pair
      commit('setCurrentPairPriceReceived', false)

      await Promise.all([
        this.$api.$unsubscribeDepth(),
        this.$api.$unsubscribeDealsHistory(),
        this.$api.$subscribeDepth({
          pair: getters.getCurrentPairName,
          limit: 100,
        }),
        this.$api.$subscribeDealsHistory([getters.getCurrentPairName]),
      ])
    },
    /**
     * @deprecated to remove
     */
    // getMarketTabs (...args) {
    //   return actionWrapper({
    //     apiRequest: () => this.$api.getMarketTabs(),
    //     mutationName: 'setMarketTabs',
    //   }).bind(this)(...args)
    // },
    getInitialPairsData (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getMarketList(),
        mutationName: 'setPairs',
        successHandler: ({ context: { commit }, response: { response } }) => {
          commit('setCurrencyImages', { response })
          commit('setCurrencyNames', { response })
          commit('setTopCurrencies', { response })
        },
      }).bind(this)(...args)
    },
    fetchFavoritePairs (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getFavoritePairs(),
        mutationName: 'setFavoritePairs',
        successHandler: async ({ context: { state, rootState } }) => {
          await wait(100)
          const { marketList } = rootState.globals
          const pairs = Object.keys(state.pairs) as PairName[]
          const unlistedPairs = pairs.filter(p => !marketList.includes(p))
          // for every unlisted pair send request to remove it from favorites
          unlistedPairs.map(p => this.$api.deleteFavoritePair({ pair: p }))
        },
      }).bind(this)(...args)
    },
    updatePrice ({ commit, state, getters, rootGetters }, { pairName }) {
      const price: Hash<string> = {
        buy: rootGetters['trade/lastPrice'](pairName, 'sell'),
        sell: rootGetters['trade/lastPrice'](pairName, 'buy'),
      }
      // set price only when user clicks on new pair and do not change it later
      const firstTimeSet = state.currentPairPriceLoaded === false
      const priceOfCurrentPair = getters.getCurrentPairName === pairName
      const currentPair = getters.pairsList[pairName]

      const update = (f, options) =>
        commit(`buySell/setBuySellForm${f}`, options, { root: true })

      Object.entries(price).forEach(([type, value]) => {
        if (priceOfCurrentPair && firstTimeSet) {
          ;[
            BUY_SELL_FORM_LIMIT,
            BUY_SELL_FORM_OCO,
            BUY_SELL_FORM_STOP_LIMIT,
          ].forEach((formName) => {
            const options = { formName, currentPair, type }
            update('Price', { ...options, value })
            update('Amount', { ...options, value: '' })
          })
        }
      })

      if (priceOfCurrentPair) {
        // after updating fields (above) set that current price was received
        // in order to prevent updating fields later on price change
        commit('setCurrentPairPriceReceived', true)
      }

      if (get(state.pairs, [pairName, 'priceReceived'], false)) return

      commit('setPairPriceReceived', {
        pairName,
        received: true,
      })
    },

    socketPairsStateUpdate: actionWrapper({
      // apiRequest: pld => parseResponseFromMarket(pld),
      apiRequest: pld => pld,
      mutationName: 'updatePairMarket',
      collect: 100,
    }),
  },
}

function parseResponseToPairs (
  response: GetMarketListResponse,
): Hash<IPairStateObject> {
  return response.marketList.result
    .filter(obj => filterByLeftTicker('E2C')(obj.name))
    .filter(obj => filterByLeftTicker('E2C-EX')(obj.name))
    .filter(obj => filterByRightTicker('E2C')(obj.name))
    .reduce((acc, { name, min_amount, stock, money }: PairMeta) => {
      acc[name] = getDefaultPairObject({
        pairName: name,
        stockLabel: response.currenciesMap[stock],
        stockTickerImg: response.currenciesImages[stock],
        minAmountStock: min_amount,
        moneyLabel: response.currenciesMap[money],
        moneyTickerImg: response.currenciesImages[money],
        market: {
          volume: '0',
          volumeInMoney: '0',
          price: '0',
          change: '0%',
        },
      })

      return acc
    }, {} as Hash<IPairStateObject>)
}

// export function getDropdownListForTab(pairs) {
//     const rightSideOfPairs = pairs.map(p => p.split('_')[1]).filter(t => t !== 'USD' && t !== 'EUR')
//     const uniqueRightSide = [...new Set(rightSideOfPairs)]

//     // if there is no PLC push to first place
//     if (!uniqueRightSide.includes('PLC')) uniqueRightSide.unshift('PLC')

//     const listItems = uniqueRightSide
//         .map(ticker => new LabeledValue(ticker, ticker))
//         .sort((pr, nx) => {
//             const prOrder = STABLE_DROPDOWN_ORDERS[pr.value] || Infinity
//             const nxOrder = STABLE_DROPDOWN_ORDERS[nx.value] || Infinity
//             return prOrder - nxOrder
//         })
//     return listItems
// }

function initPairs (pairsSource: PairName[]): Hash<IPairStateObject> {
  return pairsSource.reduce((acc, m) => {
    acc[m] = getDefaultPairObject({ pairName: m })
    return acc
  }, {} as Hash<IPairStateObject>)
}

function getActivePairFromRoute (path: string, marketList: PairName[]) {
  if (!path.startsWith('/trade')) {
    return
  }

  // left and right ticker from route path
  const [pathLeft, pathRight] = path
    .split('/')
    .slice(-1)
    .pop()
    .split('_')

  const matched = marketList.find((p) => {
    const [marketLeft, marketRight] = p.split('_')
    return pathLeft === marketLeft && pathRight === marketRight
  })

  if (matched) {
    return matched
  }
}

export default PairsModule
