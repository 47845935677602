import { actionWrapper } from '@/lib/utils'

const NftModule = {
  namespaced: true,
  state: () => ({
    userOrders: null,
    sliderItems: null,
  }),
  getters: {
    userOrdersExists (state) {
      return Boolean(state.userOrders && state.userOrders.length)
    },

    sliderItemsLoaded (state) {
      return Boolean(state.sliderItems && state.sliderItems.length)
    },
  },
  mutations: {
    setUserOrders (state, { response }) {
      if (!response) return
      // TODO: remove in future. Hack for invalid format of response from backend
      if (Array.isArray(response)) state.userOrders = response
      else state.userOrders = Object.values(response)
    },

    setSliderItems (state, { response }) {
      if (!response || !response.records) return
      state.sliderItems = response.records.map(item => ({ images: item.images, mainImage: item.mainImage }))
    },
  },
  actions: {
    updateUserOrders: actionWrapper({
      apiRequest () {
        return this.$api.nftGetUserOrders()
      },
      mutationName: 'setUserOrders',
    }),

    // cache per session
    async getUserOrders (ctx) {
      if (!ctx.getters.userOrdersExists) await ctx.dispatch('updateUserOrders')
      return ctx.state.userOrders
    },

    updateSliderItems: actionWrapper({
      apiRequest () {
        return this.$api.nftGetProducts()
      },
      mutationName: 'setSliderItems',
    }),

    // cache per session
    async getSliderItems (ctx) {
      if (!ctx.getters.sliderItemsLoaded) await ctx.dispatch('updateSliderItems')
      return ctx.state.sliderItems
    },
  },
}

export default NftModule
