<template>
  <div
    :class="[`exc-dropdown-item--${size}`, disabled ? 'txt--grey' : '']"
    class="exc-dropdown-item"
  >
    <div
      v-if="option.logo"
      class="exc-dropdown-item__icon"
    >
      <exc-ticker-logo
        :src="option.logo"
        :alt="option.name || option.label || option.ticker"
        :size="tickerLogoSize"
        :transparent-border="logoWithoutBorder"
      />
    </div>
    <span class="exc-dropdown-item__text txt--14px txt--height-18px">
      {{ option.ticker || option.name || option.label }}
    </span>
  </div>
</template>
<script>
import { defaultSize, sizeValidator, getPreviousUiKitSize } from '@/lib/utils'
import ExcTickerLogo from '@/components/common/ticker/ExcTickerLogo.vue'

export default {
  name: 'DropdownItem',
  components: {
    ExcTickerLogo,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
    disabled: { type: Boolean, default: false },
    logoWithoutBorder: { type: Boolean, default: false },
  },
  computed: {
    tickerLogoSize () {
      return getPreviousUiKitSize(this.size)
    },
  },
}
</script>
<style lang="scss" scoped>
.exc-dropdown-item {
  $root: &;

  display: flex;
  align-items: center;

  &__icon {
    margin-right: toRem(7px);
  }

  &__text {
    line-height: toRem(16px);
  }

  @each $name, $val in $uiKitSizes {
    &--#{$name} {
      height: toRem($val);
      padding: 0 toRem($val / 3);
    }
  };
}
</style>
