<template>
  <exc-link
    v-bind="$attrs"
    :class="{
      'txt--color-04': onlyTheme === 'dark',
      'txt--color-04': onlyTheme === 'light',
      'txt--color-04': !onlyTheme,
      'exc-header-nav-item__isHover': hoverName,
    }"
    class="exc-header-nav-item"
    no-decoration
    v-on="$listeners"
  >
    <div
      class="exc-header-nav-item__container flx flx--items-center"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      @touchstart="isOpen = !isOpen"
    >
      <div
        :class="{ 'pl-16 pr-16': !noPadding }"
        class="exc-header-nav-item__name flx flx--items-center"
        @mouseenter="hoverName = true"
        @mouseleave="hoverName = false"
        @touchstart="hoverName = !hoverName"
      >
        <span class="txt txt--16px">
          <slot name="name" v-bind="{ hoverName, isOpen }" />
        </span>
        <span
          v-if="$slots.dropdown && !withoutArrow"
          class="rxd-header-nav-item__arrow-container flx flx--center"
        >
          <template v-if="!isMobileTrade">
            <rdx-arrow
              :direction="isArrowToTop ? 'bottom' : 'top'"
              class="exc-header-nav-item__arrow ml-8"
            />
          </template>
          <template v-else>
            <exc-dropdown-triangle
              :direction="isArrowToTop ? 'bottom' : 'top'"
              class="txt--color-04"
            />
          </template>
        </span>
      </div>
      <transition name="fade">
        <div
          v-if="$slots.dropdown"
          v-show="isOpen"
          :class="[
            `exc-header-nav-item--dropdown-position-${dropdownPosition}`,
            `exc-header-nav-item--dropdown-align-${dropdownAlign}`,
            {
              'bg--color-01': onlyTheme === 'dark',
              'bg--color-01': onlyTheme === 'light',
              'bg--color-01  ': !onlyTheme,
            },
          ]"
          class="exc-header-nav-item__dropdown sha--0420 bor--radius-12"
          @click="isOpen = false"
        >
          <slot name="dropdown" v-bind="{ hoverName, isOpen }" />
        </div>
      </transition>
    </div>
  </exc-link>
</template>

<script>
import ExcLink from '@/components/common/navigation/link/ExcLink.vue'
import { onlyThemePropValidator } from '@/lib/utils'
import RdxArrow from '@/components/common/svg/RdxArrow.vue'
import ExcDropdownTriangle from '@/components/common/svg/ExcDropdownTriangle.vue'

export default {
  name: 'ExcHeaderNavItem',
  components: {
    ExcLink,
    RdxArrow,
    ExcDropdownTriangle,
  },
  props: {
    dropdownPosition: {
      validator: v => ['top', 'bottom'].includes(v),
      default: 'bottom',
    },
    dropdownAlign: {
      validator: v => ['left', 'right', 'center'].includes(v),
      default: 'left',
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    onlyTheme: onlyThemePropValidator(),
    withoutArrow: {
      type: Boolean,
      default: false,
    },
    isMobileTrade: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle'],
  data () {
    return {
      isOpen: false,
      hoverName: false,
      isArrowToTop: false,

      onMouseTimeout: null,
      onMouseTimeoutTime: 77,
    }
  },

  watch: {
    isOpen: {
      handler (val) {
        this.$emit('toggle', val)
        this.isArrowToTop = val
      },
      immediate: true,
    },
  },

  methods: {
    onMouseEnter () {
      window.clearTimeout(this.onMouseTimeout)
      this.onMouseTimeout = window.setTimeout(() => {
        this.isOpen = true
      }, this.onMouseTimeoutTime)
    },
    onMouseLeave () {
      window.clearTimeout(this.onMouseTimeout)
      this.isOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-header-nav-item {
  $root: &;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &--dropdown-align-left {
    left: 0;
  }

  &--dropdown-align-right {
    right: 0;
  }

  &--dropdown-align-center {
    left: 50%;
    transform: translateX(-50%);
  }

  &--dropdown-position-top {
    bottom: 90%;
  }

  &--dropdown-position-bottom {
    // top: 105%;
    top: 86%;
  }

  &__container {
    height: 100%;
    position: relative;

    .main-label {
      color: color(color-09) !important;
    }
  }

  &__arrow {
    transform-origin: center;
    transform: rotate(180deg);
    width: toRem(13px);

    margin-bottom: toRem(-6px);
  }

  &__name {
    height: 100%;
  }

  &__dropdown {
    position: absolute;
    width: max-content;
    z-index: $z-dropdown-items-container;
    overflow: hidden;
  }
}
</style>
