import { Module, ModuleTree } from 'vuex'
import { RootState } from '..'
import { loans } from './loans'
import { admin } from './admin'

export interface p2pNewState { }

const modules: ModuleTree<RootState> = {
  loans,
  admin,
}

export const p2pNew: Module<p2pNewState, RootState> = {
  namespaced: true,
  modules,
}
