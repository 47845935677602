<template>
  <base-modal
    v-bind="$attrs"
    class="confirm-modal-container"
    full-content
    @close="$emit('close')"
  >
    <template #body>
      <div>
        <div
          v-if="fetching"
          class="confirm-modal-container__loader"
        >
          <font-awesome-icon
            icon="spinner"
            spin
          />
        </div>
        <div
          v-else
          class="confirm-modal-container__form pt-32 p-18 p-32--l bg--color-01"
        >
          <div
            v-if="$slots.info"
            class="confirm-modal-container__info pt-8 pb-24"
          >
            <slot name="info" />
          </div>

          <div
            v-if="$slots.header"
            class="confirm-modal-container__header"
          >
            <h4 class="flx txt--26px txt--height-34px txt--color-04 txt--bold mb-8">
              <slot name="header" />
            </h4>
            <span>
              <slot name="headerComponent" />
            </span>
          </div>

          <div
            v-if="$slots.disclaimer"
            class="confirm-modal-container__disclaimer pt-8 pb-4 pb-8--l"
          >
            <slot name="disclaimer" />
          </div>

          <div
            v-if="$slots.content"
            :class="{ 'confirm-modal-container--content-full-width': contentFullWidth }"
            class="confirm-modal-container__content"
          >
            <slot name="content" />
          </div>

          <div
            v-if="$slots.buttons"
            class="confirm-modal-container__buttons pt-16"
          >
            <slot name="buttons" />
          </div>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
// import { ExcBlockHeadingRow } from '@/components/common'

export default {
  name: 'ConfirmModalContainer',
  components: {
    // ExcBlockHeadingRow,
    BaseModal: () => import(/* webpackMode: "eager" */ '@/components/base/BaseModal.vue'),
  },
  props: {
    fetching: {
      type: Boolean,
      default: false,
    },
    contentFullWidth: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.confirm-modal-container {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include bp(0, l) {
      margin-top: toRem(15px);
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(3);
    color: color(main);
    width: 100%;
    text-align: center;
    min-height: toRem(100px);
  }

  &__buttons {
    display: flex;
    flex-direction: column;

    @include bp(sm) {
      flex-direction: row;
      justify-content: center;
    }

    &::v-deep {
      & > * {
        flex: initial;

        @include bp(sm) {
          flex: 1;
        }
      }

      & > *:not(:last-child) {
        margin-bottom: toRem(15px);

        @include bp(sm) {
          margin-bottom: 0;
          margin-right: toRem(16px);
        }
      }
    }
  }

  &--content-full-width {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
