var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('exc-link',_vm._g(_vm._b({staticClass:"exc-header-dropdown-item",attrs:{"force-use-link":_vm.forceUseLink,"no-decoration":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [_c('div',{staticClass:"exc-header-dropdown-item__container pr-16 pl-16",class:_vm.sizeClass},[(_vm.$slots.left)?_c('div',{staticClass:"exc-header-dropdown-item__left",class:{
          // 'txt--color-06-dark': isActive,
          // 'txt--color-04': onlyTheme === 'dark',
          // 'txt--color-03 txt--hover--color-04': onlyTheme === 'light',
          // 'txt--color-03 txt--hover--color-04': !onlyTheme,
          'txt--color-03': true,
        }},[_vm._t("left",null,null,{ isActive: isActive })],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"exc-header-dropdown-item__middle"},[_c('div',{staticClass:"exc-header-dropdown-item__name"},[_c('span',{staticClass:"txt txt--16px txt--height-18px",class:{
              'txt--main': isActive,
              'txt--color-04': !isActive && _vm.onlyTheme === 'dark',
              'txt--color-04': !isActive && _vm.onlyTheme === 'light',
              'txt--color-04': !isActive && !_vm.onlyTheme,
            }},[_vm._t("name",null,null,{ isActive: isActive })],2)]),_vm._v(" "),_c('div',{staticClass:"exc-header-dropdown-item__description"},[_c('span',{staticClass:"txt txt--10px",class:{ 'txt--main': isActive, 'txt--color-04': !isActive }},[_vm._t("description",null,null,{ isActive: isActive })],2)])]),_vm._v(" "),(_vm.$slots.right)?_c('div',{staticClass:"exc-header-dropdown-item__right"},[_vm._t("right",null,null,{ isActive: isActive })],2):_vm._e()])]}}],null,true)},'exc-link',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }