import { Module } from 'vuex'
import { RootState } from '@/store/index'
// import { loadingGetters } from "@/store/lib/helpers";
// import { formatShortDate } from "@/lib/utils";
import { actionWrapper } from '@/lib/utils'

export interface ReferralState {
  settings: ReferralSettings
  // game: ReferralGame;
  referrals: ReferralRow[]
  actualHistory: ReferralHistoryRow[]
  loadMoreReferrals: boolean
  loadMoreAccrual: boolean
  referralStatistics: ReferralStatistics
}

const ReferralModule: Module<ReferralState, RootState> = {
  namespaced: true,
  state: () => ({
    settings: {} as ReferralSettings,
    referrals: [],
    loadMoreReferrals: true,
    actualHistory: [],
    loadMoreAccrual: true,
    referralStatistics: {} as ReferralStatistics,
  }),
  mutations: {
    setSettingsData (state, { response }) {
      state.settings = response
    },
    setReferrals (state, { records }) {
      state.referrals = records
    },
    addReferrals (state, { records }) {
      state.referrals = [...state.referrals, ...records]
    },
    setLoadMoreReferrals (state, value: boolean) {
      state.loadMoreReferrals = value
    },
    setActualHistory (state, { records }) {
      state.actualHistory = records
    },
    addActualHistory (state, { records }) {
      state.actualHistory = [...state.actualHistory, ...records]
    },
    setLoadMoreAccrual (state, value: boolean) {
      state.loadMoreAccrual = value
    },
    setReferralStatistics (state, { response }) {
      state.referralStatistics = response
    },
  },
  actions: {
    getReferralsSettings (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getReferralSettings(),
        mutationName: 'setSettingsData',
      }).bind(this)(...args)
    },
    getReferrals (...args) {
      return actionWrapper({
        apiRequest: ({ page }) => this.$api.getReferrals({ page }),
        mutationName: '',
        successHandler: ({ context: { commit }, response: { response }, payload: { page } }) => {
          const { records, limit } = response
          const loadMore = !!records.length && records.length === limit

          commit('setLoadMoreReferrals', loadMore)

          if (records.length > 0) {
            if (page > 1) {
              commit('addReferrals', { records })
            } else {
              commit('setReferrals', { records })
            }
          } else if (page === 1) {
            commit('setReferrals', { records: [] })
          }
        },
      }).bind(this)(...args)
    },
    getActualHistory (...args) {
      return actionWrapper({
        apiRequest: ({ page }) => this.$api.getActualHistory({ page }),
        mutationName: '',
        successHandler: ({ context: { commit }, response: { response }, payload: { page } }) => {
          const { records, limit } = response
          const loadMore = !!records.length && records.length === limit

          commit('setLoadMoreAccrual', loadMore)

          if (records.length > 0) {
            if (page > 1) {
              commit('addActualHistory', { records })
            } else {
              commit('setActualHistory', { records })
            }
          } else if (page === 1) {
            commit('setActualHistory', { records: [] })
          }
        },
      }).bind(this)(...args)
    },
    getReferralStatistics (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getReferralStatistics(),
        mutationName: 'setReferralStatistics',
      }).bind(this)(...args)
    },
  },
}

export default ReferralModule
