// import { parseInvestHistoryDate } from "@/lib/utils";
import Decimal from 'decimal.js-light'
// import { $t } from '@/lib/i18n'

class InvestPlanStatus {
  type: InvestPlanStatusType
  _name: string
  constructor ({ status, name }: { status: InvestPlanStatusType, name: string }) {
    this.type = status
    this._name = name
  }

  get name () {
    return [`staking.statuses.${this._name.toLowerCase()}`, this._name]
  }
}

export default class InvestmentPlanModel {
  logo: string
  marketList: PairName[]
  _maxDeposit: Decimal
  maxPercent: Decimal
  minDeposit: Decimal
  minPercent: Decimal
  planCount: number
  redirectId: number
  status: InvestPlanStatus
  ticker: Ticker
  dropdownOptions: { name: string, pairName: PairName }[]

  constructor (d: StakingGroupResponseData) {
    this.logo = d.logo
    this.marketList = d.marketlist
    this._maxDeposit = new Decimal(d.maxdeposit || 0)
    this.maxPercent = new Decimal(d.maxpercent || 0)
    this.minDeposit = new Decimal(d.mindeposit || 0)
    this.minPercent = new Decimal(d.minpercent || 0)
    this.redirectId = d.redirect_id
    this.planCount = d.plancount
    this.status = new InvestPlanStatus({
      status: d.status,
      name: d.statusName,
    })
    this.dropdownOptions = d.marketlist.map(pair => ({
      name: pair.replace('_', '/'),
      pairName: pair,
    }))
    this.ticker = d.ticker
  }

  get maxDeposit () {
    return this._maxDeposit.isZero() ? 'staking.noLimit' : this._maxDeposit
  }
}
