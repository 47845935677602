// @TODO DELETE FROM THERE!!! it is plugin option
import Vue from 'vue'
// import i18n from "./localization";

export const bus = new Vue()

export default {
  install (Vue) {
    Vue.prototype.$bus = bus
  },
}

export function notifyError (options) {
  bus.$notify({
    ...options,
    // group: "error",
    type: 'error',
    duration: 1000,
    ignoreDuplicates: true,
  })
}

export function showP2pError (errors) {
  if (Array.isArray(errors)) {
    errors.forEach((err) => {
      // const key = `p2p.errors.${err}`
      // const value = i18n.t(key)

      // key === value means translation not found
      // if (key === value) {
      notifyError({ text: err })
      // } else {
      // notifyError({ text: value })
      // }
    })
  } else {
    console.error('ERROR::  should be array, got', errors)
    notifyError({ text: errors })
  }
}

export function notifySuccess (options) {
  bus.$notify({
    ...options,
    // group: "info",
    type: 'success',
    duration: 1000,
    ignoreDuplicates: true,
  })
}

export function notifyInfo (options) {
  bus.$notify({
    ...options,
    // group: "info",
    type: 'info',
    duration: 1000,
    ignoreDuplicates: true,
  })
}
