import { Module, MutationTree, ActionContext } from 'vuex'
import {
  LendingApplicationItem,
  // LendingApplicationItemInterface,
} from '@/data-objects/p2p/LendingApplicationItem'
import { SHOW_MORE_STEP } from '@/constants'
import { showP2pError } from '@/lib/bus'
import { RootState } from '..'
// import { conditionallyAwaited } from "@/lib/utils";

interface P2pAppLendStateInterface {
  offsetAllApps: number
  totalRecordsLeft: null | number

  lendingAllApps: null | LendingApplicationItem[]
  lendingUserApps: null | LendingApplicationItem[]
}

const state: P2pAppLendStateInterface = {
  offsetAllApps: 0,
  totalRecordsLeft: null,

  lendingAllApps: null,
  lendingUserApps: null,
}

const actions = {
  async getLendingAllApps (
    { commit, state }: ActionContext<P2pAppLendStateInterface, RootState>,
    params: lendSearchParamsInterface | undefined,
  ): Promise<P2pAppLendStateInterface> {
    const { response, errors } = await this.$api.p2pLendSearch(params)
    if (errors) {
      console.error('api.p2pLendSearch::', errors)
      // notifyError({ text: errors[0] })
      showP2pError(errors)
      return
    }
    commit('setTotalRecordsLeft', response.count)

    // if we increasing offset means using with 'Show more'
    if (params && params.offset) {
      commit('setOffsetAllApps', state.offsetAllApps + SHOW_MORE_STEP)
      commit('addRecordsToLendingApps', response.data)
    }

    // if without pagination (show more btn)
    if (!params || params.offset === undefined || params.offset === 0) {
      commit('setOffsetAllApps', 0)
      commit('setLendingAllApps', response.data)
    }

    return state
  },
  async getLendingUserApps (
    { commit, state }: ActionContext<P2pAppLendStateInterface, RootState>,
  ): Promise<P2pAppLendStateInterface> {
    const { response, errors } = await this.$api.p2pLendUser()
    if (errors) {
      console.error('api.p2pLendUser::', errors)
      // notifyError({ text: errors[0] })
      showP2pError(errors)
      return
    }
    commit('setLendingUserApps', response)
    // console.log('p2pLendUser:::', state.lendingUserApps)
    return state
  },
  // async showMoreLendingApps (
  //   {
  //     commit,
  //     state,
  //     dispatch
  //   }: ActionContext<P2pAppLendStateInterface, RootState>,
  //   { isUser }
  // ) {}
}

const mutations: MutationTree<P2pAppLendStateInterface> = {
  setLendingAllApps (state, pld) {
    state.lendingAllApps = pld.map(o => new LendingApplicationItem(o))
  },
  addRecordsToLendingApps (state, pld) {
    state.lendingAllApps = [
      ...(state.lendingAllApps ? state.lendingAllApps : []),
      ...pld.map(o => new LendingApplicationItem(o)),
    ]
  },
  setLendingUserApps (state, pld) {
    state.lendingUserApps = pld.map(o => new LendingApplicationItem(o))
  },
  setOffsetAllApps (state, pld) {
    state.offsetAllApps = pld
  },
  setTotalRecordsLeft (state, pld) {
    state.totalRecordsLeft = pld
  },
}

export const appLend: Module<P2pAppLendStateInterface, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
}
