import https from 'https'
import { wait } from '@/lib/utils'
// import { Context } from '@nuxt/types'
import { Context } from '@nuxt/types'
import { AxiosRequestConfig } from 'axios'
import { createApi, TApiStructure } from '~/lib/api'
import { notifyError, notifySuccess } from '~/lib/bus'
// import { AUTH_TOKEN_COOKIE_KEY, AUTH_ACCESS_TOKEN_LIFETIME } from '~/constants'

declare module 'vue/types/vue' {
  interface Vue {
    $api: TApiStructure
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $api: TApiStructure
  }
}

// const SHOW_DEBUG_LOG = false

// const apiPlugin: Plugin = (ctx: TCtx, inject) => {
// const BASE_HEADERS = {
//   'Client-Id': +process.env.CLIENT_ID || '',
//   'Client-Secret': process.env.CLIENT_SECRET || '',
// }

// const REFRESH_TIME_OFFSET = 5 * 1000 // 5sec
// let AUTO_LOGOUT_ENABLE = false
// const REFRESHING_PROMISE:any = false

/**
 * Convert date to milliseconds without timezone
 * Need to avoid timezone errors
 *
 * @param {Date|number} [date = new Date()]
 *
 * @return number
 * */
// const getUnixDate = (addSeconds = 0) => new Date().getTime() + addSeconds

// const logout = async (app, runWithoutApi?) => {
//   await wait(1000)

//   await app.store.dispatch('profile/auth/logout', runWithoutApi)
//   await app.router.push('/')
// }
// const autoLogout = async (app, response) => {
//   if (AUTO_LOGOUT_ENABLE) return

//   const isLoggedIn = app.store.state.profile.auth.isLogined
//   const isUnAuthorized = response.data.status === 401

//   if (isUnAuthorized && isLoggedIn) {
//     AUTO_LOGOUT_ENABLE = true

//     await logout(app)

//     AUTO_LOGOUT_ENABLE = false
//   }
// }

export default function ({ $axios, $cacheFetch, app, req }: Context & {$cacheFetch: Function}, inject: Function) {
  // app.$axios.onResponse((response) => {
  //   if (process.server) {
  //     console.log(`[${response.status}] [${new Date()}] ${response.request.path}`)
  //   }
  // })

  // app.$axios.onError((err) => {
  //   if (process.server) {
  //     console.log(`[${err.response && err.response.status}] [${new Date()}] ${err.response && err.response.request.path}`)
  //     // console.log(err.response && err.response.data)
  //   }
  // })

  // const checkIsTokenExpired = ({ token_expire, refresh_token }) => {
  //   if (token_expire && refresh_token) {
  //     const currentDate = getUnixDate()

  //     return token_expire - currentDate < REFRESH_TIME_OFFSET
  //   }

  //   return false
  // }
  // const getClientToken = () => app.$cookies.get(AUTH_TOKEN_COOKIE_KEY) || {}
  // const refreshToken = authTokens => new Promise((resolve, reject) => {
  //   const checkTokens = tokens => !checkIsTokenExpired(tokens) && authTokens.access_token && authTokens.token_expire

  //   if (checkTokens(authTokens)) return resolve(authTokens)

  //   if (REFRESHING_PROMISE) {
  //     return REFRESHING_PROMISE
  //   }

  //   const body = { refresh_token: authTokens.refresh_token }
  //   const options = { headers: BASE_HEADERS }

  //   // eslint-disable-next-line eqeqeq
  //   app.$axios.post('/v2/apirefreshToken', body, options)
  //     .then(async ({ data }) => {
  //       const requestTime = new Date().toLocaleTimeString()

  //       if (data.response && data.response.refresh_token) {
  //         await app.store.dispatch('profile/auth/setAuthTokens', data.response)

  //         resolve(data.response)

  //         if (SHOW_DEBUG_LOG) {
  //           console.log(`[${requestTime}][${process.client ? 'CLIENT' : 'SERVER'}][TOKEN]:`, data.response)
  //         }
  //       }

  //       resolve(authTokens)
  //     })
  //     .catch(async (error) => {
  //       await wait(1000)

  //       const tokens = getClientToken()
  //       if (checkTokens(tokens)) return resolve(tokens)

  //       if (process.client) await logout(app, true)

  //       reject(error)
  //     })
  //     .finally(async () => {
  //       await wait(1000)

  //       if (process.client) {
  //         window.REFRESNING_PROMISE = null
  //       }
  //       REFRESHING_PROMISE = false
  //     })
  // })

  // const logStyles = (color: string) => `background: ${color};border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;`

  const errorHandler = (error: any) => {
    // Object.values(error.response?.data.errors).forEach((m) => {
    //   notifyError({
    //     title: app.i18n.t('common.notify.error'),
    //     text: app.i18n.t(`${m}`),
    //   })
    // })

    console.error(`[API REQUEST ERROR] (${error?.config?.url})`, error)

    return Promise.reject(error)
  }
  const requestHandler = async (config: AxiosRequestConfig) => {
    // config.withCredentials = true
    // config.httpsAgent = new https.Agent({
    //   rejectUnauthorized: !process.server,
    // })
    // let authTokens = app.$cookies.get('auth_tokens') || {}
    await wait(10)
    if (process.server) {
      // if (authTokens.access_token) {
      // config.headers.Authorization = authTokens.access_token
      // }

      // TODO: add x-forwarded-for support on Nginx
      if (req.headers['x-forwarded-for']) {
        config.headers.common['X-Forwarded-For'] = req.headers['x-forwarded-for']
      }

      return config
    }

    // const isLoggedIn = app.store.state.profile.auth.isLogined

    // if (!isLoggedIn) {
    //   return config
    // }

    // if (REFRESHING_PROMISE) {
    //   authTokens = await REFRESHING_PROMISE
    // } else {
    //   authTokens = getClientToken()
    // }

    // Refresh access and refresh tokens
    // if (checkIsTokenExpired(authTokens)) {
    //   if (!REFRESHING_PROMISE) {
    //     REFRESHING_PROMISE = refreshToken(authTokens)
    //     window.REFRESNING_PROMISE = REFRESHING_PROMISE
    //   }

    //   authTokens = await REFRESHING_PROMISE
    // }

    // if (authTokens) {
    // config.headers.Authorization = authTokens.access_token
    // }

    return config
  }
  const responseHandler = (response: { config: any, data: CommonResponse<any, string | [string, { [key: string]: any }]>}) => {
    if (process.server) {
    // @ts-ignore
      console.log(`[${response.status}] [${new Date()}] ${response.request.path}`)
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // @ts-ignore
    const { config } = response

    if (response.data.status === 401) {
      console.log(` [${process.client ? 'CLIENT' : 'SERVER'}][AUTH-ERROR]:`, config?.url)
    }

    if (process.client) {
      // if (response.data.status === 401 && !config._retry && REFRESHING_PROMISE) {
      //   const authTokens = await REFRESHING_PROMISE

      //   if (authTokens.access_token) {
      //     config._retry = true
      //     // config.headers.Authorization = authTokens.access_token

      //     return axiosConfig(config)
      //   }
      // }

      const { notification, warning } = response.data

      if (notification) {
        const translationProp: [string, any] = Array.isArray(notification) ? notification : [notification, undefined]
        notifySuccess({
          duration: 15000,
          title: app.i18n.t('common.notify.success'),
          text: app.i18n.t(...translationProp),
        })
      }

      // eslint-disable-next-line dot-notation
      if (warning && response['config']?.url !== '/v2/apilogout') {
        // await autoLogout(app, response)
        notifyError({
          duration: 15000,
          title: app.i18n.t('common.notify.warning'),
          text: app.i18n.t(response.data.warning),
        })
      }
    }
    // debugger

    const rawReturnUrls = ['/v2/locale']
    return rawReturnUrls.includes(response.config.url) ? response : response.data
  }

  // const apiAdapter = async (config) => {
  //   if (config.headers.Authorization) {
  //     const authTokens = process.server ? (app.$cookies.get(AUTH_TOKEN_COOKIE_KEY) || {}) : getClientToken()
  //     const sameToken = authTokens.access_token === config.headers.Authorization
  //     const expired = checkIsTokenExpired(authTokens)
  //     const currentDate = getUnixDate()
  //     const processing = Boolean(REFRESHING_PROMISE)
  //     let tokenExpireDate = authTokens.token_expire
  //     let refreshed = false

  //     if (expired) {
  //       REFRESHING_PROMISE = REFRESHING_PROMISE || refreshToken(authTokens)

  //       const refreshedTokens:any = await REFRESHING_PROMISE

  //       if (refreshedTokens.access_token && refreshedTokens.token_expire) {
  //         refreshed = true
  //         tokenExpireDate = refreshedTokens.token_expire + AUTH_ACCESS_TOKEN_LIFETIME
  //         config.headers.Authorization = refreshedTokens.access_token
  //       }
  //     }

  //     if (process.client && SHOW_DEBUG_LOG) {
  //       console.log({
  //         url: config?.url,
  //         timeLeft: `${(tokenExpireDate - currentDate) / 1000}s`,
  //         expired,
  //         refreshed,
  //         processing,
  //         sameToken,
  //         Authorization: config.headers.Authorization,
  //       })
  //     }
  //   }

  //   return app.$axios.defaults.adapter(config)
  // }

  // const axiosConfig = app.$axios.create({
  //   withCredentials: true,
  //   // adapter: apiAdapter,
  //   httpsAgent: new https.Agent({
  //     rejectUnauthorized: false, // !process.server,
  //   }),
  // }) as any

  // const axiosConfig = $axios
  // axiosConfig.
  // axiosConfig

  // REQUEST/RESPONSE INTERCEPTORS
  $axios.interceptors.request.use(requestHandler, errorHandler)
  // @ts-ignore-next-line
  $axios.interceptors.response.use(responseHandler, errorHandler)

  // create api module with configured axios
  const appApi = createApi(
    $axios,
    $cacheFetch,
    // $cacheFetch != null
    //   ? $cacheFetch
    //   : (_, fn) => fn(),
  )

  // Inject to context as $api
  inject('api', appApi)
}
