























import Vue from 'vue'
import ExcButton from '~/components/common/form/button/ExcButton.vue'

interface Button {
  id: string | number;
  text: string | number
}

const buttonValidator = (button: Button) => 'id' in button && 'text' in button

export default Vue.extend({
  name: 'ExcFilterButtons',
  components: {
    ExcButton,
  },
  props: {
    selectedButton: {
      type: [String, Number],
      required: true,
    },
    buttons: {
      type: Array,
      required: true,
      validator: (v: Button[]) => v.every(button => buttonValidator(button)),
    },
    useScroll: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes () {
      return {
        'exc-filter-buttons--use-scroll': this.useScroll,
      }
    },
  },
})
