import Vue from 'vue'
import VShowSlide from 'v-show-slide'
import ClickOutside from '~/directives/clickOutside'
import TooltipDirective from '~/directives/TooltipDirective'

Vue.directive('tooltip', TooltipDirective)
Vue.directive('click-outside', ClickOutside)

// directives plugins
Vue.use(VShowSlide)

// Custom directive to listen click outside of the element
// Vue.directive("click-outside", {
//     bind: function(el, binding, vnode) {
//         el.event = function(event) {
//             // here I check that click was outside the el and his childrens
//             if (!(el === event.target || el.contains(event.target))) {
//                 // and if it did, call method provided in attribute value
//                 vnode.context[binding.expression](event);
//             }
//         };
//         document.body.addEventListener("click", el.event);
//     },
//     unbind: function(el) {
//         document.body.removeEventListener("click", el.event);
//     },
// });
// Vue.directive('floatonly', {
//   bind (el, binding) {
//     if (!binding.value) {
//       return
//     }

//     el.keyDownEvent = function (e) {
//       if (e.key === '.' || e.key === ',') {
//         return
//       }

//       [
//         46,
//         8,
//         9,
//         27,
//         13,
//         110,
//       ].includes(e.keyCode) ||
//       (/65|67|86|88/.test(e.keyCode) &&
//         (e.ctrlKey === true || e.metaKey === true) &&
//         (!0 === e.ctrlKey || !0 === e.metaKey)) ||
//       (e.keyCode >= 35 && e.keyCode <= 40) ||
//       ((e.keyCode < 48 || e.keyCode > 57) &&
//         (e.keyCode < 96 || e.keyCode > 105) &&
//         e.preventDefault())
//     }

//     el.addEventListener('keydown', el.keyDownEvent)
//   },
//   unbind (el, binding) {
//     if (!binding.value) {
//       return
//     }

//     el.removeEventListener('keydown', el.keyDownEvent)
//   },
// })
