import { isObject } from 'util'
import { ActionContext, Commit, Dispatch, Module } from 'vuex'
// import { Map, Set } from "immutable"; //
import isString from 'lodash/isString'
import throttle from 'lodash/throttle'
import isFunction from 'lodash/isFunction'
import isArray from 'lodash/isArray'
import merge from 'lodash/merge'
import assign from 'lodash/assign'
import { TranslateResult } from 'vue-i18n'

type FieldTypes = String | Number | Boolean | Object;

export type Translator = () => string | TranslateResult;

// export type Validator = Function;
export type Validator = (
    value,
    fields: Hash<any>,
    rootState: any,
    rootGetters: any,

) => void | string | TranslateResult;

export function mapFieldsToComputed (formKey: string, fields: string[] | object) {
  const isObj = v => !isArray(v) && typeof v === 'object' && v !== null

  const createReducer = (fields) => {
    // If fields is object - return its field name form right side (value)
    // If fields is array - return it string as field name
    const fieldName = fld => isObj(fields) ? fields[fld] : fld

    return (acc, fld) => {
      acc[fld] = {
        get () {
          if (typeof this.$store.getters[`${formKey}/field`] !== 'function') {
            throw new TypeError(
              'did you declare right formKey ? must be with module namespace (namespace/formName) + check :form-key for input',
            )
          }
          return this.$store.getters[`${formKey}/field`](fieldName(fld))
        },
        set (value) {
          this.$store.dispatch(`${formKey}/changeField`, { value, field: fieldName(fld) })

          return value
        },
      }

      return acc
    }
  }

  if (isObj(fields)) {
    return Object.keys(fields).reduce(createReducer(fields), {})
  } else if (isArray(fields)) {
    return fields.reduce(createReducer(fields), {})
  } else {
    throw new Error('fields param should be array or object')
  }
}

export interface InterceptorContext {
    value: any;
    commit: Commit;
    dispatch: Dispatch;
    fields: {[key: string]: any};
    // fields: Hash<any>;
    rootState: any;
    rootGetters: any;
    meta: any;
}

export interface FieldConfig {
    type: StringConstructor | NumberConstructor | BooleanConstructor | ObjectConstructor;
    validators?: Validator[] | Function;
    interceptor?(ctx: InterceptorContext): any;
}

type FieldsHash<TField> = { [K in keyof TField]: FieldConfig };
export type DefaultValues = {[field: string]: any};

interface FormOptions<TField> {
    fields: FieldsHash<TField>;
    defaultValues?: DefaultValues;
    throttle?: number;
    onSubmit(ctx: ActionContext<FormState<TField>, any>, payload: any, fields: any): void;
}

type FieldsState<TFields, K extends keyof TFields = keyof TFields> = Map<
    K,
    TFields[K]
>;
type ErrorsState<TFields> = Map<keyof TFields, string[]>;

interface FormState<TFields> {
    submited: boolean;
    fetchStatus: FetchStatus;
    touched: TFields[];
    // touched: Set<keyof TFields>;
    custom: Object; // handle manually (@onBlur...)
    fields: FieldsState<TFields>;
    errors: {[key: string]: string[]};
    // errors: ErrorsState<TFields>;
}

interface SubmitMeta {
    validate?: boolean;
    validateOnly?: string[];
}

export class Form<TFields = any> implements Module<FormState<TFields>, any> {
    readonly fields: FieldsHash<TFields>;
    readonly defaultValues: DefaultValues | undefined;
    readonly onSubmit: (
        context: ActionContext<FormState<TFields>, any>,
        payload: any,
        fields?: any,
    ) => void;

    public namespaced = true;
    public state: FormState<TFields>;

    constructor (private options: FormOptions<TFields>) {
      this.fields = this.options.fields
      this.defaultValues = this.options.defaultValues
      this.onSubmit = this.options.onSubmit
      this.state = this.getDefaultState()

      if (this.options.throttle) {
        this.throttledValidate = throttle(
          this.throttledValidate,
          this.options.throttle,
        )
      }
    }

    get mutations () {
      const self = this

      return {
        setFetchStatus (state, status: FetchStatus) {
          state.fetchStatus = status
        },
        setSubmited (state, submited: boolean) {
          state.submited = submited
        },
        reset (state, params: { clearFields: boolean }) {
          state.submited = false
          state.valid = false

          state.errors = {}
          // state.errors = state.errors.clear();
          state.fetchStatus = 'init'
          state.touched = []
          // state.touched = state.touched.clear();

          if (params && params.clearFields) {
            state.fields = self.generateFields()
          }
        },
        setErrors (state, { errors }) {
          const map = Object.keys(errors || {})
            .filter(k => state.fields[k] !== undefined || k === 'form')
            .reduce((acc, k) => {
              acc[k] = errors[k]
              return acc
            }, {})
          // const map = Map(errors)
          //     .filter((value, key) => state.fields.has(key) || key === "form");

          state.errors = assign({ ...state.errors }, map)
          // state.errors = state.errors.merge(map);
        },
        setFieldValue (state, { field, value }) {
          state.fields[field] = value
          state.fields = { ...state.fields }
          // state.fields = state.fields.set(field, value);
        },
        setTouched (state, { field }) {
          state.touched.push(field)
          // state.touched = state.touched.add(field);
        },
        setCustom (state, { field, value }) {
          if (!isObject(value)) throw new Error('Custom form data for field must be Object')

          state.custom = merge(state.custom, {
            [field]: value,
          })
        },
        setData (state, { fields }) {
          // debugger
          if (Object.keys(fields).some(k => state.fields[k] === undefined)) {
            // if (Object.keys(fields).some(k => !state.fields.has(k))) {
            throw new Error(
              "You can't set fields that don't exist in config",
            )
          }

          state.fields = assign({ ...state.fields }, fields)
          // state.fields = state.fields.merge(fields);
        },
      }
    }

    get actions () {
      const self = this

      return {
        async setData ({ commit, state, dispatch }, { fields, validate }) {
          commit('setData', { fields })

          if (validate) {
            const validateFields = isArray(validate)
              ? validate
              : Object.keys(fields)

            for (const field of validateFields) {
              await dispatch('validate', { field })
            }
          }

          return Promise.resolve()
        },
        changeField (
          { commit, state, dispatch, rootState, rootGetters },
          { field, value, noInterceptors, noValidate, meta, clearErrors },
        ) {
          if (state.touched[field] !== undefined) {
            // if (!state.touched.has(field)) {
            commit('setTouched', { field })
          }

          // if (isString(value)) {
          //     value = value.trim();
          // }

          if (self.fields[field].interceptor && !noInterceptors) {
            value = self.fields[field].interceptor({
              value,
              commit,
              fields: state.fields,
              // fields: state.fields.toObject(),
              rootState,
              rootGetters,
              dispatch,
              meta,
            })
          }

          commit('setFieldValue', { field, value })

          if (noValidate) {
            return
          }

          if (clearErrors) {
            this.clearErrors(commit, { field })
          }

          self.throttledValidate(dispatch, { field })
        },
        async validate ({ commit, state, getters, rootState, rootGetters }, { field }) {
          const value = getters.field(field)
          const errors = self.validateField(
            field,
            value,
            state.fields,
            // state.fields.toObject(),
            rootState,
            rootGetters,
          )

          if (typeof getters.error(field) === 'undefined' && errors.length === 0) {
            return Promise.resolve()
          }

          commit('setErrors', {
            errors: { [field]: errors, form: [] },
          })

          return Promise.resolve()
        },
        async validateAll (
          { commit, dispatch, state },
          params,
        ) {
          const { validateOnly } = params || {} as any
          const fields = state.fields

          if ((validateOnly || []).some(f => fields[f] === undefined)) {
            // if ((validateOnly || []).some(f => !fields.has(f))) {
            throw new Error(
              'validateOnly should contain only existing fields',
            )
          }

          await Promise.all(
            Object.keys(fields)
            // fields
              .filter(
                field =>
                // (f, field) =>
                  (validateOnly || []).length !== 0
                    ? validateOnly.includes(field)
                    : true,
              )
              .map(field => dispatch('validate', { field })),
            // .map((f, field) => dispatch("validate", { field }))
          )

          return Promise.resolve()
        },
        async submit (
          ctx,
          params: { payload: any; meta: SubmitMeta },
        ) {
          const { meta, payload } = params || {} as any

          if (!ctx.state.submited) {
            ctx.commit('setSubmited', true)
          }

          const validate = meta && meta.validate ? meta.validate : true

          if (validate) {
            await ctx.dispatch('validateAll', {
              validateOnly: meta && meta.validateOnly,
            })
          }

          const isValid = ctx.getters.isValid(meta && meta.validateOnly)

          if (validate && !isValid) {
            console.error('Form is not valid')
            return Promise.resolve()
          }

          await self.onSubmit(ctx, payload, ctx.getters.allFields)

          return Promise.resolve()
        },
        clearErrors ({ commit }, { field }) {
          commit('setErrors', {
            errors: { [field]: [], form: [] },
          })
        },
      }
    }

    get getters () {
      return {
        loading (state) {
          return state.fetchStatus === 'loading'
        },
        isValid: state => (validateOnly: string[] = []) => {
          return !Object.keys(state.errors)
            .some((field) => {
              const errs = state.errors[field]
              return validateOnly.length !== 0
                ? validateOnly.includes(field) && errs.length !== 0
                : errs.length !== 0
            })

          // return !state.errors.some(
          //     (e, field) =>
          //         validateOnly.length !== 0
          //             ? validateOnly.includes(field) && e.length !== 0
          //             : e.length !== 0
          // );
        },
        dirty: (state) => {
          return state.touched.length !== 0
          // return state.touched.size !== 0;
        },
        field: state => (field) => {
          return state.fields[field]
          // return state.fields.get(field);
        },
        allFields: (state) => {
          return state.fields
          // return state.fields.toObject();
        },
        error: state => (field) => {
          return (state.errors[field] || [])[0]
          // return (state.errors.get(field) || [])[0];
        },
      }
    }

    private getDefaultState (): FormState<TFields> {
      return {
        submited: false,
        fetchStatus: 'init',
        touched: [],
        // touched: Set(),
        custom: {},
        fields: this.generateFields(),
        errors: {},
        // errors: Map()
      }
    }

    private throttledValidate (dispatch, { field }) {
      dispatch('validate', { field })
    }

    private generateFields (): FieldsState<TFields> {
      return Object.keys(this.fields).reduce(
        (acc, fieldName) => {
          const field = this.fields[fieldName]
          const defaultValue = this.defaultValues && this.defaultValues[fieldName] !== undefined ? this.defaultValues[fieldName] : this.getFieldDefaultValue(field.type)

          acc[fieldName] = defaultValue
          return acc
          // return acc.set(fieldName as any, defaultValue as any);
        }, {} as FieldsState<TFields>,
        // Map() as FieldsState<TFields>
        // Map() as FieldsState<TFields>
      )
    }

    private getFieldDefaultValue (type: FieldTypes): string | number | boolean {
      if (!type) {
        throw new Error('Type is required')
      }

      return (type as any)()
    }

    private validateField (
      field: string,
      value: any,
      fields: Hash<any>,
      rootState: any,
      rootGetters: any,
    ): string[] {
      const { validators } = this.fields[field]

      let validatorsArray
      if (typeof validators === 'function') {
        validatorsArray = validators(value, fields, rootState, rootGetters)
      } else {
        validatorsArray = validators
      }

      const errors = (validatorsArray || []).reduce((acc, validator) => {
        if (!isFunction(validator)) {
          throw new Error('Validator should be function.')
        }

        let result
        try {
          result = validator(value, fields, rootState, rootGetters)
        } catch (e) {
          console.error(e)
        }

        if (result) {
          if (!isString(result)) {
            throw new Error(
              'Validator should return string if invalid.',
            )
          }

          acc.push(result)
        }

        return acc
      }, [])

      return errors
    }
}

// export function createForm<TFields>(name: string, config): Form<TFields> {
//     window.FORMS.push(name);

//     return new Form(config);
// }
