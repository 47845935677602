import { WalletBalanceBaseModel } from './WalletBalanceBaseModel'
import { WalletPaymentMethod } from './WalletPaymentMethod'
import { WalletPaymentMethodDeposit } from './WalletPaymentMethodDeposit'
import { WalletPaymentMethodWithdrawal } from './WalletPaymentMethodWithdrawal'

export class WalletDepositWithdrawDataModel extends WalletBalanceBaseModel {
  readonly marketList: Pair[] // список пар для трейда
  readonly rawPayments: IWalletPaymentMethodData[]
  readonly payments: WalletPaymentMethod[]

  constructor (o: IWalletDepositWithdrawData) {
    super(o)

    this.marketList = o.marketList // список пар для трейда
    this.rawPayments = o.paymentList

    this.payments = this.paymentFactory(o.paymentList)
  }

  paymentFactory (paymentList: IWalletPaymentMethodData[]) {
    if (this.action === 'DEPOSIT') {
      // NOTE: temporary for testing wrap every payment method in
      // WalletPaymentMethodDeposit
      return paymentList.map(p => new WalletPaymentMethodDeposit(p))

      // if (this.isCrypto) {
      //     // for crypto use only that payments which has address
      //     return paymentList
      //         // .filter(p => p.address && p.address.address)
      //         .map(p => new WalletPaymentMethod(p))

      // } else if (this.isFiat) {
      //     // for fiat use all payments
      //     return paymentList.map(p => new WalletPaymentMethodDeposit(p))
      // }
    } else if (this.action === 'WITHDRAWAL') {
      return paymentList.map(p => new WalletPaymentMethodWithdrawal(p))
    }
  }
}
