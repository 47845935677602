<template>
  <!-- use @click.native on this component -->
  <exc-link
    v-bind="$attrs"
    :force-use-link="forceUseLink"
    class="exc-mob-dropdown-item"
    no-decoration
  >
    <template #default="{ isActive }">
      <div
        class="exc-mob-dropdown-item__container"
      >
        <div
          class="exc-mob-dropdown-item__name"
          :class="{ 'txt--main': (isActive || isSubmenuSelected), 'txt--color-04': (!isActive && !isSubmenuSelected) }"
        >
          <!-- <div class="exc-mob-dropdown-item__dot-container mr-16">
            <slot name="dot">
              <exc-dot
                :color="(isActive || isSubmenuSelected) ? 'main' : 'grey'"
                class="ml-4"
              />
            </slot>
          </div> -->
          <div class="exc-mob-dropdown-item__name-text ml-26">
            <!-- <div> -->
            <!-- name & icon -->
            <span class="txt txt--14px txt--height-18px txt--height-18px">
              <span>
                <slot name="icon" />
              </span>
              <slot name="name" />
            </span>

            <!-- description -->
            <!-- <br> -->
            <span
              v-if="$slots.description"
              class="txt txt--10px"
            >
              <slot name="description" />
            </span>
            <!-- </div> -->
          </div>
        </div>

        <!-- icon (on right side) -->
        <div
          v-if="$slots.icon"
          class="exc-mob-dropdown-item__icon ml-auto"
        >
          <slot name="icon" />
        </div>
      </div>
    </template>
  </exc-link>
</template>

<script>
import ExcLink from '@/components/common/navigation/link/ExcLink.vue'
// import Vue from 'vue'

export default {
  name: 'ExcMobDropdownItem',
  components: {
    ExcLink,
  },
  props: {
    forceUseLink: { type: Boolean, default: false },
    isSubmenuSelected: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-mob-dropdown-item {
  display: block;

  &__container {
    height: toRem(32px);
    display: flex;
    align-items: center;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
      width: 2px;
      transition: all .2s;
      background-color: transparent;
    }
  }

  &__name {
    display: flex;
  }

  &__name-text {
    flex: 1;
    display: flex;
    // align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  // &__dot-container {
  //   width: 30px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   // display: inline-block;
  // }

  // &__icon {}
}
</style>
