import { BurningStageItemDto } from '@/lib/data-models/burning-stage/BurningStageItemDto'
import { Module } from 'vuex'
import { notifyError } from '@/lib/bus'
import { actionWrapper } from '@/lib/utils'

export interface IBurningStageState {
  burningItems: IBurningStageResponseItem[]
}

const BurningStageModule: Module<IBurningStageState, any> = {
  namespaced: true,
  state: () => ({
    burningItems: [],
  }),
  getters: {
    getBurningItems (state: IBurningStageState) {
      return state.burningItems.map(i => new BurningStageItemDto(i))
    },
  },
  mutations: {
    setBurningItems (state, { response }) {
      state.burningItems = response.map((o: IBurningStageResponseItem) => new BurningStageItemDto(o))
    },
  },
  actions: {
    getBurningStage (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getBurningStageList(),
        mutationName: 'setBurningItems',
        errorHandler: ({ response: { errors } }) => {
          notifyError({
            title: 'getBurningStageList error',
            text: errors.message[0],
          })
        },
      }).bind(this)(...args)
    },
    // getBurningStage: actionWrapper({
    //   apiRequest () {
    //     return this.$api.getBurningStageList()
    //   },
    //   mutationName: 'setBurningItems',
    //   errorHandler: ({ response: { errors } }) => {
    //     notifyError({
    //       title: 'getBurningStageList error',
    //       text: errors.message[0],
    //     })
    //   },
    // }),
  },
}

export default BurningStageModule
