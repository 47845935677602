<template>
  <div class="exc-mob-nav-item" v-on="$listeners">
    <!-- <pre>{{ $slots }}</pre> -->
    <div class="exc-mob-nav-item__container">
      <exc-link
        v-bind="$attrs"
        class="exc-mob-nav-item__item-content pl-12 pr-12"
        no-decoration
        :class="{
          'exc-mob-nav-item__item-content--is-active-menu-item': openDropdown,
        }"
        @click.native="onClickItemName"
      >
        <div class="exc-mob-nav-item__name">
          <span
            :class="{
              'txt--main': isMenuGroupSelected,
              'txt--color-04': !isMenuGroupSelected && onlyTheme === 'dark',
              'txt--color-04': !isMenuGroupSelected && onlyTheme === 'light',
              'txt--color-04': !isMenuGroupSelected && !onlyTheme,
              classes,
            }"
            class="txt txt--14px txt--height-18px flx txt--color-04"
          >
            <slot name="mobile-menu-icon" />

            <slot name="name" v-bind="{ isMenuGroupSelected }" />
          </span>
        </div>
        <div
          v-if="$slots.dropdown || $slots.icon"
          class="exc-mob-nav-item__icon"
        >
          <slot name="icon" />

          <div
            v-if="$slots.dropdown && !$slots.icon"
            class="exc-mob-nav-item__arrow-container pl-4"
          >
            <rdx-arrow
              :direction="!openDropdown ? 'bottom' : 'top'"
              arrow-direction="bottom"
              class="exc-mob-nav-item__arrow"
              :class="{
                'txt--main': isMenuGroupSelected,
                'txt--color-04': !isMenuGroupSelected && onlyTheme === 'dark',
                'txt--color-04': !isMenuGroupSelected && onlyTheme === 'light',
                'txt--color-04': !isMenuGroupSelected && !onlyTheme,
              }"
            />
          </div>
        </div>
      </exc-link>

      <!-- :style="{ 'max-height': dropdownHeight + 'px' }" -->
      <div
        v-if="$slots.dropdown"
        :class="{ 'exc-mob-nav-item--open-dropdown': openDropdown }"
        class="exc-mob-nav-item__dropdown"
      >
        <slot name="dropdown" />
      </div>
    </div>
  </div>
</template>

<script>
import ExcLink from '@/components/common/navigation/link/ExcLink.vue'
import { onlyThemePropValidator } from '@/lib/utils'
import RdxArrow from '@/components/common/svg/RdxArrow.vue'

export default {
  name: 'ExcMobNavItem',
  components: {
    RdxArrow,
    ExcLink,
  },

  props: {
    onlyTheme: onlyThemePropValidator(),
    isMenuGroupSelected: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      openDropdown: false,
      dropdownHeight: 0,
    }
  },
  watch: {
    isMenuGroupSelected (v) {
      this.openDropdown = v
    },
  },
  mounted () {
    this.openDropdown = this.isMenuGroupSelected
  },
  methods: {
    onClickItemName (_) {
      if (this.$slots.dropdown) {
        this.openDropdown = !this.openDropdown
        this.openDropdown ? this.$emit('open') : this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-mob-nav-item {
  cursor: pointer;

  &--open-dropdown {
    max-height: 1000px !important;
    overflow: auto !important;
    @include webkit-scroll;
  }

  // &__container {}

  &__item-content {
    height: toRem(44px);
    display: flex;
    align-items: center;

    &--is-active-menu-item {
      // background-color: $c-color-01;
    }
  }

  &__name {
    flex: 1;
  }

  // &__icon {}

  // &__arrow {
  //   // transform-origin: center;
  //   // transition: all .2s;
  // }

  &__dropdown {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s;
  }

  &__arrow-container {
    display: flex;
  }
}
</style>
