import Decimal from "decimal.js-light"

interface BorrowingApplicationItemInterface {
    amount: string
    days: number
    status: number
    username: string
    percent: number
    id_borrow: number
}

export class BorrowingApplicationItem {
    amount: number
    days: number
    status: number
    username: string
    percent: number
    borrowId: number
    constructor(o: BorrowingApplicationItemInterface) {
        this.amount = parseFloat(new Decimal(o.amount).toFixed(2))
        this.days = o.days
        this.status = o.status
        this.username = o.username    
        this.percent = parseFloat(new Decimal(o.percent).toFixed(2))
        this.borrowId = o.id_borrow
        // console.warn('BorrowingApplicationItemInterface', o)
    }
}