// import { forEach } from 'lodash'
// import { notTradableIeoTickers } from '@/lib/hardcode-data/tickers'
// import { filterByLeftTicker, filterByRightTicker } from '@/lib/utils'

// declare global {

//   interface Window {
//     MARKET_TABS: RawMarketListTab[]
//     MSStream: any;
//     APP_NAME: string;
//     LOGINED: boolean;
//     MARKET_LIST: PairName[];
//     DEFI_LIST: PairName[];
//     FIAT_TICKERS: Ticker[]
//     TICKER_LIST: Ticker[];
//     DEFAULT_PAIR: PairName;
//     WEBSOCKET_TOKEN: string;
//     IS_LOADING_TIME: Boolean;
//     APP_LANGUAGE: string;
//     LANGUAGES: { code: string; id: number, name: string }[];
//     PARSED_LANGUAGES: string[];
//     WS_URL: string;
//     TWITTER_URL: string;
//     FACEBOOK_URL: string;
//     TELEGRAM_URL: string;
//     REDDIT_URL: string;
//     INSTAGRAM_URL: string;
//     LINKED_IN_URL: string;
//     MEDIUM_URL: string;
//     YOUTUBE_URL: string;
//     CSS_VARS: Hash<string>;
//     DARK_MODE: boolean;
//     CAPTCHA_ENABLED: boolean;
//     // TRANSCODE_PREFIX: string;
//     ECHO_PORT: string;
//     API_URL: string;
//     OTC_USER: boolean, // if user allowed to got to otc admin
//     P2P_FIAT: [{ tiker: Ticker }]
//     P2P_CURRENCY: { tiker: Ticker }
//     STABLE_TICKERS: Ticker[]
//     MARGIN_LIST: PairName[]
//     REFRESNING_PROMISE: any;

//     // GTest: any;
//     LAST_CLICKED_LINK: string;
//     // FORMS: string[];

//     // KLINE_DAY_CHART: any;

//     GLOBAL_FNS: any
//     // SHOULD_LOAD_YANDEX_METRICA: boolean
//     ym: any // yandex metrica
//     zE: Function // zendesk widget
//     twttr: any // twitter
//     zESettings: any // zendesk settings

//     // preferences for MarketList rows
//     MARKET_LIST_PAIR_HIGHLIGHT: MarketListRowsPreferences
//     DYNAMIC_IMAGES: any
//   }
// }

// const { content: value } = document.querySelector(
//   "meta[name='config']",
// ) as HTMLMetaElement
// const variables = JSON.parse(value)

// variables.MARKET_LIST = variables.MARKET_LIST
//   .filter(filterByLeftTicker('E2C'))
//   .filter(filterByLeftTicker('E2C-EX'))
//   .filter(filterByRightTicker('E2C'))

// window.FORMS = [];

// forEach(variables, (value, key) => {
//   switch (key) {
//     case 'CAPTCHA_ENABLED':
//       if (process.env.NODE_ENV === 'production') {
//         return
//       }
//       break
//     case 'LANGUAGES':
//       window.PARSED_LANGUAGES = value.map(({ code }) => code)
//       break
//     case 'MARKET_LIST': {
//       window.TICKER_LIST = Array.from(
//         value.reduce((acc, pair) => {
//           pair
//             .split('_')
//             .forEach(t => acc.add(t))

//           return acc
//         }, new Set()),
//       )

//       // add to TICKER_LIST tickers which will not be available for trading
//       // but available for deposit and withdrawal
//       notTradableIeoTickers.map(t => window.TICKER_LIST.push(t))
//     }
//   }

//   window[key] = value
// })

if (process.client) {
  window.GLOBAL_FNS = (globalFunctions)()

  const appLanguage = document.documentElement.lang
  window.APP_LANGUAGE = appLanguage
}

function globalFunctions () {
  return {
    activateReferralForMobileApp,
  }

  async function activateReferralForMobileApp () {
    // const api = await require('@/lib/api.ts').default as any
    this.$api.setReferralForMobileApp().catch(err => console.error(err))
  }
}
