interface IActionHandler {
  action: string[] | Function
  params?: any
}

export class SocketActionsMapper {
  private readonly defaultActions: Hash<IActionHandler>
  private readonly actionsMap: Hash<IActionHandler>

  constructor (actionsMap: Hash<string[]>) {
    this.defaultActions = {}
    const actionData = Object.entries(actionsMap)
    actionData.forEach((action) => {
      this.defaultActions[action[0]] = {
        action: action[1],
      }
    })

    this.actionsMap = { ...this.defaultActions }
  }

  getActions () {
    return this.actionsMap
  }

  // replace handler with new one
  changeActionHandler (actionType, action, params?) {
    this.actionsMap[actionType] = {
      action,
      params,
    }
  }

  // add handler to the actions array (need for trade table tabs)
  addActionHandler (actionType, action, params?) {
    this.actionsMap[actionType] = {
      action: [...(this.actionsMap[actionType].action as string[]), action],
      params,
    }
  }

  resetActionHandler (actionType) {
    this.actionsMap[actionType] = this.defaultActions[actionType]
  }
}
