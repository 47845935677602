
type KeyFieldData = 'memo' | 'cardNumber' | 'address' | 'account'
type DataObject = {[key in KeyFieldData]?: string}

export class WalletSavedPaymentAddress {
  ticker: Ticker
  paymentMethodKey: string
  paymentMethodName: string
  image: string
  savedData: DataObject
  id: number
  savedDataFormatted: any
  constructor (o: IAddressManagementItem) {
    this.ticker = o.ticker
    this.paymentMethodKey = o.paymentMethodKey
    this.paymentMethodName = o.paymentMethodName
    this.image = o.image
    this.savedData = o.savedData
    this.id = o.id
    this.savedDataFormatted = (() => {
      const labels = { // TODO: translate
        memo: 'user.addresses.memo',
        cardNumber: 'user.addresses.cardNumber',
        address: 'user.addresses.address',
        account: 'user.addresses.account',
      }

      const val = Object.keys(this.savedData).reduce((acc, key) => {
        acc.push({
          label: labels[key],
          value: this.savedData[key],
        })
        return acc
      }, [])
      return val
    })()
  }
}
