import { notifyError } from '@/lib/bus'
import debounce from 'lodash/debounce'
import { visibilityChangeService } from './visibility-change/visibilityChangeService'

interface JSONRPCSend {
  id?: number
  method: string
  params: any[]
}

interface JSONRPCAnswer {
  id?: number
  result: any[]
  error?: null
}

type WsStatusListener = (newStatus: string) => any

export class WebsocketService {
  public socket: WebSocket

  private url: string
  private reconnected: boolean
  private _wsStatus: string = 'init'
  private authorizeFetchStatus: FetchStatus = 'init'
  private paused = false

  private id: number = 1
  private authReconnect: number = 0
  private reconnectTime: number = 0
  private keepAliveTimerId: any
  private timeoutId: any

  public customListeners: Map<string, Function[]> = new Map()
  private subscribeRequests: Map<string, any> = new Map()
  private reconnectHandlers: Array<() => any> = []
  private wsStatusListeners: Array<WsStatusListener> = []
  private dispatchEventCallback: Function

  private token: string

  constructor () {
    if (!process.client) return

    // this.url = window.WS_URL
    // TODO: DELETE THIS WHEN WS WILL BE READY
    this.url = process.env.WS_URL || ''
    this.init()

    visibilityChangeService.sockets.add('all_websocket_subscribers', {
      onDisable: () => {
        this.paused = true
        this.socket.close()
      },
      onEnable: () => {
        this.init()
        this.paused = false
      },
    })
  }

  public get wsStatus () {
    return this._wsStatus
  }

  public set wsStatus (value) {
    this._wsStatus = value
    this.publishSubscribeWsStatus(value)
  }

  public close () {
    this.socket.close()
  }

  public logout () {
    this.authorizeFetchStatus = 'init'
  }

  public setUrl (url) {
    this.url = url
  }

  public setToken (token) {
    this.token = token
  }

  // public listen(method: string, callback: any) {
  //     const list = this.customListeners.get(method) || [];
  //
  //     list.push(callback);
  //
  //     this.customListeners.set(method, list);
  // }
  //
  // public unlisten(method: string, callback: any) {
  //     const list = (this.customListeners.get(method) || []).filter( listener => !isEqual(listener, callback));
  //
  //     this.customListeners.set(method, list);
  // }

  // public subscribeWsStatus(callback: WsStatusListener): void {
  //     this.wsStatusListeners.push(callback);
  // }

  // public vuexPlugin = (store: Store<any>) => {
  //     this.store = store;
  // };

  public authorize (): Promise<CommonResponse> {
    if (this.authorizeFetchStatus === 'ok') {
      return Promise.resolve({ response: {}, errors: null, status: 200 } as any)
    }

    this.authorizeFetchStatus = 'loading'

    const socketToken = this.token || localStorage.getItem('socketToken')

    if (!socketToken) {
      return
    }

    return this.fetch('server.auth', [socketToken, 'web']).then((p) => {
      if (p.errors && (p.errors.code === 5 || p.errors.code === 2)) {
        this.authReconnect++

        if (this.authReconnect >= 3) {
          notifyError({
            // title: window.I18N.t('common.notify.wsTimeout.title'),
            // text: window.I18N.t('common.notify.wsTimeout.text'),
          })

          this.socket.close()

          return Promise.resolve(p)
        }

        return this.authorize()
      }

      this.authReconnect = 0
      this.authorizeFetchStatus = 'ok'

      return Promise.resolve(p)
    })
  }

  public onReconnect (handler: () => any): void {
    this.reconnectHandlers.push(handler)
  }

  public fetch (
    method: string,
    params: any[] = [],
    shouldAuthorize: boolean = false,
  ): Promise<any> {
    if (this.wsStatus === 'closed') {
      return Promise.resolve({
        response: null,
        errors: { message: 'WebSocket for trading server is closed' },
        status: 402,
      })
    }

    params = params || []

    const data: JSONRPCSend = {
      method,
      params,
    }

    const allowed = this.processSubscriber(method, params, shouldAuthorize)
    if (!allowed) return

    const id = this.id++

    data.id = id

    const stringified = JSON.stringify(data)

    return this.waitForConnection()
      .then(() =>
        !shouldAuthorize
          ? Promise.resolve()
          : this.authorizeFetchStatus === 'init'
            ? this.authorize()
            : this.waitForAuthorizeOnWs(),
      )
      .then(
        () =>
          new Promise((resolve) => {
            const handleFetch = ({ data }: MessageEvent) => {
              const parsed = JSON.parse(data) as JSONRPCAnswer

              if (parsed.id === id) {
                const response = parsed.error
                  ? { response: null, errors: parsed.error, status: 400 }
                  : { response: parsed.result, errors: null, status: 200 }

                resolve(response)

                this.socket.removeEventListener('message', handleFetch)
              }
            }

            this.socket.addEventListener('message', handleFetch)

            if (this.socket.readyState === this.socket.OPEN) {
              this.socket.send(stringified)
            }
          }),
      )
      .catch((err) => {
        return Promise.resolve({ response: null, errors: err, status: 400 })
      })
  }

  private init (): void {
    this.timeoutId && clearTimeout(this.timeoutId)
    this.keepAliveTimerId && clearTimeout(this.keepAliveTimerId)
    this.id = 0
    this.authorizeFetchStatus = 'init'
    this.wsStatus = 'init'
    this.socket = new WebSocket(this.url)

    this.socket.onopen = this.handleOpen
    this.socket.onclose = this.handleClose

    this.keepAlive()
    this.fetchSubscribersIfExist()
    this.dispatchEvents()
  }

  private dispatchEvents () {
    this.socket.addEventListener('message', ({ data }) => {
      this.dispatchEventCallback(data)
    })
  }

  public setDispatchEvent (eventCallback) {
    this.dispatchEventCallback = eventCallback
  }

  private handleOpen = (): void => {
    this.wsStatus = 'ok'

    if (this.reconnected) {
      this.reconnectHandlers.forEach(f => f())
    }
  }

  private handleClose = (): void => {
    this.wsStatus = 'closed'
    if (!this.paused) {
      this.reconnect()
    }
  }

  private keepAlive () {
    this.fetch('server.ping')

    this.keepAliveTimerId = setTimeout(this.keepAlive.bind(this), 30000)
  }

  private waitForConnection (): Promise<void> {
    return new Promise((resolve, reject) => {
      const handlePending = () => {
        switch (this.wsStatus) {
          case 'ok':
            return resolve()
          case 'closed':
            return reject({ message: 'Socket is closed' })
          default:
            setTimeout(handlePending, 1000)
        }
      }

      handlePending()
    })
  }

  private waitForAuthorizeOnWs (): Promise<any> {
    return new Promise((resolve) => {
      const handlePending = () => {
        if (this.authorizeFetchStatus === 'ok') {
          return resolve(true)
        } else {
          setTimeout(handlePending, 5000)
        }
      }

      handlePending()
    })
  }

  // public isUpdateEvent(data: any): data is JSONRPCSend {
  //     return data.hasOwnProperty("method") && data.method.includes("update");
  // }

  private reconnect = debounce(
    () => {
      this.reconnectTime = 1000 + this.authReconnect * 100
      this.reconnected = true
      this.init()
    },
    this.reconnectTime > 10000 ? 10000 : this.reconnectTime,
  )

  public recreate () {
    this.reconnect()
  }

  private processSubscriber (method: string, params, shouldAuthorize: boolean) {
    if (method.split('.').length > 2) console.error('Wrong method name', method)

    const [methodName, methodAction] = method.split('.')

    if (methodAction === 'unsubscribe') {
      return this.subscribeRequests.delete(methodName + '.subscribe')
    }

    if (methodAction === 'subscribe') {
      // already subscribed
      // if (this.subscribeRequests.get(method)) {
      //     // TODO: refactor duplicate subscriptions, should not go here, when !this.paused

      //     // skip duplicates if not paused, when paused return true in order to fetch again
      //     return !!this.paused
      // }

      return this.subscribeRequests.set(method, [
        method,
        params,
        shouldAuthorize,
      ])
    }

    return true
  }

  private fetchSubscribersIfExist () {
    for (const args of this.subscribeRequests.values()) {
      this.fetch(args[0], args[1], args[2])
    }
  }

  private publishSubscribeWsStatus (newValue: string): void {
    this.wsStatusListeners.forEach(f => f(newValue))
  }
}

// .setUrl(store.state.globals.wsUrl) // TODO: WS_ERROR
const service = process.client ? new WebsocketService() : null
export default service
// export default WebsocketService;
