import {
  escapeComma,
  isFloat,
  parseNumber,
  valFromObj,
  formatNumber,
  actionWrapper,
} from '@/lib/utils'
import { Module } from 'vuex'
import { Decimal } from 'decimal.js-light'
import capitalize from 'lodash/capitalize'
// import { notifySuccess, notifyError } from "@/lib/bus";
import Vue from 'vue'
import {
  BLOCKED_BUY_SELL_PRICE_TICKERS,
  BUY_SELL_FORM_LIMIT,
  BUY_SELL_FORM_OCO,
  BUY_SELL_FORM_STOP_LIMIT,
} from '@/lib/constants'
// import { $t } from '@/lib/i18n'
import { ConfirmationModal } from '@/lib/ConfirmationModal'

type BuySellFormFields = {
  buy: { price: string; amount: string; total: string }
  sell: { price: string; amount: string; total: string }
}
export interface BuySellState {
  fee: number
  feePlc: number
  feePlcBuy: number
  form: {
    [BUY_SELL_FORM_LIMIT]: BuySellFormFields
    [BUY_SELL_FORM_STOP_LIMIT]: BuySellFormFields
    [BUY_SELL_FORM_OCO]: BuySellFormFields
    clearErrorsTimeToWatch: number
  }
}

type BuySellMutationsProps = {
  formName:
    | typeof BUY_SELL_FORM_LIMIT
    | typeof BUY_SELL_FORM_STOP_LIMIT
    | typeof BUY_SELL_FORM_OCO
  type: 'buy' | 'sell'
  value: string
  currentPair: Pair
}

// export const BLOCKED_BUY_SELL_PRICE_TICKERS = ['PLC']

const tradeTypes = ['buy', 'sell'] as TradeType[]

const BuySellModule: Module<BuySellState, any> = {
  namespaced: true,
  state: () => ({
    feePlc: 0,
    feePlcBuy: 0,
    fee: 0,

    form: {
      [BUY_SELL_FORM_LIMIT]: {
        buy: {
          price: '',
          amount: '',
          total: '',
        },

        sell: {
          price: '',
          amount: '',
          total: '',
        },
      },
      [BUY_SELL_FORM_STOP_LIMIT]: {
        buy: {
          price: '',
          amount: '',
          total: '',
        },

        sell: {
          price: '',
          amount: '',
          total: '',
        },
      },
      [BUY_SELL_FORM_OCO]: {
        buy: {
          price: '',
          amount: '',
          total: '',
        },

        sell: {
          price: '',
          amount: '',
          total: '',
        },
      },

      // set new date in ms and when updates - watch
      // and $v.$reset() errors inside BuySellControl
      clearErrorsTimeToWatch: null,
    },
  }),
  mutations: {
    setFeePlc (state, feePlc) {
      state.feePlc = feePlc
    },
    setFeePlcBuy (state, feePlcBuy) {
      state.feePlcBuy = feePlcBuy
    },
    setFee (state, fee) {
      state.fee = fee
    },
    setFeeSettings (state, { response: { fee, fee_plc, fee_plc_buy } }) {
      state.fee = fee
      state.feePlc = fee_plc
      state.feePlcBuy = fee_plc_buy
    },
    updateClearErrorsToWatch (state) {
      state.form.clearErrorsTimeToWatch = new Date().getMilliseconds()
    },

    // INSIDE FORM FIELDS, this changes other form fields

    setBuySellFormPrice (state, { formName, type, value, currentPair }: BuySellMutationsProps) {
      let priceValue = value

      if (value) {
        if (BLOCKED_BUY_SELL_PRICE_TICKERS.includes(currentPair.stockTicker)) {
          priceValue = currentPair.market.price || '0'
        }
      }

      const callback = () => {
        return calculateTotalWithFee({
          tradeType: type,
          total: calculateTotal(priceValue, state.form[formName][type].amount),
          fee: getFeeIncludingPlc(currentPair.stockTicker, state, type),
        })
      }

      updateValue(state.form[formName], type)([['price', priceValue], ['total', callback]])
    },

    setBuySellFormAmount (state, { formName, type, value, currentPair }: BuySellMutationsProps) {
      const callback = () => calculateTotalWithFee({
        tradeType: type,
        total: calculateTotal(value, state.form[formName][type].price),
        fee: getFeeIncludingPlc(currentPair.stockTicker, state, type),
      })

      updateValue(state.form[formName], type)([['amount', value], ['total', callback]])
    },

    setBuySellFormTotal (state, { formName, type, value, currentPair }: BuySellMutationsProps) {
      const callback = () => {
        const price = state.form[formName][type].price
        const fee = getFeeIncludingPlc(currentPair.stockTicker, state, type)
        const total = calculateTotalFromTotalWithoutFee(type, value, fee.toString())

        return isFloat(price) && Number(price)
          ? parseNumber(new Decimal(total || '0').div(parseFloat(price) || 1))
          : '0'
      }

      updateValue(state.form[formName], type)([['total', value], ['amount', callback]])
    },
  },
  actions: {
    // async init({ commit }) {
    //     const { response, errors } = await api.getTradeSettings();

    //     if (errors) {
    //         console.error(errors);
    //         notifyError({
    //             title: i18n.t("notify.uhOh"),
    //             text: i18n.t("notify.tradeSettings"),
    //         });

    //         return;
    //     }

    //     commit("setFee", response!.fee);
    //     commit("setFeePlc", response!.fee_plc);
    //     commit("setFeePlcBuy", response!.fee_plc_buy || 0);
    // },
    getFeeSettings (...args) {
      return actionWrapper<BuySellState>({
        apiRequest: () => this.$api.getTradeSettings(),
        mutationName: 'setFeeSettings',
        cacheTime: 1000 * 60,
      }).bind(this)(...args)
    },
    changeForms ({ dispatch }, { tradeType, fields }) {
      Object.keys(fields).forEach((field) => {
        if (tradeType && tradeTypes.includes(tradeType)) {
          dispatch(`${tradeType}Form/changeField`, {
            field,
            value: fields[field],
          })
          return
        }

        tradeTypes.forEach((key) => {
          dispatch(`${key}Form/changeField`, {
            field,
            value: fields[field],
          })
        })
      })
    },
    resetForms ({ commit }, payload) {
      tradeTypes.forEach((tradeType) => {
        commit(`${tradeType}Form/reset`, {
          clearFields: payload && payload.clearFields,
        })
      })
    },
    // this is TEMP here
    async confirm (
      { getters, rootGetters, dispatch },
      { tradeType },
    ) {
      const amountTicker = rootGetters.amountCurrency
      const totalTicker = rootGetters.totalCurrency
      const amount = getters[`${tradeType}Form/field`]('amount')
      const totalWithFee = formatNumber(
        getters[`${tradeType}Form/field`]('totalWithFee'),
      )
      const translateProps = { amount, amountTicker, totalWithFee, totalTicker }
      const text =
        tradeType === 'buy'
          ? ['common.confirmationModal.buyForText', translateProps]
          : ['common.confirmationModal.sellForText', translateProps]

      const confirm = await ConfirmationModal.open({
        title: `${capitalize(tradeType)} ${amountTicker}`,
        text,
        disclaimer: null,
        confirmationImg: 'buy.svg',
        contentClassName: '',
        hideCancelBtn: false,
        cryptoCard: false,
      })

      if (!confirm) return

      dispatch('submit', { tradeType })
    },
    async submit (
      { rootGetters, commit, getters },
      { tradeType },
    ) {
      commit(`${tradeType}Form/setFetchStatus`, 'loading')

      const { price, amount } = getters[`${tradeType}Form/allFields`]

      const body = {
        price: escapeComma(price.toString()),
        amount: escapeComma(amount.toString()),
        pair: rootGetters['pairs/getCurrentPairName'],
        direction: tradeType,
      }

      const { errors } = await this.$api.postOrder({ body })

      if (errors) {
        console.error(errors)

        Array.isArray(errors.message) &&
          errors.message.map(m =>
            Vue.notify({
              // group: 'error',
              type: 'error',
              title: 'Error',
              text: m,
              // text: 'You must have KYC to invest'
            }),
          )
        commit(`${tradeType}Form/setErrors`, { errors })
        commit(`${tradeType}Form/setFetchStatus`, 'error')
        return
      }

      // notifySuccess({
      //     title: i18n.t("notify.created"),
      //     text: i18n.t("notify.postOrder")
      // });

      commit(`${tradeType}Form/reset`)
      commit(`${tradeType}Form/setFetchStatus`, 'ok')
    },
  },
  // modules: {
  //     ...tradeTypes.reduce((forms, key) => {
  //         forms[`${key}Form`] = new Form({
  //             throttle: 300,
  //             defaultValues: {
  //                 amount: "1"
  //             },
  //             fields: {
  //                 price: {
  //                     type: Number,
  //                     interceptor({ value, commit, fields, rootState, dispatch, meta, rootGetters }) {
  //                         if (!isFloat(value)) {
  //                             return value;
  //                         }

  //                         // for PLC всегда подставлять значение Last price
  //                         const currentPairLeftSide = rootGetters.getCurrentPair.stockTicker // moneyTicker - right side
  //                         if (BLOCKED_BUY_SELL_PRICE_TICKERS.includes(currentPairLeftSide)) {
  //                             value = rootGetters.getCurrentPair.market.price
  //                         }

  //                         // Отображать при продаже PLC комиссию feePlc (15%) а при покупке feePlcBuy
  //                         // в остальніх случаях оставить как біло (просто fee)
  //                         // const fee = (() => {
  //                         //     const { fee, feePlc, feePlcBuy } = rootState.buySell
  //                         //     if (BLOCKED_BUY_SELL_PRICE_TICKERS.includes(currentPairLeftSide)) {
  //                         //         return key === 'sell' ? feePlc : feePlcBuy
  //                         //     }
  //                         //     return fee
  //                         // })()
  //                         const fee = getFeeIncludingPlc(currentPairLeftSide, rootState.buySell, key)

  //                         value = addZeroToValue(value);

  //                         const parsed = new Decimal(escapeComma(value) || "0");
  //                         const total = calculateTotal(parsed, fields.amount);

  //                         const totalWithFee = calculateTotalWithFee({
  //                             tradeType: key,
  //                             total: total,
  //                             fee: fee
  //                         });

  //                         if (key === "buy") {
  //                             commit("setFieldValue", { field: "total", value: total });
  //                             dispatch("changeField", {
  //                                 field: "totalWithFee",
  //                                 value: totalWithFee,
  //                                 noInterceptors: true,
  //                                 noValidate: true,
  //                             });
  //                         } else {
  //                             commit("setData", {
  //                                 fields: {
  //                                     total,
  //                                     totalWithFee
  //                                 }
  //                             });
  //                         }

  //                         return value;
  //                     },
  //                     validators: [required(), float()]
  //                 },
  //                 amount: {
  //                     type: Number,
  //                     interceptor({ value, commit, fields, rootState, dispatch, rootGetters}) {
  //                         if (!isFloat(value)) {
  //                             return value;
  //                         }

  //                         value = addZeroToValue(value);

  //                         const parsed = new Decimal(
  //                             escapeComma(value) || "0"
  //                         );
  //                         const total = calculateTotal(parsed, fields.price);

  //                         // Отображать при продаже PLC комиссию 15%
  //                         const currentPairLeftSide = rootGetters.getCurrentPair.stockTicker
  //                         const fee = getFeeIncludingPlc(currentPairLeftSide, rootState.buySell, key)
  //                         // const fee = key === 'sell' && BLOCKED_BUY_SELL_PRICE_TICKERS.includes(currentPairLeftSide)
  //                         //     ? rootState.buySell.feePlc
  //                         //     : rootState.buySell.fee

  //                         const totalWithFee = calculateTotalWithFee({
  //                             tradeType: key,
  //                             total: total,
  //                             fee: fee
  //                         });

  //                         if (key === "buy") {
  //                             commit("setFieldValue", { field: "total", value: total, noValidate: true });
  //                             dispatch("changeField", {
  //                                 field: "totalWithFee",
  //                                 value: totalWithFee,
  //                                 noInterceptors: true,
  //                                 noValidate: true,
  //                             });
  //                         } else {
  //                             commit("setData", {
  //                                 fields: {
  //                                     total,
  //                                     totalWithFee
  //                                 }
  //                             });
  //                         }

  //                         return value;
  //                     },
  //                     validators: [
  //                         required(),
  //                         float(),
  //                         custom((value, _, rootState, rootGetters) => {
  //                             const pair = rootGetters.getCurrentPair;
  //                             const min = pair.minAmountStock;

  //                             if (
  //                                 isFloat(value) &&
  //                                 new Decimal(escapeComma(value) || '0').lessThan(
  //                                     min
  //                                 )
  //                             ) {
  //                                 return `Min amount is ${min}`;
  //                             }
  //                         }),
  //                         custom((value, fields, rootState, rootGetters) => {
  //                             if (key !== "sell" || !isFloat(value)) {
  //                                 return;
  //                             }

  //                             const sellBalanceTicker = rootGetters.amountCurrency;
  //                             const balance = rootGetters['wallet/getCurrencyAvailableBalance'](
  //                                 sellBalanceTicker
  //                             );

  //                             if (
  //                                 new Decimal(
  //                                     escapeComma(value) || "0"
  //                                 ).greaterThan(balance || "0")
  //                             ) {
  //                                 return i18n.t('trade.buySellControl.amountValueLessMsg')
  //                             }
  //                         })
  //                     ]
  //                 },
  //                 total: {
  //                     type: Number,
  //                     interceptor({value, commit, fields, rootState, dispatch, rootGetters}) {
  //                         if (!isFloat(value)) {
  //                             return value;
  //                         }

  //                         value = addZeroToValue(value);

  //                         const escaped = escapeComma(value) || '0';
  //                         const parsed = new Decimal(escaped);
  //                         const amount =
  //                             isFloat(fields.price) && Number(fields.price)
  //                                 ? parsed.div(
  //                                       parseFloat(fields.price) || 1
  //                                   ).toFixed(8)
  //                                 : new Decimal("0").toFixed(8);

  //                         // Отображать при продаже PLC комиссию 15%
  //                         const currentPairLeftSide = rootGetters.getCurrentPair.stockTicker
  //                         const fee = getFeeIncludingPlc(currentPairLeftSide, rootState.buySell, key)
  //                         // const fee = key === 'sell' && BLOCKED_BUY_SELL_PRICE_TICKERS.includes(currentPairLeftSide)
  //                         //     ? rootState.buySell.feePlc
  //                         //     : rootState.buySell.fee

  //                         const totalWithFee = calculateTotalWithFee({
  //                             tradeType: key,
  //                             total: escaped,
  //                             fee: fee
  //                         });

  //                         // const priceValue = new Decimal(value).dividedBy(fields.amount).toFixed(8)

  //                         if (key === "buy") {
  //                             // commit("setFieldValue", { field: "amount", value: amount });
  //                             commit('setFieldValue', {
  //                                 field: 'amount',
  //                                 value: amount
  //                             })
  //                             dispatch("changeField", {
  //                                 field: "totalWithFee",
  //                                 value: totalWithFee,
  //                                 noInterceptors: true,
  //                                 noValidate: true,
  //                             });
  //                         } else {
  //                             commit("setData", {
  //                                 fields: {
  //                                     totalWithFee,
  //                                     // price: priceValue,
  //                                     amount
  //                                 }
  //                             });
  //                         }

  //                         return value;
  //                     },
  //                     validators: [
  //                         required(),
  //                         float(),
  //                         custom((value,fields, rootState, rootGetters) => {
  //                             if (key === 'sell' || !isFloat(value)) return

  //                             const buyBalanceTicker = rootGetters.totalCurrency;
  //                             const balance = rootGetters['wallet/getCurrencyAvailableBalance'](
  //                                 buyBalanceTicker
  //                             );

  //                             if (
  //                                 new Decimal(
  //                                     escapeComma(value) || "0"
  //                                 ).greaterThan(balance || "0")
  //                             ) {
  //                                 return i18n.t('trade.buySellControl.amountValueLessMsg')
  //                             }
  //                         })
  //                     ]
  //                 },
  //                 totalWithFee: {
  //                     type: Number,
  //                     validators: [
  //                         custom((value, fields, rootState, rootGetters) => {
  //                             if (key !== "buy" || !isFloat(value)) {
  //                                 return;
  //                             }

  //                             value = escapeComma(value);

  //                             const buyBalanceTicker = rootGetters.totalCurrency;
  //                             const balance = rootGetters['wallet/getCurrencyAvailableBalance'](buyBalanceTicker);

  //                             if (new Decimal(value || "0").greaterThan(balance || "0")) {
  //                                 return "Total + fee should not be greater than your balance";
  //                             }
  //                         })
  //                     ],
  //                     interceptor({ value, commit, fields, rootState, rootGetters}) {
  //                         if (!isFloat(value)) {
  //                             return value;
  //                         }

  //                         const currentPairLeftSide = rootGetters.getCurrentPair.stockTicker
  //                         const fee = getFeeIncludingPlc(currentPairLeftSide, rootState.buySell, key)

  //                         const total = calculateTotalFromTotalWithoutFee(escapeComma(value), fee.toString());
  //                         const amount = isFloat(fields.price) && Number(fields.price)
  //                             ? parseNumber(new Decimal(total || '0').div(parseFloat(fields.price) || 1))
  //                             : "0";

  //                         commit("setData", {
  //                             fields: {
  //                                 total,
  //                                 amount
  //                             }
  //                         });

  //                         return value;
  //                     },
  //                 }
  //             },
  //             async onSubmit(
  //                 { commit, dispatch },
  //                 { shouldOpenConfirmation }
  //             ) {
  //                 dispatch(
  //                     shouldOpenConfirmation
  //                         ? "buySell/confirm"
  //                         : "buySell/submit",
  //                     { tradeType: key },
  //                     { root: true }
  //                 );
  //             }
  //         });

  //         return forms;
  //     }, {})
  // }
}

// Отображать при продаже PLC комиссию feePlc (15%) а при покупке feePlcBuy
// в остальніх случаях оставить как біло (просто fee)
export function getFeeIncludingPlc (
  currentPairLeftSide,
  stateBuySell: BuySellState,
  key: string,
) {
  const { fee, feePlc, feePlcBuy } = stateBuySell
  // console.log(fee, feePlc, feePlcBuy)
  if (BLOCKED_BUY_SELL_PRICE_TICKERS.includes(currentPairLeftSide)) {
    return key === 'sell' ? feePlc : feePlcBuy
  }
  return fee
}

export function calculateTotal (
  decimal: Decimal | string,
  value: string,
): string {
  const v = valFromObj(value)
  const escaped = escapeComma(v) || '0'
  return new Decimal(decimal || '0')
    .times(isNaN(parseFloat(escaped)) ? '0' : new Decimal(escaped))
    .toFixed(8)
}

export function calculateTotalFromTotalWithoutFee (
  tradeType: TradeType,
  totalWithFee: string,
  fee: string,
): string {
  return parseNumber(
    // new Decimal(totalWithFee || '0').div(parseFloat(fee) + 1 || "1")
    new Decimal(totalWithFee || '0')
      .div(
        (tradeType === 'sell' ? 1 - parseFloat(fee) : 1) ||
          '1',
      )
      .toFixed(8),
  )
}

export function calculateTotalWithFee ({
  tradeType,
  total,
  fee,
}: {
  tradeType: TradeType
  total: string | Decimal
  fee: string | number
}): string {
  return new Decimal(total || '0')
    .times(tradeType === 'sell' ? fee : 0)
    .times(tradeType === 'sell' ? -1 : 1)
    .plus(total)
    .toFixed(8)
}

// function addZeroToValue (value: string = ''): string {
//   return (value.toString() || '').charAt(0) === '.' ? `0${value}` : value
// }

function updateValue (state, key) {
  return (payload) => {
    const main = Object.fromEntries([payload[0]])
    const add = Object.fromEntries([[payload[1][0], payload[0][1] ? payload[1][1]() : '']])
    Vue.set(state, key, Object.assign(state[key], main, add))
  }
}

export default BuySellModule
