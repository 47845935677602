<template>
  <div class="exc-block-with-image-layout-container">
    <div
      :class="[{'container-new': !noContainer}, containerClasses]"
      class="exc-block-with-image-layout-container__container"
    >
      <div
        :class="{'exc-block-with-image-layout-container--this-goes-first': imageOnLeft, 'exc-block-with-image-layout-container--hide-image-on-mobile': hideImageOnMobile}"
        class="exc-block-with-image-layout-container__image"
      >
        <img
          :src="img"
          :alt="imgAlt"
          :class="{'exc-block-with-image-layout-container--img-full-width': fullWidthOnMobile}"
          :style="{'transform': `scale(${parseFloat(imgScale)})`}"
        >
      </div>
      <div
        :class="{'exc-block-with-image-layout-container--this-goes-first': imageOnRight}"
        class="exc-block-with-image-layout-container__content"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExcBlockWithImageLayoutContainer',
  props: {
    imageOnLeft: {
      type: Boolean,
      default: false,
    },
    imageOnRight: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      required: true,
    },
    imgScale: {
      type: [String, Number],
      default: 1,
    },
    imgAlt: {
      type: String,
      default: null,
    },
    // centerImg: {
    //   type: Boolean,
    //   default: false,
    // },
    hideImageOnMobile: {
      type: Boolean,
      default: false,
    },
    noContainer: {
      type: Boolean,
      default: false,
    },
    fullWidthOnMobile: {
      type: Boolean,
      default: false,
    },
    autoWidthBlocks: {
      type: Boolean,
      default: false,
    },
    contentFirstOnMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    containerClasses () {
      return {
        'exc-block-with-image-layout-container--img-left': this.imageOnLeft,
        'exc-block-with-image-layout-container--img-right': this.imageOnRight,
        'exc-block-with-image-layout-container--auto-width-blocks': this.autoWidthBlocks,
        'exc-block-with-image-layout-container--content-first-on-mobile': this.contentFirstOnMobile,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-block-with-image-layout-container {
  $root: &;

  &--this-goes-first {
    order: -1;
  }

  &--hide-image-on-mobile {
    @include bp(0px, m) {
      display: none !important;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    @include bp(m) {
      flex-direction: row;
      // height: 509px;
    }
    @include bp(l) {
      // height: 709px;
    }
  }

  &--img-left {
    #{$root}__image {
      @include bp(m) {
        // justify-content: center;// flex-start; // try if center will be ok
        justify-content: flex-start;
      }
    }
  }

  &--img-right {
    #{$root}__image {

      @include bp(m) {
        // justify-content: center;// flex-end; // try if center will be ok
        justify-content: flex-end;// flex-end; // try if center will be ok
      }
    }
  }

  &--auto-width-blocks {
    #{$root}__image {
      flex: auto
    }

    #{$root}__content {
      flex: auto;
      max-width: 100%;
    }
  }

  &__image {
    flex: 10;
    display: flex;

    align-items: center;
    @include bp(0px, m) {
      order: -1; // img goes always first on mobile;
      justify-content: center;
    }

    #{$root}--img-full-width {
      max-width: 100vw;

      @include bp(s) {
        max-width: 40vw;
      }
    }

    img {
      width: 100%;
      object-fit: contain;
      max-width: 40vw;
      max-height: 100%;
      @include bp(m) {
        // max-width: 100%;
        width: initial;
        // height: 100%;
        // margin: -30%;
        // max-height: 1000px;
        // max-height: 75vh;
      }
      @include bp(1025px) {
        // height: 100%;
      }
    }
  }

  &__content {
    flex: 9;
    justify-content: center;
    display: flex;
    flex-direction: column;
    // padding: 55px 0 20px;
    padding: 0;
    @include bp(s) {
      // padding: 55px 0 40px;
    }
    @include bp(m) {
      max-width: 50%;
      flex: 0 0 calc(#{$containerMaxWidthNew} - 140px);
      // padding: 55px 0 30px 50px;
      // padding-left: toRem(30px);
    }
  }

  // space between image and text
  &__content#{&}--this-goes-first {
    @include bp(m) {
      // padding-right: toRem(80px);
      padding-left: 0;
    }
  }

  &--content-first-on-mobile {
    #{$root}__image {
      @include bp(0px, m) {
        order: 10; // img goes last on mobile;
      }
    }
  }
}
</style>
