// import { get as _get } from 'lodash'
// import { i18n } from '@/lib/bootstrap'
// import { store } from '..'
// import { getStore } from '@/plugins/extend-context'

// const store = getStore()

// export function loadingGetters() {
//     return {
//         loading: state => ['init', 'loading'].includes(state.fetchStatus),
//         loaded: (state, getters) => !getters.loading,
//     }
// }

export function clearPropsFromEmptyFields (obj) {
  Object.keys(obj).forEach((key) => {
    if ([null, undefined, ''].includes(obj[key])) delete obj[key]
  })
  return obj
}

export function fromToValidator (fromVal, toVal, messageFieldName) {
  if (isNaN(parseFloat(toVal))) return
  const from = parseFloat(fromVal)
  const to = parseFloat(toVal)
  //   if (from > to) return `${i18n.t('p2p.validators.lessOrEqual')} '${messageFieldName}'`
  if (from > to) return `p2p.validators.lessOrEqual '${messageFieldName}'`
}

// // type mapGetSetToStoreParams = { path: string, setter?: string }
// export function mapGetSetToStore (obj) {
//   const result = Object.keys(obj).reduce((acc, key) => {
//     const { path, setter } = obj[key]
//     const getterPath = path + '.' + key
//     const setterPath = path + '.' + setter

//     acc[key] = {
//       get () {
//         return _get(store.state, `${getterPath}`, null)
//       },
//       set (v) {
//         if (setter) {
//           store.commit(`${setterPath.replace(/\./g, '/')}`, v)
//         } else {
//           console.error('You did not specify setter for field', getterPath)
//         }
//       },
//     }
//     return acc
//   }, {})

//   return result
// }

interface FieldSetupProps {
  default: any
  type?: any
  validators?: any[] | Function
  assignValueFn: Function
}

export interface FieldsSetupStructureInterface {
  [key: string]: FieldSetupProps
}

export function destructAndMapFields (formFieldsProps): any {
  return Object.keys(formFieldsProps).reduce((acc, key) => {
    acc[key] = {
      type: formFieldsProps[key].type,
      validators: formFieldsProps[key].validators,
    }
    return acc
  }, {})
}

export function destructAndMapFieldsDefaults (formFieldsProps): any {
  return Object.keys(formFieldsProps).reduce((acc, key) => {
    acc[key] = formFieldsProps[key].default
    return acc
  }, {})
}

export function destructAndMapFieldsEndValues (
  fieldsWithValues,
  formFieldsProps,
): any {
  return Object.keys(formFieldsProps).reduce((acc, key) => {
    const fn = formFieldsProps[key].assignValueFn
    acc[key] = fn(fieldsWithValues[key])
    return acc
  }, {})
}
