import { Module, ModuleTree } from 'vuex'
import { RootState } from '..'
import { statistics } from './statistics'
import { appBorrow } from './appBorrow'
import { appLend } from './appLend'
import { lendBorrowOrder } from './lendBorrowOrder'
import { orderDescription } from './orderDescription'
// import { orderRequest } from './orderRequest'
import { fundsManagement } from './fundsManagement'
import { searchOrder } from './searchOrder'

export interface P2pState {}

const modules: ModuleTree<RootState> = {
  statistics,
  appBorrow,
  appLend,
  lendBorrowOrder,
  orderDescription,
  // orderRequest,
  fundsManagement,
  searchOrder,
}

export const p2p: Module<P2pState, RootState> = {
  namespaced: true,
  modules,
}
