import Vue from 'vue'
import { Module } from 'vuex'
import { merge } from 'lodash'

import { KYC_ADDRESS_INFO_KEY, KYC_PERSONAL_INFO_KEY } from '@/constants'
import { RootState } from '@/store'
// import { $t } from '@/lib/i18n'
import { actionWrapper } from '@/lib/utils'
import { notifyError } from '@/lib/bus'
import { LabeledValue } from '@/data-objects/props/LabeledValue'
import * as fecha from 'fecha'

interface UserInfo {
  firstName: string
  middleName: string
  lastName: string
  gender: string
  birthDay: string
  birthMonth: string
  birthYear: string
  country: string
  city: string
  street: string
  house: string
  apartment: string
  zip: string
}

export interface KycState {
  status: Hash<Hash<boolean | null | string>>
  // documents: Hash<boolean | null>;
  countryList: object[]
  userInfo: UserInfo
  documentsInfo: Hash<File | File[] | null>
}

export const KycStates = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
}

export const KycStatuses = {
  NO_DATE_ON_PAPER: 'NO_DATE_ON_PAPER',
  NO_PAPER: 'NO_PAPER',
  EMPTY_SELFIE: 'EMPTY_SELFIE',
  NO_FRONT_SIDE: 'NO_FRONT_SIDE',
  NO_BACK_SIDE: 'NO_BACK_SIDE',
  UNDER_18: 'UNDER_18',
  OTHER: 'OTHER',
}

export const kycCommonData = Vue.observable({
  fetching: true, // should be true for ssr
})

const KycModule: Module<KycState, RootState> = {
  namespaced: true,
  state: () => ({
    countryList: [],
    status: {
      info: {
        submitted: false,
        approved: false,
      },
      documents: {
        submitted: false,
        approved: false,
      },
      customer: {
        approved: false,
        state: null,
        status: null,
        comment: null,
      },
    },
    userInfo: {
      firstName: '',
      middleName: '',
      lastName: '',
      gender: '',
      birthDay: '',
      birthMonth: '',
      birthYear: '',
      country: '',
      city: '',
      street: '',
      house: '',
      apartment: '',
      zip: '',
    },
    documentsInfo: {
      passport: null,
      nationalPassport: null,
      idCard: null,
      driverLicense: null,
      selfie: null,
    },
  }),
  mutations: {
    setCountryList (state, pld) {
      state.countryList = pld
        ? Object.keys(pld).map(k => new LabeledValue(pld[k], k))
        : [
            new LabeledValue('United States', 'US'),
            new LabeledValue('Ukraine', 'UA'),
          ]
      // NOTE: UA on top
      // state.countryList = pld
      //   ? Object.keys(pld).sort((a, b) => a === 'UA' ? -1 : 0).map(k => (new LabeledValue(pld[k], k)))
      //   : [new LabeledValue('United States', 'US'), new LabeledValue('Ukraine', 'UA')] // TODO: remove temp hardcode
    },
    setKycStatuses (
      state,
      {
        response: {
          passport,
          documents,
          customer,
        } = {
          passport: {},
          documents: {},
          customer: {},
        },
      },
    ) {
      const statuses = {
        info: passport,
        documents,
        customer,
      }
      state.status = merge(state.status, statuses)
    },
    // setDocumentsStatuses (state, { fields }) {
    //   state.documents = assign(state.documents, fields)
    // },
    setUserInfo (state, payload) {
      state.userInfo = {
        ...state.userInfo,
        ...payload,
      }
    },
    setDocumentInfo (state, payload) {
      state.documentsInfo = {
        ...state.documentsInfo,
        ...payload,
      }
    },
    resetDocumentInfo (state) {
      Object.keys(state.documentsInfo).forEach((key) => {
        state.documentsInfo[key] = null
      })
    },
    resetKycCustomerState (state) {
      state.status.info.submitted = false
      state.status.info.approved = false
      state.status.customer.state = null
    },
  },
  actions: {
    actionKycStatus (...args) {
      return actionWrapper({
        beforeRequestHandler: () => {
          kycCommonData.fetching = true
        },
        apiRequest: () => this.$api.getKycStatus(),
        mutationName: 'setKycStatuses',
        successHandler: ({
          context: { commit },
          response: { response },
        }) => {
          if (response?.countryList) {
            commit('setCountryList', response.countryList)
          }
          kycCommonData.fetching = false
        },
        errorHandler: () => {
          kycCommonData.fetching = false
        },
      }).bind(this)(...args)
    },

    updateKycStatus (...args) {
      return actionWrapper({
        apiRequest: () => this.$api.getKycStatus(),
        mutationName: 'setKycStatuses',
      }).bind(this)(...args)
    },

    async submitInfo ({
      commit,
      state,
    }) {
      const birthMonth = +state.userInfo.birthMonth
      const birthDay = +state.userInfo.birthDay
      const birthYear = +state.userInfo.birthYear
      const birthDate = fecha.format(new Date(birthYear, birthMonth, birthDay), 'YYYY-MM-DD')

      const body = {
        first_name: state.userInfo.firstName,
        middle_name: state.userInfo.middleName,
        last_name: state.userInfo.lastName,
        gender: state.userInfo.gender,
        birth_date: birthDate,
        country: state.userInfo.country,
        city: state.userInfo.city,
        street_address: state.userInfo.street,
        house: state.userInfo.house,
        apartment: state.userInfo.apartment,
        postal_code: state.userInfo.zip,
      }
      const { errors } = await this.$api.postPassport({ body })
      if (errors) {
        notifyError({
          duration: 9999,
          text: this.$i18n.t(`${errors}`),
        })
        return Promise.reject(errors)
      }

      commit('setKycStatuses', { response: { passport: { submitted: true } } })
      return Promise.resolve()
    },

    async submitDocuments ({
      commit,
      state,
    }) {
      function appendFileToBody (field: File | Array<File>, name) {
        if (field && (field as Array<File>).length) {
          ;(field as Array<File>).forEach(f => body.append(`${name}[]`, f))
        } else {
          body.append(`${name}`, field as File)
        }
      }

      const body = new FormData()
      for (const document in state.documentsInfo) {
        state.documentsInfo[document] &&
        appendFileToBody(state.documentsInfo[document], document)
      }

      const { errors } = await this.$api.postDocuments({ body })
      if (errors) {
        if (errors.form?.includes?.('validators.user.docs.alreadyExist')) {
          // Reload page to check actual status if documents already exists
          location.reload()
        }

        return { errors }
      }
      location.reload()
      commit('setKycStatuses', { response: { documents: { submitted: true } } })
      return Promise.resolve()
    },

    async submit ({
      commit,
      state,
    }) {
      // notifyInfo({
      //   duration: 3333,
      //   text: this.$i18n.t('user.submitting.info'),
      // })
      const birthMonth = +state.userInfo.birthMonth
      const birthDay = +state.userInfo.birthDay
      const birthYear = +state.userInfo.birthYear
      const birthDate = fecha.format(new Date(birthYear, birthMonth, birthDay), 'YYYY-MM-DD')

      function appendFileToBody (field: File | Array<File>, name) {
        if (field && (field as Array<File>).length) {
          ;(field as Array<File>).forEach(f => body.append(`${name}[]`, f))
        } else {
          body.append(`${name}`, field as File)
        }
      }

      const body = new FormData()
      for (const document in state.documentsInfo) {
        state.documentsInfo[document] &&
        appendFileToBody(state.documentsInfo[document], document)
      }

      const bodyData = {
        first_name: state.userInfo.firstName,
        middle_name: state.userInfo.middleName,
        last_name: state.userInfo.lastName,
        gender: state.userInfo.gender,
        birth_date: birthDate,
        country: state.userInfo.country,
        city: state.userInfo.city,
        street_address: state.userInfo.street,
        house: state.userInfo.house,
        apartment: state.userInfo.apartment,
        postal_code: state.userInfo.zip,
      }
      Object.entries(bodyData).forEach(([k, v]) => body.append(k, v))

      const { errors } = await this.$api.kycVerification({ body })
      if (errors) {
        return { errors }
      }
      // Clear cached data in localStorage
      localStorage.removeItem(KYC_PERSONAL_INFO_KEY)
      localStorage.removeItem(KYC_ADDRESS_INFO_KEY)
      commit('setKycStatuses', {
        response: { statuses: { info: { submitted: true } } },
      })
      commit('setKycStatuses', {
        response: { statuses: { documents: { submitted: true } } },
      })
      return {}
    },
  },
  getters: {
    kycIsVerified: (state) => {
      const info = state.status.info
      const documents = state.status.documents
      const approved = state.status.customer.approved

      return (info.approved && documents.approved) || approved
    },
    kycState: (state) => {
      return state.status.customer.state
    },
    kycStatus: (state) => {
      return state.status.customer.status
    },
    kycStatusComment: (state) => {
      return state.status.customer.comment
    },
    kycCustomer: (state) => {
      return state.status.customer
    },
    kycDocuments: (state) => {
      return state.documentsInfo
    },
  },
}

export default KycModule
