
type functionsObj = { onDisable: Function, onEnable: Function }

export class VisibilityChange {
  visibilityChange: 'visibilitychange' | 'msvisibilitychange' | 'webkitvisibilitychange'
  hidden: 'hidden' | 'msHidden' | 'webkitHidden'
  toggleState: {[key: string]: functionsObj}
  timeout: number // for setTimeout()
  timer: number
  shouldEnable = false

  constructor ({ timer }) {
    this.timer = timer

    this.setNames()
    this.addListener()
    this.toggleState = {}
  }

  // Set the name of the hidden property and the change event for visibility
  setNames () {
    if (process.server) return

    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
      this.hidden = 'hidden'
      this.visibilityChange = 'visibilitychange'
    } else if (typeof (document as any).msHidden !== 'undefined') {
      this.hidden = 'msHidden'
      this.visibilityChange = 'msvisibilitychange'
    } else if (typeof (document as any).webkitHidden !== 'undefined') {
      this.hidden = 'webkitHidden'
      this.visibilityChange = 'webkitvisibilitychange'
    }
  }

  addListener () {
    if (process.server) return

    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === 'undefined' || this.hidden === undefined) {
      console.log('This requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility this.$api.')
    } else {
      // Handle page visibility change
      process.client && document.addEventListener(this.visibilityChange, this.handleVisibilityChange.bind(this), false)
    }
  }

  add (key: string, functionsObj: functionsObj) {
    this.toggleState[key] = functionsObj
  }

  remove (key: string) {
    if (process.server) return

    if (!this.toggleState[key]) {
      console.error(`There is no key "${key}" in`, this.toggleState)
      return
    }
    if (document[this.hidden]) {
      // do not delete, because it should toggle
      return
    }
    delete this.toggleState[key]
  }

  handleVisibilityChange () {
    if (process.server) return

    const isEmpty = () => !Object.keys(this.toggleState).length
    if (isEmpty()) return

    window.clearTimeout(this.timeout)

    if (document[this.hidden]) {
      this.timeout = window.setTimeout(() => {
        // Object.keys(this.toggleState).map(n => console.warn('VisibilityChange onDisable', n))
        if (isEmpty()) return
        Object.values(this.toggleState).map(o => o.onDisable && o.onDisable())
        this.shouldEnable = true
      }, this.timer)
    } else if (this.shouldEnable) {
      this.shouldEnable = false
      // Object.keys(this.toggleState).map(n => console.warn('VisibilityChange onEnable', n))
      Object.values(this.toggleState).map(o => o.onEnable && o.onEnable())
    }
  }
}
