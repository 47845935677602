import Vue from 'vue'
import IMask from 'imask'

export class SimpleForm {
    _fields: object
    validations: object
    fieldNames: string[]
    // initialValues: object

    constructor(fieldsParams) {
        this.fieldNames = Object.keys(fieldsParams)
        this._fields = Vue.observable(fieldsParams)
        const vm = new Vue()
        for (let field in fieldsParams) {
            // this.initialValues[field] = JSON.parse(JSON.stringify(fieldsParams[field].value))
            this[field] = fieldsParams[field]
            this[field].error = null
            if (fieldsParams[field].imask) {
                this._fields[field].masked = IMask.createMask({
                    ...this._fields[field].imask,
                });
                vm.$watch(
                    () => this._fields[field].value,
                    (val, prevVal) => {
                        this.validateField(this._fields[field])
                    },
                    { deep: true }
                )
            } else {
                vm.$watch(
                    () => this._fields[field].value, 
                    () => this.validateField(this[field]), 
                    { deep: true }
                )
            }
        }
        // console.log(this)
    }

    resolveMaskedValue(field) {
        if (typeof field.value !== 'string') {
            console.error(`Value ${field.value} should be a string type`)
        }
        const maskedValue = field.masked.resolve(field.value);
        // const unmaskedValue = field.masked.unmaskedValue
        // const isComplete = field.masked.isComplete
        field.value = maskedValue
    }

    validateField(field) {
        const validators = field.validators || []
        field.error = null
        field.error = validators.map(fn => fn(field.value, this._fields)).find(err => err) || null
        if (field.error === null) {
            if (field.imask) {
                this.resolveMaskedValue(field)
                if (!field.masked.isComplete) {
                    field.error = 'Input mask is not filled'
                }
            }
        }
        return field.error
    }

    getErrors() {
        const errors = this.fieldNames.reduce((acc, field) => {
            const err = this[field].error
            if (err) acc[field] = err
            return acc
        }, {})
        return errors
    }

    validateAllFields() {
        this.fieldNames.map(name => {
            this.validateField(this[name])
        })
    }

    getFieldValues() {
        const fields = this.fieldNames.reduce((acc, val) => {
            acc[val] = this[val].value
            return acc
        }, {})
        return fields
    }

    onSubmit(submitFn) {
        this.validateAllFields()
        
        const fields = this.getFieldValues()
        const errors = this.getErrors()

        if (Object.keys(errors).length) console.error(errors)

        return submitFn({
            isValid: !Object.keys(errors).length,
            errors: errors,
            fields: JSON.parse(JSON.stringify(fields))
        })
    }

    reset() {
        // this.fieldNames.map(name => {
        //     // reset fields
        //     this[name].value = JSON.parse(JSON.stringify(this.initialValues[name]))
        //     // reset errors
        //     this[name].error = undefined
        // })
    }
}