// [TRADE]
export const TRADING_VIEW_ENABLED_ON_PRO_TRADE = true
export const TRADING_VIEW_ENABLED_ON_TRADE = true

// [V2]
export const AUTH_CONTAINER_V2 = true
export const USER_VERIFICATION_V2 = true
export const WITHDRAWAL_LIMIT_V2 = false

// [STAKING / EARH]
export const STAKING_AUTO_WITDWRAWAL_AVAILABLE = false

// [AUTO KYC]
export const AUTO_KYC_LINK = 'https://wrong_url/auto-kyc'
