
<template>
  <div class="exc-breadcrumbs">
    <div class="exc-breadcrumbs__container">
      <span
        v-for="(crumb, ind) in $route.meta.breadcrumbs"
        :key="crumb.name"
        class="exc-breadcrumbs__item"
      >
        <span
          v-if="ind > 0"
          class="txt txt--theme-08 txt--opacity-07 txt--12px"
        >
          &nbsp;>&nbsp;
        </span>
        <exc-link
          v-slot="{ isActive, route }"
          :to="{ ...route, params: $route.params }"
          exact
          no-decoration
          class="txt txt--10px"
        >
          <span
            :class="{
              'txt--opacity-07 txt--theme-08': !isActive,
              'txt--main': isActive,
            }"
          >

            <slot v-bind="{ ...crumb, isActive }" />

          </span>
        </exc-link>
      </span>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import ExcLink from '@/components/common/navigation/link/ExcLink.vue'

// import ExcLink from '../link/ExcLink.vue'

export default {
  name: 'ExcBreadcrumbs',
  components: {
    ExcLink,
    // ExcLink,
  },
}
</script>

<style lang="scss" scoped>
.exc-breadcrumbs {
  $root: &;

  // &__container {}

  // &__item {}
}
</style>
