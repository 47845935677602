// import { $t } from '@/lib/i18n'

export default class TradingCompetitionBase {
  logo: string
  ticker: Ticker
  status: TradingCompStatus

  constructor ({ logo, ticker, status }) {
    this.logo = logo
    this.ticker = ticker
    this.status = status
  }

  get isPending () { return this.status === 'PENDING' }
  get isActive () { return this.status === 'ACTIVE' }
  get isFinished () { return this.status === 'FINISHED' }
  get statusName () {
    return `staking.statuses.${this.status.toLowerCase()}`
  }

  get statusColor () {
    return this.isActive
      ? 'green'
      : this.isFinished
        ? 'grey'
        : this.isPending
          ? 'main'
          : 'red'
  }
}
