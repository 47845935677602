import { Module, MutationTree } from 'vuex'
// import api from '@/lib/api'
import {
  OrderDescriptionModel,
  OrderDescriptionModelInterface,
} from '@/lib/data-models/p2p/OrderDescriptionModel'
import {
  OrderRequestModel,
  OrderRequestModelInterface,
} from '@/lib/data-models/p2p/OrderRequestModel'
import { showP2pError } from '@/lib/bus'
// import { conditionallyAwaited } from '@/lib/utils'
import { RootState } from '..'

interface P2pOrderDescriptionStateInterface {
  descriptionData: { [key: string]: OrderDescriptionModel }
  orderRequests: { [key: string]: OrderRequestModel[] }
}

type SetDescriptionDataPayloadInterface = {
  response: OrderDescriptionModelInterface
  loanId: string | number
}
type SetOrderRequestPayloadInterface = {
  response: OrderRequestModelInterface[]
  loanId: string | number
}

const state: P2pOrderDescriptionStateInterface = {
  descriptionData: {},
  orderRequests: {}, // p2pOrderRequest
}

const actions = {
  async getDescriptionData ({ commit, state }, loanId) {
    const { response, errors } = await this.$api.p2pOrderDescription(loanId)
    if (errors) {
      console.error('api.p2pOrderDescription(loanId)::', errors)
      // notifyError({ text: errors[0], title: 'api.p2pOrderDescription(loanId)' })
      showP2pError(errors)
      return
    }
    commit('setDescriptionData', { response, loanId })
    return state
  },
  async getOrderRequest ({ commit, state }, loanId) {
    const { response, errors } = await this.$api.p2pOrderRequest(loanId)
    if (errors) {
      console.error('api.p2pOrderRequest(loanId)::', errors)
      // notifyError({ text: errors[0], title: 'api.p2pOrderRequest(loanId)' })
      showP2pError(errors)
      return
    }
    commit('setOrderRequest', { response, loanId })
    return state
  },
}
const mutations: MutationTree<P2pOrderDescriptionStateInterface> = {
  setDescriptionData (
    state,
    { response, loanId }: SetDescriptionDataPayloadInterface,
  ) {
    state.descriptionData = {
      ...state.descriptionData,
      [loanId]: new OrderDescriptionModel(response),
    }
  },
  setOrderRequest (
    state,
    { response, loanId }: SetOrderRequestPayloadInterface,
  ) {
    state.orderRequests = {
      ...state.orderRequests,
      [loanId]: response.map(o => new OrderRequestModel(o)),
    }
  },
}
const getters = {
  descriptionDataById: state => id => state.descriptionData[id],
}

export const orderDescription: Module<
  P2pOrderDescriptionStateInterface,
  RootState
> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
