<template>
  <exc-input-label-error-wrapper
    :field="field"
    :label="label"
    :info-message="infoMessage"
    :custom-error="customError"
    :name="name"
    :form-key="formKey"
    :optional-errors="optionalErrors"
    :label-disabled="labelDisabled"
    class="exc-dropdown"
  >
    <template #default="slotProps">
      <!-- eslint-disable vue/valid-v-on -->
      <!-- eslint-disable vue/no-parsing-error  -->
      <exc-base-dropdown
        ref="base-dropdown"
        :value="value"
        v-bind="{ ...$attrs, ...slotProps, size, options }"
        v-on="{ ...$listeners, input: val => $emit('input', val) }"
      >
        <!-- Pass through all slots -->
        <!-- Available slots: "empty", "selected", "icon", "item". -->
        <!-- "selected" and "icon" has defaults -->
        <!-- See in BaseDropdown.vue -->
        <template
          v-for="(_, slot) of $scopedSlots"
          #[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </exc-base-dropdown>
    </template>
  </exc-input-label-error-wrapper>
</template>

<script>
import { sizeValidator, defaultSize } from '@/lib/utils'
import ExcInputLabelErrorWrapper from '@/components/common/form/validation/ExcInputLabelErrorWrapper.vue'
import ExcBaseDropdown from './ExcBaseDropdown.vue'
export default {
  name: 'DropdownSimple',
  components: {
    ExcBaseDropdown,
    ExcInputLabelErrorWrapper,
  },
  props: {
    value: {
      type: [Array, Object],
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    customError: {
      type: String,
      default: '',
    },
    field: {
      type: Object, // vuelidate field
      default: null,
    },
    size: {
      validator: sizeValidator,
      default: defaultSize,
    },
    infoMessage: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => ([]),
    },
    name: {
      type: String,
      default: '',
    },
    formKey: {
      type: String,
      default: '',
    },
    optionalErrors: {
      type: Object,
      default: () => ({}),
    },
    labelDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    resetSearchValue () {
      this.$refs['base-dropdown'].resetSearchValue()
    },
  },
}
</script>
