import Decimal from 'decimal.js-light'
import TradingCompetitionBase from './TradingCompetitionBase'

export class TradingCompetitionModel extends TradingCompetitionBase {
  bestPrize: Decimal
  marketList: PairName[]
  planCount: number
  redirectId: number
  prizeTicker: Ticker
  // statusName: string

  dropdownOptions: { name: string, pairName: PairName }[]

  constructor (d: TradingCompetitionGroupsResponseItem) {
    super(d)

    this.bestPrize = new Decimal(d.bestPrize ?? 0)
    this.marketList = d.marketlist ?? []
    this.planCount = d.plancount
    this.redirectId = d.redirect_id
    this.prizeTicker = d.prizeTicker
    // this.statusName = d.statusName

    this.dropdownOptions = this.marketList.map((pair: PairName) => ({
      name: pair.replace('_', '/'),
      pairName: pair,
    }))
  }
}
