<template>
  <div class="notification-cookies mt-64  sha--0420 bor--radius-10">
    <div class="notification-cookies__container p-16">
      <!-- <div class="notification-cookies__text-container pl-32 pr-32 pt-16 pb-16 pl-0--m pr-0--m mr-32--m"></div> -->
      <!-- <cookies-component class="notification-cookies__cookie" /> -->
      <img
        src="@/assets/icons/cookies/CookiesNew.svg"
        class="notification-cookies__cookie"
        width="64"
        height="64"
        alt="cookie"
      >

      <!-- <p class="notification-cookies__title txt txt--20px txt--color-08 mb-8 txt--center txt--left--m">
          {{ $t('common.notify.useCookie') }}
        </p> -->

      <i18n
        path="common.notify.cookie"
        class="notification-cookies__text txt txt--14px txt--height-18px txt--color-04"
        tag="span"
      >
        <template #url>
          <exc-link
            :to="localePath('/privacy-notice')"
            class="txt txt--color-04 txt--bold"
          >
            {{ $t('common.notify.cookiePolicy') }}
          </exc-link>
        </template>
      </i18n>

      <div class="notification-cookies__button-container">
        <exc-button
          size="s"
          class="txt--on-one-line ml-auto mr-auto ml-0--m"
          style="width: 160px;"
          @click="$emit('close')"
        >
          {{ $t('common.notify.btn') }}
        </exc-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ExcButton, ExcLink } from '@/components/common'

export default {
  name: 'NotificationCookies',
  components: {
    ExcButton,
    ExcLink,
    // CookiesComponent: () =>
    //   import(
    //     /* webpackMode: "eager" */ '@/assets/icons/cookies/CookiesNew.svg?inline'
    //   ),
  },
}
</script>

<style lang="scss" scoped>
.notification-cookies {
  background: white;
  max-width: toRem(800px);

  @include bp(0px, m) {
    border-radius: toRem(30px) toRem(30px) 0 0;
  }

  @include bp(m) {
    margin-left: toRem(60px);
    margin-bottom: toRem(60px);
  }

  &__container {
    display: flex;
    gap: toRem(14px);
    align-items: center;
    flex-direction: column;

    @include bp(m) {
      flex-direction: row;
      gap: toRem(20px);
      align-items: center;
    }
  }

  &__cookie {
    display: block;
    min-width: toRem(90px);
    min-height: toRem(90px);

    @include bp(m) {
      min-width: toRem(64px);
      min-height: toRem(64px);
      // position: absolute;
      // top: space(16);
      // left: toRem(-75px);
      // width: toRem(50px);
    }
  }

  // &__text-container {
  //   position: relative;

  //   @include bp(m, 1271px) {
  //     margin-left: toRem(70px);
  //   }
  // }

  // &__button-container {
  //   display: flex;
  //   align-items: center;
  // }
}
</style>
