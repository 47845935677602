var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-layout",class:{
    'is-dark': _vm.darkTheme,
  },style:(_vm.$store.state.darktheme.variableStyles)},[(!_vm.isHiddenHeader)?_c('div',{staticClass:"base-layout__header",attrs:{"id":"base-header"}},[_c('exchange-header',{class:{
        'base-layout__header-header--application-available': _vm.mobileApplicationAvailable,
      },attrs:{"is-transparent":_vm.isTransparentLayout,"is-trade":_vm.isExchange,"only-theme":"light"},on:{"select-language":function () { return (_vm.selectLanguageModalShown = true); },"show-listing-modal":function () { return (_vm.isShowListingModal = true); }}})],1):_vm._e(),_vm._v(" "),_c('main',{staticClass:"base-layout__main",class:{
      'base-layout__main--no-header': _vm.isHiddenHeader,
      'bg--color-01': _vm.footerWithoutShadow,
      'bg--color-02': !_vm.footerWithoutShadow,
    }},[_c('div',{staticClass:"base-layout__container"},[_c('client-only',[_c('notifications',{staticClass:"base-layout__notifications",attrs:{"position":"top right","width":"min(400px, 98vw)"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
    var item = ref.item;
    var close = ref.close;
return [_c('notification-general',{staticClass:"mb-8 mr-4",attrs:{"item":item},on:{"close":close}})]}}])}),_vm._v(" "),_c('notifications',{attrs:{"group":"bottom","ignore-duplicates":"","position":"bottom center","width":"100%"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
    var item = ref.item;
    var close = ref.close;
return [_c('notification-cookies',{attrs:{"item":item},on:{"close":function($event){return _vm.onClickCloseCookies(close)}}})]}}])})],1),_vm._v(" "),_c('nuxt'),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showScrollBtn),expression:"showScrollBtn"}],staticClass:"base-layout__scroll-btn",on:{"click":_vm.scrollToTop}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12 7L12 21","stroke":"white","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_vm._v(" "),_c('path',{attrs:{"d":"M4 3L20 3","stroke":"white","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_vm._v(" "),_c('path',{attrs:{"d":"M6.99998 12L12.001 6.999L17.001 12","stroke":"white","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])])],1),_vm._v(" "),(!_vm.isHiddenFooter)?_c('div',{staticClass:"base-layout__footer-container",class:{
        'base-layout__footer-container--lifted-up': _vm.footerIsliftedUp,
        // 'sha--03': isExchange,
        'bor bor--top bor--transp-02': _vm.isExchange,
      }},[_c('exchange-footer',{staticClass:"base-layout__footer",class:{
          'sha--03': !_vm.isExchange,
        },attrs:{"only-theme":"light"}})],1):_vm._e()]),_vm._v(" "),_c('confirmation-modal'),_vm._v(" "),_c('select-language-modal',{attrs:{"show":_vm.selectLanguageModalShown},on:{"close":function () { return (_vm.selectLanguageModalShown = false); }}}),_vm._v(" "),_c('apply-for-listing-modal',{attrs:{"show":_vm.isShowListingModal},on:{"close":function () { return (_vm.isShowListingModal = false); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }