import { WebsocketService } from '@/lib/websocket'
import { SocketActionsMapper } from '@/lib/SocketActionMapper'

export default function createWebSocketPlugin (wsService: WebsocketService, actionsMap: SocketActionsMapper) {
  return (store) => {
    if (!wsService || process.server) return
    // wsService.setUrl(store.state.globals.wsUrl) // TODO: WS_ERROR
    wsService.setDispatchEvent((data) => {
      const parsed = JSON.parse(data)
      const { method } = parsed

      const actions = actionsMap.getActions()
      const actionObject = actions[method]

      if (isUpdateEvent(parsed) && actionObject?.action) {
        if (Array.isArray(actionObject?.action)) {
          return actionObject?.action.forEach(actionName => store.dispatch(actionName, (actionObject.params || parsed.params)))
        }

        const callback = (actionObject.action as Function)
        callback((actionObject.params || parsed.params))
      }
    })
  }
}

const isUpdateEvent = (data: any) => {
  return data.method && data.method.includes('update')
  // return data.hasOwnProperty('method') && data.method.includes('update')
}
