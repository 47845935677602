import urlJoin from 'url-join'
interface IMetaProps {
  url?: string
  title?: string
  themeColor?: string
  description?: string
  img?: string
  locale?: string
  // site_name?: string
  twitter_card?: string
  twitter?: string
  width: string
  height: string
}

export function fillMetaTags (customParams: IMetaProps) {
  const defaultParams: IMetaProps = {
    url: process.env.ORIGIN_URL || 'https://c-patex.com',
    title: 'C-Patex Exchange',
    themeColor: '#FFFFFF', // '#1C1D20',
    description: 'Latin American platform for crypto exchange, crypto education and career guidance around the world',
    img: '/image/og-image-new.png',
    locale: 'en',
    // site_name: 'C-Patex',
    twitter_card: 'summary_large_image',
    twitter: 'twitter',
    width: '749',
    height: '400',
  }

  const params = Object.assign(defaultParams, customParams)

  const metaTags = [
    // Global
    { name: 'author', content: params.url },
    { name: 'publisher', content: params.url },
    { name: 'apple-mobile-web-app-title', content: params.title },
    { name: 'theme-color', content: params.themeColor },
    { name: 'description', content: params.description },

    // Facebook & LinkedIn
    { property: 'og:title', content: params.title },
    { property: 'og:description', content: params.description },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: params.url },
    { property: 'og:image', content: urlJoin(params.url, params.img) },
    { property: 'og:image:alt', content: params.title + ' page cover' },
    { property: 'og:locale', content: params.locale },
    { property: 'og:site_name', content: '' },
    { property: 'og:image:width', content: params.width },
    { property: 'og:image:height', content: params.height },

    // Twitter
    { name: 'twitter:card', content: params.twitter_card },
    { name: 'twitter:site', content: params.twitter },
    { name: 'twitter:creator', content: params.twitter },
    { name: 'twitter:title', content: params.title },
    { name: 'twitter:description', content: params.description },
    { name: 'twitter:image', content: urlJoin(params.url, params.img) },
  ]

  // eslint-disable-next-line
  return metaTags.map((tag) => {
    if (tag.content !== undefined && tag.content !== null) {
      if (tag.name) {
        return {
          hid: tag.name,
          name: tag.name,
          content: tag.content,
        }
      } else if (tag.property) {
        return {
          hid: tag.property,
          property: tag.property,
          content: tag.content,
        }
      }
    }
  })
}
