import { render, staticRenderFns } from "./ExcWithSidebarLayout.vue?vue&type=template&id=93df1dba&scoped=true&"
import script from "./ExcWithSidebarLayout.vue?vue&type=script&lang=js&"
export * from "./ExcWithSidebarLayout.vue?vue&type=script&lang=js&"
import style0 from "./ExcWithSidebarLayout.vue?vue&type=style&index=0&id=93df1dba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93df1dba",
  null
  
)

export default component.exports