import { Module } from 'vuex'
import { Decimal } from 'decimal.js-light'
// import { Map } from "immutable"; //
import fromPairs from 'lodash/fromPairs'
import merge from 'lodash/merge'
import toPairs from 'lodash/toPairs'
import { RootState } from '@/store/index'
import { actionWrapper } from '@/lib/utils'
import { ADK_TICKER, DEPOSIT, EDR_TICKER, WITHDRAW } from '@/constants'
// import { notifyError, notifySuccess } from '~/lib/bus'

export type CurrenciesMap = { [key in Ticker]: Currency } | {};

// export type CurrenciesMap = Map<Ticker, Currency>;

export interface CurrencyState {
  data: CurrenciesMap;
  fetchStatus: FetchStatus;
  countryList: { id: Ticker, name: string }[];
}

const CurrencyModule: Module<CurrencyState, RootState> = {
  namespaced: true,
  state: () => ({
    // data: window.TICKER_LIST.reduce((acc, ticker) => {
    //     acc[ticker] = getDefaultCurrency(ticker)
    //     return acc
    // }, {}) as CurrenciesMap,
    data: {},
    // data: window.TICKER_LIST.reduce(
    //     (acc, ticker) => acc.set(ticker, getDefaultCurrency(ticker)),
    //     Map() as CurrenciesMap
    // ),
    fetchStatus: 'init',
    countryList: [],
  }),
  mutations: {
    // setCountryList (state, list) {
    //   state.countryList = Object.keys(list).map((k) => {
    //     return { id: k as Ticker, name: list[k] as string }
    //   })
    // },
    // setFetchStatusAddress(state, { ticker, status }) {
    //     state.data[ticker].fetchStatusAddress = status;
    //     state.data = { ...state.data }

    //     // state.data = state.data.setIn(
    //     //     [ticker, "fetchStatusAddress"],
    //     //     status,
    //     // );
    // },
    // setHeightFetchStatus (state, { ticker, status }) {
    //   state.data[ticker].fetchStatusHeight = status
    //   state.data = { ...state.data }

    //   // state.data = state.data.setIn(
    //   //     [ticker, "fetchStatusHeight"],
    //   //     status,
    //   // );
    // },
    // setFeeFetchStatus (state, { ticker, method, status }) {
    //   state.data[ticker][method].fetchStatus = status
    //   state.data = { ...state.data }

    //   // state.data = state.data.setIn(
    //   //     [ticker, method, "fetchStatus"],
    //   //     status,
    //   // );
    // },
    // setTickersImg (state, { tickerImages }) {
    //   Object.keys(tickerImages).forEach((ticker) => {
    //     state.data[ticker].img = tickerImages[ticker] || '/img/balance/coinDefaultIcon.png'
    //   })
    //   state.data = { ...state.data }

    //   // state.data = Object.keys(tickerImages)
    //   //     .reduce((acc, ticker) => acc.setIn(
    //   //         [ticker, "img"],
    //   //         tickerImages[ticker] || '/img/balance/coinDefaultIcon.png'
    //   //     ), state.data);
    // },
    // setFetchStatus (state, { status }) {
    //   state.fetchStatus = status
    // },
    // setAddress (state, { ticker, address, memo, system_address }) {
    //   state.data[ticker] = {
    //     ...state.data[ticker],
    //     address,
    //     system_address,
    //     memo: memo || null,
    //   }
    //   state.data = { ...state.data }

    //   // state.data = state.data.updateIn([ticker], value => ({
    //   //     ...value,
    //   //     address,
    //   //     system_address,
    //   //     memo: memo || null,
    //   // }));
    // },
    // setEDRMemo (state, { memo }) {
    //   state.data[EDR_TICKER].memo = memo || null
    //   state.data = { ...state.data }

    //   // state.data = state.data.setIn([EDR_TICKER, "memo"], memo || null);
    // },
    // setHeight (state, { ticker, height }) {
    //   state.data[ticker].height = height || null
    //   state.data = { ...state.data }

    //   // state.data = state.data.setIn([ticker, "height"], height || null);
    // },
    // setFee (state, { ticker, method, data }) {
    //   state.data[ticker][method] = {
    //     ...state.data[ticker][method],
    //     ...data,
    //   }
    //   state.data = { ...state.data }

    //   // state.data = state.data.updateIn([ticker, method], value => ({
    //   //     ...value,
    //   //     ...data,
    //   // }));
    // },
    setAllFee (state, { response }) {
      const data = parseCurrenciesFee(response)
      state.data = merge({ ...state.data }, data)

      // state.data = state.data.mergeDeep(data);
    },
    // setData (state, response) {
    //   const parsed = parseRawCurrencies(response, state)
    //   state.data = merge({ ...state.data }, parsed)

    //   // state.data = state.data.mergeDeep(parsed);
    // },
  },
  getters: {
    currency: state => (ticker: Ticker) => {
      const fromState = state.data[ticker]
      // const fromState = state.data.get(ticker)
      const defaultCurr = getDefaultCurrency(ticker)
      return fromState || defaultCurr
    },
    formattedFee: (_, getters) => ({ ticker, method }) => {
      const { fixed, flex } = getters.currency(ticker)[method]

      if (!fixed && !flex.percent) {
        return null
      }

      const fixedFormatted =
        fixed !== null ? `${parseFloat(fixed)} ${ticker}` : ''
      const flexLimits = [
        flex.minFee !== null
          ? `Min: ${parseFloat(flex.minFee)} ${ticker}`
          : null,
        flex.maxFee !== null
          ? `Max: ${parseFloat(flex.maxFee)} ${ticker}`
          : null,
      ].filter(s => s)
      const flexFormatted =
        flex.percent !== null
          ? `${parseFloat(flex.percent)}% ${
            flexLimits.length ? `(${flexLimits.join(', ')})` : ''
          }`
          : ''
      const concatenated = [fixedFormatted, flexFormatted]
        .filter(s => s)
        .join(' + ')

      return concatenated
    },
    // getFee: (_, getters) => ({ ticker, method, amount }) => {
    //   const { fixed, flex } = getters.currency(ticker)[method]
    //   const parsed = new Decimal(amount || '0')
    //   const calculatedFlex = new Decimal(flex.percent || '0')
    //     .div(100)
    //     .times(parsed)
    //   const flexFee =
    //     flex.minFee !== null && calculatedFlex.lessThan(flex.minFee)
    //       ? flex.minFee
    //       : flex.maxFee !== null && calculatedFlex.greaterThan(flex.maxFee)
    //         ? flex.maxFee
    //         : calculatedFlex
    //   return {
    //     fixed: fixed || 0,
    //     flexFee: (new Decimal(flexFee)).toNumber(),
    //   }
    // },
    // calculateWithFee: (_, getters) => ({ ticker, method, amount }) => {
    //   const parsed = new Decimal(amount || '0')
    //   const fee = getters.getFee({ ticker, method, amount })
    //   const result = parsed.minus(fee.fixed || '0').minus(fee.flexFee)
    //   return result.greaterThanOrEqualTo('0') ? result : new Decimal('0')
    // },
    currenciesFee (state) {
      // first three
      const order = ['BTC', 'ETH', 'USD'].reverse()

      function withOrder (pr, nx) {
        const prIndex = order.findIndex(t => t === pr.ticker)
        const nxIndex = order.findIndex(t => t === nx.ticker)
        return nxIndex - prIndex
      }

      function byTicker ({ ticker: a }, { ticker: b }) {
        return a > b ? 1 : -1
      }

      return Object.keys(state.data)
        .map(k => state.data[k])
        .sort(byTicker)
        .sort(withOrder)
    },
  },
  actions: {
    // async fetchCurrencyData (
    //   { dispatch },
    //   { ticker, method, isFiat, type },
    // ) {
    //   if (method === 'deposit' && !isFiat) {
    //     await dispatch('getAddress', ticker)
    //   }

    //   if (!isFiat) {
    //     await dispatch('fetchHeight', { ticker })
    //   }

    //   await dispatch('getFeeByMethod', { ticker, method, type })
    // },
    // async createNewAddress ({ commit }, { ticker, system_address }) {
    //   const { response, errors } = await this.$api.postCurrencyAddress({
    //     body: { ticker, system_address },
    //   })

    //   if (errors) {
    //     notifyError({
    //       title: this.$i18n.t('common.notify.badRequest.title'),
    //       text: this.$i18n.t('common.notify.badRequest.text'),
    //     })
    //     return
    //   }

    //   if (ticker !== EDR_TICKER) {
    //     commit('setAddress', {
    //       ticker,
    //       address: response!.address,
    //       system_address: response!.system_address,
    //     })
    //   } else {
    //     commit('setEDRMemo', {
    //       ticker,
    //       memo: response!.address,
    //     })
    //   }
    //   notifySuccess({
    //     title: this.$i18n.t('common.notify.createdNewAddress.title'),
    //     text: this.$i18n.t('common.notify.createdNewAddress.text'),
    //   })
    // },
    // async getAddress ({ commit }, ticker: Ticker) {
    //   let res

    //   if (ticker !== EDR_TICKER) {
    //     res = await this.$api.getCurrencyAddress({
    //       ticker,
    //     })
    //   } else {
    //     res = await this.$api.getEDRAddress()
    //   }

    //   const { response, errors } = res

    //   if (errors) {
    //     notifyError({
    //       title: this.$i18n.t('common.notify.warning'),
    //       text: this.$i18n.t('common.notify.cantGetAddress', { ticker }),
    //     })
    //     commit('setFetchStatusAddress', {
    //       ticker,
    //       status: 'error',
    //     })

    //     return
    //   }

    //   commit('setAddress', {
    //     ticker,
    //     system_address: response!.system_address, // for isMemo ? or ONFO
    //     address: response!.address || response!.account, // account for EDR
    //     memo: response!.memo, // for EDR,
    //   })
    // },
    // async fetchHeight ({ commit, getters }, { ticker }) {
    //   if (
    //     getters.currency(ticker).fetchStatusHeight === 'ok' ||
    //     ticker === ADK_TICKER
    //   ) {
    //     return
    //   }

    //   commit('setHeightFetchStatus', {
    //     ticker,
    //     status: 'loading',
    //   })

    //   const { response, errors } = await this.$api.getCurrencyBlockHeight({
    //     ticker,
    //   })

    //   if (errors) {
    //     notifyError({
    //       title: this.$i18n.t('common.notify.cantGetHeight'),
    //     })
    //     commit('setHeightFetchStatus', {
    //       ticker,
    //       status: 'error',
    //     })

    //     return
    //   }

    //   commit('setHeight', { ticker, height: response!.height })
    //   commit('setHeightFetchStatus', {
    //     ticker,
    //     status: 'ok',
    //   })
    // },
    // async getFeeByMethod ({ commit, getters }, { ticker, method, type }) {
    //   const currency = getters.currency(ticker)

    //   if (currency[method].fetchStatus === 'ok' && !type) {
    //     return
    //   }

    //   commit('setFeeFetchStatus', {
    //     ticker,
    //     method,
    //     status: 'loading',
    //   })

    //   const { response, errors } = await this.$api.getCurrencyFeeByMethod({
    //     ticker,
    //     method,
    //     type,
    //   })

    //   if (errors) {
    //     commit('setFeeFetchStatus', {
    //       ticker,
    //       method,
    //       status: 'error',
    //     })
    //     console.error(errors)

    //     return
    //   }

    //   commit('setCountryList', response.countryList)

    //   // for advCache fiat withdrawal
    //   const { advWithdrawlFee } = response
    //   if (advWithdrawlFee) {
    //     commit('setFee', {
    //       ticker,
    //       method: 'advWithdrawal',
    //       data: {
    //         max: advWithdrawlFee.adv_withdrawl_max,
    //         min: advWithdrawlFee.adv_withdrawl_min,
    //         percent: advWithdrawlFee.adv_withdrawl_percent,
    //         fixed: advWithdrawlFee.adv_withdrawl_statick,
    //       },
    //     })
    //   }

    //   commit('setFee', {
    //     ticker,
    //     method,
    //     data: {
    //       fixed: response!.fixed,
    //       flex: response!.flex,
    //       min: response!.minAmount,
    //       max: response!.maxAmount,
    //     },
    //   })

    //   commit('setFeeFetchStatus', {
    //     ticker,
    //     method,
    //     status: 'ok',
    //   })
    // },
    getFeeByAllCurrencies (...args) {
      return actionWrapper({
        apiRequest: () => {
          return this.$api.getCurrencyFee()
        },
        mutationName: 'setAllFee',
      }).bind(this)(...args)
    },
  },
}

function getDefaultCurrency (ticker: Ticker): Currency {
  return {
    id: 0,
    ticker: ticker || ('' as Ticker),
    isWithdrawal: false,
    isDepositable: false,
    fetchStatusAddress: 'init',
    fetchStatusHeight: 'init',

    address: null,
    img: '',

    // specific for EDR
    memo: null,

    height: 0,
    deposit: {
      fetchStatus: 'init',
      min: null,
      max: null,
      fixed: null,
      flex: {
        minFee: null,
        maxFee: null,
        percent: null,
      },
    },
    withdraw: {
      fetchStatus: 'init',
      min: null,
      max: null,
      fixed: null,
      flex: {
        minFee: null,
        maxFee: null,
        percent: null,
      },
    },
  }
}

export function parseRawCurrencies (response: RawBalance[], state: CurrencyState): CurrenciesMap {
  return response.reduce(
    (acc, { ticker, currency_id, is_withdrawal, is_depositable, is_int }) => {
      const tickerFromState = state.data[ticker]
      const d = {
        ...getDefaultCurrency(ticker), // stay as it was, in order to create new props
        ...tickerFromState, // overwrite data default data with already existing data
        id: currency_id,
        isInt: !!is_int,
        ticker: ticker as Ticker,
        isWithdrawal: Boolean(is_withdrawal),
        isDepositable: Boolean(is_depositable),
      } as Currency
      acc[ticker] = d
      return acc
    }, {} as any,
  )
}

export function parseCurrenciesFee (response: GetCurrencyFeeResponse) {
  return fromPairs(toPairs(response!)
    .map(([ticker, data]) => [ticker, {
      ticker,
      deposit: {
        max: data[DEPOSIT].maxAmount,
        min: data[DEPOSIT].minAmount,
        fixed: data[DEPOSIT].fixed,
        flex: {
          minFee: data[DEPOSIT].flex.minFee,
          maxFee: data[DEPOSIT].flex.maxFee,
          percent: data[DEPOSIT].flex.percent,
        },
      },
      withdraw: {
        max: data[WITHDRAW].maxAmount,
        min: data[WITHDRAW].minAmount,
        fixed: data[WITHDRAW].fixed,
        flex: {
          minFee: data[WITHDRAW].flex.minFee,
          maxFee: data[WITHDRAW].flex.maxFee,
          percent: data[WITHDRAW].flex.percent,
        },
      },
      isFiat: data.isFiat,
    }]),
  )
}

export default CurrencyModule
