import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
// import { $t } from '@/lib/i18n'
import { RootState } from '../index'

// this.$i18n.t
export const allItemForSelectCurrency = {
  get name () { return 'otc.buttons.select' },
  id: 'all',
}

type CurrenciesList = { [key in string]: otcCurrenciesResponse }

export interface OtcCurrenciesState {
  fiat: CurrenciesList
  crypto: CurrenciesList
  all: CurrenciesList
  selectedFiat: otcCurrenciesResponse
  selectedCrypto: otcCurrenciesResponse
}

const state = () => ({
  fiat: {},
  crypto: {},
  all: {},

  selectedFiat: (allItemForSelectCurrency as unknown) as otcCurrenciesResponse,
  selectedCrypto: (allItemForSelectCurrency as unknown) as otcCurrenciesResponse,
}) as OtcCurrenciesState

const actions: ActionTree<OtcCurrenciesState, RootState> = {
  async getCurrencies ({ commit }) {
    const { response, errors } = await this.$api.otcGetCurrencies()

    if (errors) {
      console.error(errors)
      return
    }

    commit('setCurrencies', response)
  },
}

const mutations: MutationTree<OtcCurrenciesState> = {
  setCurrencies (state, pld: otcCurrenciesResponse[]) {
    const fiat = pld.reduce((acc, c: otcCurrenciesResponse) => {
      if (c.type === 'fiat') acc[c.ticker] = c
      return acc
    }, {})

    const crypto = pld.reduce((acc, c: otcCurrenciesResponse) => {
      if (c.type === 'crypto') acc[c.ticker] = c
      return acc
    }, {})

    state.fiat = fiat
    state.crypto = crypto
  },
  setSelectedFiat (state, pld) {
    state.selectedFiat = pld
  },
  setSelectedCrypto (state, pld) {
    state.selectedCrypto = pld
  },
  resetSelectedFilters (state) {
    state.selectedCrypto = (allItemForSelectCurrency as unknown) as otcCurrenciesResponse
    state.selectedFiat = (allItemForSelectCurrency as unknown) as otcCurrenciesResponse
  },
}

const modules = {}

const getters: GetterTree<OtcCurrenciesState, RootState> = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules,
} as Module<OtcCurrenciesState, RootState>
