import { Module } from 'vuex'
import { RootState } from '@/store/index'
import merge from 'lodash/merge'
import { Decimal } from 'decimal.js-light'
import { parseDepth } from '@/store/trade'
import { LabeledValue } from '@/data-objects/props/LabeledValue'
import Vue from 'vue'
import { ORDER_BUY, ORDER_SELL } from '@/constants'

type PricesMap = {
  [key in PairName]: { price: string; }
}

export interface ExchangeState {
  fetchStatus: FetchStatus;
  data: PricesMap;
  direction: number;
}

// Instead of ExchangeForm common fields
export const exchangeCurrencies = Vue.observable({
  giveCurrency: new LabeledValue('', ''),
  receiveCurrency: new LabeledValue('', ''),
  giveAmount: '',
  receiveAmount: '',
  exchangePair: null,
})

const ExchangeModule: Module<ExchangeState, RootState> = {
  namespaced: true,
  state: () => ({
    fetchStatus: 'init',
    data: {} as PricesMap,
    direction: ORDER_SELL,
  }),
  mutations: {
    setFetchStatusPrices (state, status: FetchStatus) {
      state.fetchStatus = status
    },
    setMarketPrice (state, market) {
      state.data = merge({ ...state.data }, market)
    },
    setDirection (state, direction) {
      state.direction = direction
    },
  },
  getters: {
    getCurrentPrice: state => (pair) => {
      return state.data[pair] ? state.data[pair].price : '0'
    },
  },
  actions: {
    socketPairsStateUpdate: {
      root: true,
      async handler ({ commit }, payload: UpdateMarketResponseParams) {
        const [pair, data] = payload
        const price = data.last
        commit('setMarketPrice', { [pair]: { price } })
      },
    },
    async fetchMarketPrice ({ commit }, pair) {
      const { response: price, errors } = await this.$api.$getPrice(pair)
      if (errors) {
        console.error(pair)
        console.error(errors)
        return
      }

      commit('setMarketPrice', { [pair]: { price } })
    },
    priceUpdate: {
      root: true,
      async handler ({ commit }, payload: UpdatePriceResponseParams) {
        const [pair, price] = payload
        commit('setMarketPrice', { [pair]: { price } })
      },
    },
    async changeDirection ({ state, commit, getters, dispatch }) {
      const direction = state.direction === ORDER_SELL ? ORDER_BUY : ORDER_SELL
      commit('setDirection', direction)

      const fields = getters['exchangeForm/allFields']

      dispatch('exchangeForm/setData', {
        fields: {
          give: fields.receive,
          receive: fields.give,
          giveCurrency: fields.receiveCurrency,
          receiveCurrency: fields.giveCurrency,
        },
      })
    },
    async getDepth ({ commit }, pair) {
      const { response, errors } = await this.$api.$getDepth({ pair: pair as PairName, limit: 50, offset: 50 })

      if (errors) {
        console.error(errors)
        return
      }

      const orders = parseDepth(response!)
      commit('trade/updatePairDepth', { pairName: pair, orders, shouldReplace: false }, { root: true })
    },
  },
}

export default ExchangeModule

export function depthTotal (pair, rootGetters, isSell) {
  const column = isSell ? 1 : 2
  return rootGetters[`${isSell ? 'buy' : 'sell'}Depth`](pair)
    .reduce((acc: Decimal, order) => acc.add(order[column]), new Decimal('0'))
    .toString()
}
