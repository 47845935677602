import { Module } from 'vuex'
import { actionWrapper } from '@/lib/utils'

// Interfaces
export interface FaqState {
  faq: FaqGroup[],
  faqWelcome: FaqGroup[]
}

// Module
const FaqModule: Module<FaqState, any> = {
  namespaced: true,

  state: () => ({
    faq: [],
    faqWelcome: [],
  }),

  mutations: {
    SET_FAQ (state, { response }) {
      state.faq = response?.groups?.filter((group: FaqGroup) => group.posts.length) || []
    },
    SET_FAQ_WELCOME (state, { response }) {
      state.faqWelcome = response?.groups?.filter((group: FaqGroup) => group.posts.length) || []
    },
  },

  getters: {
    getFaqs: state => (count: number | null) => {
      return count === null
        ? state.faq
        : state.faq.slice(0, count)
    },
    getFaqsWelcome: state => (count: number | null) => {
      return count === null
        ? state.faqWelcome
        : state.faqWelcome.slice(0, count)
    },
  },

  actions: {
    actionGetFaq: actionWrapper<FaqState>({
      apiRequest () {
        return this.$api.getFaqList({
          language: this.$i18n.locale || 'en',
          lang: this.$i18n.locale || 'en',
        })
      },
      // For cache of selected language on 5m
      payloadParser () {
        return { language: this.$i18n.locale || 'en' }
      },
      cacheTime: 5 * 60 * 1000, // 5m
      mutationName: 'SET_FAQ',
    }),
    getFaqListForWelcome: actionWrapper<FaqState>({
      apiRequest () {
        return this.$api.getFaqListForWelcome({
          language: this.$i18n.locale || 'en',
          lang: this.$i18n.locale || 'en',
        })
      },
      // For cache of selected language on 5m
      payloadParser () {
        return { language: this.$i18n.locale || 'en' }
      },
      mutationName: 'SET_FAQ_WELCOME',
    }),
  },
}

export default FaqModule
