// import Vue from 'vue'
// import Vuex from 'vuex'

import ws from '@/lib/websocket'
import { SocketActionsMapper } from '@/lib/SocketActionMapper'
import createWebSocketPlugin from '@/lib/createWebSocketPlugin'

import { FaqState } from './faq'
import { TradeState } from './trade'
// import confirmation, { ConfirmationState } from './confirmation'
import { ProfileState } from './profile'
import { SettingsModules } from './settings'
import { PairsState } from './pairs'
// import tabs, { TabsState } from './tabs'
// import applyToList, { ApplyToListState } from './applyToList'
// import partnership, { PartnershipState } from './partnership'
// import support, { SupportState } from './support'
import { TicketState } from './ticket'
import { NewTicketModalState } from './newTicketModal'
// import depositModal, { DepositModalState } from './depositModal'
// import transferModal, { TransferModalState } from './transferModal'
// import withdrawModal, { WithdrawModalState } from './withdrawModal'
import { CurrencyState } from './currency'
import { WelcomeState } from './welcome'
import { BuySellState } from './buySell'
// import gridLayout, { GridLayoutState } from './gridLayout'
// import { TweetsState } from './tweets'
import { ExchangeState } from './exchange'
import { DarkThemeState } from './darktheme'
// import { subscribeLocaleChange } from '@/lib/localization'
import { StakingGroupState } from './stakingPool'
import { ieoState } from './ieo'
import { ChatState } from './chat'
// import { P2pState } from './p2p'
// import { p2pNewState } from './p2pNew'
// import { OtcState } from './otc'
// import { IBurningStageState } from './burningStage'
// import { StableState } from './stable'
import { ListingCardsState } from './listing'
// import { TradingCompetitionState } from './tradingCompetition'
import { WalletState } from './wallet'
import { GlobalsState } from './globals'
import { UiState } from './ui'
import { LatamexExchangeState } from './latamexExchange'
import { CryptoCardsState } from './cryptoCards'
import { clearCachedData } from '~/lib/utils'

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $cookies: any
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $cookies: any
  }
}

const actionsMap = {
  'asset.update': ['wallet/socketUpdateBalancesAction'],
  'asset_margin.update': ['wallet/socketUpdateMarginBalance'],
  'deals.update': ['trade/socketMarketHistoryUpdate'],
  'depth.update': ['trade/socketOrderBookUpdate'],
  'order.update': ['profile/orders/socketOrderUpdate'],
  'today.update': [
    'pairs/socketPairsStateUpdate',
    'welcome/socketPairsStateUpdate',
  ],
  'market.update': ['pairs/socketMarketUpdate'],
  'kline.update': [''], // пустое - там нету дефолтных методов, для этого респонса вешается обработчик в контроллере графика
}
export const actionsMapService = process.client
  ? new SocketActionsMapper(actionsMap)
  : null

export const plugins = process.client
  ? [
      createWebSocketPlugin(ws, actionsMapService),
      (store) => {
        // ws.setUrl(store.state.globals.wsUrl) // TODO: WS_ERROR
        ws.onReconnect(() => {
          if (window.LOGINED) {
            store.dispatch('profile/orders/fetchWsProfileOrders', {
              pairName: store.getters['pairs/getCurrentPairName'],
              offset: 0,
            })
            // store.dispatch('fetchTradeBalances') // TODO: REMOVE
            store.dispatch('wallet/getWalletBalances')
          }
        })

        // subscribeLocaleChange(() => {
        // window.FORMS.forEach(f => {
        //     const dirty = store.getters[`${f}/dirty`]

        //     if (dirty) {
        //         store.dispatch(`${f}/validateAll`)
        //     }
        // })
        // })
      },
    ]
  : []
// Vue.use(VueI18n)

// export const i18n = new VueI18n({
//   fallbackLocale: 'en',
// })

// Vue.use(Vuex)
// // import VuexActionLogger from 'vuex-action-logger'
// // Vue.use(VuexActionLogger(Vuex))

export interface RootState {
  // p2p: P2pState
  // p2pNew: p2pNewState
  ieo: ieoState
  faq: FaqState
  cryptoCards: CryptoCardsState
  trade: TradeState
  profile: ProfileState
  settings: SettingsModules
  pairs: PairsState
  // confirmation: ConfirmationState
  // tabs: TabsState
  // applyToList: ApplyToListState
  // partnership: PartnershipState
  // support: SupportState
  ticket: TicketState
  newTicketModal: NewTicketModalState
  // depositModal: DepositModalState
  // transferModal: TransferModalState
  // withdrawModal: WithdrawModalState
  currency: CurrencyState
  buySell: BuySellState
  welcome: WelcomeState
  // gridLayout: GridLayoutState
  // tweets: TweetsState
  exchange: ExchangeState
  chat: ChatState
  darktheme: DarkThemeState
  // stable: StableState
  // otc: OtcState
  stakingPool: StakingGroupState
  // tradingCompetition: TradingCompetitionState
  // burningStage: IBurningStageState
  listing: ListingCardsState
  ui: UiState
  latamexExchange: LatamexExchangeState
  wallet: WalletState
  globals: GlobalsState
  auth: any
}

export const mutations = {
  resetData () {
    // reset data in store and cache to default
    // after logout (for example)
    clearCachedData()
    this.commit('profile/finishedOrders/resetData')
  },
}

export const actions = {
  nuxtServerInit ({ commit }, { req }) {
    commit('darktheme/init')
  },
}

// export const store = new Vuex.Store<any>({
//   modules: {
//     ieo,
//     faq,
//     trade,
//     profile,
//     settings,
//     pairs,
//     // confirmation,
//     // tabs,
//     // applyToList,
//     // partnership,
//     // support,
//     ticket,
//     stable,
//     newTicketModal,
//     // depositModal,
//     // transferModal,
//     // withdrawModal,
//     currency,
//     buySell,
//     // @TODO add welcome!
//     welcome,
//     tweets,
//     exchange,
//     // gridLayout,
//     darktheme,
//     chat,
//     p2p,
//     p2pNew,
//     otc,
//     stakingPool,
//     tradingCompetition,
//     burningStage,
//     listing,
//     ui,
//     globals,
//     wallet,
//   },
//   plugins,
// })

// export default () => store
