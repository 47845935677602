













































































































































































































import Vue from 'vue'

export default Vue.extend({
  name: 'ExcNoItems',
  props: {
    // isDarkTheme: { type: Boolean, default: true },
    message: { type: String, default: '' },
  },
})
