import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/autoKyc.ts'), 'autoKyc.ts')
  resolveStoreModules(require('../store/burningStage.ts'), 'burningStage.ts')
  resolveStoreModules(require('../store/buySell.ts'), 'buySell.ts')
  resolveStoreModules(require('../store/chat.ts'), 'chat.ts')
  resolveStoreModules(require('../store/cryptoCards.ts'), 'cryptoCards.ts')
  resolveStoreModules(require('../store/currency.ts'), 'currency.ts')
  resolveStoreModules(require('../store/darktheme.ts'), 'darktheme.ts')
  resolveStoreModules(require('../store/exchange.ts'), 'exchange.ts')
  resolveStoreModules(require('../store/faq.ts'), 'faq.ts')
  resolveStoreModules(require('../store/globals.ts'), 'globals.ts')
  resolveStoreModules(require('../store/ieo.ts'), 'ieo.ts')
  resolveStoreModules(require('../store/latamexExchange.ts'), 'latamexExchange.ts')
  resolveStoreModules(require('../store/listing.ts'), 'listing.ts')
  resolveStoreModules(require('../store/newTicketModal.ts'), 'newTicketModal.ts')
  resolveStoreModules(require('../store/nft.ts'), 'nft.ts')
  resolveStoreModules(require('../store/pairs.ts'), 'pairs.ts')
  resolveStoreModules(require('../store/stable.ts'), 'stable.ts')
  resolveStoreModules(require('../store/stakingPool.ts'), 'stakingPool.ts')
  resolveStoreModules(require('../store/tdxManager.ts'), 'tdxManager.ts')
  resolveStoreModules(require('../store/ticket.ts'), 'ticket.ts')
  resolveStoreModules(require('../store/trade.ts'), 'trade.ts')
  resolveStoreModules(require('../store/tradingCompetition.ts'), 'tradingCompetition.ts')
  resolveStoreModules(require('../store/tweets.ts'), 'tweets.ts')
  resolveStoreModules(require('../store/ui.ts'), 'ui.ts')
  resolveStoreModules(require('../store/vesting.ts'), 'vesting.ts')
  resolveStoreModules(require('../store/wallet.ts'), 'wallet.ts')
  resolveStoreModules(require('../store/welcome.ts'), 'welcome.ts')
  resolveStoreModules(require('../store/settings/index.ts'), 'settings/index.ts')
  resolveStoreModules(require('../store/profile/index.ts'), 'profile/index.ts')
  resolveStoreModules(require('../store/p2pNew/index.ts'), 'p2pNew/index.ts')
  resolveStoreModules(require('../store/p2p/index.ts'), 'p2p/index.ts')
  resolveStoreModules(require('../store/otc/index.ts'), 'otc/index.ts')
  resolveStoreModules(require('../store/lib/helpers.ts'), 'lib/helpers.ts')
  resolveStoreModules(require('../store/otc/otcAdmin.ts'), 'otc/otcAdmin.ts')
  resolveStoreModules(require('../store/otc/otcBuySell.ts'), 'otc/otcBuySell.ts')
  resolveStoreModules(require('../store/otc/otcCurrency.ts'), 'otc/otcCurrency.ts')
  resolveStoreModules(require('../store/otc/otcPayments.ts'), 'otc/otcPayments.ts')
  resolveStoreModules(require('../store/p2p/appBorrow.ts'), 'p2p/appBorrow.ts')
  resolveStoreModules(require('../store/p2p/appLend.ts'), 'p2p/appLend.ts')
  resolveStoreModules(require('../store/p2p/fundsManagement.ts'), 'p2p/fundsManagement.ts')
  resolveStoreModules(require('../store/p2p/lendBorrowOrder.ts'), 'p2p/lendBorrowOrder.ts')
  resolveStoreModules(require('../store/p2p/orderDescription.ts'), 'p2p/orderDescription.ts')
  resolveStoreModules(require('../store/p2p/orderRequest.ts'), 'p2p/orderRequest.ts')
  resolveStoreModules(require('../store/p2p/searchOrder.ts'), 'p2p/searchOrder.ts')
  resolveStoreModules(require('../store/p2p/statistics.ts'), 'p2p/statistics.ts')
  resolveStoreModules(require('../store/p2pNew/admin.ts'), 'p2pNew/admin.ts')
  resolveStoreModules(require('../store/p2pNew/loans.ts'), 'p2pNew/loans.ts')
  resolveStoreModules(require('../store/profile/auth.ts'), 'profile/auth.ts')
  resolveStoreModules(require('../store/profile/codes.ts'), 'profile/codes.ts')
  resolveStoreModules(require('../store/profile/deposits.ts'), 'profile/deposits.ts')
  resolveStoreModules(require('../store/profile/finishedOrders.ts'), 'profile/finishedOrders.ts')
  resolveStoreModules(require('../store/profile/helpers.ts'), 'profile/helpers.ts')
  resolveStoreModules(require('../store/profile/levels.ts'), 'profile/levels.ts')
  resolveStoreModules(require('../store/profile/orders.ts'), 'profile/orders.ts')
  resolveStoreModules(require('../store/profile/trades.ts'), 'profile/trades.ts')
  resolveStoreModules(require('../store/profile/withdrawals.ts'), 'profile/withdrawals.ts')
  resolveStoreModules(require('../store/settings/api.ts'), 'settings/api.ts')
  resolveStoreModules(require('../store/settings/kyc.ts'), 'settings/kyc.ts')
  resolveStoreModules(require('../store/settings/referral.ts'), 'settings/referral.ts')
  resolveStoreModules(require('../store/settings/security.ts'), 'settings/security.ts')
  resolveStoreModules(require('../store/settings/verification.ts'), 'settings/verification.ts')
  resolveStoreModules(require('../store/otc/otcQuotations/index.ts'), 'otc/otcQuotations/index.ts')
  resolveStoreModules(require('../store/otc/otcOrders/index.ts'), 'otc/otcOrders/index.ts')
  resolveStoreModules(require('../store/otc/otcAdminOrders/index.ts'), 'otc/otcAdminOrders/index.ts')
  resolveStoreModules(require('../store/lib/vuex-form/index.ts'), 'lib/vuex-form/index.ts')
  resolveStoreModules(require('../store/lib/vuex-form/form.ts'), 'lib/vuex-form/form.ts')
  resolveStoreModules(require('../store/lib/vuex-form/SimpleForm.ts'), 'lib/vuex-form/SimpleForm.ts')
  resolveStoreModules(require('../store/lib/vuex-form/validators.ts'), 'lib/vuex-form/validators.ts')
  resolveStoreModules(require('../store/otc/mixin-modules/paginationInfo.ts'), 'otc/mixin-modules/paginationInfo.ts')
  resolveStoreModules(require('../store/otc/otcAdminOrders/paginationInfo.ts'), 'otc/otcAdminOrders/paginationInfo.ts')
  resolveStoreModules(require('../store/otc/otcOrders/paginationInfo.ts'), 'otc/otcOrders/paginationInfo.ts')
  resolveStoreModules(require('../store/otc/otcQuotations/paginationInfo.ts'), 'otc/otcQuotations/paginationInfo.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
