import Vue from 'vue'
import { escapeLocaleFromRoute } from '@/lib/utils'

Vue.use({
  install (Vue) {
    Vue.mixin({
      computed: {
        $routePath () {
          return escapeLocaleFromRoute(this.$route.path)
        },
      },
      methods: {
        localePush (location) {
          this.$router.push(this.localeLocation(location))
        },
      },
    })
  },
})

// Fix so that the page does not change when you change the language. You may need to add for some cases
const localizationGuard = (to, from, next) => {
  const allowedPages = [
    'index',

    'login',
    'register',
    'reset-password',
  ]

  const _getRouteUniqueKey = (route = {}) => {
    const name = (route.name || 'index').split('__')[0]
    return Object.keys(route.params || {}).length
      ? [name, JSON.stringify(route.params)].join('__')
      : name
  }

  const _compareRoutes = (to, from) => {
    if (!to.name || !from.name) return false

    return _getRouteUniqueKey(to) === _getRouteUniqueKey(from)
  }

  if (_compareRoutes(to, from) && allowedPages.includes(_getRouteUniqueKey(to))) {
    // mandatory sequence
    next(false)
    return window.history.pushState(null, null, to.fullPath)
  }

  return next()
}

export default ({ app }) => app.router.beforeEach(localizationGuard)
