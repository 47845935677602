var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tdx-table",class:_vm.classes},[(_vm.tableTitle)?_c('div',{staticClass:"tdx-table__title"},[_c('h2',{staticClass:"txt--weight-700 txt--22px txt--height-26px txt--26px--l txt--height-34px--l txt--color-04",class:[
        _vm.cellSize === 's'
          ? 'pr-12 txt--14px txt--height-20px txt--24px--l txt--height-32px--l'
          : 'pr-20 txt--22px txt--height-26px txt--26px--l txt--height-34px--l' ]},[_vm._v("\n      "+_vm._s(_vm.tableTitle)+"\n    ")]),_vm._v(" "),_vm._t("widgets")],2):_vm._e(),_vm._v(" "),_c('div',{ref:"table",staticClass:"tdx-table__container",class:_vm.wrapperClasses},[_c('table',[_c('thead',{staticClass:"txt--color-03 txt--weight-400 txt--14px txt--height--18px"},[_c('tr',_vm._l((_vm.heading),function(th,ind){return _c('th',{key:ind,staticClass:"txt--weight-400 tdx-table__cell",class:[
              'tdx-table__head',
              ("tdx-table__head-" + ind),
              Object.assign({}, {'tdx-table--col-with-sorting': !!_vm.sorting[ind]},
                // 'tdx-table__hide-column-mobile': th.hideOnMobile,
                _vm.cellSizeClass,
                {'tdx-table__cell--adaptive': _vm.adaptive,
                'tdx-table__head-custom': th.isCustom}) ],on:{"click":function($event){!!_vm.sorting[ind] && _vm.onClickTh(ind)}}},[_c('span',{staticClass:"tdx-table__heading-container"},[(Array.isArray(_vm.heading))?_vm._t(("th-" + ind),function(){return [_vm._v("\n                "+_vm._s(_vm.compColumnValue(th))+"\n              ")]},null,{ heading: _vm.heading, specifiedSorting: _vm.specifiedSorting }):_vm._t(("th-" + ind),function(){return [_vm._v("\n                "+_vm._s(_vm.compColumnValue(_vm.heading[ind]))+"\n              ")]},null,{ heading: _vm.heading, specifiedSorting: _vm.specifiedSorting }),_vm._v(" "),_c('span',{staticClass:"tdx-table__arrow-container"},[(!!_vm.sorting[ind] && !!_vm.specifiedSorting[ind])?_c('exc-dropdown-triangle',{staticClass:"tdx-table__arrow",attrs:{"direction":_vm.specifiedSorting[ind] === _vm.DESC ? 'bottom' : 'top'}}):_vm._e()],1)],2)])}),0)]),_vm._v(" "),(_vm.rows.length)?_c(_vm.animated ? 'transition-group' : 'tbody',{tag:"tbody",staticClass:"txt--color-04 txt--16px txt--height--20px txt--weight-500",attrs:{"name":_vm.animated ? 'sort-list' : undefined}},_vm._l((_vm.rows),function(row,index){return _c('tr',{key:row.id || index,staticClass:"tdx-table__row txt--14px txt--height--18px txt--weight-500",class:[
            row.rowClass,
            {
              visible: _vm.isVisible[("row" + index)],
              'tdx-table__row--adaptive': _vm.adaptive,
            } ],on:{"click":function($event){return _vm.$emit('select-row', { row: row, index: index })},"mouseover":function($event){return _vm.$emit('on-mouse-over', row)},"mouseout":function($event){return _vm.$emit('on-mouse-out', row)}}},[_vm._l((_vm.heading),function(_,indKey){return _c('td',{key:indKey,staticClass:"tdx-table__cell",class:[
              ("tdx-table__cell-" + indKey),
              row[indKey] ? row[indKey].class : '',
              Object.assign({}, {'tdx-table__active-row bg--main':
                  indKey === _vm.firstColumnName &&
                  _vm.activeRow.id &&
                  _vm.activeRow.id === row.id,
                // 'tdx-table__hide-column-mobile': heading[indKey].hideOnMobile,
                'tdx-table__cell-custom': _vm.heading[indKey].isCustom},
                _vm.cellSizeClass,
                {'tdx-table__cell--adaptive': _vm.adaptive}) ],attrs:{"data-label":_vm.compColumnValue(_vm.heading[indKey]) || _vm.compColumnValue(row.custom)}},[(Array.isArray(_vm.heading))?_vm._t('td-' + (indKey + 1),null,null,row):_vm._t('td-' + indKey,function(){return [_vm._v("\n              "+_vm._s(_vm.compColumnValue(row[indKey]))+"\n            ")]},null,row)],2)}),_vm._v(" "),(_vm.showMobileToggle)?_c('td',{staticClass:"\n              txt--main\n              txt--14px\n              txt--height-18px\n              txt--underline\n              tdx-table__toggle tdx-table__cell\n            ",class:Object.assign({}, _vm.cellSizeClass,
              {'tdx-table__cell--adaptive': _vm.adaptive}),on:{"click":function($event){return _vm.toggleAllOptionsVisibility(index)}}}):_vm._e()],2)}),0):_vm._e(),_vm._v(" "),(!_vm.loading && !_vm.rows.length)?_c('tbody',[_c('tr',{staticClass:"tdx-table__no-items-row bor--top bor--transp-01"},[_c('td',{staticClass:"tdx-table__no-items-cell txt--16px txt--height--20px txt--color-04 txt--weight-700 txt--center pt-24",attrs:{"colspan":"999"}},[_vm._v("\n            "+_vm._s(_vm.emptyMessage)+"\n          ")])])]):_vm._e()],1),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"tdx-table__loading ml-auto mt-32 mb-20 mr-auto txt--center"},[_c('exc-loader',{staticClass:"txt--main mt-32"})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }